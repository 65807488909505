import { Model } from '@vuex-orm/core';
import _ from "lodash";

export default class Gameboard extends Model {
  static entity = 'gameboards';

  static fields() {
    return {
      id: this.attr(null),
      training_session_data_id: this.attr(null),
      team_id: this.attr(null),

      land_n_building: this.attr(null),
      suppliers: this.attr(null),
      customers: this.attr(null),
      investors: this.attr(null),
      bank: this.attr(null),

      receivables: this.attr(null),
      cash: this.attr(null),
      payables: this.attr(null),
      loans: this.attr(null),
      pfme: this.attr(null),

      rnd: this.attr(null),
      depreciation: this.attr(null),
      ffoh: this.attr(null),
      moge: this.attr(null),
      interest: this.attr(null),

      tax: this.attr(null),
      dividends: this.attr(null),
    };
  }

  getValue(column, type = '*') {
    let value = 0;
    const items = this[column];
    if (items && items.length > 0) {
      let filteredItems = [];
      if (type === '*') {
        filteredItems = items;
      } else if (type != null) {
        filteredItems = _.filter(items, v => v.type === type);
      } else {
        filteredItems = _.filter(items, v => v.type == null);
      }
      value = _.sumBy(filteredItems, 'value');
    }
    return value;
  }
}
