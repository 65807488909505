<template>
<div class="Section_Payables">
    <img src="../../assets/Gameboard/Sections/section_payables.png" class="PlayerBoard_Payables">


    <div v-if="vDebug" class="debug">
        <div>
            payables: {{ payables }}<br>
            payables_owe: {{ payables_owe }}<br>
            <button v-if="payables_canPay" @click="payPayables">Pay</button>
        </div>
    </div>

    <div v-if="payables_owe !== payables" class="payable_box" ref="Payable_Box">
        <CoinIndicator :pass_currency_type="11" :pass_cash="payables_owe" :pass_debt_offset="true" />
    </div>
</div>
</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue'
import { mapGetters } from "vuex";
import Profitability from '@/profitability-nestle-js';

export default {
    components: { CoinIndicator },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vGameboard: 'gameboard',
            vPhase: 'phase',
        }),
        payables() {
            return this.vGameboard?.getValue('payables', 'paid');
        },
        payables_owe() {
            return this.vGameboard?.getValue('payables', 'owe');
        },
        payables_actualOwe() {
            return this.payables_owe - this.payables;
        },
        payables_canPay() {
            if (this.vPhase.market_phase === 0 && this.vPhase.phase_step === 2) {
                return this.payables_actualOwe > 0;
            }
            return false;
        },
    },
    methods: {
        payPayables() {
            const actualOweAmount = this.payables_actualOwe;
            if (actualOweAmount === 0) return;

            // TODO: amount is actually defined by user at 'cash'
            // so amount need to be pass in to backend to check if amount is correct or not
            const amount = actualOweAmount; // for now we simply use actual owe amount

            // TODO: you can add a frontend-validation here when player drag the wrong amount
            if (actualOweAmount !== amount) {
                // TODO: warning?
                alert("Please pay exact amount")
                return;
            }

            Profitability.Participant.payPayables(amount);
        }
    },
}
</script>

<style>
    .Section_Payables{
        top: 76%;
        left: 43.25%;
        position: fixed;
        width: 7.3%;
        height: 20.5%;
    }
    .PlayerBoard_Payables{
        top: 76%;
        left: 43.25%;
        position: fixed;
        width: 7.3%;
        height: 20.5%;
    }

    .Section_Payables > .debug {
        position: absolute;
        inset: 0;
        margin-top: 1.5rem;
        pointer-events: none;

        display: flex;
        font-weight: bold;
        color: black;
    }

    .Section_Payables > .debug button {
        pointer-events: auto;
    }

    .payable_box {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .payable_amt {
        right: 48.5%;
        top: 73.35%;
    }
</style>
