<template>
<div class="Section_Payables">
    <img src="../../assets/Gameboard/Sections/section_payables.png" class="PlayerBoard_Payables">
</div>
</template>

<script>

</script>

<style>
    .Section_Payables{
        top: 76%;
        left: 43.25%;
        position: fixed;
        width: 7.3%;
        height: 20.5%;
    }
    .PlayerBoard_Payables{
        top: 76%;
        left: 43.25%;
        position: fixed;
        width: 7.3%;
        height: 20.5%;
    }

    .Section_Payables > .debug {
        position: absolute;
        inset: 0;
        margin-top: 1.5rem;
        pointer-events: none;

        display: flex;
        font-weight: bold;
        color: black;
    }

    .Section_Payables > .debug button {
        pointer-events: auto;
    }

    .payable_box {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .payable_amt {
        right: 48.5%;
        top: 73.35%;
    }
</style>
