export default class Auth {
  context = null;

  constructor(context) {
    this.context = context;
  }

  get axios() {
    return this.context.axios;
  }

  /**
   *
   * @param {String} credentials.username
   * @param {String} credentials.password
   */
  login(credentials) {
    return this.axios.post('/api/login', credentials).then((response) => {
      const { data } = response;
      localStorage.setItem('profitability:auth', JSON.stringify(data));
      this.context.setAuthToken(data.access_token);
      this.context._user = data.user;

      return this.context._onLoggedIn().then(() => {
        return response;
      });
    });
  }

  logout() {
    return this.axios.post('/api/logout').then(() => {
      this.axios.token = null;
      this.context._user = null;
      localStorage.removeItem('profitability:auth');
      localStorage.clear();
    });
  }

  me() {
    return this.axios.get('/api/me').then(({ data }) => data);
  }
}
