<style lang="scss">
.delivery_standalone.form_panel {
    z-index: 15;
    top: 22%;

    &.Delivery_1 {
        background-image: url('../../assets/Gameboard/Panel/Form_DeliveryForm_NC.png');
    }

    &.Delivery_2 {
        background-image: url('../../assets/Gameboard/Panel/Form_DeliveryForm_NG.png');
    }

    &.Delivery_3 {
        background-image: url('../../assets/Gameboard/Panel/panel_delivery_record3.png');

        .DL_Period{
            position: absolute;
            width: 10%;
            height: 5%;

            color:black;
            text-align: center;
            font-size: 22px;

            &.Net_Sales{
              &.NC{left: 58.25%; top: 19.7%;}
              &.NG{left: 80%; top: 19.7%;}
            }
            &.Goods_Sold{
              &.NC{left: 58.25%; top: 27.2%;}
              &.NG{left: 80%; top: 27.2%;}
            }
            &.Marginal_Contribution{
              &.NC{left: 58.25%; top: 34.5%;}
              &.NG{left: 80%; top: 34.5%;}
            }
        }
    }

    &.frame_border {
        border: 5px solid white;
        border-radius: 8px;
    }

    .DL_Input{
        font-family: NestleBrush_reg;
        font-size: 22px;
        position: absolute;
        width: 10%;
        height: 5%;
        border: none;
        outline: none;

        background-color: transparent;
        outline: none;
        text-align: center;

        &.Stock_Produced{left: 40.8%; top: 6.6%;}
        &.Stock_Copacked{left: 70.45%; top: 6.6%;}
        &.Units_Sold{left: 70.5%; top: 34%;}
        &.Selling_Price{left: 70.5%; top: 40.8%;}
        &.Sales{left: 70.5%; top: 47.5%;}
        &.Produced_Units{left: 41.2%; top: 60.3%;}
        &.Copacked_Units{left: 41.2%; top: 67.3%;}
        &.Total_Cost{left: 70.5%; top: 74.3%}
        &.Margins_Made{left: 70.5%; top: 81.3%}
    }

    .MarketCard_Button {
        position: absolute;
        top: 14%;
        left: 2%;
        width: 35%;
        height: 5%;

        color: white;
        font-size: 20px;
        border-radius: 30px;
        border-color: transparent;
    }
    .MarketCard_Button.classic {
        background: #e1261d;
    }
    .MarketCard_Button.classic:hover {
        background: #9e1a15;
    }
    .MarketCard_Button.gold {
        color: black;
        background: #ffd751;
    }
    .MarketCard_Button.gold:hover {
        background: #b39638;
    }
    .MarketCard_Button.clicked {
        color: white;
        background: #392c23;
    }

    .cards-container {
        position: absolute;
        top: 0;
        left: -73%;
        width: 70%;
        height: 80%;
        background: #63513d;
        border-radius: 15px;
    }

    .cards-wrapper {
        position: absolute;
        padding: 15px;
        top: 2.5%;
        left: 4%;
        width: 92%;
        height: 95%;
        background: #392c23;
        border-radius: 15px;
        overflow-y: auto;
    }

    .marketcard {
        height: 170px;
        width: 255px;
        margin-bottom: 15px;

        p {
            margin-left: 22px;
            margin-top: -5px;
        }
    }
    .marketcard_classic.overlay {
        position: absolute;
        height: 170px;
        width: 255px;
        margin-bottom: 15px;
        z-index: 20;

        background: url('../../assets/Gameboard/Panel/Overlay_MarketCard_DeliveredNC.png');
        background-size: 100% 100%;
    }
    .marketcard_gold.overlay {
        position: absolute;
        height: 170px;
        width: 255px;
        margin-bottom: 15px;
        z-index: 20;

        background: url('../../assets/Gameboard/Panel/Overlay_MarketCard_DeliveredNG.png');
        background-size: 100% 100%;
    }

    .Close_Button{
        bottom: 3.4%;
        left: 35%;
        position: absolute;
        width: 31%;
        height: 5%;

        background: #a4d65d;
        font-size: 23px;
        border-radius: 30px;
        border-color: transparent;
    }
    .Close_Button:hover {
        background: #729541;
    }
    .Close_Button:active {
        background: #392c23;
        color: white;
    };
    .Delivered_Button {
        color: white;
        font-size: 19px;
        border-radius: 30px;
        background: none;
        position: relative;
        width: 74%;
        height: auto;
        padding: 0.7% 0;
        top: 90%;
        left: 0%;
    }
    .Delivered_Button.classic{
        border: 4px solid #e1261d;
    }
    .Delivered_Button.classic:hover{
        border: 4px solid #9e1a15;
    }
    .Delivered_Button.classic:active {
        border: 4px solid #9e1a15;
        background: #392c23;
    }
    .Delivered_Button.gold {
        border: 4px solid #ffd751;
    }
    .Delivered_Button.gold:hover {
        border: 4px solid #b39638;
    }
    .Delivered_Button.gold:active {
        border: 4px solid #b39638;
        background: #392c23
    }

    .money_collected_btn {
        bottom: 5%;
        left: 10%;
        position: absolute;
        width: 80%;
        height: 6%;

        background: #a4d65d;
        border-radius: 30px;
        font-size: 18px;
        border-color: transparent;
    }
    .money_collected_btn:hover {
        background: #729541;
    }
    .money_collected_btn:active {
        background: #392c23;
        color: white;
    }

    .delivery_num {
        text-align: left;
        color: black;
        position: absolute;
        top: 28%;
        left: 3.6%;
        width: 50%;
        font-size: 30px;
    }
    .delivery_num.white {
        color: white;
    }
    .delivery_card {
        position: absolute;
        top: 47.85%;
        right: 5%;
        background: url("../../assets/Gameboard/Panel/panel_delivery_cash.png");
        background-size: 100% 100%;
        width: 12%;
        height: 4.7%;
    }
    .delivery_card.credit {
        background: url("../../assets/Gameboard/Panel/panel_delivery_credit.png");
        background-size: 100% 100%;
    }


    .debug {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        font-weight: bold;
        color: black;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .answer_check {
        background: url("../../assets/Gameboard/Period/checked_step_white.png");
        background-size: 100% 100%;
        position: absolute;
        right: 14%;
        width: 7%;
        height: 3.5%;
        z-index: 5;

        &.p_units { top: 61.5%; right: 44%; }
        &.co_units { top: 68.5%; right: 44%; }
        &.total_cost { top: 75.5%; }
        &.margins_made { top: 82.2%; }
    }
}

</style>

<template>
    <div class="delivery_standalone form_panel Delivery_1" v-if="!is_final_page" :class="{frame_border: phase === 4}">
        <p class="delivery_num">Delivery #{{index + 1}}</p>

        <div class="delivery_card"></div>

        <input
            class="DL_Input Stock_Produced" disabled
            :value="0"
        >
        <input
            class="DL_Input Stock_Copacked" disabled
            :value="5"
        >

        <input
            class="DL_Input Units_Sold" disabled
            :value="5"
        >
        <input
            class="DL_Input Selling_Price" disabled
            :value="5"
        >
        <input
            class="DL_Input Sales" disabled
            :value="25"
        >

        <input
            class="DL_Input Produced_Units" placeholder="0"
            v-model.number="rawUnit"
            type="number"
            maxlength="3"
            @focus="$event.target.select()"
            @change="check_input"
        >
        <input
            class="DL_Input Copacked_Units" placeholder="0"
            v-model.number="coPackUnit"
            type="number"
            maxlength="3"
            @focus="$event.target.select()"
            @change="check_input"
        >
        <input
            class="DL_Input Total_Cost"
            maxlength="3"
            v-model.number="totalCost_input"
            @focus="$event.target.select()"
            @change="check_input"
        >
        <input
            class="DL_Input Margins_Made"
            maxlength="3"
            v-model.number="marginsMade_input"
            @focus="$event.target.select()"
            @change="check_input"
        >

        <div v-if="check.p_units" class="answer_check p_units"></div>
        <div v-if="check.co_units" class="answer_check co_units"></div>
        <div v-if="check.totalCost" class="answer_check total_cost"></div>
        <div v-if="check.marginsMade" class="answer_check margins_made"></div>

        <button class="Delivered_Button classic" @click="deliver">{{ is_delivered ? is_delivered_text : not_delivered_text}}</button>
    </div>

    <div v-else class="delivery form_panel Delivery_3" :class="{frame_border: phase === 4}">

        <p class="DL_Period Net_Sales NC">25</p>
        <p class="DL_Period Goods_Sold NC">20</p>
        <p class="DL_Period Marginal_Contribution NC">5</p>

        <p class="DL_Period Net_Sales NG">0</p>
        <p class="DL_Period Goods_Sold NG">0</p>
        <p class="DL_Period Marginal_Contribution NG">0</p>

        <button class="Close_Button" @click="close">Close</button>

        <div v-if="vDebug" class="debug">
            <div>Phase: {{form_phase}}</div>
            <div>Index: {{index}}</div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Profitability from '@/profitability-nestle-js';

// The cost constants
const costs = {
    classic: {
        raw: 2,
        coPack: 4,
    },
    gold: {
        raw: 4,
        coPack: 7,
    },
};

export default {
    props: {
        phase: Number,
        cards: Object,
        customer_cash: Number,
        customer_credit: Number
    },
    emits: [
        'close',
    ],
    data() {
        return {
            deliveryClass: {
                classic: 'Delivery_1',
                gold: 'Delivery_2',
            },

            delivered_type: {
                classic: "classic",
                gold: "gold"
            },

            // determine which marketCards is currently 'deliver'
            index: 0,

            // form value
            rawUnit: 0,
            coPackUnit: 0,
            totalCost_input: null,
            marginsMade_input: null,

            delivery_form_classic: {
                net_sales: 0,
                goods_sold: 0,
                marginal_contribution: 0
            },
            delivery_form_gold: {
                net_sales: 0,
                goods_sold: 0,
                marginal_contribution: 0
            },
            is_delivered: false,
            not_delivered_text: "Deliver",
            is_delivered_text: "Move chips to cash / receivables",
            isMoneyCollectedData: true,
            is_final_page: false,
            check: {},
            form_phase: 0,
        };
    },
    computed: {
        classicRawCount() {
            return this.cards.raw.classic;
        },
        classicCoPackCount() {
            return this.cards.copack.classic;
        },
        totalCost() {
            return 20;
        },
        marginsMade() {
            return 5;
        },
        is_marketing_phase() {
            return this.phase == 4;
        },
    },
    mounted() {
        if (this.phase === 4) {
            if (!this.cards.copack.classic)
                this.is_final_page = true
        }
    },
    methods: {
        deliver() {
            // check if fulfill card amount
            if (this.phase === 4) {
                if (this.coPackUnit !== this.cards.copack.classic) {
                    // console.log('incorrect amount');
                    return;
                }
            } else {
                if (this.rawUnit !== this.cards.raw.classic) {
                    // console.log('incorrect amount');
                    return;
                }
            }

            if (this.totalCost_input != this.totalCost) {
                alert("Total Cost value is wrong. Try again")
                return;
            }

            if (this.marginsMade_input !== this.marginsMade) {
                alert("Margins Made value is wrong. Try again")
                return;
            }

            // check if enough stock
            if (this.rawUnit > this.cards.raw.classic) {
                // console.log('insufficient raw stock');
                return;
            }
            if (this.coPackUnit > this.cards.copack.classic) {
                // console.log('insufficient coPack stock');
                return;
            }

            if (this.customer_cash == 0 && this.customer_credit == 0) {
                this.is_delivered = true;

                this.$emit("Deliver_Copack")
                this.is_final_page = true
            }
        },
        check_input() {
            this.check.p_units = this.rawUnit == 0
            this.check.co_units = this.coPackUnit == 5
            this.check.totalCost = this.totalCost_input == this.totalCost
            this.check.marginsMade = this.marginsMade_input == this.marginsMade
        },
        close() {
            this.$emit('close');
        },
        release_btn() {
            if (this.customer_cash == 0 && this.customer_credit == 0) {
                this.isMoneyCollectedData = true;
            }
        },
    },
}
</script>
