<template>
  <div class="Standalone" :style="cssProps">
    <Background />
    <div class="Panel_PlayerBoard">
      <h1>Team A</h1>

      <MBS
        :phase="standalone_data.phase"
        :cash_stored="standalone_data.cash_stored"
        @canMovePhase="canMovePhase"
      />

      <SectionCash
        ref="SectionCash"
        :phase="standalone_data.phase"
        :cash_stored="standalone_data.cash_stored"
        @withdrawal="Withdrawal"
        @withdraw_cash="Withdraw_Cash"
        @cash_put_in="Cash_Put_In"
      />

      <SectionExpenses
        ref="SectionExpenses"
        :phase="standalone_data.phase"
        :expenses="standalone_data.expenses"
        @Pay_Expense="Pay_Expense"
      />

      <SectionLand
        ref="SectionLand"
        :phase="standalone_data.phase"
        @to_land="to_land"
      />

      <SectionLoans
        ref="SectionLoans"
        :phase="standalone_data.phase"
        :loan="standalone_data.loan"
        @Pay_Loan="Pay_Loan"
      />

      <SectionPayables ref="SectionPayables" />

      <SectionPlot
        ref="SectionPlot"
        :phase="standalone_data.phase"
        :factory="standalone_data.factory"
        :dep="standalone_data.expenses.depreciation"
        @buy_wip="buy_wip"
        @Buy_Classic_Plot="Buy_Classic_Plot"
        @Pay_Classic_WIP="Pay_Classic_WIP"
        @Depreciate_Line="Depreciate_Line"
      />

      <SectionExternalLocations
        ref="SectionExternalLocations"
        @open_supplier_panel="ReturnPanelClicked"
        @open_bank_button="ReturnPanelClicked"
        @from_investor="from_investor"
        @Pay_Copack="Pay_Copack"
        @Pay_Raw="Pay_Raw"
        @Collect_Copack_Receive="Collect_Copack_Receive"
        @Loan_To_Cash="Loan_To_Cash"
        :phase="standalone_data.phase"
        :investorCash="standalone_data.investor_cash"
        :customerCash="standalone_data.customer_cash"
        :customerCredit="standalone_data.customer_credit"
        :bankAmt="standalone_data.bank_cash"
        :supplierCash="standalone_data.supplier_cash"
        :cash_stored="standalone_data.cash_stored"
      />

      <SectionReceivable
        ref="SectionReceivable"
        :phase="standalone_data.phase"
        :receivables="standalone_data.receivables"
      />
    </div>

    <div class="Side_Controller">
      <div class="Side_Buttons">
        <li>
          <a href="/Rules" target="_blank">
            <SideButtons
              class="btnActivity"
              :pass_button_name="Sidepanel_list[0].name"
              :pass_position="Sidepanel_list[0].position"
              :pass_icon="Sidepanel_list[0].icon"
            />
          </a>
        </li>
        <li v-for="index in 7" :key="index">
          <SideButtons
            ref="Sidepanel_list[index].name"
            :pass_button_name="Sidepanel_list[index].name"
            :pass_position="Sidepanel_list[index].position"
            :pass_icon="Sidepanel_list[index].icon"
            @select_side_panel="ReturnPanelClicked"
            @return_side_button="UpdateSideButtonRef"
            :phase="standalone_data.phase"
          />
        </li>
      </div>

      <SidePanel
        :phase="standalone_data.phase"
        :cash_stored="standalone_data.cash_stored"
        :cards="standalone_data.cards"
        :customer_cash="standalone_data.customer_cash"
        :customer_credit="standalone_data.customer_credit"
        @return_side_panel="UpdateSidePanelRef"
        @close_side_panel="ReturnPanelClicked"
        @Borrow="Borrow"
        @customer_cash_emit="Customer_Cash"
        @buy_raw_cards="buy_raw_cards"
        @buy_copack_cards="buy_copack_cards"
        @Deliver_Copack="Deliver_Copack"
      />
    </div>

    <button v-if="standalone_data.phase < 10" class="next_mbs" @click="next">
      Next
    </button>

    <CoinComponent
      ref="coincomponent"
      @use_coin="UpdateCoinInteractivity"
      :standalone="true"
    />

    <div style="position: absolute; bottom: 0; right: 0">
      <button class="btn btn-logout" @click="logoutDialog = true">
        Logout
      </button>
    </div>

    <Alert
      v-if="logoutDialog == true"
      :alertOpen="logoutDialog"
      :message="'Logout?'"
      popUpSize="gameboard"
      @close_alert="logoutDialog = false"
      @confirm_alert="confirmLogout"
    />

    <StoryWindow :phase="standalone_data.phase" />
  </div>
</template>

<script>
import Background from "../components/Background.vue";
import SideButtons from "../components/Standalone/Gameboard_SideButtons.vue";
import SidePanel from "../components/Standalone/Gameboard_SidePanels_2";
import MBS from "../components/Standalone/Gameboard_MBS.vue";

import SectionCash from "../components/Standalone/Section_Cash.vue";
import SectionExpenses from "../components/Standalone/Section_Expenses.vue";
import SectionExternalLocations from "../components/Standalone/Section_External_Locations.vue";
import SectionLand from "../components/Standalone/Section_Land.vue";
import SectionLoans from "../components/Standalone/Section_Loans.vue";
import SectionPayables from "../components/Standalone/Section_Payables.vue";
import SectionPlot from "../components/Standalone/Section_Plot.vue";
import SectionReceivable from "../components/Standalone/Section_Receivable.vue";

import StoryWindow from "../components/Standalone/Gameboard_Story_Window.vue";
import CoinComponent from "../components/Coin_Component.vue";

import { useWindowSize } from "vue-window-size";
import Alert from "../components/Alert_PopUp.vue";

export default {
  setup() {
    const { width, height } = useWindowSize();

    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  components: {
    Background,
    SideButtons,
    SidePanel,
    MBS,
    SectionCash,
    SectionExpenses,
    SectionExternalLocations,
    SectionLand,
    SectionLoans,
    SectionPayables,
    SectionPlot,
    SectionReceivable,
    StoryWindow,
    CoinComponent,
    Alert,
  },
  emits: ["login", "logout", "return"],
  data() {
    return {
      logoutDialog: false,
      Sidepanel_list: [
        {
          name: "Rules",
          position: 28,
          icon: "rules",
          button: null,
          associated_panel: null,
        },
        {
          name: "Marketing",
          position: 36.5,
          icon: "marketing_plan",
          button: null,
          associated_panel: null,
        },
        {
          name: "Delivery",
          position: 45,
          icon: "delivery_record",
          button: null,
          associated_panel: null,
        },
        {
          name: "Income",
          name2: "Statement",
          position: 53.5,
          icon: "is",
          button: null,
          associated_panel: null,
        },
        {
          name: "Balance",
          name2: "Sheet",
          position: 62,
          icon: "balance_statement",
          button: null,
          associated_panel: null,
        },
        {
          name: "Performance",
          position: 70.5,
          icon: "performance",
          button: null,
          associated_panel: null,
        },
        {
          name: "Forecast",
          position: 79,
          icon: "forecast",
          button: null,
          associated_panel: null,
        },
        {
          name: "Planning",
          position: 87.5,
          icon: "planning",
          button: null,
          associated_panel: null,
        },
        {
          name: "Supplier_Pre",
          position: 96,
          icon: "",
          button: null,
          associated_panel: null,
        },
        {
          name: "Supplier_Post",
          position: 104,
          icon: "",
          button: null,
          associated_panel: null,
        },
        {
          name: "Bank",
          position: 0,
          icon: "",
          button: null,
          associated_panel: null,
        },
      ],

      standalone_data: {
        phase: 0,
        cash_stored: 0,
        investor_cash: 75,
        bank_cash: 0,
        loan: 0,
        supplier_cash: 0,
        customer_cash: 0,
        customer_credit: 0,
        receivables: 0,
        factory: {
          0: {
            type: null,
            wip: 0,
            production_line: 0,
            slots: {
              0: {
                type: null,
                subtype: null,
              },
              1: {
                type: null,
                subtype: null,
              },
              2: {
                type: null,
                subtype: null,
              },
              3: {
                type: null,
                subtype: null,
              },
              4: {
                type: null,
                subtype: null,
              },
              5: {
                type: null,
                subtype: null,
              },
            },
          },
          1: {
            type: null,
            slots: {
              0: {
                type: null,
                subtype: null,
              },
              1: {
                type: null,
                subtype: null,
              },
              2: {
                type: null,
                subtype: null,
              },
              3: {
                type: null,
                subtype: null,
              },
              4: {
                type: null,
                subtype: null,
              },
              5: {
                type: null,
                subtype: null,
              },
            },
          },
          2: {
            type: null,
          },
          3: {
            type: null,
          },
        },
        cards: {
          raw: {
            classic: 0,
            bought: false,
          },
          copack: {
            classic: 0,
            bought: false,
          },
        },
        expenses: {
          ffoh: 0,
          moge: 0,
          interest: 0,
          depreciation: 0,
        },
      },
      canMoveNext: false,
    };
  },
  computed: {
    cssProps() {
      // the base resolution
      const width = 1920;
      const height = 1080;
      const ratio = width / height;

      // the actual view resolution
      let vw = this.windowWidth;
      let vh = this.windowHeight;
      const vRatio = vw / vh;

      // maintain aspect ratio
      if (vRatio >= ratio) {
        // when height is dominant, adjust the width
        vw = vh * ratio;
      } else {
        // when width is dominant, adjust the height
        vh = vw / ratio;
      }

      // get the scaleW and scaleH
      const scaleW = vw / width;
      const scaleH = vh / height;

      // center the view
      const marginTop = (this.windowHeight - vh) / 2;
      const marginLeft = (this.windowWidth - vw) / 2;

      return {
        // '--width': scaleW,
        // '--height': scaleH,
        // '--ori-width': width + "px",
        // '--ori-height': height + "px",

        // you can direct set the css style here, unless you need to use the variable in hierarchy child
        width: `${width}px`,
        height: `${height}px`,
        transform: `scale(${scaleW}, ${scaleH})`,

        "margin-top": `${marginTop}px`,
        "margin-left": `${marginLeft}px`,
      };
    },
  },
  methods: {
    ReturnPanelClicked(name) {
      for (let i = 0; i < this.Sidepanel_list.length; i++) {
        if (this.Sidepanel_list[i].button != null)
          this.Sidepanel_list[i].button.Signal_Hide(name);
      }

      this.Sidepanel_list.find(
        (x) => x.name == name
      ).associated_panel.Show_Panel(name);
    },
    UpdateSideButtonRef(name, ref) {
      this.Sidepanel_list.find((x) => x.name == name).button = ref;
    },

    UpdateSidePanelRef(ref) {
      for (let i = 0; i < this.Sidepanel_list.length; i++)
        this.Sidepanel_list[i].associated_panel = ref;
    },
    Withdrawal(amount) {
      this.$refs["coincomponent"].updateCoin(amount);
    },
    Withdraw_Cash(amount) {
      this.standalone.cash_stored -= amount;
    },
    Cash_Put_In(amount) {
      this.standalone.cash_stored += amount;
    },

    UpdateCoinInteractivity(cash, section_name) {
      switch (section_name) {
        case "Section_Land":
          this.$refs["SectionLand"].Interact_Land(cash);
          return;
        case "Plot_1":
          this.$refs["SectionPlot"].Interact_Board(cash, section_name);
          break;
        case "FFOH_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "MOGE_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "Interest_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "Standard_Loan_Pay":
          this.$refs["SectionLoans"].Interact_Loan_Standard(cash);
          break;
        case "Cash_Box":
          this.$refs["SectionCash"].insert_cash(cash);
          break;
        case "Supplier_Box":
          this.$refs["SectionExternalLocations"].paySuppliers(cash);
          break;
        case "Payable_Box":
          this.$refs["SectionPayables"].payPayables(cash);
          break;
      }
    },
    from_investor() {
      this.standalone_data.investor_cash -= 75;
      this.standalone_data.cash_stored += 75;
    },
    to_land() {
      this.standalone_data.cash_stored -= 20;
    },
    buy_wip() {
      this.standalone_data.cash_stored -= 10;
    },
    Buy_Classic_Plot() {
      this.standalone_data.factory[0].type = "classic";
      this.standalone_data.factory[0].wip = 0;
      this.standalone_data.factory[0].production_line = 15;

      this.standalone_data.cash_stored -= 15;
    },
    Pay_Classic_WIP() {
      this.standalone_data.cash_stored -= 10;
      this.standalone_data.factory[0].wip = 10;
    },
    buy_raw_cards() {
      if (!this.standalone_data.cards.raw.bought) {
        this.standalone_data.supplier_cash += 12;
        this.standalone_data.cards.raw.classic += 6;
        this.standalone_data.cards.raw.bought = true;

        for (let i = 0; i < 6; i++) {
          this.standalone_data.factory[0].slots[i].type = "classic";
          this.standalone_data.factory[0].slots[i].subtype = "raw";
        }
      } else {
        alert(
          "You have already bought " + 6 + " raw cards. Please pay supplier"
        );
      }
    },
    buy_copack_cards() {
      if (!this.standalone_data.cards.copack.bought) {
        this.standalone_data.supplier_cash += 20;
        this.standalone_data.cards.copack.classic += 5;
        this.standalone_data.cards.copack.bought = true;

        this.standalone_data.factory[1].type = "warehouse";

        for (let i = 0; i < 5; i++) {
          this.standalone_data.factory[1].slots[i].type = "classic";
          this.standalone_data.factory[1].slots[i].subtype = "co_pack";
        }
      } else {
        alert("You have already bought " + 5 + " copacks. Please pay supplier");
      }
    },
    Pay_Copack() {
      this.standalone_data.supplier_cash -= 20;
      this.standalone_data.cash_stored -= 20;
    },
    Pay_Raw() {
      this.standalone_data.supplier_cash -= 12;
      this.standalone_data.cash_stored -= 12;
    },
    Deliver_Copack() {
      this.standalone_data.customer_credit += 25;
      this.standalone_data.cards.copack.classic -= 5;

      for (let i = 0; i < 5; i++) {
        this.standalone_data.factory[1].slots[i].type = null;
        this.standalone_data.factory[1].slots[i].subtype = null;
      }
    },
    Collect_Copack_Receive(amount) {
      this.standalone_data.receivables += amount;
      this.standalone_data.customer_credit -= amount;
    },
    Pay_Expense(target) {
      if (target === "ffoh") {
        this.standalone_data.expenses.ffoh += 1;
        this.standalone_data.cash_stored -= 1;
      } else if (target == "moge") {
        this.standalone_data.expenses.moge += 2;
        this.standalone_data.cash_stored -= 2;
      } else if (target === "interest") {
        this.standalone_data.expenses.interest += 1;
        this.standalone_data.cash_stored -= 1;
      }
    },
    Borrow(amount) {
      this.standalone_data.bank_cash += amount;
    },
    Loan_To_Cash(amount) {
      this.standalone_data.bank_cash -= amount;
      this.standalone_data.loan += amount;
      this.standalone_data.cash_stored += amount;
    },
    Pay_Loan(amount) {
      this.standalone_data.loan -= amount;
      this.standalone_data.cash_stored -= amount;
    },
    Depreciate_Line() {
      this.standalone_data.expenses.depreciation += 1;
      this.standalone_data.factory[0].production_line -= 1;
    },

    canMovePhase(bool) {
      this.canMoveNext = bool;
    },
    next() {
      if (this.standalone_data.phase === 0) {
        if (this.standalone_data.cash_stored !== 75) {
          alert("Collect cash from investor");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 1) {
        if (this.standalone_data.cash_stored !== 55) {
          alert("Pleae pay 20 coins to land and buildings");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 2) {
        if (this.standalone_data.cash_stored === 40) {
          alert("Please pay the WIP of your Nescafe Classic equipment line");
          return;
        } else if (this.standalone_data.cash_stored !== 30) {
          alert("Please buy a Nescafe Classic equipment line on the top left");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 3) {
        if (this.standalone_data.cash_stored !== 10) {
          alert("Please purchase 5 packs of copack from supplier");
          return;
        } else if (this.standalone_data.supplier_cash > 0) {
          alert(
            "Please pay for your copack by dragging $20 coin to the supplier box"
          );
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 4) {
        if (this.standalone_data.receivables < 0) {
          alert(
            "Please collect your payment from your customer by dragging the silver coin to receivables box"
          );
          return;
        } else if (this.standalone_data.cards.copack.classic > 0) {
          alert("Please deliver your copack to the customer");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 5) {
        if (this.standalone_data.cash_stored !== 7) {
          alert("Please pay 1 coin FFOH and 2 coins to MOGE");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 6) {
        if (this.standalone_data.cash_stored !== 17) {
          alert("Please take a bank loan of $10");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 7) {
        if (this.standalone_data.cash_stored !== 5) {
          alert("Please purchase 6 units of classic raw units");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 8) {
        if (this.standalone_data.cash_stored !== 3) {
          alert("Please pay interest and loan");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else if (this.standalone_data.phase === 9) {
        if (this.standalone_data.expenses.depreciation !== 1) {
          alert("Please depreciate your Equipment Line");
          return;
        }

        if (!this.canMoveNext) {
          alert("Please fill up the Moving Balance Sheet correctly");
          return;
        }
      } else {
        alert(
          "Oops! Something went wrong on our end. Please refresh to replay Prework. Sorry for the issue!"
        );
        return;
      }

      this.standalone_data.phase += 1;
      this.canMoveNext = false;
    },
    confirmLogout() {
      this.logoutDialog = false;
      localStorage.clear();
      window.location.href = "/";
    },
  },
};
</script>

<style lang="scss">
.Standalone {
  transform-origin: top left;
  overflow: hidden;

  li {
    list-style: none;
  }

  .Panel_PlayerBoard {
    top: 3%;
    left: 6%;
    position: fixed;
    width: 59.5%;
    height: 95%;

    background-image: url("../assets/Gameboard/Panel/panel_gameboard.png");
    background-position: center;
    background-size: 100% 100%;

    z-index: 1;

    h1 {
      left: 7%;
      top: 3.1%;
      position: fixed;
      color: white;
      font-size: 36px;
    }
  }

  .btnActivity {
    border-radius: 8px 0 0 8px;
  }
  .btnActivity:hover {
    background: #b2b2b2;
  }
  .btnActivity:active {
    background: #392c23;
    width: 7%;
    color: white;
  }
  .btnActivity:active > p {
    color: white;
  }
  .next_mbs {
    border: 0;
    position: absolute;
    bottom: 3%;
    right: 15.5%;
    width: 8%;
    height: 4%;
    background: #a3d65d;
    border-radius: 30px;
    z-index: 10;
  }
  .next_mbs:hover {
    background: #729541;
  }
  .next_mbs:active {
    background: #392c23;
    color: white;
  }
}
</style>
