<style lang="scss">

.pre-market-phase {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    font-family: NestleBrush_Reg;

    .pre-market-phase-content {
        height: 100%;
        display: flex;
        flex: 1;

        .ts {
            display: flex;
            flex: 1;
            margin: 0 0.25rem;
        }

        .marketing-plans {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 0 0.25rem;

            & > div {
                display: flex;
                flex: 1;
            }

            h2 {
                position: absolute;
                top: 1.5%;
                left: 67%;
                color: white;
                font-size: 2.5rem;
            }
        }
    }

    .open-board-button {
        background: none;
        border: none;
        position: absolute;
        width: 5.5%;
        height: 5%;

        &.a { top: 11.9%; left: 19.05%; }
        &.b { top: 11.9%; left: 43.1%; }
        &.c { top: 40.2%; left: 19.05%; }
        &.d { top: 40.2%; left: 43.1%; }
        &.e { top: 70.5%; left: 19.05%; }
        &.f { top: 70.5%; left: 43.1%; }
    }

    .setup-button {
        background: #a4d65d;
        width: 16%;
        height: 7%;
        border: none;
        border-radius: 30px;

        position: absolute;
        right: 0;
        bottom: -7.5%;
    }
    .setup-button:hover {
        background: #729541;
    }
    .setup-button:active {
        background: #392c23;
        color: white;
    }

    .reset-button {
        background: #e1261d;
        color: white;
        border-radius: 25px;
        width: 30%;
        height: 7%;
        border: none;
        
        position: absolute;
        left: 0%;
        bottom: -7.5%;
    }
    .reset-button:hover {
        background: #9e1a15;
    }
    .reset-button:active {
        background: #392c23;
        color: white;
    }
}


</style>

<template>

    <div class="pre-market-phase">

        <template v-if="phase === 0">
            <div class="pre-market-phase-content">
                <div class="ts">
                    <TeamStatus 
                        @participantBox="participantBox"
                    />

                    <template v-for="(team, i) in vTeams" :key="i">
                        <button class="open-board-button" :class="String.fromCharCode(97 + i)" @click="participant_box_ctrl(i)"></button>
                    </template>

                </div>
                <div class="marketing-plans">
                    <h2>Marketing Plans</h2>
                    <div>
                        <MarketingPlan2
                            type="classic"
                        ></MarketingPlan2>
                    </div>
                    <div>
                        <MarketingPlan2
                            type="gold"
                        ></MarketingPlan2>
                    </div>
                </div>


            </div>
            <div class="controls">
                <button class="setup-button" @click="movePhase">Set-up Market</button>
                <button class="reset-button" @click="reset">Reset to Start of Period/Phase</button>
            </div>
        </template>

        <template v-if="phase === 1">
            <CardSetup
                :type="['classic', 'gold'][page]"
                v-model="cardSetup[['classic', 'gold'][page]]"
                @prev="prev"
                @next="next"
            ></CardSetup>
            <MarketingPlan
                :type="['classic', 'gold'][page]"
            ></MarketingPlan>
        </template>

    </div>

    <ViewParticipantsBoard v-if="participantBoxOpen" 
        v-on:click="participantBoxOpen = false" 
        :isAtPeriod0="false"
        :team_id="team_id"
    />

    <Alert v-if="trigger_alert == true" 
        :alertOpen="trigger_alert" 
        :message="message" 
        :onlyClose="false" 
        :popUpSize="'trainer'"
        @close_alert="trigger_alert = false" 
        @confirm_alert="next_alert"
    />

    <Alert v-if="trigger_alert_next == true" 
        :alertOpen="trigger_alert_next" 
        :message="message2" 
        :onlyClose="false" 
        :popUpSize="'trainer'"
        @close_alert="trigger_alert_next = false" 
        @confirm_alert="confirm_alert"
    />

    <Alert v-if="trigger_alert_2 == true" 
        :alertOpen="trigger_alert_2" 
        :message="reset_message" 
        :onlyClose="false" 
        :popUpSize="'trainer'"
        @close_alert="trigger_alert_2 = false" 
        @confirm_alert="reset_alert"
    />
</template>

<script>
import { mapGetters } from "vuex";
import CardSetup from "./CardSetup";
import MarketingPlan from "./MarketingPlan";
import MarketingPlan2 from "./MarketingPlan_2";
import ViewParticipantsBoard from "../ViewParticipantsBoard"
import TeamStatus from './TeamStatus';
import Alert from "@/components/Alert_PopUp";

export default {
    components: {
        TeamStatus,
        CardSetup,
        MarketingPlan,
        MarketingPlan2,
        ViewParticipantsBoard,
        Alert
    },
    data() {
        return {
            phase: 0,
            page: 0,

            cardSetup: {
                classic: {},
                gold: {},
            },

            participantBoxOpen: false,

            message: "Are you sure you want to proceed?",
            message2: "Are you sure? Moving forward may cause the game to CRASH.",
            reset_message: "Are you sure?",
            trigger_alert: false,
            trigger_alert_next: false,
            trigger_alert_2: false,
            team: {},
            team_id: null,
        };
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
            vTeamDict: 'teamDict',
            vTrainingSessionData: 'trainingSessionData',
        }),
    },
    mounted() {
        // console.log("Team ID loaded: " + this.vTeams[0].id)
    },
    methods: {
        prev() {
            this.page = Math.max(0, this.page - 1);
        },
        movePhase() {
            Profitability.Trainer.checkAllReady().then(() => {
                this.phase = 1
            }, e => {
                this.trigger_alert = true;
                this.confirm_alert;
            })
        },

        next_alert() {
            this.trigger_alert = false;
            this.trigger_alert_next = true;
        },

        confirm_alert() {
            this.trigger_alert_next = false;
            this.phase = 1
        },
        next() {
            this.page++;
            if (this.page === 2) {
                this.submitMarketCardSetup();
            }
        },
        reset() {
            this.trigger_alert_2 = true;
        },
        reset_alert() {
            Profitability.Trainer.resetMarketPhase();
            this.trigger_alert_2 = false;
        },
        submitMarketCardSetup() {
            return Profitability.Trainer.submitMarketCardSetup(this.cardSetup).then(() => {
                return Profitability.Trainer.updateMarketPhase(Profitability.Trainer.MarketPhase.Market);
            });
        },
        participant_box_ctrl(num) {
            this.participantBoxOpen === true ? this.participantBoxOpen = false : this.participantBoxOpen = true
            this.team_id = this.vTeams[num].id
        },
    },
}
</script>
