<style lang="scss">
.PA_Container {
  /* background-image: url("../../assets/Gameboard/Panel/Form_PerformanceA_P2-3.png"); */
  top: 27.5%;
  right: 7%;
  position: fixed;
  width: 26%;
  height: 70%;
  background-size: 100% 100%;

  &.p3 {
    background-image: url("../../assets/Gameboard/Panel/Form_PerformanceA_P3.png");
    background-size: 100% 100%;
  }
  &.p4 {
    background-image: url("../../assets/Gameboard/Panel/Form_PerformanceA_P4.png");
    background-size: 100% 100%;
  }
  &.p5 {
    background-image: url("../../assets/Gameboard/Panel/Form_PerformanceA_P5.png");
    background-size: 100% 100%;
  }

  .period_btn {
    /* background: red; */
    position: absolute;
    top: 0;
    left: 0.7%;
    width: 10%;
    height: 20%;

    button {
      border: 0;
      margin-top: 20%;
      width: 100%;
      height: 25%;
      background: none;
    }
  }

  .PA_col {
    position: absolute;
    top: 11.5%;
    left: 40.7%;
    width: 54.6%;
    height: 81.5%;

    .input_box {
      /* background: yellow; */
      position: absolute;
      width: 30.1%;
      height: 4.1%;

      input {
        position: relative;
        font-size: 1rem;
        text-align: center;
        width: 100%;
        height: auto;
        background: transparent;
        border: none;
        outline: none;
        font-family: NestleBrush_Reg;
      }
    }

    .input_box.gold_p2 { top: 6.1%; }
    .input_box.total_p2 { top: 12.2%; }
    .input_box.classic_p3 { top: 27.2%; }
    .input_box.gold_p3 { top: 33.1%; }
    .input_box.total_p3 { top: 39%; }

    .nns { left: 34.74%; }
    .us_price { left: 69.9%; }

    .input_box_btm {
      top: 52.2%;
      left: 62%;
      position: absolute;
      width: 40%;
      height: auto;
      /* background: rgb(22, 211, 58, 0.7); */

      input {
        position: relative;
        font-size: 1.5rem;
        text-align: center;
        width: 100%;
        height: auto;
        background: transparent;
        border: none;
        outline: none;
        font-family: NestleBrush_Reg;
      }

      &.rig { top: 61.8%; }
      &.op1 { top: 71.2%; }
      &.trade_net { top: 80.9%; }

      .answer_check {
        background: url("../../assets/Gameboard/Period/checked_step.png");
        background-size: 100% 100%;
        position: absolute;
        width: 13.5%;
        height: 5%;
        z-index: 2;

        &.left { left: 41%; }
        &.right { left: 92%; }
        &.og { top: 60.8%; }
        &.rig { top: 71.8%; }
        &.op1 { top: 83%; }
        &.trade_net { top: 94.3%; }
      }
    }

    .input_box_2_btm {
      top: 52.5%;
      left: 62%;
      position: absolute;
      width: 40%;
      height: auto;
      /* background: rgb(22, 211, 58, 0.7); */

      input {
        position: relative;
        font-size: 1.5rem;
        text-align: center;
        width: 100%;
        height: auto;
        background: transparent;
        border: none;
        outline: none;
        font-family: NestleBrush_Reg;
      }

      &.rig { top: 61.7%; }
      &.ncf { top: 71.2%; }
      &.op1 { top: 81%; }
      &.atr { top: 90%; }
      &.roic { top: 99.7%; }

    }

    .answer_check {
      background: url("../../assets/Gameboard/Period/checked_step_white.png");
      background-size: 100% 100%;
      position: absolute;
      width: 11%;
      height: 4%;
      z-index: 2;
      left: 95%;

      &.nns_c { top: 26.9%; }
      &.nns_g { top: 32.6%; }
      &.total { top: 39%; }
      &.og { top: 52.5%; }
      &.rig { top: 61.9%; }

      &.p3 {
        &.op1 { top: 71.5%; }
        &.trade_net { top: 81%; }
      }

      &.p4_5 {
        &.net_cash_flow { top: 71.5%; }
        &.op1 { top: 81%; }
        &.atr { top: 90%; }
        &.roic { top: 99.5%; }
      }
    }
  }

  .debug {
    position: absolute;
    bottom: 0;
    color: white;
    font-weight: bold;
    text-align: left;
    background: rgb(0,0,0,0.6);
    width: 25%;
  }
}

.black_text {
  color: black;
}
.white_text {
  color: white;
}
</style>

<template>
  <div class="PA_Container" :class="{p3: period === 2, p4: period === 3, p5: period === 4}">
    <div v-if="vDebug" class="debug">
      Period {{ vPhase.period + 1 }}
      <br />
      NNS_C : {{ summary['units_sold.classic'] * summaryPrev['unit_price.classic'] }} {{inputs.ans_3.nns_c }} {{inputs.check.nns_c}}
      <br />
      NNS_G : {{ summary['units_sold.gold'] * summaryPrev['unit_price.gold'] }} {{ inputs.ans_3.nns_g }} {{inputs.check.nns_g}}
      <br />
      TOTAL : {{ (summary['units_sold.classic'] * summaryPrev['unit_price.classic']) + (summary['units_sold.gold'] * summaryPrev['unit_price.gold']) }} {{ inputs.ans_3.total }} {{ inputs.check.total }}

      <div v-if="period === 2">
        <br />
        OG : {{ summary['og%'] }} {{ inputs.ans_3.og }} {{ inputs.check.og }}
        <br />
        RIG : {{ summary['rig%'] }} {{ inputs.ans_3.rig }} {{ inputs.check.rig }}
        <br />
        OP1%: {{ summary['operating_profit%'] }} {{ inputs.ans_3.op1 }} {{ inputs.check.op1 }}
        <br />
        TNWC: {{ summary['trade_net_working_capital%'] }} {{ inputs.ans_3.trade_net }} {{ inputs.check.trade_net }}
        <br />
        {{ inputs.check.p3 }}
        <br />
        Period: {{period}}, phase_step: {{ vPhase.period }}
      </div>

      <div v-else>
        <br />
        OG% : {{ summary['og%'] }} {{ inputs.ans_4_5.og }} {{ inputs.check.og }}
        <br />
        RIG% : {{ summary['rig%'] }} {{ inputs.ans_4_5.rig }} {{ inputs.check.rig }}
        <br />
        NCF : {{ balanceSheet.cash - balanceSheetPrev.cash }} {{ inputs.ans_4_5.net_cash_flow }} {{ inputs.check.net_cash_flow }}
        <br />
        OP1 : {{ summary['operating_profit%'] }} {{inputs.ans_4_5.op1 }} {{ inputs.check.op1 }}
        <br />
        ATR : {{ summary['atr'] }} {{inputs.ans_4_5.atr}} {{inputs.check.atr}}
        <br />
        ROIC : {{ summary['roic'] }} {{inputs.ans_4_5.roic}} {{inputs.check.roic}}
        <br />
        {{ inputs.check.p4_5 }}
        <br />
        Period: {{period}}, phase_step: {{ vPhase.period }}
      </div>
    </div>

    <div v-if="canCheckPrevPA" class="period_btn">
      <!-- P3, P4 and P5 respectively -->
      <button @click="period = 2"></button>
      <button v-if="vPhase.period >= 3" @click="period = 3"></button>
      <button v-if="vPhase.period >= 4" @click="period = 4"></button>
    </div>


    <div class="PA_col">
      <div class="input_box">
        <input
          class="white_text"
          maxlength="5"
          :disabled="true"
          :value="summaryPrev['units_sold.classic']"
        />
      </div>
      <div class="input_box nns">
        <input
          class="white_text"
          maxlength="5"
          :disabled="true"
          :value="summaryPrev['nns.classic']"
        />
      </div>
      <div class="input_box us_price">
        <input
          class="white_text"
          maxlength="5"
          :disabled="true"
          :value="summaryPrev['unit_price.classic']"
        />
      </div>

      <div class="input_box gold_p2">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="summaryPrev['units_sold.gold']"
        />
      </div>
      <div class="input_box gold_p2 nns">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="summaryPrev['nns.gold']"
        />
      </div>
      <div class="input_box gold_p2 us_price">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="summaryPrev['unit_price.gold']"
        />
      </div>

      <div class="input_box total_p2">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="isNaN(summaryPrev['units_sold.classic'] + summaryPrev['units_sold.gold']) ? null : summaryPrev['units_sold.classic'] + summaryPrev['units_sold.gold']"
        />
      </div>
      <div class="input_box total_p2 nns">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="isNaN(summaryPrev['nns.classic'] + summaryPrev['nns.gold']) ? null : summaryPrev['nns.classic'] + summaryPrev['nns.gold']"
        />
      </div>

      <div class="input_box classic_p3">
        <input
          class="white_text"
          maxlength="5"
          :disabled="true"
          :value="summary['units_sold.classic']"
        />
      </div>
      <div class="input_box classic_p3 nns">
        <input
          class="white_text"
          maxlength="5"
          :disabled="true"
          :value="summary['nns.classic']"
        />
      </div>

      <div class="input_box classic_p3 us_price">
        <input
          v-if="period != vPhase.period"
          class="white_text"
          maxlength="5"
          :value="isNaN(summary['units_sold.classic'] * summaryPrev['unit_price.classic']) ? null : summary['units_sold.classic'] * summaryPrev['unit_price.classic']"
          readonly
        />
        <input
          v-else-if="isPAPhase"
          class="white_text"
          maxlength="5"
          v-model.number="inputs.ans_nns.nns_c"
          @change="check_input_nns"
          @focus="$event.target.select()"
        />
      </div>

      <div class="input_box gold_p3">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="summary['units_sold.gold']"
        />
      </div>
      <div class="input_box gold_p3 nns">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="summary['nns.gold']"
        />
      </div>
      <div class="input_box gold_p3 us_price">
        <input
          v-if="period != vPhase.period"
          class="black_text"
          maxlength="5"
          :value="isNaN(summary['units_sold.gold'] * summaryPrev['unit_price.gold']) ? null : summary['units_sold.gold'] * summaryPrev['unit_price.gold']"
          readonly
        />
        <input
          v-else-if="isPAPhase"
          class="black_text"
          maxlength="5"
          v-model.number="inputs.ans_nns.nns_g"
          @change="check_input_nns"
          @focus="$event.target.select()"
        />
      </div>

      <div class="input_box total_p3">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="isNaN(summary['units_sold.classic'] + summary['units_sold.gold']) ? null : summary['units_sold.classic'] + summary['units_sold.gold']"
        />
      </div>
      <div class="input_box total_p3 nns">
        <input
          class="black_text"
          maxlength="5"
          :disabled="true"
          :value="isNaN(summary['nns.classic'] + summary['nns.gold']) ? null : summary['nns.classic'] + summary['nns.gold']"
        />
      </div>
      <div class="input_box total_p3 us_price">
        <input
          v-if="period != vPhase.period"
          class="black_text"
          maxlength="5"
          :value="isNaN((summary['units_sold.classic'] * summaryPrev['unit_price.classic']) + (summary['units_sold.gold'] * summaryPrev['unit_price.gold'])) ? null : (summary['units_sold.classic'] * summaryPrev['unit_price.classic']) + (summary['units_sold.gold'] * summaryPrev['unit_price.gold'])"
          readonly
        />
        <input
          v-else-if="isPAPhase"
          class="black_text"
          maxlength="5"
          v-model.number="inputs.ans_nns.total"
          @change="check_input_nns"
          @focus="$event.target.select()"
        />
      </div>

      <div v-if="isPAPhase">
        <div v-if="vPhase.period === 2">
          <div v-if="inputs.check.p3.nns_c" class="answer_check nns_c"></div>
          <div v-if="inputs.check.p3.nns_g" class="answer_check nns_g"></div>
          <div v-if="inputs.check.p3.total" class="answer_check total"></div>
          <div v-if="inputs.check.p3.og" class="answer_check og"></div>
          <div v-if="inputs.check.p3.rig" class="answer_check rig"></div>
          <div v-if="inputs.check.p3.op1" class="answer_check p3 op1"></div>
          <div v-if="inputs.check.p3.trade_net" class="answer_check p3 trade_net"></div>
        </div>

        <div v-else>
          <div v-if="inputs.check.p4_5.nns_c" class="answer_check nns_c"></div>
          <div v-if="inputs.check.p4_5.nns_g" class="answer_check nns_g"></div>
          <div v-if="inputs.check.p4_5.total" class="answer_check total"></div>
          <div v-if="inputs.check.p4_5.og" class="answer_check og"></div>
          <div v-if="inputs.check.p4_5.rig" class="answer_check rig"></div>
          <div v-if="inputs.check.p4_5.trade_net" class="answer_check p3 trade_net"></div>
          <div v-if="inputs.check.p4_5.op1" class="answer_check p4_5 op1"></div>
          <div v-if="inputs.check.p4_5.net_cash_flow" class="answer_check p4_5 net_cash_flow"></div>
          <div v-if="inputs.check.p4_5.atr" class="answer_check p4_5 atr"></div>
          <div v-if="inputs.check.p4_5.roic" class="answer_check p4_5 roic"></div>
        </div>
      </div>

      <template v-if="period === 2">
        <div class="input_box_btm">
          <input
            v-if="isPAPhase && period == vPhase.period"
            class="black_text"
            v-model.number="inputs.ans_3.og"
            @change="check_input_p3"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(summary['og%'], 1)) ? null : round(summary['og%'], 1)"
            readonly
          />
        </div>

        <div class="input_box_btm rig">
          <input
            v-if="isPAPhase && period == vPhase.period"
            class="black_text"
            v-model.number="inputs.ans_3.rig"
            @change="check_input_p3"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(summary['rig%'], 1)) ? null : round(summary['rig%'], 1)"
            readonly
          />
        </div>

        <div class="input_box_btm op1">
          <input
            v-if="isPAPhase && period == vPhase.period"
            class="black_text"
            v-model.number="inputs.ans_3.op1"
            @change="check_input_p3"
            @focus="$event.target.select()"
          />
          <input
            v-else="period != vPhase.period"
            class="black_text"
            :value="isNaN(round(summary['operating_profit%'], 1)) ? null : round(summary['operating_profit%'], 1)"
            readonly
          />
        </div>

        <div class="input_box_btm trade_net">
          <input
            v-if="isPAPhase && period == vPhase.period"
            class="black_text"
            v-model.number="inputs.ans_3.trade_net"
            @change="check_input_p3"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(summary['trade_net_working_capital%'], 1)) ? null : round(summary['trade_net_working_capital%'], 1)"
            readonly
          />
        </div>
      </template>

      <template v-if="period >= 3">
        <div class="input_box_2_btm">
          <input
            v-if="isPAPhase && vPhase.period == period"
            class="black_text"
            v-model.number="inputs.ans_4_5.og"
            @change="check_input_p4"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(summary['og%'], 1)) ? null : round(summary['og%'], 1)"
            readonly
          />
        </div>

        <div class="input_box_2_btm rig">
          <input
            v-if="isPAPhase && vPhase.period == period"
            class="black_text"
            v-model.number="inputs.ans_4_5.rig"
            @change="check_input_p4"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(summary['rig%'], 1)) ? null : round(summary['rig%'], 1)"
            readonly
          />
        </div>

        <div class="input_box_2_btm ncf">
          <input
            v-if="isPAPhase && vPhase.period == period"
            class="black_text"
            v-model.number="inputs.ans_4_5.net_cash_flow"
            @change="check_input_p4"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(balanceSheet.cash - balanceSheetPrev.cash, 1)) ? null : round(balanceSheet.cash - balanceSheetPrev.cash, 1)"
            readonly
          />
        </div>

        <div class="input_box_2_btm op1">
          <input
            v-if="isPAPhase && vPhase.period == period"
            class="black_text"
            v-model.number="inputs.ans_4_5.op1"
            @change="check_input_p4"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(summary['operating_profit%'], 1)) ? null : round(summary['operating_profit%'], 1)"
            readonly
          />
        </div>

        <div class="input_box_2_btm atr">
          <input
            v-if="isPAPhase && vPhase.period == period"
            class="black_text"
            v-model.number="inputs.ans_4_5.atr"
            @change="check_input_p4"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(summary['atr'], 2)) ? null : round(summary['atr'], 2)"
            readonly
          />
        </div>

        <div class="input_box_2_btm roic">
          <input
            v-if="isPAPhase && vPhase.period == period"
            class="black_text"
            v-model.number="inputs.ans_4_5.roic"
            @change="check_input_p4"
            @focus="$event.target.select()"
          />
          <input
            v-else
            class="black_text"
            :value="isNaN(round(summary['roic'], 1)) ? null : round(summary['roic'], 1)"
            readonly
          />
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    canCheckPrevPA: Boolean
  },
  data() {
    return {
      canSubmitPA: false,
      period: 2
    };
  },
  computed: {
    ...mapGetters("profitability/participant", {
      vTeam: "team",
      vTeamData: "teamData",
      vTeamDataByPeriod: "teamDataByPeriod",
      vPhase: "phase",
    }),
    ...mapGetters({
      vDebug: "debug",
    }),
    inputs() {
      return this.$store.state.performanceAnalysis
    },
    incomeStatement() {
      return this.vTeamDataByPeriod[this.period].income_statement || {};
    },
    balanceSheet() {
      return this.vTeamDataByPeriod[this.period].balance_sheet || {};
    },
    summary() {
      return _.reduce(this.vTeamDataByPeriod[this.period].summary ?? {}, (result, value, key) => {
        result[key] = _.round(value, key === 'atr'? 2 : 1);
        return result;
      }, {});
    },
    incomeStatementPrev() {
      if (this.period === 0) return {};
      return this.vTeamDataByPeriod[this.period - 1].income_statement || {};
    },
    balanceSheetPrev() {
      if (this.period === 0) return {};
      return this.vTeamDataByPeriod[this.period - 1].balance_sheet || {};
    },
    summaryPrev() {
      if (this.period === 0) return {};
      return this.vTeamDataByPeriod[this.period - 1].summary || {};
    },
    isPAPhase() {
      if (this.vPhase.period === this.period && this.vPhase.market_phase == 2 && this.vPhase.phase_step == 8)
        return true
    }
  },
  methods: {
    Initialize() {
      this.period = this.vPhase.period;
    },

    round: _.round,

    check_input_nns() {
      const summaryNNSC = this.summary['units_sold.classic'] * this.summaryPrev['unit_price.classic']
      const summaryNNSG = this.summary['units_sold.gold'] * this.summaryPrev['unit_price.gold']
      const summaryTotal = (this.summary['units_sold.classic'] * this.summaryPrev['unit_price.classic']) + (this.summary['units_sold.gold'] * this.summaryPrev['unit_price.gold'])

      if (this.vPhase.period === 2) {
        this.inputs.check.p3.nns_c = this.inputs.ans_nns.nns_c == summaryNNSC
        this.inputs.check.p3.nns_g = this.inputs.ans_nns.nns_g == summaryNNSG
        this.inputs.check.p3.total = this.inputs.ans_nns.total == summaryTotal
      } else {
        this.inputs.check.p4_5.nns_c = this.inputs.ans_nns.nns_c == summaryNNSC
        this.inputs.check.p4_5.nns_g = this.inputs.ans_nns.nns_g == summaryNNSG
        this.inputs.check.p4_5.total = this.inputs.ans_nns.total == summaryTotal
      }
    },

    check_input_p3() {
      _.each(_.keys(this.inputs.ans_3), (key) => {
        this.inputs.check.p3[key] =
          this.inputs.ans_3[key] === this.summary[key + `%`];
      });

      this.inputs.check.p3.op1 =
        this.inputs.ans_3.op1 === this.summary[`operating_profit%`];
      this.inputs.check.p3.trade_net =
        this.inputs.ans_3.trade_net === this.summary['trade_net_working_capital%'];

      if (Object.values(this.inputs.check.p3).every(Boolean)) {
        this.canSubmitPA = true
        this.$emit("canSubmitPA", true, Object.assign(this.inputs.ans_nns, this.inputs.ans_3))
      } else {
        this.canSubmitPA = false
        this.$emit("canSubmitPA", false, null)
      }
    },

    check_input_p4() {
      _.each(_.keys(this.inputs.ans_4_5), (key) => {
        this.inputs.check.p4_5[key] =
          this.inputs.ans_4_5[key] === this.summary[key + `%`];
      });

      this.inputs.check.p4_5.net_cash_flow =
        this.inputs.ans_4_5.net_cash_flow === this.balanceSheet.cash - this.balanceSheetPrev.cash
      this.inputs.check.p4_5.op1 =
        this.inputs.ans_4_5.op1 === this.summary['operating_profit%']
      this.inputs.check.p4_5.atr =
        this.inputs.ans_4_5.atr === this.summary['atr']
      this.inputs.check.p4_5.roic =
        this.inputs.ans_4_5.roic === this.summary['roic']

      if (Object.values(this.inputs.check.p4_5).every(Boolean)) {
        this.canSubmitPA = true
        this.$emit("canSubmitPA", this.canSubmitPA, Object.assign(this.inputs.ans_nns, this.inputs.ans_4_5))
      } else {
        this.canSubmitPA = false
        this.$emit("canSubmitPA", this.canSubmitPA, null)
      }
    }
  },
  watch: {
    vPhase: {
      deep: true,
      handler() {
        this.period = this.vPhase.period;

        if (!this.isPAPhase) {
          this.canSubmitPA = false
          this.$emit("canSubmitPA", this.canSubmitPA, null)
        }
      },
    },
  },
  mounted() {
    this.Initialize();
  },
};
</script>
