<style lang="scss">

.delivery.form_panel {
    z-index: 15;

    &.Delivery_1 {
        background-image: url('../assets/Gameboard/Panel/Form_DeliveryForm_NC.png');
    }

    &.Delivery_2 {
        background-image: url('../assets/Gameboard/Panel/Form_DeliveryForm_NG.png');
    }

    &.Delivery_3 {
        background-image: url('../assets/Gameboard/Panel/panel_delivery_record3.png');

        .DL_Period{
            position: absolute;
            width: 10%;
            height: 5%;

            color:black;
            text-align: center;
            font-size: 22px;

            &.Net_Sales{
              &.NC{left: 58.25%; top: 19.7%;}
              &.NG{left: 80%; top: 19.7%;}
            }
            &.Goods_Sold{
              &.NC{left: 58.25%; top: 27.2%;}
              &.NG{left: 80%; top: 27.2%;}
            }
            &.Marginal_Contribution{
              &.NC{left: 58.25%; top: 34.5%;}
              &.NG{left: 80%; top: 34.5%;}
            }
        }
    }

    &.frame_border {
        border: 5px solid white;
        border-radius: 8px;
    }

    .DL_Input{
        font-family: NestleBrush_reg;
        font-size: 22px;
        position: absolute;
        width: 10%;
        height: 5%;
        border: none;
        outline: none;

        background-color: transparent;
        outline: none;
        text-align: center;

        &.Stock_Produced{left: 40.8%; top: 6.6%;}
        &.Stock_Copacked{left: 70.45%; top: 6.6%;}
        &.Units_Sold{left: 70.5%; top: 34%;}
        &.Selling_Price{left: 70.5%; top: 40.8%;}
        &.Sales{left: 70.5%; top: 47.5%;}
        &.Produced_Units{left: 41.2%; top: 60.3%;}
        &.Copacked_Units{left: 41.2%; top: 67.3%;}
        &.Total_Cost{left: 70.5%; top: 74.3%}
        &.Margins_Made{left: 70.5%; top: 81.3%}
    }

    .MarketCard_Button {
        position: absolute;
        top: 14%;
        left: 2%;
        width: 35%;
        height: 5%;

        color: white;
        font-size: 20px;
        border-radius: 30px;
        border-color: transparent;
    }
    .MarketCard_Button.classic {
        background: #e1261d;
    }
    .MarketCard_Button.classic:hover {
        background: #9e1a15;
    }
    .MarketCard_Button.gold {
        color: black;
        background: #ffd751;
    }
    .MarketCard_Button.gold:hover {
        background: #b39638;
    }
    .MarketCard_Button.clicked {
        color: white;
        background: #392c23;
    }

    .cards-container {
        position: absolute;
        top: 0;
        left: -73%;
        width: 70%;
        height: 80%;
        background: #63513d;
        border-radius: 15px;
    }

    .cards-wrapper {
        position: absolute;
        padding: 15px;
        top: 2.5%;
        left: 4%;
        width: 92%;
        height: 95%;
        background: #392c23;
        border-radius: 15px;
        overflow-y: auto;
    }

    .marketcard {
        height: 170px;
        width: 255px;
        margin-bottom: 15px;

        p {
            margin-left: 22px;
            margin-top: -5px;
        }
    }
    .marketcard_classic.overlay {
        position: absolute;
        height: 170px;
        width: 255px;
        margin-bottom: 15px;
        z-index: 20;

        background: url('../assets/Gameboard/Panel/Overlay_MarketCard_DeliveredNC.png');
        background-size: 100% 100%;
    }
    .marketcard_gold.overlay {
        position: absolute;
        height: 170px;
        width: 255px;
        margin-bottom: 15px;
        z-index: 20;

        background: url('../assets/Gameboard/Panel/Overlay_MarketCard_DeliveredNG.png');
        background-size: 100% 100%;
    }
    .marketcard_delivering {
        position: absolute;
        height: 170px;
        width: 255px;
        margin-bottom: 15px;
        z-index: 20;

        background: url('../assets/Gameboard/Panel/Overlay_MarketCard_Delivering.png');
        background-size: 100% 100%;
    }

    .Close_Button{
        bottom: 3.4%;
        left: 35%;
        position: absolute;
        width: 31%;
        height: 5%;

        background: #a4d65d;
        font-size: 23px;
        border-radius: 30px;
        border-color: transparent;
    }
    .Close_Button:hover {
        background: #729541;
    }
    .Close_Button:active {
        background: #392c23;
        color: white;
    };
    .Delivered_Button {
        color: white;
        font-size: 19px;
        border-radius: 30px;
        background: none;
        position: relative;
        width: 74%;
        height: auto;
        padding: 0.7% 0;
        top: 90%;
        left: 0%;
    }
    .Delivered_Button.classic{
        border: 4px solid #e1261d;
    }
    .Delivered_Button.classic:hover{
        border: 4px solid #9e1a15;
    }
    .Delivered_Button.classic:active {
        border: 4px solid #9e1a15;
        background: #392c23;
    }
    .Delivered_Button.gold {
        border: 4px solid #ffd751;
    }
    .Delivered_Button.gold:hover {
        border: 4px solid #b39638;
    }
    .Delivered_Button.gold:active {
        border: 4px solid #b39638;
        background: #392c23
    }

    .money_collected_btn {
        bottom: 5%;
        left: 10%;
        position: absolute;
        width: 80%;
        height: 6%;

        background: #a4d65d;
        border-radius: 30px;
        font-size: 18px;
        border-color: transparent;
    }
    .money_collected_btn:hover {
        background: #729541;
    }
    .money_collected_btn:active {
        background: #392c23;
        color: white;
    }

    .delivery_num {
        text-align: left;
        color: black;
        position: absolute;
        top: 28%;
        left: 3.6%;
        width: 50%;
        font-size: 30px;
    }
    .delivery_num.white {
        color: white;
    }
    .delivery_card {
        position: absolute;
        top: 47.85%;
        right: 5%;
        background: url("../assets/Gameboard/Panel/panel_delivery_cash.png");
        background-size: 100% 100%;
        width: 12%;
        height: 4.7%;
    }
    .delivery_card.credit {
        background: url("../assets/Gameboard/Panel/panel_delivery_credit.png");
        background-size: 100% 100%;
    }


    .debug {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        font-weight: bold;
        color: black;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .answer_check {
        background: url("../assets/Gameboard/Period/checked_step_white.png");
        background-size: 100% 100%;
        position: absolute;
        right: 14%;
        width: 7%;
        height: 3.5%;
        z-index: 5;

        &.p_units { top: 61.5%; right: 44%; }
        &.co_units { top: 68.5%; right: 44%; }
        &.total_cost { top: 75.5%; }
        &.margins_made { top: 82.2%; }
    }
}

</style>

<template>
    <div class="delivery form_panel" v-if="is_marketCard" :class="[deliveryClass[marketCard?.type], {frame_border: vPhase.period === 0 && vPhase.phase_step === 4}]">
        <p v-if="marketCard.type === 'classic'" class="delivery_num">Delivery #{{index + 1}}</p>
        <p v-else class="delivery_num white">Delivery #{{index + 1}}</p>

        <div class="delivery_card" :class="{credit: marketCard.payment === 'credit'}"></div>

        <input
            class="DL_Input Stock_Produced" disabled
            :value="marketCard.type === 'classic'? classicRawCount : goldRawCount"
        >
        <input
            class="DL_Input Stock_Copacked" disabled
            :value="marketCard.type === 'classic'? classicCoPackCount : goldCoPackCount"
        >

        <input
            class="DL_Input Units_Sold" disabled
            :value="marketCard.unit"
        >
        <input
            class="DL_Input Selling_Price" disabled
            :value="vMarketingData[marketCard.type].unit_price"
        >
        <input
            class="DL_Input Sales" disabled
            :value="sales"
        >

        <input
            class="DL_Input Produced_Units" placeholder="0"
            v-model.number="rawUnit"
            type="number"
            maxlength="3"
            @focus="$event.target.select()"
            @change="check_input"
            :disabled="!this.$store.state.releaseDeliveryButton.release"
        >
        <input
            class="DL_Input Copacked_Units" placeholder="0"
            v-model.number="coPackUnit"
            type="number"
            maxlength="3"
            @focus="$event.target.select()"
            @change="check_input"
            :disabled="!this.$store.state.releaseDeliveryButton.release"
        >
        <input
            class="DL_Input Total_Cost"
            maxlength="3"
            v-model.number="totalCost_input"
            @focus="$event.target.select()"
            @change="check_input"
            :disabled="!this.$store.state.releaseDeliveryButton.release"
        >
        <input
            class="DL_Input Margins_Made"
            maxlength="3"
            v-model.number="marginsMade_input"
            @focus="$event.target.select()"
            @change="check_input"
            :disabled="!this.$store.state.releaseDeliveryButton.release"
        >

        <div v-if="check.p_units" class="answer_check p_units"></div>
        <div v-if="check.co_units" class="answer_check co_units"></div>
        <div v-if="check.totalCost" class="answer_check total_cost"></div>
        <div v-if="check.marginsMade" class="answer_check margins_made"></div>

        <button v-if="vPhase.period !== 0" class="MarketCard_Button" :class="[delivered_type[marketCard?.type], {clicked: click_market_btn == true}]" @click="show_marketCards">&lt; Market Cards</button>
        <button class="Delivered_Button" :class="delivered_type[marketCard?.type]" @click="deliver">{{ is_delivered ? is_delivered_text : not_delivered_text}}</button>

        <button v-if="$store.state.releaseDeliveryButton.release == false || (marketCard.delivered && customers_cash == 0 && customers_credit == 0)" class="money_collected_btn" @click="release_btn">Click here after collecting your payment</button>

        <div v-if="click_market_btn == true && vPhase.period !== 0" class="cards-container">
            <div class="cards-wrapper">
                <template v-for="(mc, i) in vTeamMarketingCards" :key="i">
                    <div v-if="vTeamMarketingCards[i].delivered && mc.type === 'classic'" class="marketcard_classic overlay"></div>
                    <div v-if="vTeamMarketingCards[i].delivered && mc.type === 'gold'" class="marketcard_gold overlay"></div>
                    <div v-if="marketCard === vTeamMarketingCards[i]" class="marketcard_delivering">{{i}}</div>
                    <MarketCard
                        class="marketcard"
                        role="participant"
                        :marketCard="mc"
                        :teamDict="vTeamDict"
                        :assignedBG="false"
                    />
                </template>
            </div>
        </div>

        <div v-if="vDebug" class="debug">
            <!-- <div>Payment: {{ marketCard.payment }}</div> -->
            <div>{{ classicRaw }} {{ classicCo }} {{ goldRaw }} {{ goldCo }} = {{ marketCard.unit }}</div>
            <div>TotalCost: {{totalCost}}</div>
            <div>MarginsMade: {{marginsMade}}</div>
        </div>
    </div>

    <div v-else-if="!is_marketCard && vPhase.period !== 0" class="delivery form_panel Delivery_3" :class="{frame_border: vPhase.period === 0 && vPhase.phase_step === 4}">

        <p class="DL_Period Net_Sales NC">{{deliveryStatement.classic.sales}}</p>
        <p class="DL_Period Goods_Sold NC">{{deliveryStatement.classic.cost}}</p>
        <p class="DL_Period Marginal_Contribution NC">{{deliveryStatement.classic.margin}}</p>

        <p class="DL_Period Net_Sales NG">{{deliveryStatement.gold.sales}}</p>
        <p class="DL_Period Goods_Sold NG">{{deliveryStatement.gold.cost}}</p>
        <p class="DL_Period Marginal_Contribution NG">{{deliveryStatement.gold.margin}}</p>

        <button class="Close_Button" @click="close">Close</button>

        <div v-if="vDebug" class="debug">
            <div>Phase: {{phase}}</div>
            <div>Index: {{index}}</div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Profitability from '@/profitability-nestle-js';
import MarketCard from '@/components/MarketCard';

// The cost constants
const costs = {
    classic: {
        raw: 2,
        coPack: 4,
    },
    gold: {
        raw: 4,
        coPack: 7,
    },
};

export default {
    components: { MarketCard },
    emits: [
        'close',
    ],
    data() {
        return {
            deliveryClass: {
                classic: 'Delivery_1',
                gold: 'Delivery_2',
            },

            delivered_type: {
                classic: "classic",
                gold: "gold"
            },

            phase: 0,

            // determine which marketCards is currently 'deliver'
            index: 0,

            // form value
            rawUnit: 0,
            coPackUnit: 0,
            totalCost_input: null,
            marginsMade_input: null,

            delivery_form_classic: {
                net_sales: 0,
                goods_sold: 0,
                marginal_contribution: 0
            },
            delivery_form_gold: {
                net_sales: 0,
                goods_sold: 0,
                marginal_contribution: 0
            },
            is_delivered: false,
            not_delivered_text: "Deliver",
            is_delivered_text: "Move chips to cash / receivables",
            click_market_btn: true,
            classicRaw: 0,
            classicCo: 0,
            goldRaw: 0,
            goldCo: 0,
            check: {}
        };
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vTeam: 'team',
            vTeams: 'teams',
            vTeamData: 'teamData',
            vTeamDict: 'teamDict',
            vMarketingData: 'marketing_data',
            vTeamMarketingCards: 'teamMarketCards',
            vPhase: 'phase',
            vMarketCards: 'marketCards',
            vGameboard: 'gameboard',
        }),
        classicRawCount() {
            return this.vTeam.classicRawCount;
        },
        classicCoPackCount() {
            return this.vTeam.classicCoPackCount;
        },
        goldRawCount() {
            return this.vTeam.goldRawCount;
        },
        goldCoPackCount() {
            return this.vTeam.goldCoPackCount;
        },
        marketCard() {
            return this.vTeamMarketingCards[this.index];
        },
        marketCards() {
            return _.filter(this.vMarketCards, card => card.type === this.type);
            const { market_cards } = this.vTrainingSessionData;
            return market_cards[this.type];
        },

        sales() {
            return Math.floor(this.marketCard.unit * this.vMarketingData[this.marketCard.type].unit_price);
        },
        totalCost() {
            return this.rawUnit * costs[this.marketCard.type].raw + this.coPackUnit * costs[this.marketCard.type].coPack;
        },
        marginsMade() {
            return this.sales - this.totalCost;
        },
        customers_cash() {
            return this.vGameboard?.getValue('customers', 'cash');
        },
        customers_credit() {
            return this.vGameboard?.getValue('customers', 'credit');
        },

        deliveryStatement() {
            const { delivery_statement } = this.vTeamData;

            const classicItems = _.filter(delivery_statement, item => item.type === 'classic');
            const goldItems = _.filter(delivery_statement, item => item.type === 'gold');

            const classic = {
                sales: _.sum(_.map(classicItems, 'sales')),
                cost: _.sum(_.map(classicItems, 'cost')),
                margin: _.sum(_.map(classicItems, 'margin')),
            };
            const gold = {
                sales: _.sum(_.map(goldItems, 'sales')),
                cost: _.sum(_.map(goldItems, 'cost')),
                margin: _.sum(_.map(goldItems, 'margin')),
            };

            return {
                classic,
                gold,
            };
        },
        is_marketing_phase() {
            if (this.vPhase.period !== 0) {
                return this.vPhase.market_phase == 2 && this.vPhase.phase_step == 0
            } else if (this.vPhase.period === 0) {
                return this.vPhase.phase_step == 4;
            }
        },
        is_marketCard() {
            return this.phase === 0 && this.marketCard && this.is_marketing_phase
        },
    },
    mounted() {
        _.each(this.vTeamMarketingCards, card => {
            if (card.delivered) {
                this.index++;
            }
        })

        if (this.marketCard) {
            let totalUnit = this.marketCard.unit
            let r_c = this.classicRawCount
            let cp_c = this.classicCoPackCount
            let r_g = this.goldRawCount
            let cp_g = this.goldCoPackCount

            while (totalUnit > 0) {
                if (this.marketCard.type === 'classic') {
                    if (r_c) {
                        this.classicRaw++
                        r_c--
                        totalUnit--
                    } else if (cp_c) {
                        this.classicCo++
                        cp_c--
                        totalUnit--
                    }
                } else if (this.marketCard.type === 'gold') {
                    if (r_g) {
                        this.goldRaw++
                        r_g--
                        totalUnit--
                    } else if (cp_g) {
                        this.goldCo++
                        cp_g--
                        totalUnit--
                    }
                }
            }
        }

        if (this.customers_cash === 0 && this.customers_credit === 0) {
            this.$store.state.releaseDeliveryButton.release = true
        }
    },
    methods: {
        deliver() {
            // check if fulfill card amount
            const totalUnit = this.rawUnit + this.coPackUnit;
            if (totalUnit !== this.marketCard.unit) {
                alert('Incorrect amount of deliveries. Please refer to "Market Cards" and deliver according to the units on the cards');
                return;
            }

            if (this.totalCost_input != this.totalCost) {
                alert("Total Cost value is wrong. Try again")
                return;
            }

            if (this.marginsMade_input !== this.marginsMade) {
                alert("Margins Made value is wrong. Try again")
                return;
            }

            const type = this.marketCard.type;

            // check if enough stock
            if (this.rawUnit > (type === 'classic' ? this.classicRawCount : this.goldRawCount)) {
                alert('insufficient raw stock');
                return;
            }
            if (this.coPackUnit > (type === 'classic' ? this.classicCoPackCount : this.goldCoPackCount)) {
                alert('insufficient coPack stock');
                return;
            }

            const deliveryForm = {
                card_id: this.marketCard.id,
                raw: this.rawUnit,
                co_pack: this.coPackUnit,
            };

            if (this.customers_cash == 0 && this.customers_credit == 0) {
                this.is_delivered = true;

                if (this.vPhase.period !== 0) {
                    Profitability.Participant.makeDelivery(deliveryForm)
                } else {
                    Profitability.Participant.mbsMakeDelivery(deliveryForm)
                }

                this.totalCost_input = null
                this.marginsMade_input = null
                this.check = {}
                this.rawUnit = 0
                this.coPackUnit = 0
                this.classicRaw = 0
                this.classicCo = 0
                this.goldRaw = 0
                this.goldCo = 0
                this.index++;
                this.is_delivered = false;
                this.$store.state.releaseDeliveryButton.release = false;
            }
        },
        check_input() {
            let checkValue = this.rawUnit + this.coPackUnit

            if (checkValue === this.marketCard.unit) {
              const type = this.marketCard.type;
              const typeUnits = {
                classic: {
                  raw: this.classicRawCount,
                  co_pack: this.classicCoPackCount,
                },
                gold: {
                  raw: this.goldRawCount,
                  co_pack: this.goldCoPackCount,
                },
              };

              // check sufficient stock
              this.check.p_units = typeUnits[type].raw >= this.rawUnit;
              this.check.co_units = typeUnits[type].co_pack >= this.coPackUnit;
              // ensure both valid
              this.check.p_units &= this.check.co_units;
              this.check.co_units &= this.check.p_units;
            } else {
                this.check.p_units = false
                this.check.co_units = false
            }

            if (checkValue === this.marketCard.unit) {
                this.check.totalCost = this.totalCost_input == this.totalCost
                this.check.marginsMade = this.marginsMade_input == this.marginsMade
            } else {
                this.check.totalCost = false
                this.check.marginsMade = false
            }
        },
        close() {
            this.$emit('close');
        },
        release_btn() {
            if (this.customers_cash == 0 && this.customers_credit == 0) {
                this.$store.state.releaseDeliveryButton.release = true;

                let totalUnit = this.marketCard.unit
                let r_c = this.classicRawCount
                let cp_c = this.classicCoPackCount
                let r_g = this.goldRawCount
                let cp_g = this.goldCoPackCount

                while (totalUnit > 0) {
                    if (this.marketCard.type === 'classic') {
                        if (r_c) {
                            this.classicRaw++
                            r_c--
                            totalUnit--
                        } else if (cp_c) {
                            this.classicCo++
                            cp_c--
                            totalUnit--
                        }
                    } else if (this.marketCard.type === 'gold') {
                        if (r_g) {
                            this.goldRaw++
                            r_g--
                            totalUnit--
                        } else if (cp_g) {
                            this.goldCo++
                            cp_g--
                            totalUnit--
                        }
                    }
                }
            }
        },
        show_marketCards() {
            this.click_market_btn === false ? this.click_market_btn = true : this.click_market_btn = false
        }
    },
}
</script>
