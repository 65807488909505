<style lang="scss">
.Participants_Board {
    position: absolute;
    top: -8.5%;
    left: -4.5%;
    width: 108.5%;
    height: 121%;
    background: rgb(0,0,0,0.8);
    z-index: 20;

    &.at_period_0 {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .wrapper {
        position: absolute;
        top: 7%;
        left: 8%;
        width: 84%;
        height: 84%;
        background: #352921;
        border-radius: 10px;

        .board {
            position: absolute;
            /* left: 0.5%; */
            width: 98%;
            height: 96%;
            margin: 1%;
            border-radius: 10px;
            background: none;
        }
    }
}
</style>

<template>
    <div class="Participants_Board" :class="{at_period_0: isAtPeriod0}">
        <div class="wrapper">
            <div class="board">
                <GBE 
                    :team_id="team_id"
                    :vPhase="vPhase"
                />
            </div>
        </div>
    </div>
</template>

<script>
import GBE from './GameboardElement'
import { mapGetters } from 'vuex';

export default {
    props: {
        isAtPeriod0 : Boolean,
        team_id: Number,
    },
    components: { GBE },
    computed: {
        ...mapGetters('profitability/trainer', {
            vPhase: 'phase',
        }),
    },
    // data() {
    //     return {

    //     }
    // },
    // methods: {
        
    // }
}

</script>
