<style lang="scss">
.MBS_Container {
  background-image: url("../../assets/Gameboard/Panel/Form_MovingBS.png");
  top: 3%;
  right: 6.5%;
  position: fixed;
  width: 27%;
  height: 95%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: white;

  .step_display {
    position: absolute;
    top: 34.5%;
    width: 20%;
    font-size: 18px;
    font-weight: bold;
  }
  .step_display.prev {
    left: 47.5%;
  }
  .step_display.curr {
    left: 63.5%;
  }
  .step_display.next {
    left: 79.5%;
  }

  .wrapper {
    position: absolute;
    overflow-y: auto;
    top: 4.2%;
    left: 3%;
    width: 94.5%;
    height: 25.5%;
    padding: 0 2%;

    .text_description {
      position: relative;
      font-size: 20px;
      text-align: justify;
      width: 100%;
      height: 100%;

      pre {
        padding: 0;
        margin: 0;
        font-family: NestleText;
        white-space: pre-wrap; /* Since CSS 2.1 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
      }
    }
  }

  .text_display {
    position: absolute;
    top: 39%;
    right: 52%;
    width: 36.5%;
    height: 54.5%;
    /* background: green; */

    p {
      position: absolute;
      width: 100%;
      font-size: 18px;
      text-align: right;
    }

    .factory { top: 9%; }
    .equipment { top: 18%; }
    .wip { top: 27%; }
    .fgoods { top: 35.5%; }
    .owed { top: 44.7%; }
    .total_wwh { top: 53%; }
    .shared_cap { top: 66.4%; }
    .pl { top: 75.6%; }
    .loans { top: 84.5%; }
    .total_wicf { top: 93%; }
  }

  .mbs_col {
    position: absolute;
    top: 38.7%;
    width: 14%;
    height: 54.5%;
  }
  .mbs_col.col_1 {
    left: 51%;
  }
  .mbs_col.col_2 {
    left: 67%;
  }
  .mbs_col.col_3 {
    left: 82.7%;
  }

  .input_box {
    position: absolute;
    width: 100%;
    border-radius: 5px;

    input {
      position: relative;
      font-size: 20px;
      text-align: center;
      width: 100%;
      padding: 3px 0;
      font-family: NestleBrush_Reg;
    }
  }
  .input_factory { top: 9%; }
  .input_eq { top: 18%; }
  .input_wip { top: 27%; }
  .input_fg { top: 35.5%; }
  .input_owed { top: 44.7%; }
  .input_wwh_t { top: 53%; }
  .input_sc { top: 66.4%; }
  .input_pl { top: 75.6%; }
  .input_loans { top: 84.5%; }
  .input_wicf_t { top: 93%; }

  .brown {
    background: #63503f;
    border: none;
  }
  .white {
    background: white;
    border: none;
  }
  .black_text {
    color: black;
    background: none;
    border: none;
    outline: none;
  }
  .white_text {
    color: white;
    background: none;
    border: none;
    outline: none;
  }

  .answer_check {
    background: url("../../assets/Gameboard/Period/checked_step_white.png");
    background-size: 100% 100%;
    position: absolute;
    right: 1%;
    width: 5%;
    height: 2.5%;
    z-index: 5;

    &.cash { top: 39%; }
    &.land_n_building { top: 43.8%; }
    &.equipment { top: 48.6%; }
    &.wip { top: 53.5%; }
    &.finished_good { top: 58.1%; }
    &.owe_by_customers { top: 63.2%; }
    &.share_capital { top: 75.2%; }
    &.profit { top: 80%; }
    &.bank_loans { top: 85%; }
  }

  .next_mbs {
    border: 0;
    position: absolute;
    bottom: 0.8%;
    left: 35%;
    width: 30%;
    height: 4%;
    background: #a3d65d;
    border-radius: 30px;
  }
  .next_mbs:hover {
    background: #729541;
  }
  .next_mbs:active {
    background: #392c23;
    color: white;
  }

  .debug {
    position: fixed;
    /* top: 0; */
    z-index: 100;
    /* pointer-events: none; */
    color: white;
  }
}
</style>

<template>
    <div class="MBS_Container">
        <div v-if="vDebug && curr_step < 10" class="debug">
            <button v-if="curr_step < 10" @click="hackButton">Hack for step {{curr_step + 1}}</button>
            <!-- <div v-for="(className, i) in classNames" :key="i">
                {{className}} : {{ check[className] }}
            </div> -->
        </div>

        <p v-if="curr_step_capped - 1 > 0" class="step_display prev">Step {{ curr_step_capped - 1 }}</p>
        <p v-if="curr_step_capped > 0" class="step_display curr">Step {{ curr_step_capped }}</p>
        <p class="step_display next">Step {{ curr_step_capped + 1 }}</p>

        <div class="wrapper">
            <p class="text_description">
                <pre><b>{{actions_step[curr_step]}}</b></pre>
                <br />
                <pre>{{actions[curr_step]}}</pre>
            </p>
        </div>

        <div class="text_display">
            <p class="black_text">{{ text_display_desc[0] }}</p>
            <p v-if="curr_step >= 1" class="factory black_text">{{ text_display_desc[1] }}</p>
            <p v-if="curr_step >= 2" class="equipment black_text">{{ text_display_desc[2] }}</p>
            <p v-if="curr_step >= 2" class="wip black_text">{{ text_display_desc[3] }}</p>
            <p v-if="curr_step >= 3" class="fgoods black_text">{{ text_display_desc[4] }}</p>
            <p v-if="curr_step >= 4" class="owed black_text">{{ text_display_desc[5] }}</p>
            <p class="total_wwh">{{ text_display_desc[6] }}</p>

            <p class="shared_cap black_text">{{ text_display_desc[7] }}</p>
            <p v-if="curr_step >= 4" class="pl bottom black_text">{{ text_display_desc[8] }}</p>
            <p v-if="curr_step >= 6" class="loans bottom black_text">{{ text_display_desc[9] }}</p>
            <p class="total_wicf bottom">{{ text_display_desc[10] }}</p>
        </div>

        <template v-for="(col, i) in [curr_step_capped - 2, curr_step_capped - 1, curr_step_capped]">

            <div v-if="col >= 0 && form[col]" class="mbs_col" :class="`col_${i + 1}`" :key="i">

                <!-- Cash, 2 x Total-->
                <div class="input_box" :class="{white: col===curr_step, brown: col!==curr_step}">
                    <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                           :disabled="col !== curr_step" v-model.number="form[col].cash" @focus="$event.target.select()" @change="check_input">
                </div>

                <div class="input_box input_wwh_t" :class="{white: col===curr_step, brown: col!==curr_step}">
                    <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                           :disabled="true" :value="getWWHTotal(col)" @focus="$event.target.select()">
                </div>

                <div class="input_box input_wicf_t" :class="{white: col===curr_step, brown: col!==curr_step}">
                    <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                           :disabled="true" :value="getWICFTotal(col)" @focus="$event.target.select()">
                </div>

                <!-- Factory -->
                <div v-if="col >= 1">
                    <div class="input_box input_factory" :class="{white: col===curr_step, brown: col!==curr_step}">
                        <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                               :disabled="col !== curr_step" v-model.number="form[col].land_n_building" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- Equipment/Land & Buildings-->
                <div v-if="col >= 2">
                    <div class="input_box input_eq" :class="{white: col===curr_step, brown: col!==curr_step}">
                        <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                               :disabled="col !== curr_step" v-model.number="form[col].equipment" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                    <div class="input_box input_wip" :class="{white: col===curr_step, brown: col!==curr_step}">
                        <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                               :disabled="col !== curr_step" v-model.number="form[col].wip" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- WIP + Finished Goods -->
                <div v-if="col >= 3">
                    <div class="input_box input_fg" :class="{white: col===curr_step, brown: col!==curr_step}">
                        <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                               :disabled="col !== curr_step" v-model.number="form[col].finished_good" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- Owed By Customers + Profit/Loss -->
                <div v-if="col >= 4">
                    <div class="input_box input_owed" :class="{white: col===curr_step, brown: col!==curr_step}">
                        <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                               :disabled="col !== curr_step" v-model.number="form[col].owe_by_customers" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- Shared Capitals -->
                <div class="input_box input_sc" :class="{white: col===curr_step, brown: col!==curr_step}">
                    <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                           :disabled="col !== curr_step" v-model.number="form[col].share_capital" @focus="$event.target.select()"
                           @change="check_input">
                </div>

                <!-- Profit/Loss -->
                <div v-if="col >= 4">
                    <div class="input_box input_pl" :class="{white: col===curr_step, brown: col!==curr_step}">
                        <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                               :disabled="col !== curr_step" v-model.number="form[col].profit" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- Bank Loans -->
                <div v-if="col >= 6">
                    <div class="input_box input_loans" :class="{white: col===curr_step, brown: col!==curr_step}">
                        <input :class="{black_text: col===curr_step,white_text: col!==curr_step}" maxlength="3"
                               :disabled="col !== curr_step" v-model.number="form[col].bank_loans" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

            </div>
            
        </template>
        
        <div v-for="className in classNames" :key="className">
            <div v-if="check[className]" class="answer_check" :class="className"></div>
        </div>

        <button v-if="curr_step < 10" class="next_mbs" @click="next">Next</button>

    </div>
    
</template>

<script type="text/javascript">

import { mapGetters } from "vuex";
import { period_0_step, period_0 } from '@/constants';

export default {
    data() {
        return {
            actions_step: period_0_step,
            actions: period_0,
            text_display_desc: [
                'Cash',
                'Land & Buildings',
                'Production Line',
                'Work In Progress',
                'Finished Goods',
                'Owed By Customers',
                'Total',
                'Share Capital',
                'Profit/Loss',
                'Bank Loans',
                'Total'
            ],
            mbs_hack: {},
            classNames: [
                'cash',
                'land_n_building',
                'equipment',
                'wip',
                'finished_good',
                'owe_by_customers',
                'share_capital',
                'profit',
                'bank_loans'
            ],
            ans: {
                cash: null,
                land_n_building: null,
                equipment: null,
                wip: null,
                finished_good: null,
                owe_by_customers: null,
                share_capital: null,
                profit: null,
                bank_loans: null
            },
            check: {},
        };
    },
    mounted() {
        this.initializeData();
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vTeam: 'team',
            vTeamData: 'teamData',
            vPhase: 'phase',
            vTeamMarketingCards: 'teamMarketCards'
        }),
        marketCard() {
            return this.vTeamMarketingCards[0];
        },
        form() {
            return this.vTeamData?.moving_balance_sheet?.form || [];
        },
        curr_step() {
            return this.vPhase.phase_step;
        },
        curr_step_capped() {
            return this.vPhase.phase_step > 9? 9 : this.vPhase.phase_step;
        },
    },
    watch: {
        vPhase(next, prev) {
            if (this.vPhase.period === 0) {
                if (next.phase_step !== prev.phase_step && this.curr_step < 10) {
                    Profitability.Participant.mbsData(this.vPhase.phase_step).then(data => {
                        _.each(this.classNames, type => {
                            this.ans[type] = data[type]
                            // console.log(data[type])
                        })    
                    })
                }
    
                if (this.curr_step < 10)
                    _.each(this.classNames, type => {
                        if (this.form[this.curr_step][type] == null)
                            this.check[type] = false
                    })
            }
        },
    },
    methods: {
        initializeData() {
            Profitability.Participant.mbsData(this.vPhase.phase_step).then(data => {
                _.each(this.classNames, type => {
                    this.ans[type] = data[type]
                })
            })
        },
        getWWHTotal(col) {
            const form = this.form;
            const colForm = form[col];
            const wwhFields = [
                'cash',
                'land_n_building',
                'equipment',
                'wip',
                'finished_good',
                'owe_by_customers',
            ];

            const filtered = _.map(wwhFields, (field) => {
                return Number.isInteger(colForm[field]) ? colForm[field] : 0
            });

            return _.sum(filtered);
        },
        getWICFTotal(col) {
            const form = this.form;
            const colForm = form[col];
            const wicfFields = [
                'share_capital',
                'profit',
                'bank_loans',
            ];
            const filtered = _.map(wicfFields, (field) => {
                return Number.isInteger(colForm[field]) ? colForm[field] : 0
            });
            
            return _.sum(filtered);
        },
        check_input() {
            _.each(this.classNames, type => {
                if (this.form[this.curr_step][type] !== null) {
                    this.$data.check[type] = this.form[this.curr_step][type] == this.$data.ans[type];
                }
            });
        },
        next() {
            const data = {
                step: this.curr_step,
                data: this.form[this.curr_step],
            };

            // if (Object.values(this.check).every(item => item)) {
                
            // }

            return Profitability.Participant.mbsNext(data).then(() => {
                _.each(this.classNames, type => {
                    this.check[type] = false
                });
            }, e => {
                _.each(this.classNames, type => {
                    if (this.form[this.curr_step][type] == this.$data.ans[type] && this.form[this.curr_step][type] !== null)
                        this.check[type] = true
                });
                
                // console.log("Error! " + e.reason)

                if (e.reason === "Please pay Suppliers") {
                    alert("Pay for the materials with cash")
                } else if (e.reason === "Invalid form") {
                    alert("Not all fields are correct. Press next only when all fields have green ticks")
                } else {
                    alert(e.reason)
                }
            })
        },
        hackButton() {
            if (this.vPhase.period === 0) {
                switch (this.vPhase.phase_step) {
                    case 0:
                        Profitability.Participant.mbsInvestorToCash().then(() => {
                            return Profitability.Participant.mbsNext({
                                step: this.vPhase.phase_step,
                                data: {
                                    cash: 75,
                                    share_capital: 75
                                },
                            });
                        });

                        this.check = {init: false}
                    break;

                    case 1:
                        Profitability.Participant.mbsCashToLandNBuilding().then(() => {
                            return Profitability.Participant.mbsNext({
                                step: this.vPhase.phase_step,
                                data: {
                                    cash: 55,
                                    land_n_building: 20,
                                    share_capital: 75
                                },
                            });
                        });

                        this.check = {init: false}
                    break;

                    case 2:
                        Profitability.Participant.mbsBuyEquipment(0, 'classic').then(() => {
                            Profitability.Participant.mbsBuyEquipmentPayWip(0, 10).then(() => {
                                return Profitability.Participant.mbsNext({
                                    step: this.vPhase.phase_step,
                                    data: {
                                        cash: 30,
                                        land_n_building: 20,
                                        equipment: 15,
                                        wip: 10,
                                        share_capital: 75
                                    },
                                });
                            });
                        });

                        this.check = {init: false}
                    break;

                    case 3:
                        this.mbs_hack = {
                            classic: 5
                        };

                        Profitability.Participant.mbsBuyCoPack(this.mbs_hack).then(() => {
                            Profitability.Participant.mbsBuyCoPackPaySuppliers(20).then(() => {
                                return Profitability.Participant.mbsNext({
                                    step: this.vPhase.phase_step,
                                    data: {
                                        cash: 10,
                                        land_n_building: 20,
                                        equipment: 15,
                                        wip: 10,
                                        finished_good: 20,
                                        share_capital: 75
                                    },
                                });
                            })
                        });

                        this.check = {init: false}
                    break;

                    case 4:
                        this.mbs_hack = {
                            card_id: this.marketCard.id,
                            raw: 0,
                            co_pack: 5,
                        };

                        Profitability.Participant.mbsMakeDelivery(this.mbs_hack).then(() => {
                            Profitability.Participant.mbsCustomersToReceivables().then(() => {
                                return Profitability.Participant.mbsNext({
                                    step: this.vPhase.phase_step,
                                    data: {
                                        cash: 10,
                                        land_n_building: 20,
                                        equipment: 15,
                                        wip: 10,
                                        finished_good: 0,
                                        owe_by_customers: 25,

                                        share_capital: 75,
                                        profit: 5
                                },
                            });
                        });

                        this.check = {init: false}
                    });
                    break;

                    case 5:
                        Profitability.Participant.mbsPayFFOHnMOGE('ffoh', 1).then(() => {
                            Profitability.Participant.mbsPayFFOHnMOGE('moge', 2).then(() => {
                                return Profitability.Participant.mbsNext({
                                    step: this.vPhase.phase_step,
                                    data: {
                                        cash: 7,
                                        land_n_building: 20,
                                        equipment: 15,
                                        wip: 10,
                                        finished_good: 0,
                                        owe_by_customers: 25,

                                        share_capital: 75,
                                        profit: 2
                                    },
                                });
                            })
                        });

                        this.check = {init: false}
                    break;

                    case 6:                      
                        Profitability.Participant.mbsTakeLoan(10).then(() => {
                            Profitability.Participant.bankLoanToCash().then(() => {
                                Profitability.Participant.bankLoanToLoans("standard").then(() => {
                                    return Profitability.Participant.mbsNext({
                                        step: this.vPhase.phase_step,
                                        data: {
                                            cash: 17,
                                            land_n_building: 20,
                                            equipment: 15,
                                            wip: 10,
                                            finished_good: 0,
                                            owe_by_customers: 25,
        
                                            share_capital: 75,
                                            profit: 2,
                                            bank_loans: 10
                                        },
                                    });
                                })
                            })
                        });

                        this.check = {init: false}
                    break;

                    case 7:
                        this.mbs_hack = {
                            classic: 6,
                            gold: 0
                        };

                        Profitability.Participant.mbsBuyRaw(this.mbs_hack).then(() => {
                            Profitability.Participant.mbsBuyRawPaySuppliers(12).then(() => {
                                return Profitability.Participant.mbsNext({
                                    step: this.vPhase.phase_step,
                                    data: {
                                        cash: 5,
                                        land_n_building: 20,
                                        equipment: 15,
                                        wip: 10,
                                        finished_good: 12,
                                        owe_by_customers: 25,

                                        share_capital: 75,
                                        profit: 2,
                                        bank_loans: 10
                                    },
                                });
                            })
                        });

                        this.check = {init: false}
                    break;

                    case 8:
                        Profitability.Participant.mbsPayInterest(1).then(() => {
                            Profitability.Participant.mbsPayLoan(1).then(() => {
                                return Profitability.Participant.mbsNext({
                                    step: this.vPhase.phase_step,
                                    data: {
                                        cash: 3,
                                        land_n_building: 20,
                                        equipment: 15,
                                        wip: 10,
                                        finished_good: 12,
                                        owe_by_customers: 25,

                                        share_capital: 75,
                                        profit: 1,
                                        bank_loans: 9
                                    },
                                });
                            })
                        });

                        this.check = {init: false}
                    break;

                    case 9:
                        Profitability.Participant.mbsDepreciateEquipment(0).then(() => {
                            return Profitability.Participant.mbsNext({
                                step: this.vPhase.phase_step,
                                data: {
                                    cash: 3,
                                    land_n_building: 20,
                                    equipment: 14,
                                    wip: 10,
                                    finished_good: 12,
                                    owe_by_customers: 25,

                                    share_capital: 75,
                                    profit: 0,
                                    bank_loans: 9
                                },
                            });
                        });

                        this.check = {init: false}
                    break;
                }
            }
        },
    }
}

</script>