import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './vuex/store';
import Profitability from '@/profitability-nestle-js';
import '@/assets/scss/app.scss';

Profitability.initialize({
    host: process.env.VUE_APP_PROFITABILITY_HOST || 'http://localhost:8000',
    echo: {
        host: process.env.VUE_APP_PROFITABILITY_ECHO_HOST || 'http://localhost:6001',
    },
    store,
    log: process.env.VUE_APP_PROFITABILITY_LOG === '1' || process.env.VUE_APP_PROFITABILITY_LOG === 'true',
}).then(() => {
    if (Profitability.authenticated) {
        // TODO: you can redirect to route respectively if authenticated
        Profitability.debug('authenticated')({
            user: Profitability.user,
            role: Profitability.role,
        });
    }
});

createApp(App).use(router).use(store).mount('#app')
