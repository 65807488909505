<style lang="scss">
.post-market-phase {
    .balance-sheet {
        position: absolute;
        top: 6.2%;
        right: 0.55%;
        width: 48.7%;
        height: 92.5%;

        .container {
            position: absolute;
            width: 100%;
            height: 100%;

            .team_display {
                position: absolute;
                top: 1.6%;
                left: 1.6%;
                width: 96.3%;
                height: 8%;
                background: url("../../../assets/Facilitator/Form_Facilitator_TEAMROW.png");
                background-size: 98.4% 100%;
            }

            .score_display {
                width: 100%;
                height: 100%;
                background: url("../../../assets/Facilitator/Form_Facilitator_TAB.png");
                background-size: 100% 100%;

                .score {
                    position: absolute;
                    top: 9.7%;
                    left: 1.6%;
                    width: 96.3%;
                    height: 87.8%;
                    background: url("../../../assets/Facilitator/Form_Facilitator_BS.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-attachment: local;

                    overflow-y: auto;
                }
            }
        }

        .teams {

            .team {
                position: absolute;
                top: 9%;
                height: 150%;

                &.A { left: 32%; }
                &.B { left: 43.2%; }
                &.C { left: 54%; }
                &.D { left: 65%; }
                &.E { left: 75.9%; }
                &.F { left: 87%; }
            }
        }

        .field-item {
            position: absolute;
            padding: 0.3vh 0;
            width: 4vw;
            color: white;
            font-size: 1.3rem;

            &.f-1 { top: 5.6%; }
            &.f-2 { top: 11%; }
            &.f-3 { top: 16.7%; }
            &.f-4 { top: 22.2%; }
            &.f-5 { top: 27.8%; }
            &.f-6 { top: 33.3%; }
            &.f-7 { top: 38.9%; }
            &.f-8 { top: 44.3%; }
            &.f-9 { top: 49.8%; }
            &.f-10 { top: 55.7%; }
            &.f-11 { top: 61%; }
            &.f-12 { top: 72.2%; }
            &.f-13 { top: 77.6%; }
            &.f-14 { top: 83.6%; }
            &.f-15 { top: 89%; }
            &.f-16 { top: 94.5%; }
            &.black { color: black; }
        }
    }
}
</style>

<template>

    <div class="balance-sheet">
        <div class="container">
            <div class="team_display"></div>
            <div class="score_display">
                <div class="score">
                    
                    <div class="teams">
                        <template v-for="i in indexes" :key="i">

                            <div class="team" :class="String.fromCharCode(65 + i)" v-if="vTeams[i]" :key="i">
                                <template v-for="(field, j) in fields" :key="j">
                                    <div class="field-item" :class="[`f-${j}`, {black: i === 4 || i === 5}]">
                                        {{ vTeams[i]?.teamData.balance_sheet ? getValue(vTeams[i]?.teamData.balance_sheet, field) : '&nbsp' }}
                                    </div>
                                </template>
                            </div>
                            <div class="team" :class="String.fromCharCode(65 + i)" v-else>
                                <template v-for="(field, j) in fields" :key="j">
                                    <div class="field-item" :class="`f-${j}`">
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

const fields = [
    // liquid assets
    'cash',

    // other current assets
    'due_from_customer',

    'wip',
    'finished_good',
    'total_current_asset',

    // current liabilities
    'owe_to_suppliers',

    'trade_net_working_capital',
    'net_current_assets',

    // fixed assets
    'land_n_building',
    'production_line_n_equipment',
    'total_fixed_assets',

    'net_assets',

    // liabilities
    'bank_loans',

    // shareholders' equity
    'share_capital',
    'retained_earnings_to_date',
    'total_equity',

    'total_liabilities_n_shareholders_funds',
];

export default {
    data() {
        return {
            indexes: _.times(6),

            fields,
        };
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
        }),
    },
    methods: {
        getValue(statement, field) {
            return _.get(statement, field);
        },
    },
}
</script>
