import Profitability from './profitability';

function createInstance() {
  return new Profitability();
}

const instance = createInstance();

// Expose Profitability class to allow class inheritance
instance.Profitability = Profitability;

export default instance;

if (typeof window !== 'undefined') {
  window.Profitability = instance;
}
