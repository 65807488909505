import _debug from 'debug';
import EventType from "../EventType";
import _ from "lodash";

const debug = (namespace) => _debug(`profitability:${namespace}`);
const log = debug('participant');

export default class Participant {
  context = null;

  constructor(context) {
    this.context = context;
  }

  emit(...params) {
    return this.context.emit(...params);
  }

  get axios() {
    return this.context.axios;
  }

  get echo() {
    return this.context.echo;
  }

  get store() {
    return this.context.store;
  }

  _onLoggedIn() {
    return this.getTrainingSessionData().then(() => {
      // subscribe to trainingSession
      const trainingSession = this.store.getters['profitability/participant/trainingSession'];
      log(`subscribe to trainingSession.${trainingSession.id}`);
      const trainingSessionChannel = this.echo.channel(`trainingSession.${trainingSession.id}`);
      this.emit(EventType.SubscribeToTrainingSession, trainingSessionChannel);

      trainingSessionChannel.listen('.TrainingSessionDataUpdatedEvent', this._onTrainingSessionDataUpdatedEvent);
      trainingSessionChannel.listen('.TrainingSessionPeriodUpdatedEvent', this._onTrainingSessionPeriodUpdatedEvent);

      // subscribe to team
      const team = this.store.getters['profitability/participant/team'];
      log(`subscribe to team.${team.id}`);
      const teamChannel = this.echo.private(`team.${team.id}`);
      this.emit(EventType.SubscribeToTeam, teamChannel);

      teamChannel.listen(`.TrainingTeamDataUpdatedEvent`, this._onTrainingTeamDataUpdatedEvent);

    });
  }

  _onTrainingTeamDataUpdatedEvent = (event) => {
    debug(`profitability:Echo.TrainingTeamDataUpdatedEvent`)(event);

    const { data } = event;
    const { team, teamData, teamGameboard, factories, marketCards, _marketCards } = data;

    if (team) {
      this.store.commit('profitability/participant/setTeam', team);
    }
    if (teamData) {
      const { marketing_data } = teamData;
      this.store.commit('profitability/participant/setTeamData', teamData);
      this.store.commit('profitability/participant/setMarketingData', marketing_data);
    }
    if (data.teamDataList) {
      this.store.commit('profitability/participant/setTeamDataList', data.teamDataList);
    }
    if (teamGameboard) {
      this.store.commit('profitability/participant/setGameboard', teamGameboard);
    }
    if (marketCards) {
      this.store.commit('profitability/participant/setMarketCards', marketCards);
    }
    if (_marketCards) {
      this.store.commit('profitability/participant/clearMarketCards');
      this.store.commit('profitability/participant/setMarketCards', _marketCards);
    }

    if (factories) {
      this.store.commit('profitability/participant/setFactories', factories);
    }
  };

  _onTrainingSessionDataUpdatedEvent = (event) => {
    debug(`profitability:Echo.TrainingSessionDataUpdatedEvent`)(event);

    const { data } = event;
    const { trainingSessionData, marketCards, _marketCards, allTeamSummaries, marketSummary } = data;
    if (trainingSessionData) {
      this.store.commit('profitability/participant/setTrainingSessionData', trainingSessionData);
    }
    if (marketCards) {
      this.store.commit('profitability/participant/setMarketCards', marketCards);
    }
    if (_marketCards) {
      this.store.commit('profitability/participant/clearMarketCards');
      this.store.commit('profitability/participant/setMarketCards', _marketCards);
    }
    if (allTeamSummaries) {
      this.store.commit('profitability/participant/allTeamSummaries', allTeamSummaries);
    }
    if (marketSummary) {
      this.store.commit('profitability/participant/marketSummary', marketSummary);
    }
  };

  _onTrainingSessionPeriodUpdatedEvent = (event) => {
    debug(`profitability:Echo._onTrainingSessionPeriodUpdatedEvent`)(event);

    this.store.commit('profitability/participant/reset');

    this.getTrainingSessionData();
  };

  get(name, url) {
    return this.axios.get(url).then((response) => {
      const { data } = response;
      debug(name)(data);
      return data;
    }).catch(err => {
      debug(`${name}:err`)(err);
      throw err;
    });
  }

  post(name, url, data) {
    return this.axios.post(url, data).then((response) => {
      const { data } = response;
      debug(name)(data);
      return data;
    }).catch(err => {
      debug(`${name}:err`)(err);
      throw err;
    });
  }

  api() {
    return this.get('/api/participant').then((response) => {
      const { data } = response;
      return data;
    });
  }

  getTrainingSessionData() {
    return this.get('getTrainingSessionData', '/api/participant/trainingSessionData').then((data) => {
      const {
        training,
        trainingSession,
        trainingSessionData,
        teams,
        team,
        teamData,
        teamGameboard,
        factories,
        marketCards,
        allTeamSummaries,
        marketSummary,
      } = data;
      const { marketing_data } = teamData;

      this.store.commit('profitability/participant/setTraining', training);
      this.store.commit('profitability/participant/setTrainingSession', trainingSession);
      this.store.commit('profitability/participant/setTrainingSessionData', trainingSessionData);
      this.store.commit('profitability/participant/setTeams', teams);
      this.store.commit('profitability/participant/setTeam', team);
      this.store.commit('profitability/participant/setTeamData', teamData);
      if (data.teamDataList) this.store.commit('profitability/participant/setTeamDataList', data.teamDataList);
      this.store.commit('profitability/participant/setGameboard', teamGameboard);
      this.store.commit('profitability/participant/setMarketingData', marketing_data);
      this.store.commit('profitability/participant/setFactories', factories);
      if (marketCards) {
        this.store.commit('profitability/participant/setMarketCards', marketCards);
      }
      if (allTeamSummaries) {
        this.store.commit('profitability/participant/allTeamSummaries', allTeamSummaries);
      }
      if (marketSummary) {
        this.store.commit('profitability/participant/marketSummary', marketSummary);
      }

      return data;
    });
  }

  prevPhaseStep() {
    const phaseStep = this.store.getters['profitability/participant/teamData'].phase_step;
    const targetStep = Math.max(phaseStep - 1, 0);
    if (phaseStep !== targetStep) {
      return this.updateTeamStep(targetStep);
    }
    return Promise.resolve();
  }

  nextPhaseStep() {
    const phaseStep = this.store.getters['profitability/participant/teamData'].phase_step;
    const targetStep = phaseStep + 1;
    return this.updateTeamStep(targetStep);
  }

  /**
   * Update phase's step
   * @param {Integer} targetStep
   * @returns {*}
   */
  updateTeamStep(targetStep) {
    return this.post('updateTeamStep', '/api/participant/team/step', {
      phase_step: targetStep,
    });
  }

  updateState(state) {
    return this.post('updateState', '/api/participant/team/state', state);
  }

  /**
   * Take loan
   * @param {Number} amount
   */
  takeLoan(amount) {
    return this.post('takeLoan', '/api/participant/team/takeLoan', {
      amount,
    });
  }

  /**
   * The action that take loan (cash) from bank to cash
   */
  bankLoanToCash() {
    return this.post('bankLoanToCash', '/api/participant/team/bankLoanToCash');
  }

  /**
   * The action that take loan from bank to loan (interest)
   * @param {'standard'|'high'} type
   */
  bankLoanToLoans(type) {
    return this.post('bankLoanToLoans', '/api/participant/team/bankLoanToLoans', {
      type,
    });
  }

  /**
   * Pre-market
   * Step: 0
   * @param {Object} operationalPlan
   * @returns {*}
   */
  updateOperationalPlan(operationalPlan) {
    return this.post('updateOperationalPlan', '/api/participant/team/operationalPlan', operationalPlan);
  }

  updateTeamOperationalPlan() {
    return this.get('updateTeamOperationalPlan', '/api/participant/team/updateTeamOperationalPlan');
  }

  /**
   * Pre-market
   * Step: 0
   * @param {Object} cashflowForecast
   * @returns {*}
   */
  updateCashflowForecast(cashflowForecast) {
    return this.post('updateCashflowForecast', '/api/participant/team/cashflowForecast', cashflowForecast);
  }

  updateTeamData(operationalPlan, cashflowForecast) {
    return this.post('updateTeamData', '/api/participant/team/teamData', 
      {
        "operational_plan": operationalPlan,
        "cashflow_forecast": cashflowForecast
      }
    )
  }

  /**
   * Pre-market
   * Step: 0
   * @param {Object} marketingData
   * @param {Object} marketingData.classic
   * @param {Number} marketingData.classic.unit_price
   * @param {Integer} marketingData.classic.pfme
   * @param {Integer} marketingData.classic.rnd
   * @param {Object} marketingData.gold
   * @param {Number} marketingData.gold.unit_price
   * @param {Integer} marketingData.gold.pfme
   * @param {Integer} marketingData.gold.rnd
   * @returns {*}
   */
  updateTeamMarketingData(marketingData) {
    return this.post('updateTeamMarketingData', '/api/participant/team/marketingData', marketingData);
  }

  /**
   * Pre-market
   * Step: 1
   * @returns {*}
   */
  collectPaymentFromCustomer() {
    return this.post('collectPaymentFromCustomer', '/api/participant/team/collectPaymentFromCustomer');
  }

  /**
   * Pre-market
   * Step: 2
   * @param {Integer} amount
   * @returns {*}
   */
  payPayables(amount) {
    return this.post('payPayables', '/api/participant/team/payPayables', {
      amount,
    });
  }

  /**
   * Pre-market
   * Step: 3.1
   * @param {Integer} factory_slot
   * @param {('classic'|'gold')} equipment_type
   * @returns {*}
   */
  buyEquipment(factory_slot, equipment_type) {
    return this.post('buyEquipment', '/api/participant/team/buyEquipment', {
      factory_slot,
      equipment_type,
    });
  }

  /**
   * Pre-market
   * Step: 3.2
   * @param {Integer} factory_slot
   * @param {Integer} amount
   * @returns {*}
   */
  buyEquipmentPayWip(factory_slot, amount) {
    return this.post('buyEquipmentPayWip', '/api/participant/team/buyEquipmentPayWip', {
      factory_slot,
      amount,
    });
  }

  /**
   * Pre-market
   * Step: 4
   * @param {Integer} amount
   * @param {'rnd'|'pfme'} type
   * @returns {*}
   */
  payRnDnPFME(type, amount) {
    return this.post('payRnDnPFME', '/api/participant/team/payRnDnPFME', {
      type,
      amount,
    });
  }

  /**
   * Pre-market
   * Step: 4
   * @param {Integer} amount
   * @returns {*}
   */
  payRnD(amount) {
    return this.payRnDnPFME('rnd', amount);
  }

  /**
   * Pre-market
   * Step: 4
   * @param {Integer} amount
   * @returns {*}
   */
  payPFME(amount) {
    return this.payRnDnPFME('pfme', amount);
  }

  /**
   * Pre-market
   * Step: 5.1
   * @param {Object} data
   * @param {Integer} data.classic
   * @param {Integer} data.gold
   * @returns {*}
   */
  buyCoPack(data) {
    return this.post('buyCoPack', '/api/participant/team/buyCoPack', {
      ...data,
    });
  }

  /**
   * Pre-market
   * Step: 5.2
   * @param {Integer} amount
   * @returns {*}
   */
  buyCoPackPaySuppliers(amount) {
    return this.post('buyCoPackPaySuppliers', '/api/participant/team/buyCoPackPaySuppliers', {
      amount,
    });
  }

  /**
   * Post-market
   * Step: 0.1
   * @param {Object} data
   * @param {Integer} data.card_id
   * @param {Integer} data.raw
   * @param {Integer} data.co_pack
   * @returns {*}
   */
  makeDelivery(data) {
    return this.post('makeDelivery', '/api/participant/team/makeDelivery', {
      ...data,
    });
  }

  /**
   * Post-market
   * Step: 0.2
   * @param {'cash'|'credit'} type
   * @param {Integer} amount
   * @returns {*}
   */
  collectDeliveryPayment(type, amount) {
    return this.post('collectDeliveryPayment', '/api/participant/team/collectDeliveryPayment', {
      type,
      amount,
    });
  }

  /**
   * Post-market
   * Step: 1
   * @param {'ffoh'|'moge'} type
   * @param {Integer} amount
   * @returns {*}
   */
  payFFOHnMOGE(type, amount) {
    return this.post('payFFOHnMOGE', '/api/participant/team/payFFOHnMOGE', {
      type,
      amount,
    });
  }

  /**
   * Post-market
   * Step: 2
   * @param {Object} data
   * @param {Integer} data.classic
   * @param {Integer} data.gold
   * @returns {*}
   */
  buyRawPackagingMaterials(data) {
    return this.post('buyRawPackagingMaterials', '/api/participant/team/buyRawPackagingMaterials', {
      ...data,
    });
  }

  buyRawPackagingMaterialsPaySuppliers(amount) {
    return this.post('buyRawPackagingMaterialsPaySuppliers', '/api/participant/team/buyRawPackagingMaterialsPaySuppliers', {
      amount
    });
  }

  buyRawPackagingMaterialsToPayables() {
    return this.post('buyRawPackagingMaterialsToPayables', '/api/participant/team/buyRawPackagingMaterialsToPayables');
  }

  /**
   * Post-market
   */

  /**
   * @param {'standard'|'high'} type
   * @param {Integer} amount
   */
  repayLoan(type, amount) {
    return this.post('repayLoan', '/api/participant/team/repayLoan', {
      type,
      amount,
    });
  }

  /**
   * @param {Integer} amount
   */
  payLoanInterest(amount) {
    return this.post('payLoanInterest', '/api/participant/team/payLoanInterest', {
      amount,
    });
  }

  /**
   * Post-market
   * Step: 4
   * @param {Integer} factory_slot
   * @returns {*}
   */
  depreciateEquipment(factory_slot) {
    return this.post('depreciateEquipment', '/api/participant/team/depreciateEquipment', {
      factory_slot,
    });
  }

  /**
   * Post-market
   * Step: 5
   * @param {Object} data
   * @returns {*}
   */
  submitIncomeStatement(data) {
    return this.post('submitIncomeStatement', '/api/participant/team/submitIncomeStatement', {
      ...data,
    });
  }

  /**
   * Post-market
   * Step: 6
   * @param {'tax'|'dividends'} type
   * @param {Integer} amount
   * @returns {*}
   */
  payTaxNDividends(type, amount) {
    return this.post('payTaxNDividends', '/api/participant/team/payTaxNDividends', {
      type,
      amount,
    });
  }

  /**
   * Post-market
   * Step: 7
   * @param {Object} data
   * @returns {*}
   */
  submitBalanceSheet(data) {
    return this.post('submitBalanceSheet', '/api/participant/team/submitBalanceSheet', {
      ...data,
    });
  }

  /**
   * Post-market
   * Step: 8
   * @param {Object} data
   * @returns {*}
   */
  submitPerformanceAnalysis(data) {
    return this.post('submitPerformanceAnalysis', '/api/participant/team/submitPerformanceAnalysis', {
      ...data
    });
  }

  // ================================================================
  // MBS

  mbsData(stepValue) {
    return this.post('mbsData', '/api/participant/team/mbs/mbsForm', { step: stepValue });
  }

  /**
   *
   * @param data.step
   * @param data.data
   * @returns {*}
   */
  mbsNext(data) {
    return this.post('mbsNext', '/api/participant/team/mbs/mbsNext', data);
  }

  /**
   * Step: 0
   * @returns {*}
   */
  mbsInvestorToCash() {
    return this.post('mbsInvestorToCash', '/api/participant/team/mbs/investorToCash');
  }

  /**
   *
   * Step: 1
   * @returns {*}
   */
  mbsCashToLandNBuilding() {
    return this.post('mbsCashToLandNBuilding', '/api/participant/team/mbs/cashToLandNBuilding');
  }

  /**
   * Step: 2.1
   *
   * @param {Integer} factory_slot
   * @param {('classic'|'gold')} equipment_type
   * @returns {*}
   */
  mbsBuyEquipment(factory_slot, equipment_type) {
    return this.post('mbsBuyEquipment', '/api/participant/team/mbs/buyEquipment', {
      factory_slot,
      equipment_type,
    });
  }

  /**
   * Step: 2.2
   *
   * @param {Integer} factory_slot
   * @param {Integer} amount
   * @returns {*}
   */
  mbsBuyEquipmentPayWip(factory_slot, amount) {
    return this.post('mbsBuyEquipmentPayWip', '/api/participant/team/mbs/buyEquipmentPayWip', {
      factory_slot,
      amount,
    });
  }

  /**
   * Step: 3.1
   *
   * @param {Object} data
   * @param {Integer} data.classic
   * @returns {*}
   */
  mbsBuyCoPack(data) {
    return this.post('mbsBuyCoPack', '/api/participant/team/mbs/buyCoPack', data);
  }

  /**
   * Step: 3.2
   *
   * @param {Integer} amount
   * @returns {*}
   */
  mbsBuyCoPackPaySuppliers(amount) {
    return this.post('mbsBuyCoPackPaySuppliers', '/api/participant/team/mbs/buyCoPackPaySuppliers', {
      amount,
    });
  }

  /**
   * Step: 4
   *
   * @param {Object} data
   * @param {Integer} data.card_id
   * @param {Integer} data.raw
   * @param {Integer} data.co_pack
   * @returns {*}
   */
  mbsMakeDelivery(data) {
    return this.post('mbsMakeDelivery', '/api/participant/team/mbs/makeDelivery', data);
  }

  /**
   * Step: 4.1
   *
   * @returns {*}
   */
  mbsCustomersToReceivables() {
    return this.post('mbsCustomersToReceivables', '/api/participant/team/mbs/customersToReceivables');
  }

  /**
   * Step: 5
   * @param {'ffoh'|'moge'} type
   * @param {Integer} amount
   * @returns {*}
   */
  mbsPayFFOHnMOGE(type, amount) {
    return this.post('mbsPayFFOHnMOGE', '/api/participant/team/mbs/payFFOHnMOGE', {
      type,
      amount,
    });
  }

  /**
   * Step: 6
   * @param amount
   * @returns {*}
   */
  mbsTakeLoan(amount) {
    return this.post('takeLoan', '/api/participant/team/mbs/takeLoan', {
      amount,
    });
  }

  /**
   * Step: 7
   * @param {Object} data
   * @param {Integer} data.classic
   * @param {Integer} data.gold
   * @returns {*}
   */
  mbsBuyRaw(data) {
    return this.post('  mbsBuyRaw(data) {\n', '/api/participant/team/mbs/buyRaw', {
      ...data,
    });
  }

  /**
   * Step: 7.1
   * @param amount
   * @returns {*}
   */
  mbsBuyRawPaySuppliers(amount) {
    return this.post('mbsBuyRawPaySuppliers', '/api/participant/team/mbs/buyRawPaySuppliers', {
      amount
    });
  }

  /**
   * Step: 8
   * @param amount
   * @returns {*}
   */
  mbsPayInterest(amount) {
    return this.post('mbsBuyRawPaySuppliers', '/api/participant/team/mbs/payInterest', {
      amount
    });
  }

  /**
   * Step: 8.1
   * @param {Integer} amount
   * @returns {*}
   */
  mbsPayLoan(amount) {
    return this.post('mbsBuyRawPaySuppliers', '/api/participant/team/mbs/payLoan', {
      amount
    });
  }

  /**
   * Step: 9
   * @param {Integer} factory_slot
   * @returns {*}
   */
  mbsDepreciateEquipment(factory_slot) {
    return this.post('mbsDepreciateEquipment', '/api/participant/team/mbs/depreciateEquipment', {
      factory_slot
    });
  }

  updateFactories(factory) {
    return this.post('updateFactories', '/api/participant/team/updateTeamData', {
      factories: factory
    })
  }
}
