import _ from 'lodash';
import Factory from './models/Factory';
import Gameboard from './models/Gameboard';
import MarketCard from './models/MarketCard';
import Team from './models/Team';
import TeamData from "./models/TeamData";

export default {
  namespaced: true,
  state() {
    return {
      training: null,
      trainingSession: null,
      trainingSessionData: {
        period: 0,
        market_phase: 0,
        data: {
          phase_step: 0,
        },
      },
      marketing_data: {
        classic: {
          unit_price: 0,
          pfme: 0,
          rnd: 0,
        },
        gold: {
          unit_price: 0,
          pfme: 0,
          rnd: 0,
        },
      },
    };
  },
  getters: {
    training: state => state.training,
    trainingSession: state => state.trainingSession,
    trainingSessionData: state => state.trainingSessionData,
    teams: state => {
      return Team.all();
    },
    teamDict: state => {
      return _.keyBy(Team.all(), 'id');
    },
    team: state => {
      return Team.find(state.team_id);
    },
    teamData: state => {
      const period = state.trainingSession?.period || 0;
      return TeamData.query().where('period', period).first() || {};
    },
    teamDataList: state => {
      return TeamData.query().get();
    },
    teamDataByPeriod: state => {
      return _.keyBy(TeamData.query().get(), 'period');
    },
    phase: (state, getters) => {
      const { period, market_phase } = state.trainingSessionData;
      let phase_step = 0;
      if (market_phase === 1) {
        const { data } = state.trainingSessionData;
        phase_step = data?.phase_step || 0;
      } else {
        phase_step = getters.teamData?.phase_step || 0;
      }
      return {
        period,
        market_phase,
        phase_step,
      };
    },
    gameboard: state => {
      return Gameboard.query().where('team_id', state.team_id).first();
    },
    factories: state => {
      return Factory.query().with('slots').where('team_id', state.team_id).get() || [];
    },
    marketing_data: (state, getters) => {
      const teamData = getters.teamData;
      return teamData?.marketing_data || {};
    },
    marketCards: state => {
      return MarketCard.all();
    },
    teamMarketCards: state => {
      return MarketCard.query().where('team_id', state.team_id).get() || [];
    },
    allTeamSummaries: state => state.allTeamSummaries,
    marketSummary: state => state.marketSummary,
  },
  mutations: {
    reset(state) {
      state.trainingSessionData = {
        period: 0,
        market_phase: 0,
        data: {
          phase_step: 0,
        },
      };
      state.marketing_data = {
        classic: {
          unit_price: 0,
          pfme: 0,
          rnd: 0,
        },
        gold: {
          unit_price: 0,
          pfme: 0,
          rnd: 0,
        },
      };

      Gameboard.deleteAll();
      MarketCard.deleteAll();
    },
    setTraining(state, training) {
      state.training = training;
    },
    setTrainingSession(state, trainingSession) {
      state.trainingSession = trainingSession;
    },
    setTrainingSessionData(state, trainingSessionData) {
      state.trainingSessionData = trainingSessionData;
    },
    setTeams(state, teams) {
      _.each(teams, (team, i) => {
        team.index = i;
      });

      Team.insertOrUpdate({
        data: teams,
      });
    },
    setTeam(state, team) {
      Team.insertOrUpdate({
        data: team,
      });

      state.team_id = team.id;
    },
    setTeamData(state, teamData) {
      if (!teamData.period) {
        teamData.period = state.trainingSessionData.period;
      }
      TeamData.insertOrUpdate({
        data: teamData,
      });
    },
    setTeamDataList(state, teamDataList) {
      _.each(teamDataList, teamData => {
        teamData.period = teamData.training_session_data.period;

        TeamData.insertOrUpdate({
          data: teamData,
        });
      });
    },
    setGameboard(state, gameboard) {
      Gameboard.insertOrUpdate({
        data: gameboard,
      });
    },
    setMarketingData(state, marketing_data) {
      state.marketing_data = marketing_data;
    },
    clearMarketCards(state, marketCards) {
      MarketCard.deleteAll();
    },
    setMarketCards(state, marketCards) {
      MarketCard.insertOrUpdate({
        data: marketCards,
      });
    },
    setFactories(state, factories) {
      Factory.insertOrUpdate({
        data: factories,
      });
    },
    allTeamSummaries(state, allTeamSummaries) {
      state.allTeamSummaries = allTeamSummaries;
    },
    marketSummary(state, marketSummary) {
      state.marketSummary = marketSummary;
    },
  },
  actions: {
  },
}
