<style lang="scss">

.Marketing_Card {
    top: 8.5%;
    left: 6%;
    position: fixed;
    width: 43.6%;
    height: 79.8%;

    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.Classic {
        background-image: url("../../../assets/Facilitator/Marketing_Cards_Classic.png");
    }

    &.Gold {
        background-image: url("../../../assets/Facilitator/Marketing_Cards_Gold.png");
    }

    p {
        /* background: green; */
        color: white;
        position: absolute;
        width: 10%;
        height: 4.1%;
        font-size: 1.5rem;
        padding: 0;
        margin: 0;
    }

    .Cash {
        left: 40.2%;

        &.Unit_6 { top: 30.4%; }
        &.Unit_5 { top: 38.5%; }
        &.Unit_4 { top: 46.5%; }
        &.Unit_3 { top: 54.7%; }
        &.Unit_2 { top: 62.8%; }
        &.Unit_1 { top: 71.3%; }
    }

    .Increase_Cash {
        left: 26.9%;
        position: fixed;
        width: 1.8%;
        height: 3%;
        background-color: transparent;
        border-color: transparent;

        &.Unit_6 { top: 33%; }
        &.Unit_5 { top: 39.2%; }
        &.Unit_4 { top: 45.8%; }
        &.Unit_3 { top: 52.2%; }
        &.Unit_2 { top: 58.7%; }
        &.Unit_1 { top: 65.5%; }
    }

    .Decrease_Cash {
        left: 22.65%;
        position: fixed;
        width: 1.8%;
        height: 3%;
        background-color: transparent;
        border-color: transparent;

        &.Unit_6 { top: 33%; }
        &.Unit_5 { top: 39.2%; }
        &.Unit_4 { top: 45.8%; }
        &.Unit_3 { top: 52.2%; }
        &.Unit_2 { top: 58.7%; }
        &.Unit_1 { top: 65.5%; }
    }

    .Credit {
        left: 57.4%;

        &.Unit_6 { top: 30.4%; }
        &.Unit_5 { top: 38.5%; }
        &.Unit_4 { top: 46.5%; }
        &.Unit_3 { top: 54.7%; }
        &.Unit_2 { top: 62.8%; }
        &.Unit_1 { top: 71.3%; }
    }

    .Increase_Credit {
        left: 34.6%;
        position: fixed;
        width: 1.7%;
        height: 3%;
        background-color: transparent;
        border-color: transparent;

        &.Unit_6 { top: 33%; }
        &.Unit_5 { top: 39.2%; }
        &.Unit_4 { top: 45.8%; }
        &.Unit_3 { top: 52.2%; }
        &.Unit_2 { top: 58.7%; }
        &.Unit_1 { top: 65.5%; }
    }

    .Decrease_Credit {
        left: 30.2%;
        position: fixed;
        width: 1.8%;
        height: 3%;
        background-color: transparent;
        border-color: transparent;

        &.Unit_6 { top: 33%; }
        &.Unit_5 { top: 39.2%; }
        &.Unit_4 { top: 45.8%; }
        &.Unit_3 { top: 52.2%; }
        &.Unit_2 { top: 58.7%; }
        &.Unit_1 { top: 65.5%; }
    }

    .Total {
        left: 79%;

        &.Unit_6 { top: 30.4%; }
        &.Unit_5 { top: 38.5%; }
        &.Unit_4 { top: 46.5%; }
        &.Unit_3 { top: 54.7%; }
        &.Unit_2 { top: 62.8%; }
        &.Unit_1 { top: 71.3%; }
    }

    .Cards {
        left: 23.5%;
        top: 83%;
    }
    .Demand {
        left: 53.7%;
        top: 83%;
    }

    .Units {
        left: 83.8%;
        top: 83%;
    }
    .black-text {
        color: black;
    }


    .Button_Page {
        top: 81%;
        position: fixed;
        height: 4.8%;
        border: none;
        border-radius: 25px;
        font-size: 22px;

        &.Proceed_1 {
            left: 18%;
            width: 19.75%;
            background: #e1261d;
            color: white;
        }
        &.Proceed_1:hover {
            background: #9e1a15;
        }
        &.Proceed_1:active {
            background: #392c23;
        }

        &.Proceed_2 {
            left: 28.5%;
            width: 19.6%;
            background: #ffd751;
            color: black;
        }
        &.Proceed_2:hover {
            background: #b39638;
            color: white;
        }
        &.Proceed_2:active {
            background: #392c23;
            color: white;
        }

        &.Previous_2 {
            left: 7.5%;
            width: 12.3%;
            background: url("../../../assets/Facilitator/Button_BackClassic.png");
        }
        &.Previous_2:hover {
            background: url("../../../assets/Facilitator/Button_BackClassic_HOVER.png");
        }
        &.Previous_2:active {
            background: url("../../../assets/Facilitator/Button_BackClassic_ACTIVE.png");
        }
    }
}

</style>

<template>

    <div class="Marketing_Card" :class="boardClass">
        <template v-for="i in inverseOrderIndexes" :key="i">
            <button class="Increase_Cash" :class="`Unit_${i+1}`" @click="Increase_Cash(i)"></button>
            <p class="Cash" :class="`Unit_${i+1}`">{{ cash[i] }}</p>
            <button class="Decrease_Cash" :class="`Unit_${i+1}`" @click="Decrease_Cash(i)"></button>

            <button class="Increase_Credit" :class="`Unit_${i+1}`" @click="Increase_Credit(i)"></button>
            <p class="Credit" :class="`Unit_${i+1}`">{{ credit[i] }}</p>
            <button class="Decrease_Credit" :class="`Unit_${i+1}`" @click="Decrease_Credit(i)"></button>

            <template v-if="type === 'classic'">
                <p class="Total" :class="`Unit_${i+1}`">{{ totalUnitPerCards[i] }}</p>
            </template>
            <template v-if="type === 'gold'">
                <p class="Total black-text" :class="`Unit_${i+1}`">{{ totalUnitPerCards[i] }}</p>
            </template>
        </template>

        <template v-if="type === 'classic'">
            <p class="Cards">{{ totalCards }}</p>
            <p class="Demand">{{ demands }}</p>
            <p class="Units">{{ totalUnits }}</p>

            <button class="Button_Page Proceed_1" @click="next()">Confirm Nescafé Classic Market</button>
        </template>
        <template v-if="type === 'gold'">
            <p class="Cards black-text">{{ totalCards }}</p>
            <p class="Demand black-text">{{ demands }}</p>
            <p class="Units black-text">{{ totalUnits }}</p>
            
            <button class="Button_Page Previous_2" @click="prev()"></button>
            <button class="Button_Page Proceed_2" @click="next()">Confirm Nescafé Gold Market</button>
        </template>

    </div>

</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
    props: {
        type: {
            type: String,
            validator: (value) => {
                return ['classic', 'gold'].indexOf(value) !== -1;
            },
        },
        input: Object,
    },
    emits: [
        'prev',
        'next',
        'update:modelValue',
    ],
    data() {
        return {
            inverseOrderIndexes: _.reverse(_.times(6)),

            cash: _.times(6, n => 0),
            credit: _.times(6, n => 0),
            demands: 0,
        };
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
            vTrainingSessionData: 'trainingSessionData',
        }),
        boardClass() {
            return {
                classic: 'Classic',
                gold: 'Gold',
            }[this.type];
        },
        marketCardSetup() {
            return this.vTrainingSessionData?.market_card_setup || {};
        },
        totalCards() {
            return _.sum(_.map(_.times(6), i => {
                return this.cash[i] + this.credit[i];
            }));
        },
        totalUnitPerCards() {
            return _.map(_.times(6), i => {
                return (this.cash[i] + this.credit[i]) * (i + 1);
            });
        },
        totalUnits() {
            return _.sum(this.totalUnitPerCards);
        },
    },
    watch: {
        type() {
            const { marketCardSetup } = this;
            this.populateValues(marketCardSetup);
        },
        marketCardSetup(next) {
            this.populateValues(next);
        },
        cash: {
            deep: true,
            handler() {
                this.$emit('update:modelValue', {
                    cash: this.cash,
                    credit: this.credit,
                });
            },
        },
        credit: {
            deep: true,
            handler() {
                this.$emit('update:modelValue', {
                    cash: this.cash,
                    credit: this.credit,
                });
            },
        },
    },
    mounted() {
        const { marketCardSetup } = this;
        this.populateValues(marketCardSetup);
    },
    methods: {
        populateValues(marketCardSetup) {
            const { demand, cardSetup } = marketCardSetup;

            this.demands = demand && demand[this.type] || 0;
            this.cash = cardSetup && cardSetup[this.type]?.cash || [0, 0, 0, 0, 0, 0];
            this.credit = cardSetup && cardSetup[this.type]?.credit || [0, 0, 0, 0, 0, 0];
            this.$emit('update:modelValue', {
                cash: this.cash,
                credit: this.credit,
            });
        },
        Increase_Cash(column) {
            this.cash[column]++;
        },
        Decrease_Cash(column) {
            this.cash[column] = Math.max(0, this.cash[column] - 1);
        },
        Increase_Credit(column) {
            this.credit[column]++;
        },
        Decrease_Credit(column) {
            this.credit[column] = Math.max(0, this.credit[column] - 1);
        },
        validateDemandAndTotalUnit() {
            // validate demand to match unit
            if (this.demands !== this.totalUnits) {
                console.log('Demand and Units mismatch!');
                return false;
            }

            return true;
        },
        next() {
            if (!this.validateDemandAndTotalUnit()) {
                return;
            }

            this.$emit('next');
        },
        prev() {
            this.$emit('prev');
        },
    },
}
</script>
