<style lang="scss">
.pre-market-phase {
    .team-status-team {
        position: fixed;
        margin: 0% 0.5%;
        background: white;
        padding: 1rem;
        height: 22%;
        width: 21%;

        h5 {
            color: white;
            position: absolute;
            top: 2%;
            left: 8%;
            font-size: 24px;
            font-weight: normal;
        }
        h5.black {
            color: black;
        }

        .trainer_loan {
            position: absolute;
            top: 3.5%;
            right: 28%;
            width: 19%;
            height: 15%;
            border: none;
            background: #a3d65d;
            border-radius: 5px;
            font-size: 22px;
        }
        .trainer_loan:hover {
            background: #729541;
        }
        .trainer_loan:active {
            background: #392c23;
            color: white;
        }

        .reset_team {
            background: #e1261d;
            position: absolute;
            top: 3.5%;
            right: 49%;
            width: 17%;
            height: 15%;
            border: none;
            border-radius: 5px;
            font-size: 18px;
            color: #fff;

            &:hover {
                background: #9e1a15;
            }
            &:active {
                background: #392c23;
                color: white;
            }
        }

        .wrap-action {
            overflow-y: auto;
            position: relative;
            left: 4.7%;
            top: 17%;
            width: 100%;
            height: 84%;
            background: transparent;
        }

        .action {
            background: white;
            position: relative;
            width: 95.3%;
            text-align: left;
            font-size: 18px;
        }
        .action_text {
            margin-left: 3%;
            padding: 0.5px 0;
        }
        .action.round_border_up {
            border-radius: 8px 8px 0 0;
        }
        .action.round_border_down {
            border-radius: 0 0 8px 8px;
        }
        .action.done {
            background: #B5DD7D;
        }

        .ready {
            position: absolute;
            border-radius: 8px;
            margin-left: 4%;
            top: 20%;
            width: 88.5%;
            height: 75%;
            background-color: rgba(0, 0, 0, 0.8);
        }
        .ready p {
            color: white;
            margin-top: 10%;
            font-size: 1.5vw;
            width: 100%;
            height: auto;
        }
        .ready img {
            margin-top: 2%;
        }
    }

    .team-status-team.A {
        background: url("../../../assets/Facilitator/TeamA_nowhite.png");
        background-size: 100% 100%;
    }
    .team-status-team.B {
        background: url("../../../assets/Facilitator/TeamB_nowhite.png");
        background-size: 100% 100%;
        left: 27.8%;
    }
    .team-status-team.C {
        background: url("../../../assets/Facilitator/TeamC_nowhite.png");
        background-size: 100% 100%;
        top: 41%;
    }
    .team-status-team.D {
        background: url("../../../assets/Facilitator/TeamD_nowhite.png");
        background-size: 100% 100%;
        top: 41%;
        left: 27.8%;
    }
    .team-status-team.E {
        background: url("../../../assets/Facilitator/TeamE_nowhite.png");
        background-size: 100% 100%;
        top: 65%;
    }
    .team-status-team.F {
        background: url("../../../assets/Facilitator/TeamF_nowhite.png");
        background-size: 100% 100%;
        top: 65%;
        left: 27.8%;
    }

    .view_board {
        position: absolute;
        top: 3.5%;
        right: 3.4%;
        width: 22.2%;
        height: 15%;
        background: none;
        border: none;
    }
}

</style>

<template>

    <div class="team-status-team" :class="team.name">
        <h5 :class='{black: team.name == "E" || team.name == "F"}'>Team {{ team.name }}</h5>
        <button class="trainer_loan" @click="openLoanBox">Loan</button>
        <button class="reset_team" @click="resetTeamBox = true">Reset</button>

        <div class="wrap-action">
            <template v-if="vPhase.period < 3">
                <template v-for="(action, i) in preMarket" :key="i">
                    <div class="action"
                    :class="[{done: i < team.teamData.phase_step},
                            {done: team.teamData.phase_step == preMarket.length - 1},
                            {round_border_up: i == 0},
                            {round_border_down: i == preMarket.length - 1}]">
                        <div class="action_text">{{ action }}</div>
                    </div>
                </template>
            </template>

            <template v-else>
                <template v-for="(action, i) in preMarketOPCF" :key="i">
                    <div v-if="i == 0" class="action"
                    :class="[{done: submittedOPnCF},
                            {round_border_up: i == 0}]">
                        <div class="action_text">{{ action }}</div>
                    </div>
                    
                    <div v-else class="action"
                    :class="[{done: i <= team.teamData.phase_step},
                            {done: team.teamData.phase_step == preMarketOPCF.length - 1},
                            {round_border_down: i == preMarketOPCF.length - 1}]">
                        <div class="action_text">{{ action }}</div>
                    </div>
                </template>
            </template>
        </div>

        <div v-if="team.teamData.phase_step == preMarket.length - 1" class="ready">
            <p>Ready for Market</p>
            <img src="../../../assets/Facilitator/Ready_Checkmark.png" />
        </div>

        <Loan v-if="loanBox" :team_id="team.id" @takeLoan="this.loanBox = false" />

        <Alert v-if="resetTeamBox"
            :alertOpen="resetTeamBox"
            message="Reset this team?"
            :onlyClose="false"
            :resetTeamAtTrainer="true"
            @close_alert="resetTeamBox = false"
            @confirm_alert="resetTeam"
        />
    </div>

</template>

<script>
import { preMarket, preMarketOPCF } from '@/constants'
import Loan from '../Trainer_Loan.vue'
import Alert from "@/components/Alert_PopUp";
import { mapGetters } from 'vuex';

export default {
    components: { Loan, Alert },
    props: {
        team: Object,
    },
    computed: {
        ...mapGetters('profitability/trainer', {
            vTrainingSessionData: 'trainingSessionData',
            vPhase: 'phase',
            vTeams: 'teams',
            vAllTeamSummaries: 'allTeamSummaries',
        }),
        submittedOPnCF() {
            return this.team.teamData.operational_plan && this.team.teamData.cashflow_forecast
        },
    },
    data() {
        return {
            preMarket,
            preMarketOPCF,
            loanBox: false,
            resetTeamBox: false
        }
    },
    methods: {
        openLoanBox() {
            this.loanBox === true ? this.loanBox = false : this.loanBox = true
        },
        resetTeam() {
            this.resetTeamBox = false
            Profitability.Trainer.resetSingleTeam(this.team.id);
        }
    }
}

</script>
