<style lang="scss">
.period-0 {
    .team-status-team {
        position: fixed;
        background: white;
        margin: 0 4.2%;
        padding: 1rem;
        height: 30%;
        width: 28%;
        font-family: NestleBrush_Reg;

        h5 {
            color: white;
            position: absolute;
            top: 8%;
            left: 9%;
            font-size: 24px;
            font-weight: normal;
        }
        h5.black {
            color: black;
        }
        .wrap-action {
            overflow-y: scroll;
            position: relative;
            top: 17.5%;
            left: 7%;
            width: 92%;
            height: 80%;
            background: transparent;
        }

        .action {
            background: white;
            position: relative;
            width: 100%;
            /* height: 14%; */
            text-align: left;
        }
        .action_text {
            margin-left: 3%;
            padding: 6px 0;
        }
        .action.round_border_up {
            border-radius: 8px 8px 0 0;
        }
        .action.round_border_down {
            border-radius: 0 0 8px 8px;
        }
        .action.done {
            background: #B5DD7D;
        }

        .ready {
            position: absolute;
            border-radius: 8px;
            margin-left: 4%;
            top: 20%;
            width: 88.5%;
            height: 75%;
            background-color: rgba(0, 0, 0, 0.8);
        }
        .ready p {
            color: white;
            margin-top: 13%;
            font-size: 36px;
            width: 100%;
            height: auto;
        }
        .ready img {
            margin-top: 2%;
        }
    }

    .team-status-team.A {
        background: url("../../../assets/Facilitator/TeamA_mbs.png");
        background-size: 100% 100%;
    }
    .team-status-team.B {
        background: url("../../../assets/Facilitator/TeamB_mbs.png");
        background-size: 100% 100%;
        left: 32%;
    }
    .team-status-team.C {
        background: url("../../../assets/Facilitator/TeamC_mbs.png");
        background-size: 100% 100%;
        left: 62%;
    }
    .team-status-team.D {
        background: url("../../../assets/Facilitator/TeamD_mbs.png");
        background-size: 100% 100%;
        top: 47%;
    }
    .team-status-team.E {
        background: url("../../../assets/Facilitator/TeamE_mbs.png");
        background-size: 100% 100%;
        top: 47%;
        left: 32%;
    }
    .team-status-team.F {
        background: url("../../../assets/Facilitator/TeamF_mbs.png");
        background-size: 100% 100%;
        top: 47%;
        left: 62%;
    }
}

</style>

<template>

    <div class="team-status-team" :class="team.name">
        <h5 :class='{black: team.name == "E" || team.name == "F"}'>Team {{ team.name }}</h5>

        <div class="wrap-action">
            <template v-for="(action, i) in period_0_trainer" :key="i">
                <div class="action"
                    :class="[{done: i <= team.teamData?.phase_step},
                        {round_border_up: i == 0},
                        {round_border_down: i == period_0_trainer.length - 1}]">
                    <div class="action_text">{{ action }}</div>
                </div>
            </template>
        </div>

        <div v-if="team.teamData.phase_step == period_0_trainer.length" class="ready">
            <p>Ready for Debrief</p>
            <img src="../../../assets/Facilitator/Ready_Checkmark.png" />
        </div>
    </div>

</template>

<script>
import { period_0_trainer } from '@/constants';

export default {
    props: {
        team: Object,
    },
    data() {
        return {
            period_0_trainer,
        }
    },
}

</script>
