import _ from 'lodash';
import Team from './models/Team';
import MarketCard from './models/MarketCard';
import Factory from './models/Factory';

export default {
  namespaced: true,
  state() {
    return {
      training: null,
      trainingSession: null,
      trainingSessionData: {
        period: 0,
        market_phase: 0,
        data: {
          phase_step: 0,
        },
      },
    };
  },
  getters: {
    training: state => state.training,
    trainingSession: state => state.trainingSession,
    trainingSessionData: state => state.trainingSessionData,
    teams: state => {
      return Team.all();
    },
    teamDict: state => {
      return _.keyBy(Team.all(), 'id');
    },
    phase: state => {
      const { period, market_phase } = state.trainingSessionData;
      let phase_step = 0;
      if (market_phase === 1) {
        const { data } = state.trainingSessionData;
        phase_step = data?.phase_step || 0;
      } else {
        phase_step = state.teamData?.phase_step || 0;
      }
      return {
        period,
        market_phase,
        phase_step,
      };
    },
    factories: state => {
      return Factory.query().with('slots').get() || [];
    },
    marketCards: state => {
      return MarketCard.all();
    },
    allTeamSummaries: state => state.allTeamSummaries,
    marketSummary: state => state.marketSummary,
    getLeaderboardData: state => state.getLeaderboardData
  },
  mutations: {
    reset(state) {
      state.trainingSessionData = {
        period: 0,
        market_phase: 0,
        data: {
          phase_step: 0,
        },
      };
      MarketCard.deleteAll();
    },
    setTraining(state, training) {
      state.training = training;
    },
    setTrainingSession(state, trainingSession) {
      state.trainingSession = trainingSession;
    },
    setTrainingSessionData(state, trainingSessionData) {
      state.trainingSessionData = trainingSessionData;

      if (trainingSessionData.market_cards) {
        MarketCard.insertOrUpdate({
          data: trainingSessionData.market_cards,
        });
      }
    },
    setTeams(state, teams) {
      _.each(teams, (team, i) => {
        team.team_data = team.team_data[0];
        team.gameboard = team.gameboard[0];
        team.index = i;
      });

      Team.insertOrUpdate({
        data: teams,
      });
    },
    setTeam(state, data) {
      const { team_id, team, teamData, teamGameboard, factories } = data;

      let teamDirty = {
        id: team_id,
      };

      if (team) {
        teamDirty = {
          ...teamDirty,
          ...team,
        }
      }
      if (teamData) {
        teamDirty = {
          ...teamDirty,
          team_data: teamData,
        }
      }
      if (teamGameboard) {
        teamDirty = {
          ...teamDirty,
          gameboard: teamGameboard,
        }
      }

      Team.insertOrUpdate({
        data: teamDirty,
      });

      if (factories) {
        Factory.insertOrUpdate({
          data: factories,
        });
      }
    },
    clearMarketCards(state, marketCards) {
      MarketCard.deleteAll();
    },
    setMarketCards(state, marketCards) {
      MarketCard.insertOrUpdate({
        data: marketCards,
      });
    },
    setFactories(state, factories) {
      Factory.insertOrUpdate({
        data: factories,
      });
    },
    allTeamSummaries(state, allTeamSummaries) {
      state.allTeamSummaries = allTeamSummaries;
    },
    marketSummary(state, marketSummary) {
      state.marketSummary = marketSummary;
    },
  },
  actions: {
  },
}
