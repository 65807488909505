<style lang="scss">
.container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>

<template>
  <div class="container">
    <canvas id="nnlg"></canvas>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import point_A from "../../assets/Marketing/PostMarket/point_a.png";
import point_B from "../../assets/Marketing/PostMarket/point_b.png";
import point_C from "../../assets/Marketing/PostMarket/point_c.png";
import point_D from "../../assets/Marketing/PostMarket/point_d.png";
import point_E from "../../assets/Marketing/PostMarket/point_e.png";
import point_F from "../../assets/Marketing/PostMarket/point_f.png";

const pointA = new Image();
const pointB = new Image();
const pointC = new Image();
const pointD = new Image();
const pointE = new Image();
const pointF = new Image();
pointA.src = point_A;
pointB.src = point_B;
pointC.src = point_C;
pointD.src = point_D;
pointE.src = point_E;
pointF.src = point_F;

Chart.register(ChartDataLabels);
Chart.defaults.color = "#FFF";

export default {
  props: {
    data: Array,
    YAxisMax: Number,
  },
  name: "NNLG-Line-Chart",
  data() {
    let i = 0;
    return {
      lineChartData: {
        type: "line",
        data: {
          labels: ["TWO", "THREE", "FOUR", "FIVE"],
          min: 0,
          max: 70,
          datasets: [
            {
              label: "A",
              data: this.data[i++], // Edit these hard-coded values with backend values
              pointStyle: this.adjustPointStyleA,
              borderColor: "#59af8b",
              pointBackgroundColor: "#59af8b",
            },
            {
              label: "B",
              data: this.data[i++], // Edit these hard-coded values with backend values
              pointStyle: this.adjustPointStyleB,
              borderColor: "#6591d0",
              pointBackgroundColor: "#6591d0",
            },
            {
              label: "C",
              data: this.data[i++], // Edit these hard-coded values with backend values
              pointStyle: this.adjustPointStyleC,
              borderColor: "#fb8c50",
              pointBackgroundColor: "#fb8c50",
            },
            {
              label: "D",
              data: this.data[i++], // Edit these hard-coded values with backend values
              pointStyle: this.adjustPointStyleD,
              borderColor: "#9963b5",
              pointBackgroundColor: "#9963b5",
            },
            {
              label: "E",
              data: this.data[i++], // Edit these hard-coded values with backend values
              pointStyle: this.adjustPointStyleE,
              borderColor: "#ffd250",
              pointBackgroundColor: "#ffd250",
              datalabels: {
                color: "black",
              },
            },
            {
              label: "F",
              data: this.data[i++], // Edit these hard-coded values with backend values
              pointStyle: this.adjustPointStyleF,
              borderColor: "#d9cdb4",
              pointBackgroundColor: "#d9cdb4",
              datalabels: {
                color: "black",
              },
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              offset: true,
              min: 0,
              max: this.YAxisMax,
              ticks: {
                font: {
                  size: 36,
                  family: "NestleBrush_Reg",
                },
              },
              grid: {
                drawTicks: false,
                color: '#63513d',
                lineWidth: 5,
              },
            },
            x: {
              offset: true,
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 36,
                  family: "NestleBrush_Reg",
                },
              },
            },
          },
          //   layout: {
          //     padding: {
          //       right: 100,
          //       top: 50,
          //       left: 100,
          //       bottom: 50,
          //     },
          //   },
          elements: {
            point: {
              pointStyle: "circle",
              radius: 12,
            },
            line: {
              backgroundColor: "transparent",
              borderWidth: 8,
              tension: 0,
            },
          },
          plugins: {
            datalabels: {
              color: "white",
              font: {
                family: "NestleBrush_Reg",
                size: 30,
              },
              formatter: Math.round,
              display: this.displayLabel,
            },
            legend: false,
            tooltip: false,
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById("nnlg");
    new Chart(ctx, this.lineChartData);
  },
  computed: {
    ...mapGetters("profitability/participant", {
      vPhase: "phase",
    }),
  },
  methods: {
    adjustPointStyleA(ctx) {
      var index = ctx.dataIndex;
      return index + 1 === this.vPhase.period ? pointA : "circle";
    },
    adjustPointStyleB(ctx) {
      var index = ctx.dataIndex;
      return index + 1 === this.vPhase.period ? pointB : "circle";
    },
    adjustPointStyleC(ctx) {
      var index = ctx.dataIndex;
      return index + 1 === this.vPhase.period ? pointC : "circle";
    },
    adjustPointStyleD(ctx) {
      var index = ctx.dataIndex;
      return index + 1 === this.vPhase.period ? pointD : "circle";
    },
    adjustPointStyleE(ctx) {
      var index = ctx.dataIndex;
      return index + 1 === this.vPhase.period ? pointE : "circle";
    },
    adjustPointStyleF(ctx) {
      var index = ctx.dataIndex;
      return index + 1 === this.vPhase.period ? pointF : "circle";
    },
    displayLabel(ctx) {
      var index = ctx.dataIndex;
      return index + 1 === this.vPhase.period ? "auto" : false;
    },
  },
};
</script>
