<template>
  <div class="Section_Cash" ref="Cash_Box" :index="13">

    <img src="../../assets/Gameboard/Sections/section_cash.png" class="PlayerBoard_Cash" />

    <input
      :class="{
        frame_border:
          phase === 1 ||
          phase === 2 ||
          phase === 3 ||
          phase === 5 ||
          phase === 7,
      }"
      class="input_cash"
      v-model.number="amount"
      placeholder="0"
      maxlength="3"
      @change="withdraw_cash()"
      @focus="$event.target.select()"
    />

    <p class="Coin_Display">{{ cash_stored }}</p>
    <div class="Coin_Box">
      <CoinIndicator :pass_currency_type="1" :pass_cash="cash_stored" />
    </div>

    <div v-if="vDebug" style="position: absolute; font-weight: bold; color: black">
      <div>cash_stored: {{ cash_stored }}</div>
    </div>
    
    <div
      :class="{ frame_border: phase === 6 }" class="box"
    ></div>
  
  </div>
</template>

<script>
import CoinIndicator from "../Coin_Indicator.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    phase: Number,
    cash_stored: Number,
  },
  components: { CoinIndicator },
  data() {
    return {
      amount: 0,
      cash_withdrawn: 0,
      cash_put_in: 0,
    };
  },
  methods: {
    withdraw_cash() {
      var cash = parseInt(this.amount);
      this.amount = 0;

      if (cash > 0) {
        if (this.cash_stored >= cash) {
          this.cash_withdrawn = cash;
          this.$emit("withdrawal", cash);
          this.update_cash_box(cash);
        } else alert("Amount exceeds available cash");
      }
    },
    insert_cash(cash) {
      if (cash > 0) {
        // console.log("insert_cash = " + cash);
        this.cash_put_in = cash;
        this.update_cash_box(cash);
      }
    },
    update_cash_box(cash) {
      if (this.cash_withdrawn > 0) {
        this.$emit("cash_withdrawn", cash);
      } else if (this.cash_put_in > 0) {
        this.$emit("cash_put_in", cash)
      }

      this.cash_withdrawn = 0;
      this.cash_put_in = 0;
    },
  },
};
</script>

<style lang="scss">
.Section_Cash {
  top: 76%;
  left: 23.5%;
  position: fixed;
  width: 19.2%;
  height: 21.5%;

  .PlayerBoard_Cash {
    top: 76%;
    left: 23.5%;
    position: fixed;
    width: 19.2%;
    height: 21.5%;
  }

  .input_cash {
    top: 52.8%;
    left: 53.6%;
    position: absolute;
    width: 25%;
    height: 16.4%;
    font-family: NestleBrush_Reg;
    font-size: 24px;

    background: transparent;
    border: none;
    outline: none;
    text-align: center;
    z-index: 2;
  }

  .Coin_Box {
    font-weight: normal;
    position: absolute;
    width: 31%;
  }

  .Coin_Display {
    position: absolute;
    font-size: 2.1rem;
    top: 74.5%;
    right: 64.5%;
    color: white;
  }

  .frame_border {
    border: 5px solid red;
    border-radius: 8px;

    &.box {
      position: absolute;
      width: 100%;
      height: 73%;
    }
  }
}
</style>
