export const preMarket = [
    'Complete marketing plan',
    'Collect payment from customer',
    'Pay suppliers with cash',
    'Buy Equipment and WIP',
    'Pay for PFME and R&D',
    'Buy from co-packers',
    'Waiting for market phase',
];

export const preMarketOPCF = [
    'Complete Operational Planning & Cashflow',
    'Complete marketing plan',
    'Collect payment from customer',
    'Pay suppliers with cash',
    'Buy Equipment and WIP',
    'Pay for PFME and R&D',
    'Buy from co-packers',
    'Waiting for market phase',
]

export const postMarket = [
    'Complete delivery record, make deliveries',
    'Pay FFOH and MOGE',
    'Buy materials and manufacture',
    'Pay interest, repay at least 10% of loans',
    'Depreciate equipment',
    'Complete income statement',
    'Pay tax and dividends',
    'Complete balance sheet',
    'Waiting for next period',
];

export const postMarketPA = [
    'Complete delivery record, make deliveries',
    'Pay FFOH and MOGE',
    'Buy materials and manufacture',
    'Pay interest, repay at least 10% of loans',
    'Depreciate equipment',
    'Complete income statement',
    'Pay tax and dividends',
    'Complete balance sheet',
    'Complete performance analysis',
    'Waiting for next period',
]


export const period_0_trainer = [
    'Collect Cash from Investor',
    'Acquire Land & Building',
    'Buy Equipment (classic) ($15) & Pay WIP ($10)',
    'Buy Co-Pack (Classic) x 5 & Pay Supplier ($20)',
    'Make Delivery & Collect Payment',
    'Pay FFOH ($1) & MOGE ($2)',
    'Take Loan ($10)',
    'Buy Raw (Classic) x 6 & Pay Supplier ($12)',
    'Pay Bank Loan ($1) & Interest ($1)',
    'Depreciate Equipment',
];

export const period_0_step = [
    'Step 1: Start the business',
    'Step 2: Buy land & buildings',
    'Step 3: Buy equipment & WIP',
    'Step 4: Buy from co-packers',
    'Step 5: Sell 5 units',
    'Step 6: Pay FFOH and MOGE',
    'Step 7: Take bank loan',
    'Step 8: Buy materials & manufacture',
    'Step 9: Pay interest, repay loan',
    'Step 10: Depreciate equipment',
    'End of Period 1'
];

export const period_0 = [
    'Shareholders are putting money into your business! This is called Share Capital.\n\nACTION:\n\t1.\tDrag the 75 chips from Investors into Cash.\n\t2.\tPut the value of your Cash into the Balance Sheet, and the\n\t\tsame amount in Share Capital.',

    'You need to buy a factory building and warehouse.\n\nACTION:\n\t1.\tType 20 in the "Enter amount" box in Cash and press\n\t\tEnter.\n\t2.\tDrag the 20 to Land and Buildings. The chips will change\n\t\tcolour to show that this is a Fixed Asset.\n\t3.\tFill in the Moving Balance Sheet.',

    'Now buy a Classic production line. You also need to show WIP (Work in Progress).\n\nACTION:\n\t1.\tEnter 15 in Cash.\n\t2.\tDrag the 15 to the top left "Buy Equipment Line".\n\t3.\tEnter 10 in Cash.\n\t4.\tDrag the 10 to Work in Progress on the production line.\n\t5.\tFill in the Moving Balance Sheet.',

    'Your production line takes time to set up. In the meantime, we can buy products from \'Co-Packers\'...\n\nACTION:\n\t1.\tClick on "Suppliers" to open a "Co-packer Order" form.\n\t2.\tEnter 5 for Units Ordered in the Classic column and\n\t\tclick Place Order.\n\n5 co-packed units will appear in a warehouse, and 20 red chips appear on the Suppliers box to show what you owe them. Now you need to pay the suppliers from Cash.\n\nACTION:\n\t1.\tEnter 20 in Cash.\n\t2.\tDrag the 20 to Suppliers, and the cash and red chips\n\t\twill both disappear.\n\t5.\tFill in the Moving Balance Sheet.',

    'You have sold 5 of your units at a total price of 25. The customer has agreed credit terms. They won\'t pay you in cash now; they will pay you at the start of next period. The price will be shown as “Owed from Customers”.\n\nACTION:\n\t1.\tClick on Delivery to the right, to open the Delivery\n\t\tRecord.\n\t2.\tEnter 5 in Co-packed Units (under Allocate units for this\n\t\tdelivery).\n\t3.\tEnter 20 in Total Cost.\n\t4.\tEnter 5 in Margin Made.\n\t5.\tClick Deliver: the stock disappears, and 25 appears in\n\t\tCustomers.\n\t6.\tDrag the 25 down to Owed from Customers.\n\t7.\tClick Close.\n\t8.\tFill in the Moving Balance Sheet.',

    'You need to pay for the expenses.\n\nACTION:\n\t1.\tEnter 1 in Cash.\n\t2.\tDrag the 1 to FFOH.\n\t3.\tEnter 2 in Cash.\n\t4.\tDrag the 2 to MOGE.\n\t5.\tFill in the Balance Sheet.',

    'You are running low on cash, and don\'t have enough to pay for raw and packaging materials. You need a bank loan.\n\n ACTION:\n\t1.\tClick on Bank.\n\t2.\tClick the green "up" arrow once.\n\t3.\tClick on the 10 and silver appears.\n\t4.\tDrag the 10 into Cash. 10 red chips appear on Loans, to\n\t\tshow how much you owe the bank.\n\t5.\tFill in the Balance Sheet.',

    'You can now buy raw and packaging materials and make your product.\n\nACTION:\n\t1.\tClick on Suppliers to open the Order  Form.\n\t2.\tEnter 6 Units Ordered under Classic, and click Place\n\t\tOrder. 6 units of product appear in the Production Line\n\t\tand 12 red chips appear in Suppliers to show how much\n\t\tyou owe them.\n\t3.\tEnter 12 in Cash.\n\t4.\tDrag the 12 to Suppliers to pay them.\n\t5.\tFill in the Balance Sheet.',

    'You need to pay interest on your loans, and repay 10% of the loan every period.\n\nACTION:\n\t1.\tEnter 1 in Cash.\n\t2.\tDrag the 1 to Interest.\n\t3.\tEnter 1 in Cash.\n\t4.\tDrag the 1 to Loans, which goes down to 9.\n\t5.\tFill in the Balance Sheet.',

    'Depreciation is how assets reduce in value over time. We need to show this...\n\nACTION:\n\t1.\tClick on the Depreciate button on the Production Line.\n\t2.\tDrag the brown 1 to Depreciation expense.\n\t3.\tFill in the Balance Sheet.',

    'Well done you have completed period 1!\n\nWait for the facilitator to move you back to the main room.'
];

export const infoBoxP2PreMarket = [
    'Check the RULES if you need a reminder on anything. If you install new equipment now, it will only produce in next period, so if you want more stock to sell in this period, buy it from co-packers.\n\nACTION:\n1.\tClick on MARKETING to open the form\n2.\tSet your Price, PFME and R&D spends, and\n\tplace co-packer orders for each product. You\n\twill be committed to these decisions, so make\n\tsure the team agrees before moving ahead.',

    'Your customers owe you 25 (trade receivables) from last period, which they are now ready to pay.\n\nACTION:\n1.\tDrag the 25 silvers from Receivables to Cash.\n2.\tClick NEXT',
    
    '',

    '',

    'You need to pay the PFME and R&D costs shown in your Marketing Plan.\n\nACTION:\n1.\tOpen the Marketing Plan\n2.\tPay the Total PFME shown, from Cash\n3.\tPay the Total R&D shown, from Cash\n4.\tClick NEXT',

    'You are going to buy 1 unit of Gold from co-packers, and you have to pay them Cash.\n\nACTION:\n1.\tClick on SUPPLIERS; the order is pre-filled\n2.\tClick PLACE ORDER\n\nOne co-packed Gold appears in the warehouse, and 7 reds on SUPPLIERS\n\n3.\tPay 7 from Cash to SUPPLIERS\n4.\tClick NEXT',
];

export const infoBoxP2PostMarket = [
    'When you win orders, they show up as Market Cards once you click DELIVERY. The details of each card show in the Delivery Record, one at a time. You need to allocate (Produced or Co-packed) goods to each order, Deliver it, and collect the Cash or show the Receivable.\n\nACTION:\n1.\tClick DELIVERY\n2.\tEnter the number of Produced or Co-Packed\n\tunits under ALLOCATE UNITS FOR THIS\n\tDELIVERY\n3.\tCalculate and enter the Total Cost (units sold x\n\tunit cost)\n4.\tCalculate and enter the Margin Made (Sales -\n\tTotal Cost)\n5.\tCheck whether this is a Cash or Credit order,\n\tthen click DELIVER\n6.\tMove the coins from Customers to Cash or\n\tReceivables\n7.\tClick at the bottom of the Delivery Record\n8.\tRepeat steps 2-7 for each order won\n\nOnce all deliveries are complete, you can see the total results, and click CLOSE to move on.',

    'You need to pay some expenses (which will always be the same).\n\nACTION:\n1.\tPay 1 to FFOH (Fixed Factory Overheads)\n2.\tPay 2 to MOGE (Marketing and Other General\n\tExpenses)\n3.\tClick NEXT',

    'You need materials to manufacture and pack.\n\nACTION:\n1.\tClick on SUPPLIERS to open the order form\n2.\tEnter the number of Classic materials you\n\twant to order. Each production line can make\n\tand store up to 6 units, and we recommend\n\tfilling it.\n3.\tClick PLACE ORDER - product appears on the\n\tproduction line, and red chips appear on\n\tSuppliers. The supplier offers you 1 period\'s\n\tcredit. You can either pay from cash now, or\n\tdrag the red chips to OWED TO SUPPLIERS\n4.\tClick NEXT',

    'You need to pay interest on your loans, 1 per 10 (or part of 10). You also need to repay 10% of the loan amount, rounded up.\n\nACTION:\n1.\tPay from Cash to Interest\n2.\tPay from Cash to LOANS - the amount of\n\tred chips reduces to show how much is still\n\towing\n3.\tClick NEXT',

    'Production lines depreciate each period, Classic by 1 and Gold by 2.\n\nACTION:\n1.\tClick on DEPRECIATE above the Production\n\tline value\n2.\tDrag the brown chip to Depreciation\n3.\tClick NEXT',

    'You need to complete an Income Statement to show whether you have made a profit. The numbers you need are in the Delivery Record or on the board. Make sure you all understand this form and complete it together!\n\nA green tick (check mark) shows when you have entered a correct number.\n\nACTION:\n1.\tOpen the Delivery Record to find the Net Net\n\tSales and Cost of Goods Sold\n2.\tClick on INCOME STATEMENT to open the\n\tform, and complete it\n3.\tOpen the Marketing Plan to see the PFME and\n\tR&D by product\n4.\tLook on the board for all the other numbers\n\tyou need (Use the minus sign (-) to enter\n\ta negative number)\n5.\tClick NEXT',

    'You need to pay the tax and dividends that you calculated.\n\nACTION:\n1.\tPay from Cash to Tax\n2.\tPay from Cash to Dividends\n3.\tClick NEXT',

    'Now you need to complete the Balance Sheet.\n\nACTION:\n1.\tClick on BALANCE SHEET to open the form\n2.\tUse the numbers on the board to complete it\n3.\tShare Capital is always 75\n4.\tRetained Earnings is on the Income\n\tStatement\n5.\tClick NEXT'
];

export const infoBoxPreMarket = [
    'Your first step is to complete the marketing plan. See the RULES button for more information about how to complete this. This plan contains your strategic decisions this period ready for the competitive market. You will be competing with the other teams for Classic and Gold customers.\n\nACTION:\nClick on the MARKETING button on the right of the screen to open and complete the Price, PFME and R&D decisions for the products you wish to sell - you will be committed to these decisions, so make sure your team is happy.',

    'If your customers owe you money (trade receivables) from last period they pay you now.\n\nACTION:\nDrag silvers from RECEIVABLES into CASH.',

    'If you took supplier credit (trade creditors or payables) last period, you need to pay them now. This is cash you borrowed to buy raw materials.\n\nACTION:\nEnter the number shown in PAYABLES in the CASH area and drag the silver chips into the PAYABLES area.',

    'Do you want to buy a new production line? To purchase a line, Classic = 15, Gold = 20. See the RULES button for more information on Work in Progress (WIP) costs, and ongoing running expenses\n\nACTION:\nType 15 (Classic) or 20 (Gold) into the PAY area of CASH and press return. Drag the hovering silvers from cash to one of the "Buy Equipment Line” boxes. Pay the WIP (Classic =10, Gold =20).',

    'You decided how much PFME and R&D to spend in the marketing plan, you now need to pay for it.\n\nACTION:\nClick on the marketing plan icon to see the spend. Pay the total PFME owed from CASH to PFME. Pay the total R&D owed from CASH to R&D.',

    'You decided in your MARKETING PLAN whether or not to buy co-packed units. You now need to purchase them. Note, you need to pay cash for co-packed units. If you don\'t have enough, reduce the order or get a bank loan.\n\nACTION:\nClick on the SUPPLIER box (external locations) to open the co-packed order form. It is pre-filled with the volume of products. Check that you have enough in cash and click PLACE ORDER.\n\nYour co-packed units have appeared in the warehouse area. You now need to pay your supplier for them from cash. The money you owe is shown by red chips on the SUPPLIER box.\n\nACTION:\nEnter this number in CASH and drag the silvers to the SUPPLIER box.',

    'You are complete! Please wait while the facilitator sets up the market...',
];

export const infoBoxPreMarketP4P5 = [
    'Check the RULES if you need a reminder on anything. If you install new equipment now, it will only produce in next period, so if you want more stock to sell in this period, buy it from co-packers.\n\nACTION:\n1.\tClick on MARKETING to open the form.\n2.\tSet your Price, PFME and R&D spends, and\n\tplace co-packer orders for each product. You\n\twill be committed to these decisions, so make\n\tsure the team agrees before moving ahead.',

    'If your customers owe you money (trade receivables) from last period, they pay you now.\n\nACTION:\n1.\tDrag money from OWED FROM CUSTOMERS\n\tinto Cash.',

    'If you took credit from suppliers last period (trade creditors or payables), you need to pay them now.\n\nACTION:\n1.\tPay the amount owing, from Cash to OWED TO\n\tSUPPLIERS.',

    'You can now buy a new Classic production line for 15, or a Gold one for 20. The WIP is 10 for Classic, and 20 for Gold, and product will be ready for sale next period. If you want to buy one,\n\nACTION:\n1.\tPay (15 or 20) from Cash to BUY EQUIPMENT\n\tLINE.\n2.\tPay (10 or 20) from Cash to Work In Progress\n\ton the new line.',

    'You need to pay for the PFME and R&D that you committed to in your Marketing Plan.\n\nACTION:\n1.\tOpen the MARKETING plan to see the total\n\tspends.\n2.\tPay the TOTAL PFME from Cash.\n3.\tPay the TOTAL R&D from Cash.',

    'You planned how many units to buy from co-packers in your Marketing Plan. Now you have to pay for them, in Cash. Check that you have enough Cash; if not, reduce the order size or take a Bank Loan.\n\nACTION:\n1.\tClick on Suppliers to open the co-packer order\n\tform.\n2.\tCheck that you have enough cash to pay for\n\tthem.\n3.\tIf not, reduce the Units Ordered, or take a Bank\n\tLoan.\n4.\tClick PLACE ORDER - the units will appear in\n\tthe Warehouses, the amount you owe appears\n\tin Suppliers.\n5.\tPay the Suppliers from Cash.',

    'You are complete! Please wait while the facilitator sets up the market...',
];

export const infoBoxPostMarket = [
    'When you win orders, they show up as Market Cards once you click DELIVERY. The details of each card show in the Delivery Record, one at a time. You need to allocate (Produced or Co-packed) goods to each order, Deliver it, and collect the Cash or show the Receivable.\n\nACTION:\n1.\tClick DELIVERY\n2.\tEnter the number of Produced or Co-Packed\n\tunits under ALLOCATE UNITS FOR THIS\n\tDELIVERY\n3.\tCalculate and enter the Total Cost (units sold x\n\tunit cost)\n4.\tCalculate and enter the Margins Made (Sales -\n\tTotal Cost).\n5.\tCheck whether this is a Cash or Credit order,\n\tthen click DELIVER.\n6.\tMove the coins from Customers to Cash or\n\tReceivables\n7.\tClick at the bottom of the Delivery Record\n8.\tRepeat steps 2-7 for each order won.\n\nOnce all deliveries are complete, you can see the total results, and click CLOSE and NEXT to move on.',

    'Pay your FFOH (Fixed Factory Overheads) and MOGE (Marketing and Other Genral Expenses).\n\nACTION:\n1.\tPay 1 for each production line, from Cash to \n\tFFOH\n2.\tPay 2 for MOGE, from Cash to MOGE',

    'You need materials to manufacture and pack. Each Production Line can make and store up to 6 units. If you have some left over, you can make less this period.\n\nACTION:\n1.\tClick on SUPPLIERS to open the order form\n2.\tEnter the number of Classic or Gold materials\n\tyou want to order. Each production line can\n\tmake and store up to 6 units, and we\n\trecommend filling it.\n3.\tClick PLACE ORDER - product appears on the\n\tproduction line, and red chips appear on\n\tSuppliers.\n4.\tThe supplier offers you 1 period\'s credit. You\n\tcan either pay from Cash now, or drag the red\n\tchips to OWED TO SUPPLIERS',

    'You need to pay interest on your loans, 1 per 10 (or part of 10). You also need to repay 10% of the loan amount, rounded up. If you want to repay more, you can repay up to 50% of the loan outstanding.\n\nACTION:\n1.\tPay from Cash to Interest\n2.\tPay from Cash to LOANS - the amount of red\n\tchips reduces to show how much is still\n\towing',

    'Production lines depreciate each period, Classic by 1 and Gold by 2.\n\nACTION:\n1.\tClick on DEPRECIATE above the Production\n\tline value\n2.\tDrag the brown chips to Depreciation\n3.\tRepeat for other production lines ',

    'You need to complete an Income Statement to show whether you have made a profit. The numbers you need are in the Delivery Record or on the board. Make sure you all understand this form and complete it together!\n\nA green tick (check mark) shows when you have entered a correct number.\n\nACTION:\n1.\tOpen the Delivery Record to find the Net Net\n\tSales and Cost of Goods Sold\n2.\tClick on INCOME STATEMENT to open the\n\tform, and complete it\n3.\tOpen the Marketing Plan to see the PFME and\n\tR&D by product\n4.\tLook on the board for all the other numbers\n\tyou need (Use the minus sign (-) to enter\n\ta negative number)',

    'You need to pay the tax and dividends that you calculated.\n\nACTION:\n1.\tPay from Cash to Tax\n2.\tPay from Cash to Dividends',

    'Now you need to complete the Balance Sheet.\n\nACTION:\n1.\tClick on BALANCE SHEET to open the form\n2.\tUse the numbers on the board to complete it\n3.\tShare Capital is always 75\n4.\tRetained Earnings is on the Income\n\tStatement\n5.\tClick NEXT',
]

export const infoBoxPostMarketP4P5 = [
    'When you win orders, they show up as Market Cards once you click DELIVERY. The details of each card show in the Delivery Record, one at a time. You need to allocate (Produced or Co-packed) goods to each order, Deliver it, and collect the Cash or show the Receivable.\n\nACTION:\n1.\tClick DELIVERY\n2.\tEnter the number of Produced or Co-Packed\n\tunits under ALLOCATE UNITS FOR THIS\n\tDELIVERY\n3.\tCalculate and enter the Total Cost (units sold x\n\tunit cost)\n4.\tCalculate and enter the Margins Made (Sales -\n\tTotal Cost).\n5.\tCheck whether this is a Cash or Credit order,\n\tthen click DELIVER.\n6.\tMove the coins from Customers to Cash or\n\tReceivables\n7.\tClick at the bottom of the Delivery Record\n8.\tRepeat steps 2-7 for each order won.\n\nOnce all deliveries are complete, you can see the total results, and click CLOSE and NEXT to move on.',

    'Pay your FFOH (Fixed Factory Overheads) and MOGE (Marketing and Other Genral Expenses).\n\nACTION:\n1.\tPay 1 for each production line, from Cash to \n\tFFOH\n2.\tPay 2 for MOGE, from Cash to MOGE',

    'You need materials to manufacture and pack. Each Production Line can make and store up to 6 units. If you have some left over, you can make less this period.\n\nACTION:\n1.\tClick on SUPPLIERS to open the order form\n2.\tEnter the number of Classic or Gold materials\n\tyou want to order. Each production line can\n\tmake and store up to 6 units, and we\n\trecommend filling it.\n3.\tClick PLACE ORDER - product appears on the\n\tproduction line, and red chips appear on\n\tSuppliers.\n4.\tThe supplier offers you 1 period\'s credit. You\n\tcan either pay from Cash now, or drag the red\n\tchips to OWED TO SUPPLIERS',

    'You need to pay interest on your loans, 1 per 10 (or part of 10). You also need to repay 10% of the loan amount, rounded up. If you want to repay more, you can repay up to 50% of the loan outstanding.\n\nACTION:\n1.\tPay from Cash to Interest\n2.\tPay from Cash to LOANS - the amount of red\n\tchips reduces to show how much is still\n\towing',

    'Production lines depreciate each period, Classic by 1 and Gold by 2.\n\nACTION:\n1.\tClick on DEPRECIATE above the Production\n\tline value\n2.\tDrag the brown chips to Depreciation\n3.\tRepeat for other production lines ',

    'You need to complete an Income Statement to show whether you have made a profit. The numbers you need are in the Delivery Record or on the board. Make sure you all understand this form and complete it together!\n\nA green tick (check mark) shows when you have entered a correct number.\n\nACTION:\n1.\tOpen the Delivery Record to find the Net Net\n\tSales and Cost of Goods Sold\n2.\tClick on INCOME STATEMENT to open the\n\tform, and complete it\n3.\tOpen the Marketing Plan to see the PFME and\n\tR&D by product\n4.\tLook on the board for all the other numbers\n\tyou need (Use the minus sign (-) to enter\n\ta negative number)',

    'You need to pay the tax and dividends that you calculated.\n\nACTION:\n1.\tPay from Cash to Tax\n2.\tPay from Cash to Dividends',

    'Now you need to complete the Balance Sheet.\n\nACTION:\n1.\tClick on BALANCE SHEET to open the form\n2.\tUse the numbers on the board to complete it\n3.\tShare Capital is always 75\n4.\tRetained Earnings is on the Income\n\tStatement\n5.\tClick NEXT',

    'Now you are going to calculate four of the KPIs that Nestle uses to analyse performance. Organic Growth is the change in NNS as a percent of the last period\'s NNS. Real Internal Growth or RIG is the change in NNS that would have been achieved, if prices had remained constant. Operating Profit Percent or OP1% is the Trading Operating Profit, as a percent of NNS. Trade Working Capital % is the ratio of Working Capital to Net Net Sales.\n\nACTION:\n1.\tFollow the formula to calculate Organic Growth\n\tto one decimal, and enter it.\n2.\tCalculate NNS P3 @ P2 Selling Price: multiply\n\tClassic Units Sold in Period 3 x Classic Selling\n\tPrice in Period 2, and enter it. Repeat for Gold\n\tUnits Sold in Period 3 x Gold Selling Price in\n\tPeriod 2, and enter it. Add these two figures to\n\tget £. Use the formula to calculate RIG to\n\tone decimal, and enter it.\n3.\tLook on the Income Statement for OP1. Follow\n\tthe formula to calculate OP1% to one decimal,\n\tand enter it.\n4.\tLook on the Balance Sheet for the Trade Net\n\tWorking Capital. Follow the formula to calculate\n\tTrade Net Working Capital % to one decimal,\n\tand enter it.',

    'Well done! You have completed the period.\n\nWhile you are waiting for the other teams to finish, reflect on the period with your team. What went well? What could you have done differently? What have you learnt?'
]

export const balanceSheetTitle = [
    'Due from customers\n\n',
    'Owed to suppliers\n\n',
    'Trade Net working capital\n\n',
    'Net current assets\n\n',
    'Net assets\n\n',
    'Share capital\n\n',
    'Total equity\n\n',
    'Total Liabilities & shareholders’ funds\n\n',
];

export const balanceSheetInfoText = [
    'Due from customers (receivables/debtors) are shown on the bottom left of your board. This is the money owed to the business by customers who have not yet paid for their purchases.',

    'Owed to suppliers (payables/creditors) are shown to the right of cash with red chips. This is the money owed to suppliers who have provided goods or services, but who have not yet been paid.',

    'Total current assets - current liabilities (owed to suppliers). This is the money that circulates through the business. This includes Receivables, less Payables, plus inventories of raw material, packaging supplies, work in progress, and finished goods at the factory, in transport, or in the warehouse, but excludes Cash.',

    'Cash + Trade net working capital',

    'Net current assets + total fixed assets',

    'Share Capital is 75',

    'Share capital (of 75) + Retained earnings (or minus if retained earnings is a loss). This is money put into the business by the owners, plus any Profits that have been reinvested in the business, known as Retained earnings.',

    'Bank loans + Total Equity'
];

export const op_text = "The purpose of the operational plan is firstly to find out what everyone in the business is expecting to do, so that we have a practical plan where we are all going in the same direction. Secondly, to find out if the plan is worth following. In other words, is the plan profitable?\n\nHere are some tips:\n\nStart by estimating the number of units you expect to sell and at what price. Think about last period, the market demand, and your competitors.\n\nCalculate the Cost of Goods Sold by multiplying the units by the unit cost price.\n\nPFME and R&D are marketing plan decisions. What are you going to spend this period to be competitive in the market?\n\nCheck the rules to estimate your expenses for your production lines. Are these the same as last period or are you planning on investing in new lines?\n\nInterest is 10% of your loans.\n\nLook at the mathematical symbols to help you calculate the different profit lines on the plan.";

export const cf_text = "A Cash Flow Forecast focuses our attention on when cash comes in and goes out, and how much we have at any time. The Cash Flow Forecast must be consistent with the Operational Plan. The cash flow forecast shows us whether we can implement our operational plan. If we can’t, we need to change the plan!\n\nHere are some tips:\n\nStart with your current cash balance.\n\nAdd cash due from customers this period (receivables).\n\nSubtract the money owed to suppliers.\n\nAre you going to invest in any new production lines this period? Subtract the cost of the equipment and WIP if you are.\n\nEnter the cost of PFME and R&D from your operational plan and the cost of any co-packed units you are planning to buy.\n\nSubtract all these costs and investments from your cash balance and if the number is negative, you need a loan.\n\nWill you qualify for a loan? If so, add the loan to increase your planned cash balance.\n\nPredict the cash sales you might pick up at the market (units x planned price).Note, only cash cards, not credit ones.\n\nEnter the other costs from your operational plan\n.Subtract these from the balance to see your new cash balance and whether you need another loan.\n\nIf you can’t afford the plan from a cash flow point of view you need to go back and change your plan. Click on the planning button to do this."