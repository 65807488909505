export const OnLoggedIn = 'e$OnLoggedIn';
export const SubscribeToUser = 'e$SubscribeToUser';
export const SubscribeToGeneral = 'e$SubscribeToGeneral';
export const SubscribeToTrainingSession = 'e$SubscribeToTrainingSession';
export const SubscribeToTeam = 'e$SubscribeToTeam';

export const TrainingStartedEvent = 'e$TrainingStartedEvent';

export default {
  OnLoggedIn,

  SubscribeToUser,
  SubscribeToGeneral,
  SubscribeToTrainingSession,
  SubscribeToTeam,

  TrainingStartedEvent,
}
