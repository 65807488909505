<style lang="scss">

.period-0 {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .period-0-content {
        height: 100%;
        display: flex;
        flex: 1;

        .ts {
            display: flex;
            flex: 1;
            margin: 0 0.25rem;
        }
    }

    .open-board-button {
        background: none;
        opacity: 0.5;
        border: none;
        position: absolute;
        width: 6.45%;
        height: 5%;

        &.a { top: 14.3%; left: 27.3%; }
        &.b { top: 14.3%; left: 56.93%; }
        &.c { top: 14.3%; left: 86.95%; }
        &.d { top: 47.8%; left: 27.3%; }
        &.e { top: 47.8%; left: 56.93%; }
        &.f { top: 47.8%; left: 86.95%; }
    }

    .next-button {
        background: #a3d65d;
        border-radius: 30px;
        width: 13%;
        height: 7%;
        border: none;

        position: absolute;
        right: 2%;
        bottom: 3%;
    }
    .next-button:hover {
        background: #729541;
    }
    .next-button:active {
        background: #392c23;
        color: white;
    }
}


</style>

<template>
    <div class="period-0">

        <div class="period-0-content">
            <div class="ts">
                <TeamStatus></TeamStatus>
            </div>
        </div>

        <button class="next-button" @click="next">Next</button>

    </div>

    <Alert v-if="trigger_alert == true"
        :alertOpen="trigger_alert"
        :message="message"
        :onlyClose="false"
        :popUpSize="'trainerP0'"
        @close_alert="trigger_alert = false"
        @confirm_alert="confirm_alert"
    />
</template>

<script>
import { mapGetters } from "vuex";
import TeamStatus from './TeamStatus';
import ViewParticipantsBoard from "../ViewParticipantsBoard"
import Alert from "@/components/Alert_PopUp";
import Profitability from '@/profitability-nestle-js';

export default {
    components: {
        TeamStatus,
        ViewParticipantsBoard,
        Alert
    },
    data() {
        return {
            phase: 0,
            page: 0,

            cardSetup: {
                classic: {},
                gold: {},
            },

            participantBoxOpen: false,

            message: "Are you sure you want to proceed?",
            trigger_alert: false
        };
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
            vPhase: 'phase',
            vTrainingSessionData: 'trainingSessionData',
        }),
    },
    mounted() {
    },
    methods: {
        next() {
            Profitability.Trainer.checkAllReady().then(() => {
                Profitability.Trainer.nextPeriod();
            }, e => {
                this.trigger_alert = true
                this.confirm_alert;
            })
        },
        confirm_alert() {
            this.trigger_alert = false
            Profitability.Trainer.nextPeriod();
        },
        participant_box_ctrl() {
            this.participantBoxOpen === true ? this.participantBoxOpen = false : this.participantBoxOpen = true
        }
    },
}
</script>
