<template>
    <div v-if="panel === panel_name[0]">
    </div>

    <div v-if="panel === panel_name[1]">
    </div>

    <div v-if="panel === panel_name[2]">
        <Delivery @close="Close_Panel(panel_name[2])"
            @customer_cash_available = customer_cash_emit
            @Deliver_Copack="Deliver_Copack"
            :phase="phase"
            :cards="cards"
            :customer_cash="customer_cash"
            :customer_credit="customer_credit"/>
    </div>

    <div v-show="panel === panel_name[3]">
    </div>

    <div v-show="panel === panel_name[4]">
    </div>

    <div v-if="panel === panel_name[5]">
    </div>

    <div v-if="panel === panel_name[6]">
    </div>

    <div v-if="panel === panel_name[7]">
    </div>

    <div v-if="panel === panel_name[8]">
    </div>

    <div v-if="panel === panel_name[9]">
        <Supplier :panelName="panel_name[9]" @close="Close_Panel(panel_name[9])" :phase="phase" :cash_stored="cash_stored" @buy_raw_cards="buy_raw_cards" @buy_copack_cards="buy_copack_cards" />
    </div>

    <div v-if="panel === panel_name[10]">
        <Supplier :panelName="panel_name[10]" @close="Close_Panel(panel_name[10])" :phase="phase" :cash_stored="cash_stored" @buy_raw_cards="buy_raw_cards" @buy_copack_cards="buy_copack_cards" />
    </div>

    <!-- Bank button @ Section_External_Locations.vue -->
    <div v-if="panel === panel_name[11]">
        <div class="form_panel button" :class="panel_name[11]">
            <input readonly class="BM_Input" v-model="borrow" maxlength="3" @click="Borrow_Money">
            <button class="BM_Btn add" @click="Bank_Add_Money"></button>
            <button class="BM_Btn subtract" @click="Bank_Subtract_Money"></button>
        </div>
    </div>
</template>

<script>
import Supplier from './Gameboard_SidePanel_Supplier';
import Delivery from "./Gameboard_SidePanel_Delivery";
import { mapGetters } from "vuex";
import Profitability from '@/profitability-nestle-js';

export default {
    props: {
        phase: Number,
        cash_stored: Number,
        cards: Object,
        customer_cash: Number,
        customer_credit: Number
    },
    components: { Delivery, Supplier },
    data(){
        return{
            panel_name: ["Rules", "Marketing", "Delivery", "IS", "BS", "Records", "Performance", "Forecast", "Planning", "Supplier_Pre", "Supplier_Post", "Bank"],
            // should_show: [false, false, false, false, false, false, false, false, false, false, false, false],

            panel: null,
            borrow: 0,
            borrowed_total: 0,
        }
    },
    computed: {

    },
    mounted(){
        this.$emit("return_side_panel", this)
    },
    methods:{
        Show_Panel(this_name){
            // prevent panel from open if period 0
            switch (this.phase) {
                case 0:
                case 1:
                case 2:
                case 5:
                case 8:
                case 9:
                    return;
                case 3:
                    if (this_name !== 'Supplier_Pre') {
                        return;
                    }
                    break;
                case 4:
                    if (this_name !== 'Delivery') {
                        return;
                    }
                    break;
                case 6:
                    if (this_name !== 'Bank') {
                        return;
                    }
                    break;
                case 7:
                    if (this_name !== 'Supplier_Post') {
                        return;
                    }
                    break;
                default:
                    break;
            }

            if (this.panel !== this_name)
                this.panel = this_name;
            else
                this.panel = null;
        },
        Bank_Add_Money(){
            this.borrow += 10;
        },
        Bank_Subtract_Money(){
            if (this.borrow > 0) {
                this.borrow -= 10
            }
            else {
                alert("Cannot borrow less than $10")
            }
        },
        Borrow_Money() {
            if (this.borrowed_total === 10) {
                alert("Already borrowed $10")
                return;
            }

            if (this.borrow > 0 && this.borrowed_total < 10) {
                if (this.borrow === 10) {
                    this.takeLoan(this.borrow)
                    this.borrowed_total += 10
                    this.Close_Panel('Bank')

                    this.borrow = 0
                    return
                } else {
                    alert("Please only take a loan of $10 in period 1")
                    this.Close_Panel('Bank')
                    return
                }
            } else {
                alert("Cannot borrow less than $10")
            }

            this.Close_Panel('Bank');
            this.borrow = 0;
        },
        Close_Panel(name){
            this.$emit("close_side_panel", name)
        },
        takeLoan(borrow) {
            this.$emit("Borrow", borrow)
        },
        customer_cash_emit() {
            this.$emit("customer_cash_emit", cash)
        },
        buy_raw_cards() {
            this.$emit("buy_raw_cards", 5)
        },
        buy_copack_cards() {
            this.$emit("buy_copack_cards", 5)
        },
        Deliver_Copack() {
            this.$emit("Deliver_Copack")
        }
    }
}
</script>

<style>
    .form_panel{
        top: 27.5%;
        right: 8%;
        position: fixed;
        width: 23.5%;
        height: 70%;

        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;

        z-index: 2;
    }
    .form_panel.order{
        height: 24%;
    }
    .form_panel.button{
        width: 6%;
        height: 10.3%;
        top: 60%;
        left: 7.3%;
        position: fixed;
    }
    .form_panel.Rules{/* background-image: url('../assets/Gameboard/Panel/panel_rules.png'); */}

    .form_panel.Records{}
    .form_panel.Performance{}
    .form_panel.Forecast{}
    .form_panel.Planning{}
    .form_panel.button.Bank{background-image: url('../../assets/Gameboard/Sections/section_external_locations_bank.png');}

    /* Order Form */


    .BM_Input{
        top: 7%;
        height: 55%;
        width: 76%;
        position: relative;
        text-align: center;
        font-size: 28px;
        color: black;
        font-family: NestleBrush_Reg;
        outline: none;
        border: none;
        cursor: pointer;
    }
    .BM_Btn{
        background-color: transparent;
        border-color: transparent;
        position: absolute;
        width: 18%;
        height: 22%;
        bottom: 6%;
    }
    .add{
        left: 25%;
    }

    .subtract{
        left: 57%;
    }
</style>
