<style lang="scss">
.Section_External_Locations {
  top: 27%;
  left: 6.95%;
  position: fixed;
  width: 6.75%;
  height: 48%;

  .PlayerBoard_External {
    top: 27%;
    left: 6.95%;
    position: fixed;
    width: 6.75%;
    height: 48%;
  }

  .Supplier_Box {
    top: 27.5%;
    left: 7.3%;
    position: fixed;
    width: 6%;
    height: 10.5%;
    /* background-color: green; */
  }

  .Button_Supplier {
    top: 27.5%;
    left: 7%;
    position: fixed;
    width: 6%;
    height: 10.5%;
    background-color: transparent;
    border-color: transparent;
  }

  .Order_Button {
    border: none;
    border-radius: 20px;
    top: 28%;
    left: 7.8%;
    position: fixed;
    width: 5%;
    height: 3%;
    font-size: 22px;
    background: #a4d65d;
    color: black;
  }

  .Investor_Box {
    top: 49.15%;
    left: 7.3%;
    position: fixed;
    width: 6%;
    height: 10.5%;
    background: none;
  }

  .Customers_Box {
    top: 38.5%;
    left: 7.3%;
    position: fixed;
    width: 6%;
    height: 10.5%;
    background-color: none;
  }

  .Collect_Cash {
    position: absolute;
    top: 5.5%;
    left: 3.5%;
    width: 45%;
    height: 40%;
    background: url("../../assets/Gameboard/Sections/Collect_Cash_Button.png");
    background-size: 100% 100%;
    border: none;
  }

  .Collect_Credit {
    position: absolute;
    top: 5.5%;
    left: 52%;
    width: 45%;
    height: 40%;
    background: url("../../assets/Gameboard/Sections/Collect_Credit_Button.png");
    background-size: 100% 100%;
    border: none;
  }

  .Button_Bank {
    top: 60%;
    left: 7.3%;
    position: fixed;
    width: 6%;
    height: 10.3%;
    background-color: transparent;
    border-color: transparent;
  }

  .Bank_Box {
    top: 60%;
    left: 7%;
    position: fixed;
    width: 6%;
    height: 10.3%;
    /* background: black; */
  }

  .coin_adjust {
    position: absolute;
    top: -9%;
    left: -11.5%;
    width: 100%;
    height: 50%;
    /* background: green; */
  }

  .frame_border {
    border: 5px solid red;
    border-radius: 8px;
  }

  .debug {
    position: absolute;
    inset: 0;
    margin-top: 1rem;
    pointer-events: none;

    display: flex;
    flex-direction: column;

    color: black;
    font-weight: bold;

    div {
      flex: 1;
    }

    button {
      font-size: 12px;
    }
  }
}
</style>


<template>
  <div class="Section_External_Locations">
    <img src="../../assets/Gameboard/Sections/section_external_locations.png" class="PlayerBoard_External">

    <button v-if="show_order_btn" class="Order_Button" disabled>Order</button>

    <div v-if="vPhase.period === 0" class="Supplier_Box"
         :class="{frame_border: vPhase.period === 0 && (vPhase.phase_step === 3 || vPhase.phase_step === 7)}">
      <div class="coin_adjust">
        <div v-if="!(suppliers_owe == suppliers)">
          <CoinIndicator :pass_currency_type="9" :pass_cash="suppliers_owe"/>
        </div>
      </div>
    </div>

    <button class="Button_Supplier" @click="open_supplier_form"></button>

    <div class="Supplier_Box" ref="Supplier_Box" v-if="suppliers_canPay" :index="14">
      <div class="coin_adjust">
        <CoinIndicator :pass_currency_type="11" :pass_cash="suppliers_actualOwe"/>
      </div>
      <!-- <button class="To_Payables" v-if="suppliers_canDragToPayables" @click="toPayables"></button> -->
      <div v-if="suppliers_canDragToPayables"></div>
    </div>


    <div v-if="investors > 0" class="Investor_Box" :class="{frame_border: vPhase.period === 0 && vPhase.phase_step === 0}">
      <div v-if="investors_canDragToCash"></div>
    </div>

    <div class="Customers_Box" :class="{frame_border: vPhase.period === 0 && vPhase.phase_step === 4}">
      <div v-if="customers_canCollectCash"></div>
      <div v-if="customers_canCollectCredit"></div>
    </div>

    <button 
      class="Button_Bank" 
      @click="open_bank" 
      :class="{frame_border: vPhase.period === 0 && vPhase.phase_step === 6}"
      v-if="!isBankCash"
      :disabled="delivery_phase"
    >
    </button>

    <div class="Bank_Box" v-if="bank_standard > 0">
      <div v-if="bank_can_collect">
      </div>
    </div>
    
    <div v-if="vDebug" class="debug">
      <div>
        suppliers: {{ suppliers }}
        <br>
        suppliers_owe: {{ suppliers_owe }}
        <button v-if="suppliers_canPay" @click="paySuppliers" style="pointer-events: auto;">Pay</button>
        <button v-if="suppliers_canDragToPayables" @click="toPayables" style="pointer-events: auto;">To Payables</button>
      </div>
      <div>
        cash: {{ customers_cash }}
        <button v-if="customers_canCollectCash" @click="collectFromCustomer('cash')" style="pointer-events: auto;">Collect</button>
        <br>
        credit: {{ customers_credit }}
        <button v-if="customers_canCollectCredit" @click="collectFromCustomer('credit')" style="pointer-events: auto;">Collect</button>
      </div>
      <div>
        investors: {{ investors }}
        <br>
        <button v-if="investors_canDragToCash" @click="investorToCash" style="pointer-events: auto;">ToCash</button>
      </div>
      <div>
        cash: {{ bank_cash }}
        <button v-if="bank_cash > 0" @click="takeLoanToCash" style="pointer-events: auto;">Take</button>
        <br>
        standard: {{ bank_standard }}
        <button v-if="bank_cash === 0 && bank_standard > 0" @click="takeLoanToStandard" style="pointer-events: auto;">Drag To Loan</button>
        <br>
        high: {{ bank_high }}
        <button v-if="bank_cash === 0 && bank_high > 0" @click="takeLoanToHigh" style="pointer-events: auto;">Drag To Loan</button>
      </div>
    </div>
  </div>
  <CoinComponent ref="CComponent" @use_coin="drop_function" :isFromExternalLocations="isBankCash"/>
</template>

<script>
import Profitability from '@/profitability-nestle-js';
import { mapGetters } from 'vuex';
import CoinComponent from '../Coin_Component.vue';
import CoinIndicator from '../Coin_Indicator.vue';

export default {
  components: { CoinIndicator, CoinComponent },
  emits: ['open_supplier_panel', 'open_bank_panel', 'open_bank_button'],
  data() {
    return {
      isBankCash: false,
    };
  },
  computed: {
    ...mapGetters({
      vDebug: 'debug',
    }),
    ...mapGetters('profitability/participant', {
      vGameboard: 'gameboard',
      vPhase: 'phase',
    }),
    suppliers() {
      return this.vGameboard?.getValue('suppliers', 'paid');
    },
    suppliers_owe() {
      return this.vGameboard?.getValue('suppliers', 'owe');
    },
    suppliers_actualOwe() {
      return this.suppliers_owe - this.suppliers;
    },
    suppliers_canPay() {
      if (this.vPhase.market_phase === 0 && this.vPhase.phase_step === 5) {
        return this.suppliers_actualOwe > 0;
      } else if (this.vPhase.market_phase === 2 && this.vPhase.phase_step === 2) {
        return this.suppliers_actualOwe > 0;
      }
      return false;
    },
    suppliers_canDragToPayables() {
      return this.vPhase.market_phase === 2 && this.vPhase.phase_step === 2 && this.suppliers_actualOwe > 0;
    },
    customers_cash() {
      return this.vGameboard?.getValue('customers', 'cash');
    },
    customers_credit() {
      return this.vGameboard?.getValue('customers', 'credit');
    },
    customers_canCollectCash() {
      return this.vPhase.market_phase === 2 && this.vPhase.phase_step === 0 && this.customers_cash > 0;
    },
    customers_canCollectCredit() {
      return this.customers_credit > 0 && (
        (this.vPhase.market_phase === 2 && this.vPhase.phase_step === 0) ||
        (this.vPhase.period === 0 && this.vPhase.phase_step === 4)
      );
    },
    investors() {
      return this.vGameboard?.getValue('investors');
    },
    investors_canDragToCash() {
      return this.investors > 0 && this.vPhase.period === 0 && this.vPhase.phase_step === 0;
    },
    bank_cash() {
      return this.vGameboard?.getValue('bank', 'cash');
    },
    bank_standard() {
      return this.vGameboard?.getValue('bank', 'standard');
    },
    bank_high() {
      return this.vGameboard?.getValue('bank', 'high');
    },
    bank_can_collect() {
      return this.bank_cash > 0 || this.bank_high > 0;
    },
    show_order_btn() {
      if (this.vPhase.period === 0) {
        return this.vPhase.phase_step === 3 || this.vPhase.phase_step === 7;
      } else {
        if (this.vPhase.market_phase === 0)
          return this.vPhase.phase_step === 5;
        else if (this.vPhase.market_phase === 2)
          return this.vPhase.phase_step === 2;
      }
    },
    delivery_phase() {
      return this.vPhase.market_phase === 2 && this.vPhase.phase_step === 0
    }
  },
  watch: {
    bank_can_collect() {
      if (this.bank_cash > 0) {
        this.isBankCash = true;
        this.$refs['CComponent'].set_custom_grab(0.325, 0.075, this.bank_cash, 'Cash_Box', 'Bank');
      } else if (this.bank_high > 0) {
        this.isBankCash = true;
        this.$refs['CComponent'].set_custom_grab(0.325, 0.075, this.bank_high, 'Cash_Box', 'Bank');
      } else {
        this.$refs['CComponent'].reset(0);
        this.isBankCash = false;
      }
    },
    customers_canCollectCash() {
      if (this.customers_canCollectCash) {
        setTimeout(() => {
          this.$refs['CComponent'].set_custom_grab(0.525, 0.075, this.customers_cash, 'Cash_Box', 'Customer Cash');
        }, 100);
      } else {
        this.$refs['CComponent'].reset(0);
      }
    },
    customers_canCollectCredit() {
      if (this.customers_canCollectCredit) {
        setTimeout(() => {
          this.$refs['CComponent'].set_custom_grab(0.535, 0.075, this.customers_credit, 'Receivables_Box', 'Customer Credit');
        }, 100);
      } else {
        this.$refs['CComponent'].reset(0);
      }
    },
    suppliers_canDragToPayables() {
      if (this.suppliers_canDragToPayables) {
        setTimeout(() => {
          this.$refs['CComponent'].set_custom_grab(0.525, 0.075, this.suppliers_actualOwe, 'Payable_Box', 'Suppliers', 'debt');
        }, 100);
      } else {
        this.$refs['CComponent'].reset(0);
      }
    },
    investors_canDragToCash() {
      if (this.investors_canDragToCash) {
        setTimeout(() => {
          this.$refs['CComponent'].set_custom_grab(0.425, 0.075, 75, 'Cash_Box', 'Investor');
        }, 100);
      } else {
        this.$refs['CComponent'].reset(0);
      }
    },
  },
  mounted() {
    if (this.investors_canDragToCash) {
      setTimeout(() => {
        this.$refs['CComponent'].set_custom_grab(0.425, 0.075, 75, 'Cash_Box', 'Investor');
      }, 100);
    }
    if (this.suppliers_canDragToPayables) {
      setTimeout(() => {
        this.$refs['CComponent'].set_custom_grab(0.525, 0.075, this.suppliers_actualOwe, 'Payable_Box', 'Suppliers', 'debt');
      }, 100);
    }
    if (this.customers_canCollectCredit) {
      setTimeout(() => {
        this.$refs['CComponent'].set_custom_grab(0.535, 0.075, this.customers_credit, 'Receivables_Box', 'Customer Credit');
      }, 100);
    }
    if (this.customers_canCollectCash) {
      setTimeout(() => {
        this.$refs['CComponent'].set_custom_grab(0.525, 0.075, this.customers_cash, 'Cash_Box', 'Customer Cash');
      }, 100);
    }
    if (this.bank_cash > 0) {
      this.isBankCash = true;
      this.$refs['CComponent'].set_custom_grab(0.325, 0.075, this.bank_cash, 'Cash_Box', 'Bank');
    } else if (this.bank_high > 0) {
      this.isBankCash = true;
      this.$refs['CComponent'].set_custom_grab(0.325, 0.075, this.bank_high, 'Cash_Box', 'Bank');
    }
  },
  methods: {
    drop_function(cash, location, func) {
      var move_to = func + ' > ' + location;
      switch (move_to) {
        case 'Investor > Cash_Box':
          this.investorToCash();
          break;
        case 'Customer Credit > Receivables_Box':
          this.collectFromCustomer('credit');
          break;
        case 'Customer Cash > Cash_Box':
          this.collectFromCustomer('cash');
          break;
        case 'Suppliers > Payable_Box':
          this.toPayables();
          break;
        case 'Bank > Cash_Box':
          this.takeLoanToCash();
          break;
      }
    },
    open_bank() {
      if (!this.delivery_phase) {
        this.$emit('open_bank_button', 'Bank');
      }
    },
    open_supplier_form() {
      if (this.vPhase.period !== 0) {
        if (this.vPhase.market_phase === 0) {
          this.$emit('open_supplier_panel', 'Supplier_Pre');
        } else {
          this.$emit('open_supplier_panel', 'Supplier_Post');
        }
      } else {
        this.$emit('open_supplier_panel', this.vPhase.phase_step <= 3 ? 'Supplier_Pre' : 'Supplier_Post');
      }
    },
    paySuppliers(cash) {
      const actualOweAmount = this.suppliers_actualOwe;
      if (actualOweAmount === 0) return;

      // TODO: amount is actually defined by user at 'cash'
      // so amount need to be pass in to backend to check if amount is correct or not
      // const amount = actualOweAmount;

      // TODO: you can add a frontend-validation here when player drag the wrong amount
      // if (actualOweAmount !== amount) {
      //     // TODO: warning?
      //     return;
      // }

      if (this.vPhase.period !== 0) {
        if (this.vPhase.market_phase === 0 && this.vPhase.phase_step === 5) {
          if (cash == actualOweAmount) {
            Profitability.Participant.buyCoPackPaySuppliers(cash);
            this.$refs['CComponent'].reset();
          }
        } else if (this.vPhase.market_phase === 2 && this.vPhase.phase_step === 2) {
          if (cash == actualOweAmount) {
            Profitability.Participant.buyRawPackagingMaterialsPaySuppliers(cash);
            this.$refs['CComponent'].reset();
          }
        }
      } else {
        if (cash === actualOweAmount) {
          if (this.vPhase.phase_step === 3) {
            Profitability.Participant.mbsBuyCoPackPaySuppliers(cash);
            this.$refs['CComponent'].reset();
          } else if (this.vPhase.phase_step === 7) {
            Profitability.Participant.mbsBuyRawPaySuppliers(cash);
            this.$refs['CComponent'].reset();
          }
        } else {
          const type = this.vPhase.phase_step === 3 ? 'co-packers' : 'RawPack';
          alert(`Incorrect amount. Pay the full cost of ${type} from cash`);
        }
      }
    },
    toPayables() {
      const actualOweAmount = this.suppliers_actualOwe;
      if (actualOweAmount === 0) return;

      // when player drag 'coin' from supplier to payables
      // the drag amount is pre-defined by system using (this.suppliers_owe - this.suppliers)
      // so no need to fill amount when calling the api
      Profitability.Participant.buyRawPackagingMaterialsToPayables();
      this.$refs['CComponent'].reset();
    },
    collectFromCustomer(type) {
      if (this.vPhase.market_phase === 2 && this.vPhase.phase_step === 0) {
        const amount = type === 'cash' ? this.customers_cash : this.customers_credit;
        Profitability.Participant.collectDeliveryPayment(type, amount);
        this.$refs['CComponent'].reset();
      } else if (this.vPhase.period === 0 && this.vPhase.phase_step === 4 && this.customers_credit > 0) {
        Profitability.Participant.mbsCustomersToReceivables();
        this.$refs['CComponent'].reset();
      }
    },

    takeLoanToCash() {
      Profitability.Participant.bankLoanToCash().then(() => {
        if (this.bank_standard > 0) {
          Profitability.Participant.bankLoanToLoans('standard');
          this.$refs['CComponent'].reset();
        } else if (this.bank_high > 0) {
          Profitability.Participant.bankLoanToLoans('high');
          this.$refs['CComponent'].reset();
        }
      });
    },
    takeLoanToStandard() {
      Profitability.Participant.bankLoanToLoans('standard');
    },
    takeLoanToHigh() {
      Profitability.Participant.bankLoanToLoans('high');
    },
    investorToCash() {
      Profitability.Participant.mbsInvestorToCash();
      this.$refs['CComponent'].reset();
    },
  },
};
</script>
