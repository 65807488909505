import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/LoginScreen'
import Participant from '../views/Gameboard_2'
import Facilitator from '../views/Facilitatorboard_2'
import Rules from '../views/Rules'
import Prework from '../views/MovingBalanceSheet'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Participant',
    name: 'Participant',
    component: Participant
  },
  {
    path: '/Facilitator',
    name: 'Facilitator',
    component: Facilitator
  },
  {
    path: '/Rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/Prework',
    name: 'Prework',
    component: Prework
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
