<template>
    <div v-if="!noIcon">
        <button class="Side_Panel_Prework" 
            :class="[{idle: current_class === 'idle', active: current_class === 'active', frame_border_stand: phase === 4 && button_name === 'Delivery'}]" 
            :style="cssProps" 
            @click="Popup_Form">
                <img class="Icon" :src="GetImgUrl(icon)" v-bind:alt=icon>
                <p>{{button_name}}</p>
        </button>
    </div>

    <div v-else>
        <button class="Side_Panel_Prework" :class="{idle: current_class === 'idle', active: current_class === 'active'}" :style="cssProps" @click="Popup_Form">
            <p class="noIcon">{{button_name}}<br />{{button_name_2}}</p>
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        pass_button_name: String,
        pass_button_name_2: String,
        pass_position: Number,
        pass_icon: String,
        phase: Number,
        noIcon: Boolean
    },
    data() {
        return {
            isSelected: false,
            current_class: "idle",
            icon: this.pass_icon,
            button_name: this.pass_button_name,
            button_name_2: this.pass_button_name_2,
            position: this.pass_position,
        }
    },
    mounted(){
        this.$emit("return_side_button", this.button_name, this)
    },
    computed: {
        cssProps(){
            return{
                '--position-return': this.position + "%"
            }
        },
    },
    methods:{
        Popup_Form(){
            this.$emit("select_side_panel", this.button_name)
        },
        Signal_Hide(this_name){
            if(this.button_name != this_name){
                if(this.isSelected == true)
                    this.isSelected = false
                    this.current_class = "idle"
            }
            if(this.button_name == this_name){
                this.isSelected = !this.isSelected
                if (this.isSelected == true)
                    this.current_class = "active"
                else if(this.isSelected == false)
                    this.current_class = "idle"
            }
        },
        GetImgUrl(icon) {
            if(icon != "")
                return require('../../assets/Gameboard/Icon/icon_' + icon + ".png")
        }
    }
}
</script>

<style>
.Icon{
    top: 6%;
    left: 0%;
    position: relative;
    width: 50%;
    height: 60%;
}

.Side_Panel_Prework{
    top: var(--position-return);
    right: 0%;
    position: absolute;
    width: 5.5%;
    height: 7.8%;
    border-radius: 8px 0 0 8px;

    
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% 100%;
    border-color: transparent;

    z-index: 2;
}
.Side_Panel_Prework.idle{
    background: #392c23;
}
.Side_Panel_Prework:hover {
    background: #b2b2b2;
}
.Side_Panel_Prework.active{
    background: white;
    width: 7%;
}
.Side_Panel_Prework p {
    top: 3%;
    left: 2%;
    position: relative;
    width: 100%;
    height: 38%;
    font-size: 17px;
    text-align: center;
}
.noIcon {
    top: 1%;
}
.Side_Panel_Prework.idle p {
    color: white;
}
.Side_Panel_Prework.active p {
    color: black;
}
.frame_border_stand {
    border: 6px solid red;
    border-radius: 8px;
}
</style>