import auth from './auth';
import participant from './participant';
import trainer from './trainer';

export default {
  namespaced: true,
  state() {
    return {
      initialized: false,
    };
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    participant,
    trainer,
  },
}
