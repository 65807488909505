<template>
    <div class="backdrop">
    </div>
</template>

<style>
    .backdrop {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: fixed;
        background-image: url('../assets/background.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 115%;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    h1 {
        font-family: NestleBrush_Reg;
        z-index: 2;
    }
    p {
        font-family: NestleBrush_Reg;
        z-index: 2;
    }
    @font-face {
        font-family: NestleBrush_Reg;
        src: url('./Fonts/NestleBrush-Regular.ttf');
    }
    @font-face {
        font-family: NestleText;
        src: url('./Fonts/NestleTextTF-BookCnd.ttf')
    }
</style>