<style lang="scss">
.Rules_Container {
  transform-origin: top left;
  overflow: hidden;

  position: absolute;
  height: 100%;
  width: 100%;
  font-family: NestleText;

  h1 {
    padding: 0;
    margin: 0;
    font-family: NestleText;
    font-size: 52px;
  }

  .wrapper {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    color: white;
    background: #63513d;
    border-radius: 15px;
    
    .title {
      margin-top: 1%;
      color: white;
      font-family: NestleBrush_Reg;
    }
  
    .inner {
      position: absolute;
      left: 5%;
      top: 10%;
      height: 82%;
      width: 90%;
      background: white;
      color: black;
      overflow-y: scroll;
      padding: 2%;
      text-align: justify;
      border-radius: 15px;
  
      h2 {
        padding: 0;
        margin: 0;
      }
  
      pre {
        padding: 0;
        margin: 0;
        text-align: justify;
        font-family: NestleText;
        font-size: 24px;
        color: black;
        width: 100%;
        white-space: pre-wrap; /* Since CSS 2.1 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
      }
  
      table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        font-size: 20px;
        text-align: left;
      }
  
      th, td {
        border: 2px solid black;
        padding: 0.2% 2% 0.2% 1%;
      }

      th {
        width: 280px;
      }

      .table-glossary {
        table-layout: auto;
      }
    }
  }
}
</style>

<template>
  <div class="Rules_Container" :style="cssProps">
    <Background />

    <div class="wrapper">
      <h1 class="title">Nestle VVC - Rule & Cost Summary</h1>

      <div class="inner">
        <h2>Land and Buildings</h2>
        <pre>With room for 4 production lines &#09; 20</pre>

        <br /><br />

        <h2>Production Lines</h2>
        <table>
          <tr>
            <td></td>
            <td>Capital</td>
            <td>Depreciation</td>
            <td>WIP</td>
          </tr>
          <tr>
            <td>Nescafé Classic</td>
            <td>15</td>
            <td>1</td>
            <td>10 (5 units)</td>
          </tr>
          <tr>
            <td>Nescafé Gold</td>
            <td>20</td>
            <td>2</td>
            <td>20 (5 units)</td>
          </tr>
        </table>
        <br />
        <pre>
You can only sell the output from a new production line, in the next period’s market.</pre
        >
        <br /><br />

        <h2>Fixed Factory OverHeads (FFOH)</h2>
        <pre>Per Production Line / per period &#09; 1</pre>
        <br /><br />

        <h2>Marketing and Other General Expenses (MOGE)</h2>
        <pre>Per period &#09; 2</pre>
        <br /><br />

        <h2>Product</h2>
        <table>
          <tr>
            <td></td>
            <td>Variable Cost</td>
            <td>Co-packed</td>
            <td>Max Prices</td>
          </tr>
          <tr>
            <td>Nescafé Classic</td>
            <td>2</td>
            <td>4</td>
            <td>8</td>
          </tr>
          <tr>
            <td>Nescafé Gold</td>
            <td>4</td>
            <td>7</td>
            <td>11</td>
          </tr>
        </table>
        <br /><br />

        <pre><b>Minimum PFME</b> &#09;  &#09;  &#09;  &#09; 1 per product category, no upper limit</pre>
        <pre><b>Minimum R&D</b> &#09;  &#09;  &#09;  &#09; 1 per product category, no upper limit</pre>
        <pre><b>Loan Interest</b> &#09;  &#09;  &#09;  &#09; &#09; 1 silver per 10 (or part of 10)</pre>
        <pre><b>Loans are available</b> &#09;  &#09;  &#09; in units of 10, up to 50% of Net Assets</pre>
        <pre>
&#09;  &#09;  &#09;  &#09;  &#09;  &#09;  &#09;  &#09; (Repay 10% of the outstanding loan balance</pre
        >
        <pre>
&#09;  &#09;  &#09;  &#09;  &#09;  &#09;  &#09;  &#09; at the end of each period)</pre
        >
        <pre><b>Tax</b> &#09; &#09; &#09; &#09; &#09; &#09; &#09; ⅓ of Earnings Before Tax.</pre>
        <pre><b>Dividend Guide</b> &#09; &#09; &#09; &#09; ⅓ of Net Profit.</pre>

        <br /><br />
        <hr />
        <br /><br />

        <h1>Rules</h1>
        <h2>Introduction</h2>
        <pre>
You are running a coffee business which makes Nescafé Classic, shown in the simulation by white chips. You work in your own premises with room to expand, and you can also develop the business by adding Nescafé Gold (shown by gold chips) to your range.</pre
        >
        <br />

        <h2>Land and Buildings</h2>
        <pre>
When you buy land & buildings, take the cost from cash, and drag them to the land and buildings area. They will change to brown chips to show the asset. Your land and buildings cost 20 silvers, and have room for four production lines / storage areas.</pre
        >
        <br /><br />

        <h2>Production Lines & Equipment</h2>
        <pre>
Each production line can be used to make and store up to six units of product per period. At the beginning of the simulation you will be selling only Nescafé Classic. You can install new production lines if you decide to expand into another product.</pre
        >
        <br /><br />

        <pre><b>Production lines cost:</b></pre>
        <pre>Nescafé Classic &#09; 15</pre>
        <pre>Nescafé Gold &#09; &#09; 20</pre>
        <pre>
If you buy new equipment, drag the cost of the equipment from cash (silvers) and move to the buy area in the centre of the board. They will change to brown chips. Every production line must have 5 units of stock in Work In Progress. WIP must be bought for cash when the production line is installed.</pre
        >
        <br />
        <pre>
There is a delay between installing and producing with new equipment, so you can sell the output from a new machine only in the next period’s market.</pre
        >
        <br /><br />

        <h2>Depreciation</h2>
        <pre>
Production lines will wear out or become obsolete over a period of years, and eventually need replacing. The gradual loss of value is known as depreciation, and you show it by taking brown chips out of the value of the equipment, andplacing them on the Depreciation expense box.</pre
        >
        <br />
        <pre>Depreciation Expenses</pre>
        <pre>Nescafé Classic line &#09; 1 per period</pre>
        <pre>Nescafé Gold line &#09; &#09; 2 per period</pre>
        <br /><br />

        <h2>FFOH</h2>
        <pre>
The Fixed Factory Overhead costs include factory management, heat and power, and so on. They are 1 per production line.</pre
        >
        <br /><br />
        <h2>MOGE</h2>
        <pre>
The Marketing and Other General Expenses are the cost of the marketing staff, HR, Finance & Control, and so on. They are 2 per period.</pre
        >
        <br />
        <pre>Variable Costs and Maximum Prices</pre>
        <pre>
The two products have different costs of raw and packaging materials and delivery, and different maximum prices. Price is set to the nearest 0.5, and odd totals are rounded down.</pre
        >
        <br />
        <table>
          <tr>
            <td>Product</td>
            <td>Cost</td>
            <td>Max. Price</td>
          </tr>
          <tr>
            <td>Nescafé Classic (yellow)</td>
            <td>2</td>
            <td>8</td>
          </tr>
          <tr>
            <td>Nescafé Gold (green)</td>
            <td>4</td>
            <td>11</td>
          </tr>
        </table>
        <br /><br />

        <h2>Co-Packers</h2>
        <pre>
Both products may be bought for cash from co-packers. No Equipment is needed. The costs are:</pre
        >
        <pre>Nescafé Classic (white) &#09; 4</pre>
        <pre>Nescafé Gold (gold) &#09; &#09; 7</pre>
        <br /><br />

        <h2>PFME Expenses</h2>
        <pre>
You need to spend on PFME (Product Fixed Marketing Expense) to create demand for your brand, and to attract customers. There is a minimum PFME spend of 1 per product type, per period, and there is no upper limit.</pre
        >
        <br /><br />

        <h2>R&D Expenses</h2>
        <pre>
You have to spend on R&D to create new product lines, to improve production and packaging technology, and to improve existing brands. R&D is important both to expand the market for these products, and to attract consumers. There is a minimum R&D spend of 1 per product, per period, and no upper limit.</pre
        >
        <br /><br />

        <h2>The Market</h2>
        <pre>
The total demand for each product is influenced by PFME spend, from all competitors. The maximum demand for each product, in each period, is shown on the Demand Forecast. If PFME spend is too low, the actual demand may not reach this level.</pre
        >
        <br />
        <pre>
Business is won in a competitive market, based on Price, PFME and product innovation and improvement (your R&D spend). The final tiebreaker is the cumulative PFME spend you have invested in building your brand.</pre
        >
        <br />
        <pre>
As the market opens each period, the customer order cards are laid out on the screen. The facilitator sets a priority sequence (or pecking order) based on the market choice criteria, which vary by product. The team with the best score on the 1st criterion chooses a customer card first, then the team with the next highest score, and so on until all competitors have chosen.</pre
        >
        <br />
        <pre>
If there are more competitors than cards, some will not make a sale. If there are more cards than competitors, the first company will have another choice after all the others, followed by the second, and so on. If two or more teams are tied on the first criterion, the one with the highest score on the 2nd criterion will choose first, and so on. You do not have to take an order if you do not want to.</pre
        >
        <br /><br />

        <h2>Delivery</h2>
        <pre>
You must deliver all goods in the same period that you win the order and only complete orders may be delivered.</pre
        >
        <br /><br />

        <h2>Loans</h2>
        <pre>
You can borrow from the bank in units of 10 silvers. Interest of 1 silver is paid for each 10 (or part-10) on loan, at the end of each period. You must also repay 10% of the outstanding loan balance at the end of each period. You may repay up to 50% of your outstanding loan balance at that time.</pre
        >
        <br />
        <pre>
To qualify for a loan, your business must seem healthy to the bank. The bank wants to ensure that you have more money invested in the business than you have borrowed. Borrowings may not exceed 50% of your Net Assets. The more profit you make, the more the business is worth, and the more you can borrow.</pre
        >
        <br /><br />

        <h2>Tax</h2>
        <pre>
Tax is ⅓ of Earnings Before Tax. It must be paid at the close of every business period. If you have negative retained earnings (as a result of previous losses) when you reach this step, ask the facilitator for guidance.</pre
        >
        <br /><br />

        <h2>Dividends</h2>
        <pre>
Pay ⅓ of your Net Profit to shareholders as a dividend at the end of each period.</pre
        >

        <br /><br />
        <hr />
        <br /><br />

        <h1>Market Demand</h1>
        <br />
        <table>
          <tr>
            <td></td>
            <td>Classic</td>
            <td>Gold</td>
          </tr>
          <tr>
            <td>Period 1</td>
            <td>30</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Period 2</td>
            <td>36</td>
            <td>8</td>
          </tr>
          <tr>
            <td>Period 3</td>
            <td>46</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Period 4</td>
            <td>56</td>
            <td>24</td>
          </tr>
          <tr>
            <td>Period 5</td>
            <td>66</td>
            <td>30</td>
          </tr>
        </table>
        <br />
        <pre>Demand generated by PFME spend during the period:</pre>
        <pre>Classic: &#09; 2 x PFME</pre>
        <pre>Gold: &#09; &#09; PFME</pre>
        <br />
        <pre>Actual Market is the lower of:</pre>
        <pre>
&#09; &#09; Maximum possible demand or demand generated by PFME spend.</pre
        >

      <br /><br />
      <hr />
      <br /><br /><br />
      
      <pre><b>Glossary of Business Terms</b></pre>
        <br />
        <table class="table-glossary">
          <tr>
            <th>Assets</th>
            <td>Anything of value owned by the business.</td>
          </tr>
          <tr>
            <th>Asset Turn Ratio (ATR):</th>
            <td>Ratio between sales and invested capital. How much sales we generate with the amount invested in the business</td>
          </tr>
          <tr>
            <th>Balance Sheet</th>
            <td>A statement listing the assets of the business, and where the money came from to acquire them.</td>
          </tr>
          <tr>
            <th>Book Value</th>
            <td>The original purchase price of an asset, less depreciation.</td>
          </tr>
          <tr>
            <th>Borrowings</th>
            <td>Bank loans or bonds usually with fixed rates of interest and repayment terms.</td>
          </tr>
          <tr>
            <th>Business Plan</th>
            <td>A plan of how you will run the business, with the actions you will take, and the income and expenses that you expect to have as a result. A business plan shows you how much profit or loss you will make if things go according to plan, but not whether you will have enough cash to pay the bills.</td>
          </tr>
          <tr>
            <th>Cost of Capital</th>
            <td>Businesses are financed from shares and bank loans. The shareholders expect a return equal to the market average for similar investments, and the banks require interest.</td>
          </tr>
          <tr>
            <th>Cost of Goods Sold</th>
            <td>What you paid for the goods you sold this period; in a manufacturing business, what it cost you (materials, packaging and labour) to make those goods.</td>
          </tr>
          <tr>
            <th>Creditors</th>
            <td>This is the same as payables. Money owed to suppliers who have provided goods or services, but who have not yet been paid.</td>
          </tr>
          <tr>
            <th>Current Assets</th>
            <td>Assets such as Stock/Inventory and Receivables, which you expect to turn into cash within 12 months, plus cash in hand and short term deposits.</td>
          </tr>
          <tr>
            <th>Current Liabilities</th>
            <td>Amounts the business owes and must repay within 12 months</td>
          </tr>
          <tr>
            <th>Debtors</th>
            <td>Same as Receivables. Money owed to the business by customers who have not yet paid for their purchases.</td>
          </tr>
          <tr>
            <th>Depreciation</th>
            <td>A way of spreading the cost of an Asset over its working life.</td>
          </tr>
          <tr>
            <th>Dividends</th>
            <td>Cash payments to the shareholders of a corporation</td>
          </tr>
          <tr>
            <th>Equity</th>
            <td>The owners’ share of the business, including capital invested plus retained profits. In theory, the value left for the owners if all the Assets were sold off, and all other debts paid. Calculated by subtracting all liabilities from total Assets.</td>
          </tr>
          <tr>
            <th>Earnings Before Tax (EBT)</th>
            <td>Profit in the business before the obligations of tax have been paid.</td>
          </tr>
          <tr>
            <th>Financing</th>
            <td>Where the money came from to get the Assets the business uses to operate. Includes owners’ funds put in to start the business, profits kept in the business, and bank loans.</td>
          </tr>
          <tr>
            <th>Fixed Assets</th>
            <td>Assets the business does not routinely sell, but keeps in order to function, e.g. real estate, buildings, machinery, vehicles, tools, and computers used in the office.</td>
          </tr>
          <tr>
            <th>Fixed Costs</th>
            <td>All expenses that do not increase or decrease directly with sales.</td>
          </tr>
          <tr>
            <th>Income Statement</th>
            <td>A statement of all the income of the business during a period of time, and all the expenses incurred, showing the net profit or loss that results. It includes sales made and invoiced, whether or not the cash has been received, and all purchases, whether or not they have been paid for.</td>
          </tr>
          <tr>
            <th>Invested Capital</th>
            <td>Another term for the Net Assets (see below), which must be managed to deliver a good ROIC</td>
          </tr>
          <tr>
            <th>Loans</th>
            <td>Money borrowed (usually from a bank) for a given period, with interest charged.</td>
          </tr>
          <tr>
            <th>Market Capitalisation</th>
            <td>The number of shares issued, times the current market price.</td>
          </tr>
          <tr>
            <th>Net Assets</th>
            <td>Fixed Assets plus Net Current Assets.</td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import Background from "../components/Background.vue";
import { useWindowSize } from "vue-window-size";

export default {
  components: { Background },
  setup() {
    const { width, height } = useWindowSize();

    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  data() {
    return {};
  },
  computed: {
    cssProps() {
      // the base resolution
      const width = 1920;
      const height = 1080;
      const ratio = width / height;

      // the actual view resolution
      let vw = this.windowWidth;
      let vh = this.windowHeight;
      const vRatio = vw / vh;

      // maintain aspect ratio
      if (vRatio >= ratio) {
        // when height is dominant, adjust the width
        vw = vh * ratio;
      } else {
        // when width is dominant, adjust the height
        vh = vw / ratio;
      }

      // get the scaleW and scaleH
      const scaleW = vw / width;
      const scaleH = vh / height;

      // center the view
      const marginTop = (this.windowHeight - vh) / 2;
      const marginLeft = (this.windowWidth - vw) / 2;

      return {
        // '--width': scaleW,
        // '--height': scaleH,
        // '--ori-width': width + "px",
        // '--ori-height': height + "px",

        // you can direct set the css style here, unless you need to use the variable in hierarchy child
        width: `${width}px`,
        height: `${height}px`,
        transform: `scale(${scaleW}, ${scaleH})`,

        "margin-top": `${marginTop}px`,
        "margin-left": `${marginLeft}px`,
      };
    },
  },
};
</script>