import { createStore } from 'vuex';

export const createVuexStore = () => {
  // Create a new store instance.
  return createStore({
    state () {
      return {};
    },
    getters: {
      state: state => state,
    },
    mutations: {},
    actions: {},
  });
};
