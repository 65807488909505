<style lang="scss">
.AfterMarket {
    top:7%;
    left: 4.45%;
    position: fixed;
    width: 91.8%;
    height: 83%;
    font-family: NestleBrush_Reg;

    background: url('../assets/Marketing/MarketResultBoard.png');
    background-size: 100% 100%;

    .teams {
        position: absolute;
        width: 32%;
        height: 42.5%;

        &.teamA { top: 9.6%; left: 0.9%; }
        &.teamB { top: 9.6%; left: 34.1%; }
        &.teamC { top: 9.6%; left: 67.3%; }
        &.teamD { top: 54.8%; left: 0.8%; }
        &.teamE { top: 54.8%; left: 34.1%; }
        &.teamF { top: 54.8%; left: 67.3%; }
    }

    .Cash_Credit {
        position: absolute;
        width: 96%;
        height: 44.8%;
        top: 1.4%;
        left: 1.8%;
        /* background: rgba(188, 174, 235, 0.2); */

        .Cash {
            position: absolute;
            top: 15%;
            left: 0;
            width: 20%;
            height: auto;
        }

        .Credit {
            position: absolute;
            top: 15%;
            left: 51%;
            width: 20%;
            height: 100%;
        }
    }

    input {
        position: absolute;
        width: 15%;
        text-align: center;
        border: none;
        outline: none;
        background: none;
        font-family: NestleBrush_Reg;
        font-size: 22px;
    }
    .classic_cash { top: 65%; left: 52%; color: white; }
    .classic_credit { top: 65%; left: 79%; color: white; }
    .gold_cash { top: 86%; left: 52%; color: black; }
    .gold_credit { top: 86%; left: 79%; color: white; }
    .black_text { color: black; }

}

.advance_btn {
    position: absolute;
    bottom: 2.5%;
    right: 3.7%;
    width: 34%;
    height: 6.5%;
    background: white;
    border: none;
    border-radius: 30px;
}
.advance_btn:hover {
    background: #b2b2b2;
}
.advance_btn:active {
    background: #392c23;
    color: white;
}
</style>

<template>
    <PeriodDisplay v-if="!isTrainer" :isMarket="true" />

    <div class="AfterMarket">
        <template v-for="(team, i) in vTeams" :key="i">
            <div class="teams" :class="teamClass[i]">
                <div class="Cash_Credit">
                    <div class="Cash">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="removeDecimal(vMarketSummary[team.id]?.cash)" :pass_late_init="true" :is_check_pass_minute="true" />
                    </div>
                    <div class="Credit">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="removeDecimal(vMarketSummary[team.id]?.credit)" :pass_late_init="true" :is_check_pass_minute="true" />
                    </div>
                </div>

                <input class="classic_cash" :value="vMarketSummary[team.id]?.classic_unit_sell || 0" readonly>
                <input class="classic_credit" :class="{black_text: i === 4 || i === 5}" :value="vMarketSummary[team.id]?.classic_unit_price || 0" readonly>
                <input class="gold_cash" :value="vMarketSummary[team.id]?.gold_unit_sell || 0" readonly>
                <input class="gold_credit" :class="{black_text: i === 4 || i === 5}" :value="vMarketSummary[team.id]?.gold_unit_price || 0" readonly>
            </div>
        </template>
    </div>

    <button v-if="showControl && !clicked" class="advance_btn" @click="next">Advance Participants Game & Go To Dashboard</button>
</template>

<script>
import PeriodDisplay from '@/components/Period/PeriodDisplay.vue';
import CoinIndicator from '../components/Coin_Indicator.vue'
import { mapGetters } from 'vuex';

export default {
    components: { PeriodDisplay, CoinIndicator },

    props: {
        showControl: {
            default: false,
        },
        isTrainer: {
            default: false,
        },
    },

    emits: [
        'next',
    ],

    computed: {
        ...mapGetters('profitability/participant', {
            pMarketSummary: 'marketSummary',
            pPhase: 'phase',
            pTeams: 'teams'
        }),

        ...mapGetters('profitability/trainer', {
            tMarketSummary: 'marketSummary',
            tPhase: 'phase',
            tTeams: 'teams'
        }),

        vMarketSummary() {
            if (this.isTrainer) {
                return this.tMarketSummary
            } else {
                return this.pMarketSummary
            }
        },

        vPhase() {
            if (this.isTrainer) {
                return this.tPhase
            } else {
                return this.pPhase
            }
        },

        vTeams() {
            if (this.isTrainer) {
                return this.tTeams
            } else {
                return this.pTeams
            }
        }
    },

    mounted() {

    },

    data() {
        return {
            teamClass: [
                'teamA',
                'teamB',
                'teamC',
                'teamD',
                'teamE',
                'teamF',
            ],
            clicked: false,
            isLoaded: false,
            teamMarketSummary: {}
        }
    },
    
    methods: {
        next() {
            if (!this.clicked) {
                this.clicked = true;
                this.$emit('next');
            }
        },

        removeDecimal(num) {
            let str = String(num.toFixed(1));
            return parseInt(str.slice(0, str.indexOf('.')))
        }
    },
}
</script>
