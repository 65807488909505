<style lang="scss">
.Trainer_Loan_Box {
  position: absolute;
  top: 25%;
  left: 23%;
  width: 60%;
  height: 50%;
  background: #63513d;
  border-radius: 10px;
  z-index: 100;

  input {
    position: absolute;
    top: 10%;
    left: 5%;
    text-align: center;
    height: auto;
    width: 90%;
    border-radius: 6px;
    background: white;
    padding: 4% 0;
    border: none;
    outline: none;
    font-family: NestleBrush_Reg;
    font-size: 20px;
  }

  .loan_btn {
    background: #a3d65d;
    border-radius: 5px;
    border: none;
    position: absolute;
    bottom: 14%;
    left: 35%;
    width: 30%;
    padding: 5px 0;
    /* height: 25%; */
    font-size: 19px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .loan_btn:hover {
    background: #729541;
  }

  .loan_btn:active {
    background: #392c23;
    color: white;
  }
}
</style>

<template>
  <div class="Trainer_Loan_Box">
    <input
      placeholder="0"
      v-model.number="loanAmount"
      maxlength="3"
      @focus="$event.target.select()"
      :disabled="loading"
    />

    <button class="loan_btn" @click="takeLoanForTeam" :disabled="loading || loanAmount < 0">Loan</button>
  </div>
</template>

<script>

export default {
  props: {
    team_id: Number,
  },
  emits: [
    'takeLoan',
  ],
  data() {
    return {
      loanAmount: 0,
      loading: false,
    };
  },
  methods: {
    takeLoanForTeam() {
      const { team_id, loanAmount } = this;
      if (loanAmount > 0) {
        this.loading = true;
        return Profitability.Trainer.takeLoanForTeam(team_id, loanAmount).then(() => {
        }).finally(() => {
          this.loading = false;
          this.$emit('takeLoan');
        });
      }
      else if (loanAmount === 0) {
        this.$emit('takeLoan');
      }
    },
  },
};
</script>
