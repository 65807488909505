<template>
  <div v-if="panel === panel_name[0]">
  </div>

  <div v-if="panel === panel_name[1]">
    <Marketing :panelName="panel_name[1]" @close="Close_Panel(panel_name[1])"/>
  </div>

  <div v-if="panel === panel_name[2]">
    <Delivery @close="Close_Panel(panel_name[2])" @customer_cash_available=customer_cash_emit />
  </div>

  <div v-show="panel === panel_name[3]">
    <IS :panelName="panel_name[3]" @close="Close_Panel(panel_name[3])"/>
  </div>

  <div v-show="panel === panel_name[4]">
    <BS :panelName="panel_name[4]" @close="Close_Panel(panel_name[4])" :closeInfoBox="isBSformOpen"/>
  </div>

  <div v-if="panel === panel_name[5]">
  </div>

  <div v-if="panel === panel_name[6]">
    <PA v-if="vPhase.period > 1"
        :panelName="panel_name[6]"
        @close="Close_Panel(panel_name[6])"
        :canCheckPrevPA="true"
        @canSubmitPA="canSubmitPA"
    />
  </div>

  <div v-if="panel === panel_name[7]">
    <CashFlow
      v-if="vPhase.period > 2"
      :at_panel="true"
      :panelName="panel_name[7]"
      @close="Close_Panel(panel_name[7])"
      style="margin-top: 4.45%;"
      v-model:cashflowForecast="vCashflowForecast"
    />
  </div>
  <div v-if="panel === panel_name[8]">
    <OperationalPlan
      v-if="vPhase.period > 2"
      :at_panel="true"
      :panelName="panel_name[8]"
      @close="Close_Panel(panel_name[8])"
      style="margin-top: 4.45%;"
      v-model:operationalPlan="vOperationalPlan"
    />
  </div>

  <div v-if="panel === panel_name[9]">
    <Supplier :panelName="panel_name[9]" @close="Close_Panel(panel_name[9])" @storyPurchaseRawMats="storyPurchaseRawMats"/>
  </div>

  <div v-if="panel === panel_name[10]">
    <Supplier :panelName="panel_name[10]" @close="Close_Panel(panel_name[10])" @storyPurchaseRawMats="storyPurchaseRawMats"/>
  </div>

  <!-- Bank button @ Section_External_Locations.vue -->
  <div v-if="panel === panel_name[11]">
    <div class="form_panel button" :class=panel_name[11]>
      <input readonly class="BM_Input" v-model="borrow" maxlength="3" @click="Borrow_Money">
      <button class="BM_Btn add" @click="Bank_Add_Money"></button>
      <button class="BM_Btn subtract" @click="Bank_Subtract_Money"></button>
    </div>
  </div>

  <Alert
    v-if="alertDialog"
    :alertOpen="true"
    :message="alertDialog.message"
    :onlyClose="true"
    cancelText="Close"
    @close_alert="alertDialog = null"
    @confirm_alert="alertDialog = null"
  />
</template>

<script>
import Alert from '@/components/Alert_PopUp';
import Profitability from '@/profitability-nestle-js';
import { mapGetters } from 'vuex';
import Delivery from './Gameboard_SidePanel_Delivery';
import Supplier from './Gameboard_SidePanel_Supplier';
import CashFlow from './SidePanel/Gameboard_Cash_Flow';
import OperationalPlan from './SidePanel/Gameboard_Operational_Plan';
import PA from './SidePanel/Gameboard_PA';
import BS from './SidePanel/Gameboard_SidePanel_BS';
import IS from './SidePanel/Gameboard_SidePanel_IS';
import Marketing from './SidePanel/Gameboard_SidePanel_Marketing';

export default {
  components: { Alert, Delivery, Marketing, Supplier, IS, BS, PA, OperationalPlan, CashFlow },
  props: {
    submitPAData: Object,
    submitPA: Boolean,
  },
  emits: ['return_side_panel', 'close_side_panel', 'borrow_Money', 'customer_cash_emit', 'submitPAFroMSidePanel', 'storyRawMats'],
  data() {
    return {
      panel_name: ['Rules', 'Marketing', 'Delivery', 'Income', 'Balance', 'Records', 'Performance', 'Forecast', 'Planning', 'Supplier_Pre', 'Supplier_Post', 'Bank'],
      // should_show: [false, false, false, false, false, false, false, false, false, false, false, false],

      panel: null,
      borrow: 0,
      alertDialog: null,
    };
  },
  watch: {
    vPhase() {
      if (this.isPAPhase) {
        this.panel = 'Performance';
      }
    },
  },
  computed: {
    ...mapGetters({
      vDebug: 'debug',
    }),
    ...mapGetters('profitability/participant', {
      vTeam: 'team',
      vTeamData: 'teamData',
      vGameboard: 'gameboard',
      vPhase: 'phase',
    }),
    netAssets() {
      return this.vTeam.net_assets || 0;
    },
    loanAmount() {
      return this.vGameboard.getValue('loans', '*');
    },
    maxLoanAmount() {
      return Math.floor(this.netAssets * 0.5) - this.loanAmount;
    },
    bank_cash() {
      return this.vGameboard?.getValue('bank', 'cash');
    },
    bank_standard() {
      return this.vGameboard?.getValue('bank', 'standard');
    },
    bank_high() {
      return this.vGameboard?.getValue('bank', 'high');
    },
    vOperationalPlan() {
      return this.vTeamData.operational_plan;
    },
    vCashflowForecast() {
      return this.vTeamData.cashflow_forecast;
    },
    isPAPhase() {
      if (this.vPhase.period > 1 && this.vPhase.market_phase == 2 && this.vPhase.phase_step == 8)
        return true;

      return false;
    },

    isBSformOpen() {
      if (this.panel === 'Balance') {
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.$emit('return_side_panel', this);
  },
  methods: {
    Show_Panel(this_name) {
      // prevent panel from open if period 0
      if (this.vPhase.period === 0) {
        switch (this.vPhase.phase_step) {
          case 0:
          case 1:
          case 2:
          case 5:
          case 8:
          case 9:
            return;
          case 3:
            if (this_name !== 'Supplier_Pre') {
              return;
            }
            break;
          case 4:
            if (this_name !== 'Delivery') {
              return;
            }
            break;
          case 6:
            if (this_name !== 'Bank') {
              return;
            }
            break;
          case 7:
            if (this_name !== 'Supplier_Post') {
              return;
            }
            break;
          default:
            break;
        }
      }

      if (this.panel !== this_name)
        this.panel = this_name;
      else
        this.panel = null;
    },
    Borrow_Emit() {
      this.$emit('borrow', this.borrow);
    },
    Bank_Add_Money() {
      this.borrow += 10;

      // Disable maxLoanAmount capped due to New loan formula
      // prevent it exist maxLoanAmount
      // if (this.borrow > this.maxLoanAmount) {
      //     this.borrow -= 10;
      // }

      this.Borrow_Emit();
    },
    Bank_Subtract_Money() {
      if (this.borrow > 0) {
        this.borrow -= 10;
        this.Borrow_Emit();
      } else {
        // alert('Cannot borrow less than $10');
        this.alertDialog = {
          message: 'Cannot borrow less than $10',
        };
      }
    },
    Borrow_Money() {
      if (this.borrow > 0) {
        if (this.vPhase.period === 0 && this.vPhase.phase_step === 6) {
          if (this.borrow === 10) {
            return this.takeLoan().then(() => {
              //this.$emit('Borrow_Money', this.borrow)
              this.Close_Panel('Bank');
              this.borrow = 0;
            }).catch(() => {
              // alert('Please only take a loan of $10 in period 1');
              this.alertDialog = {
                message: 'Please only take a loan of $10 in period 1.',
              };
              this.Close_Panel('Bank');
              this.borrow = 0;
            });
          } else {
            // alert('Please only take a loan of $10 in period 1');
            this.alertDialog = {
              message: 'Please only take a loan of $10 in period 1.',
            };
            this.Close_Panel('Bank');
            return;
          }
        } else {
          return this.takeLoan().then(() => {
            //this.$emit('Borrow_Money', this.borrow)
            this.Close_Panel('Bank');
            this.borrow = 0;
          }).catch(() => {
            // alert('Unable to take loan now');
            this.alertDialog = {
              message: 'Your loan request has exceeded the allowable amount. Kindly check with the facilitator for help.',
            };
            this.Close_Panel('Bank');
            this.borrow = 0;
          });
        }
      } else {
        // alert('Cannot borrow less than $10');
        this.alertDialog = {
          message: 'Cannot borrow less than $10.',
        };
      }

      this.Close_Panel('Bank');
      this.borrow = 0;
    },
    Close_Panel(name) {
      this.$emit('close_side_panel', name);
    },
    takeLoan() {
      if (this.vPhase.period !== 0) {
        return Profitability.Participant.takeLoan(this.borrow);
      } else {
        if (this.vPhase.phase_step === 6) {
          if (this.borrow === 10) {
            return Profitability.Participant.mbsTakeLoan(this.borrow);
          } else {
            // alert('Please take loan of $10 only');
            this.alertDialog = {
              message: 'Please take loan of $10 only.',
            };
          }
        }
      }

      return Promise.reject();
    },
    customer_cash_emit() {
      this.$emit('customer_cash_emit', cash);
    },
    canSubmitPA(bool, data) {
      this.$emit('submitPAFroMSidePanel', bool, data);
    },
    storyPurchaseRawMats() {
      this.$emit('storyRawMats');
    },
  },
};
</script>

<style>
.form_panel {
  top: 27.5%;
  right: 8%;
  position: fixed;
  width: 23.5%;
  height: 70%;

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  z-index: 2;
}

.form_panel.order {
  height: 24%;
}

.form_panel.button {
  width: 6%;
  height: 10.3%;
  top: 60%;
  left: 7.3%;
  position: fixed;
}

.form_panel.Rules { /* background-image: url('../assets/Gameboard/Panel/panel_rules.png'); */}

.form_panel.Records {}

.form_panel.Performance {}

.form_panel.Forecast {}

.form_panel.Planning {}

.form_panel.button.Bank {background-image: url('../assets/Gameboard/Sections/section_external_locations_bank.png');}

/* Order Form */


.BM_Input {
  top: 7%;
  height: 55%;
  width: 76%;
  position: relative;
  text-align: center;
  font-size: 28px;
  color: black;
  font-family: NestleBrush_Reg;
  outline: none;
  border: none;
  cursor: pointer;
}

.BM_Btn {
  background-color: transparent;
  border-color: transparent;
  position: absolute;
  width: 18%;
  height: 22%;
  bottom: 6%;
}

.add {
  left: 25%;
}

.subtract {
  left: 57%;
}
</style>
