<style lang="scss">
.Story_Window {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.7);
    z-index: 10;

    .board {
        position: absolute;
        top: 15%;
        left: 15%;
        width: 70%;
        height: 70%;
        background: #63513d;
        border-radius: 20px;

        .wrapper {
            position: absolute;
            top: 5%;
            left: 3.2%;
            width: 93.5%;
            height: 80%;
            overflow-y: auto;
            background: #b5dd7d;
            /* color: white; */
            border-radius: 20px;
            /* text-align: justify; */
            padding: 2% 3%;

            pre {
                padding: 0;
                margin: 0;
                font-family: NestleText;
                font-size: 32px;
                white-space: pre-wrap;       /* Since CSS 2.1 */
                white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                white-space: -pre-wrap;      /* Opera 4-6 */
                white-space: -o-pre-wrap;    /* Opera 7 */
                word-wrap: break-word;       /* Internet Explorer 5.5+ */
            }
        }

    }

    button {
        position: absolute;
        bottom: 4%;
        left: 44%;
        width: 16%;
        background: #a4d65d;
        font-family: NestleBrush_Reg;
        border: none;
        outline: none;
        padding: 1% 0;
        font-size: 26px;
        border-radius: 25px;
    }

    button:hover {
        background: #729541;
    }

    button:active {
        background: #392c23;
        color: white;
    }
}
</style>

<template>    
    <div v-if="box_display" class="Story_Window">

        <div v-if="phase === 0" class="board">
            <div v-for="i in begin" class="wrapper" :key="i">
                <pre>{{ begin[counter] }}</pre>
            </div>

            <button @click="closePopUp">Close</button>
            <button v-if="begin[counter + 1]" @click="next">Next</button>
        </div>

        <div v-if="phase === 1" class="board">
            <div class="wrapper">
                <pre>{{ mbs_1 }}</pre>
            </div>
            <button @click="closePopUp">Close</button>
        </div>

        <div v-if="phase === 2" class="board">
            <div v-for="i in begin" class="wrapper" :key="i">
                <pre>{{ mbs_2[counter] }}</pre>
            </div>

            <button @click="closePopUp">Close</button>
            <button v-if="mbs_2[counter + 1]" @click="next">Next</button>
        </div>

        <div v-if="phase === 3" class="board">
            <div v-for="i in begin" class="wrapper" :key="i">
                <pre>{{ mbs_3[counter] }}</pre>
            </div>

            <button @click="closePopUp">Close</button>
            <button v-if="mbs_3[counter + 1]" @click="next">Next</button>
        </div>

        <div v-if="phase === 4" class="board">
            <div v-for="i in begin" class="wrapper" :key="i">
                <pre>{{ mbs_4[counter] }}</pre>
            </div>

            <button @click="closePopUp">Close</button>
            <button v-if="mbs_4[counter + 1]" @click="next">Next</button>
        </div>

        <div v-if="phase === 5" class="board">
            <div v-for="i in begin" class="wrapper" :key="i">
                <pre>{{ mbs_5[counter] }}</pre>
            </div>

            <button @click="closePopUp">Close</button>
            <button v-if="mbs_5[counter + 1]" @click="next">Next</button>
        </div>

        <div v-if="phase === 6" class="board">
            <div class="wrapper">
                <pre>{{ mbs_6 }}</pre>
            </div>
            <button @click="closePopUp">Close</button>
        </div>

        <div v-if="phase === 7" class="board">
            <div class="wrapper">
                <pre>{{ mbs_7 }}</pre>
            </div>
            <button @click="closePopUp">Close</button>
        </div>

        <div v-if="phase === 8" class="board">
            <div class="wrapper">
                <pre>{{ mbs_8 }}</pre>
            </div>
            <button @click="closePopUp">Close</button>
        </div>

        <div v-if="phase === 9" class="board">
            <div class="wrapper">
                <pre>{{ mbs_9 }}</pre>
            </div>
            <button @click="closePopUp">Close</button>
        </div>

        <div v-if="phase === 10" class="board">
            <div class="wrapper">
                <pre>{{ mbs_10 }}</pre>
            </div>
            <button @click="closePopUp">Close</button>
        </div>

    </div>
</template>

<script>
import { begin, mbs_1, mbs_2, mbs_3, mbs_4, mbs_5, mbs_6, mbs_7, mbs_8, mbs_9, mbs_10 } from '@/constants/storyWindow.js'

export default {
    props: {
        phase: Number,
    },
    components: { },
    data() {
        return {
            begin, mbs_1, mbs_2, mbs_3, mbs_4, mbs_5, mbs_6, mbs_7, mbs_8, mbs_9, mbs_10,
            
            counter: 0,
            box_display: true,
            log_phase: -1,
        }
    },
    watch: {
        phase() {
            if (this.log_phase !== this.phase) {
                this.log_phase += 1;
                this.box_display = true;
            }
        },
    },
    methods: {
        closePopUp() {
            this.box_display = false
            this.counter = 0
        },
        next() {
            this.counter++;
        }
    }
}
</script>