<style lang="scss">
.Story_Window {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.7);

    .board {
        position: absolute;
        top: 15%;
        left: 15%;
        width: 70%;
        height: 70%;
        background: #63513d;
        border-radius: 20px;

        .wrapper {
            position: absolute;
            top: 5%;
            left: 3.2%;
            width: 93.5%;
            height: 80%;
            overflow-y: auto;
            background: #b5dd7d;
            /* color: white; */
            border-radius: 20px;
            /* text-align: justify; */
            padding: 2% 3%;

            pre {
                padding: 0;
                margin: 0;
                font-family: NestleText;
                font-size: 32px;
                white-space: pre-wrap;       /* Since CSS 2.1 */
                white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                white-space: -pre-wrap;      /* Opera 4-6 */
                white-space: -o-pre-wrap;    /* Opera 7 */
                word-wrap: break-word;       /* Internet Explorer 5.5+ */
            }
        }

    }

    button {
        position: absolute;
        bottom: 4%;
        left: 44%;
        width: 16%;
        background: #a4d65d;
        font-family: NestleBrush_Reg;
        border: none;
        outline: none;
        padding: 1% 0;
        font-size: 26px;
        border-radius: 25px;
    }

    button:hover {
        background: #729541;
    }

    button:active {
        background: #392c23;
        color: white;
    }
}
</style>

<template>
    <div v-if="box_display_0 && vPhase.period === 0 && vPhase.phase_step === 0" class="Story_Window">
         <div class="board">
            <div v-for="i in p1_begin" class="wrapper" :key="i">
                <pre>{{ p1_begin[counter] }}</pre>
            </div>

            <button @click="closePopUp">Close</button>
            <button v-if="p1_begin[counter + 1]" @click="next">Next</button>
        </div>
    </div>
    
    <div v-if="box_display" class="Story_Window">

        <div v-if="vPhase.period === 0">
            <div v-if="vPhase.phase_step === 1" class="board">
                <div class="wrapper">
                    <pre>{{ p_1 }}</pre>
                </div>
                <button @click="closePopUp">Close</button>
            </div>

            <div v-if="vPhase.phase_step === 2" class="board">
                <div v-for="i in p_2" class="wrapper" :key="i">
                    <pre>{{ p_2[counter] }}</pre>
                </div>

                <button @click="closePopUp">Close</button>
                <button v-if="p_2[counter + 1]" @click="next">Next</button>
            </div>

            <div v-if="vPhase.phase_step === 3" class="board">
                <div v-for="i in p_3" class="wrapper" :key="i">
                    <pre>{{ p_3[counter] }}</pre>
                </div>

                <button @click="closePopUp">Close</button>
                <button v-if="p_3[counter + 1]" @click="next">Next</button>
            </div>

            <div v-if="vPhase.phase_step === 4" class="board">
                <div v-for="i in p_4" class="wrapper" :key="i">
                    <pre>{{ p_4[counter] }}</pre>
                </div>

                <button @click="closePopUp">Close</button>
                <button v-if="p_4[counter + 1]" @click="next">Next</button>
            </div>

            <div v-if="vPhase.phase_step === 5" class="board">
                <div v-for="i in p_5" class="wrapper" :key="i">
                    <pre>{{ p_5[counter] }}</pre>
                </div>

                <button @click="closePopUp">Close</button>
                <button v-if="p_5[counter + 1]" @click="next">Next</button>
            </div>

            <div v-if="vPhase.phase_step === 6" class="board">
                <div class="wrapper">
                    <pre>{{ p_6 }}</pre>
                </div>
                <button @click="closePopUp">Close</button>
            </div>

            <div v-if="vPhase.phase_step === 7" class="board">
                <div class="wrapper">
                    <pre>{{ p_7 }}</pre>
                </div>
                <button @click="closePopUp">Close</button>
            </div>

            <div v-if="vPhase.phase_step === 8" class="board">
                <div class="wrapper">
                    <pre>{{ p_8 }}</pre>
                </div>
                <button @click="closePopUp">Close</button>
            </div>

            <div v-if="vPhase.phase_step === 9" class="board">
                <div class="wrapper">
                    <pre>{{ p_9 }}</pre>
                </div>
                <button @click="closePopUp">Close</button>
            </div>

            <div v-if="vPhase.phase_step === 10" class="board">
                <div class="wrapper">
                    <pre>{{ p_10 }}</pre>
                </div>
                <button @click="closePopUp">Close</button>
            </div>
        </div>

        <div v-if="vPhase.period === 1">
            <div v-if="vPhase.market_phase === 0">
                <div v-if="vPhase.phase_step === 0" class="board">
                    <div v-for="i in p2_pre_0" class="wrapper" :key="i">
                        <pre>{{ p2_pre_0[counter] }}</pre>
                    </div>

                    <button @click="closePopUp">Close</button>
                    <button v-if="p2_pre_0[counter + 1]" @click="next">Next</button>
                </div>                
            </div>

            <div v-if="vPhase.market_phase === 2">
                <div v-if="vPhase.phase_step === 0" class="board">
                    <div class="wrapper">
                        <pre>{{ p2_post_0 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>
                
                <div v-if="vPhase.phase_step === 2" class="board">
                    <div class="wrapper">
                        <pre>{{ p2_post_2 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>

                <div v-if="triggerStoryRawMats" class="board">
                    <div class="wrapper">
                        <pre>{{ storyRawMatsText }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>

                <div v-if="vPhase.phase_step === 5" class="board">
                    <div class="wrapper">
                        <pre>{{ p2_post_5 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>

                <div v-if="vPhase.phase_step === 8" class="board">
                    <div class="wrapper">
                        <pre>{{ p2_post_8 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>

                <div v-if="vPhase.phase_step === 9" class="board">
                    <div class="wrapper">
                        <pre>{{ p2_post_9 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>
            </div>
        </div>

        <div v-if="vPhase.period === 2">
            <div v-if="vPhase.market_phase === 0">
                <div v-if="vPhase.phase_step === 0" class="board">
                    <div v-for="i in p3_pre_0" class="wrapper" :key="i">
                        <pre>{{ p3_pre_0[counter] }}</pre>
                    </div>

                    <button @click="closePopUp">Close</button>
                    <button v-if="p3_pre_0[counter + 1]" @click="next">Next</button>
                </div>

                <div v-if="vPhase.phase_step === 3" class="board">
                    <div class="wrapper">
                        <pre>{{ p3_pre_3 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>
            </div>

            <div v-if="vPhase.market_phase === 2">
                <div v-if="vPhase.phase_step === 8" class="board">
                    <div class="wrapper">
                        <pre>{{ p3_post_8 }}</pre>
                    </div>
                    
                    <button @click="closePopUp">Close</button>
                </div>

                <div v-if="vPhase.phase_step === 9" class="board">
                    <div class="wrapper">
                        <pre>{{ p3_post_9 }}</pre>
                    </div>
                    
                    <button @click="closePopUp">Close</button>
                </div>
            </div>
        </div>

        <div v-if="vPhase.period === 3">
            <div v-if="vPhase.market_phase === 0">
                <div v-if="vPhase.phase_step === 0" class="board">
                    <div v-for="i in p4_pre_0" class="wrapper" :key="i">
                        <pre>{{ p4_pre_0[counter] }}</pre>
                    </div>

                    <button @click="closePopUp">Close</button>
                    <button v-if="p4_pre_0[counter + 1]" @click="next">Next</button>
                </div>
            </div>

            <div v-if="vPhase.market_phase === 2">
                <div v-if="vPhase.phase_step === 3" class="board">
                    <div class="wrapper">
                        <pre>{{ p4_post_3 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>

                <div v-if="vPhase.phase_step === 9" class="board">
                    <div class="wrapper">
                        <pre>{{ p4_post_8 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>
            </div>
        </div>

        <div v-if="vPhase.period === 4">
            <div v-if="vPhase.market_phase === 0">
                <div v-if="vPhase.phase_step === 0" class="board">
                    <div class="wrapper">
                        <pre>{{ p5_pre_0 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>
            </div>

            <div v-if="vPhase.market_phase === 2">
                <div v-if="vPhase.phase_step === 3" class="board">
                    <div class="wrapper">
                        <pre>{{ p5_post_3 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>

                <div v-if="vPhase.phase_step === 9" class="board">
                    <div class="wrapper">
                        <pre>{{ p5_post_8 }}</pre>
                    </div>
                    <button @click="closePopUp">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { p1_begin, p_1, p_2, p_3, p_4, p_5, p_6, 
         p_7, p_8, p_9, p_10, p2_pre_0, p2_post_0, 
         p2_post_2, p2_post_5, p2_post_8, p3_pre_0,
         p3_pre_3, p3_post_8, p3_post_9, p4_pre_0, p4_post_3, p4_post_8,
         p5_pre_0, p5_post_3, p5_post_8
} from '@/constants/storyWindow.js'

export default {
    props: {
        triggerStoryRawMats: Boolean
    },
    data() {
        return {
            p1_begin,
            p_1, p_2, p_3, p_4, p_5, p_6, p_7, p_8, p_9, p_10,
            p2_pre_0, p2_post_0, p2_post_2, p2_post_5, p2_post_8,
            p3_pre_0, p3_pre_3, p3_post_8, p3_post_9,
            p4_pre_0, p4_post_3, p4_post_8,
            p5_pre_0, p5_post_3, p5_post_8,
            counter: 0,
            box_display: false,
            box_display_0: true,
            triggered: true,
            storyRawMatsText: "You now have an option to take supplier credit and pay for your raw materials in the next round or you may pay cash and remove the liability immediately.",
        }
    },
    watch: {
        vPhase(next, prev) {
            if (this.vPhase.period === 0)
                if (next.phase_step !== prev.phase_step) 
                    this.box_display = true

            if (this.vPhase.period === 1 && this.vPhase.market_phase === 0 && (this.vPhase.phase_step === 0) ||
                this.vPhase.period === 1 && this.vPhase.market_phase === 2 && (this.vPhase.phase_step === 0 || this.vPhase.phase_step === 2 || this.triggerStoryRawMats || this.vPhase.phase_step === 5 || this.vPhase.phase_step === 8) ||
                this.vPhase.period === 2 && this.vPhase.market_phase === 0 && (this.vPhase.phase_step === 0 || this.vPhase.phase_step === 3) ||
                this.vPhase.period === 2 && this.vPhase.market_phase === 2 && (this.vPhase.phase_step === 8 || this.vPhase.phase_step === 9) ||
                this.vPhase.period === 3 && this.vPhase.market_phase === 0 && this.vPhase.phase_step === 0 ||
                this.vPhase.period === 3 && this.vPhase.market_phase === 2 && (this.vPhase.phase_step === 3 || this.vPhase.phase_step === 9) ||
                this.vPhase.period === 4 && this.vPhase.market_phase === 0 && this.vPhase.phase_step === 0 ||
                this.vPhase.period === 4 && this.vPhase.market_phase === 2 && (this.vPhase.phase_step === 3 || this.vPhase.phase_step === 9)
                )
                    if (next.phase_step !== prev.phase_step)
                        this.box_display = true
                    
                    if (this.triggerStoryRawMats && this.triggered) {
                        this.box_display = true
                        this.triggered = false
                    }
            else
                this.box_display = false
        }
    },
    computed: {
        ...mapGetters('profitability/participant', {
            vTeam: 'team',
            vPhase: 'phase',
            vTeams: 'teams',
        }),
    },
    methods: {
        closePopUp() {
            if (this.vPhase.period === 0 && this.vPhase.phase_step === 0)
                this.box_display_0 = false
            else
                this.box_display = false
            
            this.counter = 0
        },
        next() {
            this.counter++;
        }
    }
}
</script>