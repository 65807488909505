<style lang="scss">
.period-0 {
    .team-status {
        flex: 1;
        background: none;
        font-family: NestleBrush_Reg;

        h4 {
            color: white;
            font-size: 2.5rem;
            padding: 2%;
            margin: 0;
        }

        .teams {
            margin-left: 1.15%;
            padding: 1%;
            display: grid;
            grid-template-columns: auto auto auto;
        }
    }
}

</style>

<template>

    <div class="team-status">
        <h4>Team Status</h4>
        <div class="teams">
            <template v-for="(team, i) in vTeams" :key="i">
                <TeamStatusTeam :team="team"></TeamStatusTeam>
            </template>
        </div>
    </div>

</template>

<script>
import TeamStatusTeam from './TeamStatusTeam';
import { mapGetters } from "vuex";

export default {
    components: {
        TeamStatusTeam,
    },
    computed: {
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
        }),
    },
}
</script>
