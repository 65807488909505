import _debug from 'debug';
import EventType from "../EventType";
import _ from "lodash"

const debug = (namespace) => _debug(`profitability:${namespace}`);
const log = debug('trainer');

const MarketPhase = {
  PreMarket: 0,
  Market: 1,
  PostMarket: 2,
  Summary: 3,
};

export default class Trainer {
  context = null;

  constructor(context) {
    this.context = context;

    const getTrainingSessionDataInterval = 15 * 1000; // to milliseconds
    this.debounceGetTrainingSessionData = _.debounce(() => {
      log('debounceGetTrainingSessionData');
      this.getTrainingSessionData();
    }, getTrainingSessionDataInterval);
  }

  emit(...params) {
    return this.context.emit(...params);
  }

  get axios() {
    return this.context.axios;
  }

  get echo() {
    return this.context.echo;
  }

  get store() {
    return this.context.store;
  }

  get MarketPhase() {
    return MarketPhase;
  };

  _onLoggedIn()
  {
    return this.getTrainingSessionData().then(() => {
      // subscribe to trainingSession
      const trainingSession = this.store.getters['profitability/trainer/trainingSession'];
      log(`subscribe to trainingSession.${trainingSession.id}`);
      const trainingSessionChannel = this.echo.channel(`trainingSession.${trainingSession.id}`);
      this.emit(EventType.SubscribeToTrainingSession, trainingSessionChannel);

      trainingSessionChannel.listen('.TrainingSessionDataUpdatedEvent', this._onTrainingSessionDataUpdatedEvent);
      trainingSessionChannel.listen('.TrainingSessionPeriodUpdatedEvent', this._onTrainingSessionPeriodUpdatedEvent);

      const trainerTrainingSessionChannel = this.echo.private(`trainingSession.${trainingSession.id}.trainer`);
      log(`subscribe to trainingSession.${trainingSession.id}.trainer`);
      trainerTrainingSessionChannel.listen(`.TrainingTeamDataUpdatedEvent`, this._onTrainingTeamDataUpdatedEvent);
      trainerTrainingSessionChannel.listen('.TrainingSessionDataUpdatedEvent', this._onTrainingSessionDataUpdatedEvent);
    });
  }

  _onTrainingTeamDataUpdatedEvent = (event) => {
    debug(`profitability:Echo.TrainingTeamDataUpdatedEvent`)(event);

    const { team_id, data } = event;
    if (data) {
      this.store.commit('profitability/trainer/setTeam', {
        team_id,
        ...data,
      });
    }

    this.debounceGetTrainingSessionData();
  };

  _onTrainingSessionDataUpdatedEvent = (event) => {
    debug(`profitability:Echo.TrainingSessionDataUpdatedEvent`)(event);

    const { data } = event;
    const { trainingSessionData, marketCards, _marketCards, allTeamSummaries, marketSummary, getLeaderboardData } = data;
    if (trainingSessionData) {
      this.store.commit('profitability/trainer/setTrainingSessionData', trainingSessionData);
    }
    if (marketCards) {
      this.store.commit('profitability/trainer/setMarketCards', marketCards);
    }
    if (_marketCards) {
      this.store.commit('profitability/trainer/clearMarketCards');
      this.store.commit('profitability/trainer/setMarketCards', _marketCards);
    }
    if (allTeamSummaries) {
      this.store.commit('profitability/trainer/allTeamSummaries', allTeamSummaries);
    }
    if (marketSummary) {
      this.store.commit('profitability/trainer/marketSummary', marketSummary);
    }
    if (getLeaderboardData) {
      this.store.commit('profitability/trainer/getLeaderboardData', getLeaderboardData);
    }

    this.debounceGetTrainingSessionData();
  };

  _onTrainingSessionPeriodUpdatedEvent = (event) => {
    debug(`profitability:Echo._onTrainingSessionPeriodUpdatedEvent`)(event);

    this.store.commit('profitability/trainer/reset');

    this.getTrainingSessionData();
  };

  get(name, url) {
    return this.axios.get(url).then((response) => {
      const { data } = response;
      debug(name)(data);
      return data;
    }).catch(err => {
      debug(`${name}:err`)(err);
      throw err;
    });
  }

  post(name, url, data) {
    return this.axios.post(url, data).then((response) => {
      const { data } = response;
      debug(name)(data);
      return data;
    }).catch(err => {
      debug(`${name}:err`)(err);
      throw err;
    });
  }

  api() {
    return this.get('/api/trainer').then((response) => {
      const { data } = response;
      return data;
    });
  }

  getTrainingSessionData() {
    return this.get('getTrainingSessionData', '/api/trainer/trainingSessionData').then((data) => {
      const { training, trainingSession, trainingSessionData, teams, factories, marketCards, allTeamSummaries, marketSummary } = data;

      this.store.commit('profitability/trainer/setTraining', training);
      this.store.commit('profitability/trainer/setTrainingSession', trainingSession);
      this.store.commit('profitability/trainer/setTrainingSessionData', trainingSessionData);
      this.store.commit('profitability/trainer/setTeams', teams);
      this.store.commit('profitability/trainer/setFactories', factories);
      if (marketCards) {
        this.store.commit('profitability/trainer/setMarketCards', marketCards);
      }
      if (allTeamSummaries) {
        this.store.commit('profitability/trainer/allTeamSummaries', allTeamSummaries);
      }
      if (marketSummary) {
        this.store.commit('profitability/trainer/marketSummary', marketSummary);
      }

      this.debounceGetTrainingSessionData();

      return data;
    });
  }

  updatePeriod(period) {
    return this.post('updatePeriod', '/api/trainer/period', {
      period,
    });
  }

  nextPeriod() {
    const period = this.store.getters['profitability/trainer/trainingSessionData'].period;
    const nextPeriod = period + 1;
    return this.updatePeriod(nextPeriod);
  }

  nextMarketPhase(check) {
    const marketPhase = this.store.getters['profitability/trainer/trainingSessionData'].market_phase;
    return this.updateMarketPhase(marketPhase + 1, check);
  }

  updateMarketPhase(marketPhase, check) {
    return this.post('updateMarketPhase', '/api/trainer/marketPhase', {
      market_phase: marketPhase, check
    });
  }

  checkAllReady() {
    return this.post('checkAllReady', '/api/trainer/checkAllReady')
  }

  updatePhaseStep(phaseStep) {
    return this.post('updatePhaseStep', '/api/trainer/phaseStep', {
      phase_step: phaseStep,
    });
  }

  submitMarketCardSetup(marketCardSetup) {
    return this.post('submitMarketCardSetup', '/api/trainer/submitMarketCardSetup', marketCardSetup);
  }

  revealTeamOrderData(type, subtype, reveal) {
    return this.post('revealTeamOrderData', '/api/trainer/revealTeamOrderData', {
      type, subtype, reveal,
    });
  }

  revealAllTeamOrderData(type) {
    if (type == 'classic') {
      return this.post('revealTeamOrderData', '/api/trainer/revealTeamOrderData',
      {
        "reveals": {
          "classic": {
            "price": [
              true,
              true,
              true,
              true,
              true,
              true
            ],
            "rnd": [
              true,
              true,
              true,
              true,
              true,
              true
            ],
            "pfme": [
              true,
              true,
              true,
              true,
              true,
              true
            ],
            "total_pfme": [
              true,
              true,
              true,
              true,
              true,
              true
            ]
          },
        }
      })
    } else {
      return this.post('revealTeamOrderData', '/api/trainer/revealTeamOrderData',
      {
        "reveals": {
          "gold": {
            "price": [
              true,
              true,
              true,
              true,
              true,
              true
            ],
            "rnd": [
              true,
              true,
              true,
              true,
              true,
              true
            ],
            "pfme": [
              true,
              true,
              true,
              true,
              true,
              true
            ],
            "total_pfme": [
              true,
              true,
              true,
              true,
              true,
              true
            ],
            "pfme_price_ratio": [
              true,
              true,
              true,
              true,
              true,
              true
            ]
          }
        }
      })
    }

  }

  revealPositionOrderData(reveal) {
    return this.post('updateRevealData', '/api/trainer/updateRevealData',
      reveal,
    );
  }

  /**
   * @param {Integer} cardId
   * @returns {*}
   */
  assignMarketCardToTeam(cardId) {
    return this.post('assignMarketCardToTeam', '/api/trainer/assignMarketCardToTeam', {
      card_id: cardId,
    });
  }

  resetMarketCards() {
    return this.post('resetMarketCards', '/api/trainer/resetMarketCards');
  }

  takeLoanForTeam(teamId, amount) {
    return this.post('takeLoanForTeam', '/api/trainer/takeLoanForTeam', {
      team_id: teamId,
      amount,
    });
  }

  resetMarketPhase() {
    return this.post('resetMarketPhase', '/api/trainer/resetMarketPhase');
  }

  resetSingleTeam(teamId) {
    return this.post('resetTeamMarketPhase', '/api/trainer/resetTeamMarketPhase', {
      team_id: teamId
    })
  }

  submitTeamInvest(teamInvestData) {
    return this.post('submitTeamInvest', '/api/trainer/submitTeamInvest', teamInvestData);
  }

  getLeaderboardData() {
    return this.get('getLeaderboardData', '/api/trainer/getLeaderboardData')
  }
}
