import { Model } from '@vuex-orm/core';
import Factory from "./Factory";

export default class FactorySlot extends Model {
  static entity = 'factory_slots';

  static fields() {
    return {
      id: this.attr(null),
      factory_id: this.attr(null),
      index: this.attr(null),

      type: this.attr(null),
      subtype: this.attr(null),

      factory: this.belongsTo(Factory, 'factory_id'),
    };
  }
}
