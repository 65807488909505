<template>
    <!-- <div class="window_refer" ref="window_refer" v-on:click="test"> </div> -->
    <div v-if="can_show == true" class="window_refer" ref="window_refer" @mousemove="movegrab" @mouseup="removegrab"> <!--  -->
        <div class="coin" ref="coin" @mousedown="startgrab"> <!-- @mouseover="dragElement()" @click="dragElement()" @mouseup="returnPosition()" -->
            <h2 class="coin_centered">{{coin_amount}}</h2>
        </div>
    </div>
    <!-- <div class="highlight_box"></div> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        standalone: Boolean,
        isFromExternalLocations: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return{
            grab_status: false,
            coin_amount: 0,
            can_show: false,
            current_position_x: 0.0,
            current_position_y: 0.0,
            component_cash: require('../assets/Gameboard/Currency/coin_grab.png'),
            component_depreciate: require('../assets/Gameboard/Currency/coin_grab_brown.png'), 
            component_debt: require('../assets/Gameboard/Currency/coin_grab_red.png'),
            location_list: [{Name: "Section_Land", X: 7.2, Y: 11.3, LocaleWidth: 6.75, LocaleHeight:14.4},
                            {Name: "Plot_1", X: 18.00, Y: 14.00, LocaleWidth: 12.5, LocaleHeight:25},
                            {Name: "Plot_2", X: 34.75, Y: 14.00, LocaleWidth: 12.5, LocaleHeight:25},
                            {Name: "Plot_3", X: 18.00, Y: 46.00, LocaleWidth: 12.5, LocaleHeight:25},
                            {Name: "Plot_4", X: 34.75, Y: 46.00, LocaleWidth: 12.5, LocaleHeight:25},
                            {Name: "PFME_Pay", X: 51.40, Y: 11.40, LocaleWidth: 6.17, LocaleHeight:14.4},
                            {Name: "RnD_Pay", X: 57.77, Y: 11.40, LocaleWidth: 6.17, LocaleHeight:14.4},
                            {Name: "Depreciation_Pay", X: 51.40, Y: 26.00, LocaleWidth: 6.17, LocaleHeight:14.4},
                            {Name: "FFOH_Pay", X: 57.77, Y: 26.00, LocaleWidth: 6.17, LocaleHeight:14.4},
                            {Name: "MOGE_Pay", X: 51.40, Y: 41.20, LocaleWidth: 6.17, LocaleHeight:14.4},
                            {Name: "Interest_Pay", X: 57.77, Y: 41.20, LocaleWidth: 6.17, LocaleHeight:14.4},
                            {Name: "Tax_Pay", X: 51.40, Y: 56.35, LocaleWidth: 6.17, LocaleHeight:14.4},
                            {Name: "Dividends_Pay", X: 57.77, Y: 56.35, LocaleWidth: 6.17, LocaleHeight:14.4},
                            {Name: "Standard_Loan_Pay", X: 51.48, Y: 76.60, LocaleWidth: 12.40, LocaleHeight: 15.70},
                            // {Name: "High_Loan_Pay", X: 57.92, Y: 76.60, LocaleWidth: 6.00, LocaleHeight: 15.70},
                            {Name: "Cash_Box", X: 23.50, Y: 76.60, LocaleWidth: 19.20, LocaleHeight: 15.70},
                            {Name: "Supplier_Box", X: 7.30, Y: 27.50, LocaleWidth: 6.00, LocaleHeight: 10.50},
                            {Name: "Payable_Box", X: 44.00, Y: 76.60, LocaleWidth: 6.00, LocaleHeight: 19.30},
                            {Name: "Receivables_Box", X:7.25, Y: 76.60, LocaleWidth:15.00, LocaleHeight: 15.70}],
            drop_location: "None",
            drop_function: "None",
            isBankCoin: false
        }
    },
    computed: {
        ...mapGetters('profitability/participant', {
            vPhase: 'phase',
        }),
    },
    methods:{
        set_custom_grab(location_y, location_x, amount, location, func, coin_type = "cash"){
            if(this.drop_location != location && this.drop_function != func) {
                this.drop_location = location
                this.drop_function = func
                this.SetCoinComponent(location_y, location_x, amount, coin_type)
            }
        },
        reset(timer = 1000){
            var element = this.$refs["coin"]
            setTimeout(() => {
                if(element != null) {
                    element.style.bottom = (100000000000000) + "px"
                    element.style.left = (10000000000000) + "px"
                }
                this.drop_location = "None"
                this.drop_function = "None"
                this.coin_amount = 0
                this.can_show = false
            }, timer)
        },
        movegrab(event)
        {
            if(this.grab_status == true){
                var element = this.$refs["coin"]
                element.style.top = (event.offsetY - 54) + "px";
                element.style.left = (event.offsetX - 54) + "px";
            }
        },
        startgrab(event)
        {
            this.grab_status = true
            this.$refs["coin"].style.pointerEvents = "none"
            this.$refs["window_refer"].style.pointerEvents = "fill"
        },
        removegrab(event)
        {
            if(this.grab_status == true)
            {
                this.returnPosition(event.offsetX, event.offsetY)
                this.$refs["coin"].style.pointerEvents = "fill"
                this.$refs["window_refer"].style.pointerEvents = "none"
            }
            this.grab_status = false
        },
        updateCoin(amount){
            this.SetCoinComponent(0.2, 0.3, amount)
        },
        updateCoinBorrow(amount){
            this.SetCoinComponent(0.39, 0.075, amount)
        },
        updateCoinCustomerCash(amount) {
            this.SetCoinComponent(0.615, 0.075, amount)
        },
        SetCoinComponent(bottom_screen_offset, left_screen_offset, amount, coin_type = "cash"){
            if(this.can_show == false)
                this.can_show = true
                setTimeout(() => {
                    var element = this.$refs["coin"]
                    element.style.bottom = (bottom_screen_offset * 1080) + "px"
                    element.style.left = (left_screen_offset * 1920) + "px"
                    if (coin_type == "cash")
                        element.style.backgroundImage = `url(${this.component_cash})`
                    else if (coin_type == "depreciate")
                        element.style.backgroundImage = `url(${this.component_depreciate})`
                    else if (coin_type == "debt")
                        element.style.backgroundImage = `url(${this.component_debt})`
                }, 1)
            
            this.coin_amount = amount
        },
        returnPosition(position_x, position_y){
            var positionXPercentage = (parseInt(position_x)/parseFloat(1920)) * 100
            var positionYPercentage = (parseInt(position_y)/parseFloat(1080)) * 100
            this.current_position_x = positionXPercentage
            this.current_position_y = positionYPercentage

            for(let i = 0; i < this.location_list.length; i++)
            {
                if(this.current_position_x > this.location_list[i].X && this.current_position_y > this.location_list[i].Y &&
                   this.current_position_x < (this.location_list[i].X + this.location_list[i].LocaleWidth) &&
                   this.current_position_y < (this.location_list[i].Y + this.location_list[i].LocaleHeight))
                   {
                        if (this.isFromExternalLocations) { // coin from bank
                            if (this.location_list[i].Name !== "Cash_Box") {
                                alert("Drag the coins borrowed from the bank to cash")
                                return;
                            }
                        } else {
                            if (!this.standalone) {
                                if (this.vPhase.period === 0) {
                                    if (this.vPhase.phase_step === 0 && this.location_list[i].Name !== "Cash_Box") {
                                        alert("Drag the silver coins to cash")
                                        return;
                                    } else if (this.vPhase.phase_step === 1 && this.location_list[i].Name !== "Section_Land" && this.location_list[i].Name !== "Cash_Box") {
                                        alert("Drag the silver coins to land & buildings")
                                        return;
                                    } else if (this.vPhase.phase_step === 2 && this.location_list[i].Name !== "Plot_1" && this.location_list[i].Name !== "Cash_Box") {
                                        alert("Please purchase/buy WIP at the top left 'Buy Equipment Line'")
                                        return;
                                    }  else if (this.vPhase.phase_step === 4 && this.location_list[i].Name !== "Receivables_Box") {
                                        alert("Please drag silver coins to receivables")
                                        return;
                                    } else if (this.vPhase.phase_step === 6 && this.location_list[i].Name !== "Cash_Box") {
                                        alert("Please drag silver coins to cash")
                                        return;
                                    }
                                } else {
                                    if (this.vPhase.market_phase === 0) {
        
                                    }
        
                                    if (this.vPhase.market_phase === 2) {
                                        if (this.vPhase.phase_step === 0 && this.location_list[i].Name !== "Cash_Box" && this.location_list[i].Name !== "Receivables_Box") {
                                            alert("Please drag silver coins to cash or receivables")
                                            return
                                        } else if (this.vPhase.phase_step === 1 && this.location_list[i].Name !== "FFOH_Pay" && this.location_list[i].Name !== "MOGE_Pay") {
                                            alert("Please drag silver coins to FFOH or MOGE")
                                            return
                                        }
                                    }
                                }
                            }
                        }

                        
                        if (this.drop_location == "None") {
                            this.dropCoin(this.location_list[i])
                        } else {
                            if (this.drop_location == this.location_list[i].Name)
                                this.dropCoin(this.location_list[i])
                        }
                   }
            }
        },
        dropCoin(location){
            this.can_show = false

            if(this.drop_function == "None") {
                this.$emit("use_coin", this.coin_amount, location.Name)
                return
            } else {
                this.$emit("use_coin", this.coin_amount, this.drop_location, this.drop_function)
                return
            }
        }
    }
}
</script>

<style>
    .window_refer {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        /* border: 5px solid white; */
        pointer-events: none;
        z-index: 9;
    }
    .coin {
        position: absolute;
        cursor: move;
        width: 108px;
        height: 108px;
        
        background-color: transparent;
        background-image: url("../assets/Gameboard/Currency/coin_grab.png");
        background-position: center;
        background-repeat: no-repeat;
        text-align: center;
        pointer-events: fill;

        z-index: 8;
    }
    h2{
        font-family: NestleBrush_Reg;
        font-size: 250%;
        z-index: 2;
        color: black;
    }
    .coin_centered {
        margin: 0;
        position: absolute;
        top: 47.5%;
        width: 100%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        pointer-events: none;
    }
    .highlight_box{
        top: 76.60%;
        left: 7.25%;
        position: fixed;
        width: 15%;
        height: 15.70%;

        background-color: black;
        opacity: 75%;
        z-index: 2;
    }
</style>