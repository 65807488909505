<style lang="scss">

.pre-market-phase {

    .marketing-plan {
        width: 93.5%;
        height: 84%;
        left: 3.7%;
        top: 10%;

        background-size: 100% 100%;
        background-repeat: no-repeat;

        position: relative;

        &.Classic {
            top: 20.5%;
            background-image: url("../../../assets/Facilitator/MarketingPlans_Classic.png");
        }

        &.Gold {
            background-image: url("../../../assets/Facilitator/MarketingPlans_Gold.png");
        }

        .col {
            position: absolute;
            top: 29%;
            left: 26.4%;
            width: 8%;
            height: 62.5%;
            font-size: 1.5rem;
            color: white;

            &.b { left: 38.5%; }
            &.c { left: 50.6%; }
            &.d { left: 62.7%; }
            &.e { left: 74.8%; }
            &.f { left: 86.9%; }

        }
        .row {
            position: absolute;
            width: 100%;
            height: auto;
            
            &.first { top: 0%; }
            &.second { top: 21.6%; }
            &.third { top: 43.2%; }
            &.total { top: 64.5%; }
            &.avail { top: 86%; }
            &.black { color: black; }
        }

        .tot {
            font-family: NestleText;
            position: absolute;
            left: 22.5%;
            font-size: 20px;

            &.pfme { top: 43.5%; }
            &.rnd { top: 57%; }
            &.avail { top: 83%; }

            &.gold {
                color: white;
                
                &.pfme { top: 29.5%; }
                &.rnd { top: 43.5%; }
            }
        }
    }
}
</style>

<template>
    <div class="marketing-plan" :class="boardClass">

        <!-- TODO: team's marketing plan here -->
        <template v-for="i in [0,1,2,3,4,5]" :key="i">
            <template v-if="teamMarketingPlans[i]">

                <template v-if="type === 'classic'">
                    <div class="col" :class="[{b: i == 1}, {c: i == 2}, {d: i == 3}, {e: i == 4}, {f: i == 5}]">
                        <div class="row first" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].unit_price }}</div>
                        <div class="row second" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].pfme }}</div>
                        <div class="row third" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].rnd }}</div>
                        <div class="row total" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].total_pfme }}</div>
                        <div class="row avail" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].available_stock }}</div>
                    </div>

                </template>

                <template v-if="type === 'gold'">
                    <div class="col" :class="[{b: i == 1}, {c: i == 2}, {d: i == 3}, {e: i == 4}, {f: i == 5}]">
                        <div class="row first" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].pfme }}</div>
                        <div class="row second" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].rnd }}</div>
                        <div class="row third" :class="{black: i == 4 || i == 5}">
                            <template v-if="teamMarketingPlans[i].unit_price">
                                {{ teamMarketingPlans[i].pfme / teamMarketingPlans[i].unit_price % 1 == 0 ? 
                                    teamMarketingPlans[i].pfme / teamMarketingPlans[i].unit_price : 
                                    (teamMarketingPlans[i].pfme / teamMarketingPlans[i].unit_price).toFixed(2)
                                }}
                            </template>
                        </div>
                        <div class="row total" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].total_pfme }}</div>
                        <div class="row avail" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].available_stock }}</div>
                    </div>
                </template>

            </template>
        </template>

        <p class="tot pfme" :class="{gold: type === 'gold'}">{{ pfme_total }}</p>
        <p class="tot rnd" :class="{gold: type === 'gold'}">{{ rnd_total }}</p>
        <p class="tot avail" :class="{gold: type === 'gold'}">{{ avail_total }}</p>

    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        type: {
            type: String,
            validator: (value) => {
                return ['classic', 'gold'].indexOf(value) !== -1;
            },
        },
    },
    mounted() {
        // _.each(this.vTeams, (team, i) => {
        //     console.log(this.teamMarketingPlans[i].pfme)
        // });
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
        }),
        boardClass() {
            return {
                classic: 'Classic',
                gold: 'Gold',
            }[this.type];
        },
        teamMarketingPlans() {
            const teamMarketingPlans = [];

            const { vTeams } = this;
            _.each(vTeams, (team) => {
                const marketingPlan = team.marketingData;
                const marketingPlanForThisType = marketingPlan[this.type] || {};

                if (marketingPlanForThisType.unit_price) {
                    const total_pfme = team.marketingDataTotalPFME;
                    const available_stock = this.type === 'classic' ? team.classicStockCount : team.goldStockCount;
                    const planToDisplay = {
                        ...marketingPlanForThisType,
                        total_pfme,
                        available_stock,
                    };
                    teamMarketingPlans.push(planToDisplay);
                } else {
                    teamMarketingPlans.push({});
                }
            });

            return teamMarketingPlans;
        },
        pfme_total() {
            let sum = 0

            _.each(this.vTeams, (team, i) => {
                Number.isInteger(this.teamMarketingPlans[i].pfme) ? sum += this.teamMarketingPlans[i].pfme : sum += 0
            });

            return isNaN(sum) ? 0 : sum;
        },
        rnd_total() {
            let sum = 0

            _.each(this.vTeams, (team, i) => {
                Number.isInteger(this.teamMarketingPlans[i].rnd) ? sum += this.teamMarketingPlans[i].rnd : sum += 0
            });

            return isNaN(sum) ? 0 : sum;
        },
        avail_total() {
            let sum = 0

            _.each(this.vTeams, (team, i) => {
                Number.isInteger(this.teamMarketingPlans[i].available_stock) ? sum += this.teamMarketingPlans[i].available_stock : sum += 0
            });

            return isNaN(sum) ? 0 : sum;
        }
    },
}
</script>
