import VuexORM from '@vuex-orm/core';

import Factory from "./Factory";
import FactorySlot from "./FactorySlot";
import Gameboard from './Gameboard';
import MarketCard from './MarketCard';
import Team from './Team';
import TeamData from './TeamData';

// Create a new instance of Database.
const database = new VuexORM.Database()

const models = {
  Factory,
  FactorySlot,
  Gameboard,
  MarketCard,
  Team,
  TeamData,
}

// Register Models to Database.
_.each(models, (model) => {
  database.register(model);
});

export default {
  registerVue: VuexORM.install(database),
  models,
};
