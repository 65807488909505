<template>
    <div class="Section_Plot">
        <div class="Plot_1" :class="{frame_border: phase === 2 || phase === 9}" >
            <Plot ref="Plot_1" 
                :index="0" 
                @spawn_coin="get_coin"
                @Depreciate_Line="Dep"
                :phase="phase" 
                :factory="factory" 
            />
        </div>

        <div class="Plot_2">
            <Plot ref="Plot_2" :index="1" :factory="factory"/>
        </div>

        <div class="Plot_3">
            <Plot ref="Plot_3" :index="2" :factory="factory"/>
        </div>

        <div class="Plot_4">
            <Plot ref="Plot_4" :index="3" :factory="factory"/>
        </div>
    </div>
    <CoinComponent ref="CComponent" @use_coin="drop_function" :standalone="true"/>
</template>

<script>
import CoinComponent from '../Coin_Component.vue'
import { mapGetters } from "vuex";
import Plot from './Plot_Detail.vue'
import _ from "lodash";

export default {
    props: {
        phase: Number,
        factory: Object,
    },
    components: { CoinComponent, Plot},
    data(){
        return{
            wipPaid: false,
        }
    },
    computed: {
        is_warehouse() {
            return this.factory.type === 'warehouse';
        },
        is_classic() {
            return this.factory.type === 'classic';
        },
        is_gold() {
            return this.factory.type === 'gold';
        },
        converted() {
            return this.factory.type !== null;
        }
    },
    methods:{
        get_coin(plot_type, coin_amount){
            this.$refs["CComponent"].reset(0)
            setTimeout(() => {
                switch(plot_type)
                {
                    case "Plot_1":
                        this.$refs["CComponent"].set_custom_grab(0.83, 0.26, coin_amount, "Depreciation_Pay", plot_type, "depreciate")
                        break
                }
            }, 50)
            
        },
        drop_function(cash, location, func){
            this.$refs[func].depreciate()
            this.$refs["CComponent"].reset()
        },
        Interact_Board(this_cash, this_plot){
            if (this.$refs[this_plot].converted == false) {
                //! Buy
                if (this_cash > 0 && this.phase === 2)
                    if (this_plot === "Plot_1")
                        if (this_cash !== 15) 
                            alert("Please purchase Nescafe Classic line with 15 coins")
                        else
                            this.$emit("Buy_Classic_Plot", this_cash, this_plot)
                    else
                        alert("Please purchase the top left equipment line in red frame")
                else 
                    alert("Not the time to purchase plot")

                //! Convert
            } else {
                this.Update_WIP(this_cash, this_plot)
            }
        },
        Update_WIP(this_cash, this_plot) {
            if (this.$refs[this_plot].is_classic == true) {
                if (this_cash == 10 && !this.wipPaid) {
                    this.$emit("Pay_Classic_WIP", this_cash, this_plot)
                    this.wipPaid = true
                } else if (this.wipPaid) {
                    alert("WIP already paid")
                } else {
                    alert("Incorrect WIP amount. Pay 10 coins for Classic, and 20 coins for Gold")
                }
            } else {
                alert("Invalid action or money dropped to fill Work-In-Progress is not sufficient")
            }
        },
        Dep() {
            this.$emit("Depreciate_Line")
        }
    }
}
</script>

<style lang="scss">
.Section_Plot{
    top: 10%;
    left: 15.5%;
    position: fixed;
    width: 34.25%;
    height: 64.75%;

    background-image: url("../../assets/Gameboard/Panel/panel_plot_highlight.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .Plot_1{
        top: 10%;
        left: 16%;
        position: fixed;
        width: 16.5%;
        height: 32%;
    }
    .Plot_2{
        top: 10%;
        left: 32.75%;
        position: fixed;
        width: 16.5%;
        height: 32%;
    }
    .Plot_3{
        top: 42%;
        left: 16%;
        position: fixed;
        width: 16.5%;
        height: 32%;
    }
    .Plot_4{
        top: 42%;
        left: 32.75%;
        position: fixed;
        width: 16.5%;
        height: 32%;
    }
    .frame_border {
        border: 5px solid red;
        border-radius: 8px;
    }
}

</style>
