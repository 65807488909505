<style lang="scss">
.container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>

<template>
  <div class="container">
    <canvas id="pbc"></canvas>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels)
Chart.register(annotationPlugin)

export default {
  props: {
    data: Array,
    YAxisMax: Number,
  },
  name: "PBC-Bar-Chart",
  data() {
    return {
      lineChartData: {
        type: "bar",
        data: {
          labels: ["", "", "", "", "", ""],
          datasets: [
            {
              label: "",
              data: this.data, // Edit these hard-coded values with backend values
              borderColor: "#59af8b",
              borderRadius: 8,
              backgroundColor: [
                "#59af8b",
                "#6591d0",
                "#fb8c50",
                "#9963b5",
                "#ffd250",
                "#d9cdb4",
              ],
              datalabels: {
                color: ["white", "white", "white", "white", "black", "black"],
              },
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              min: -15,
              max: Math.max(...this.data),
              ticks: {
                font: {
                  size: 36,
                  family: "NestleBrush_Reg",
                },
              },
              grid: {
                drawTicks: false,
                color: '#63513d',
                lineWidth: 5,
              }
            },
            x: {
              display: false,
            },
          },
          plugins: {
            datalabels: {
              font: {
                family: "NestleBrush_Reg",
                size: 30,
              },
              formatter: Math.round,
              anchor: this.checkAnchor,
              align: this.checkAlign,
            },
            legend: false,
            tooltip: false,
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById("pbc");
    new Chart(ctx, this.lineChartData);
  },
  computed: {
  },
  methods: {
    checkAnchor(ctx) {
      var index = ctx.dataIndex;
      return this.lineChartData.data.datasets[0].data[index] > 0 ? "end" : "start";
    },
    checkAlign(ctx) {
      var index = ctx.dataIndex;
      return this.lineChartData.data.datasets[0].data[index] > 0 ? "start" : "end";
    },
  },
};
</script>
