<template>
<div class="Section_Loans">
    <img v-if="vPhase.period === 0 || loans_high == 0" src="../../assets/Gameboard/Sections/Gameboard_Loansbox.png" class="PlayerBoard_Loans">
    <img v-else src="../../assets/Gameboard/Sections/section_loans.png" class="PlayerBoard_Loans">
    <div :class="{ frame_border: vPhase.period === 0 && vPhase.phase_step === 8}" class="box"></div>

    <div v-if="vPhase.period === 0 || loans_high == 0" class="Standard_Role_0" ref="Standard_Loan_Pay" :index="11">
        <div v-if="loans_standard > 0" class="coin_size_adjust">
            <CoinIndicator :pass_currency_type="9" :pass_cash="loans_standard" />
        </div>
    </div>

    <div v-else-if="vPhase.period > 0 && loans_high > 0">
        <div class="Standard_Role" ref="Standard_Loan_Pay" :index="11">
            <CoinIndicator :pass_currency_type="11" :pass_cash="loans_standard" :pass_debt_offset="true" />
        </div>

        <div class="High_Interest" ref="High_Loan_Pay" :index="12">
            <CoinIndicator :pass_currency_type="11" :pass_cash="loans_high" :pass_debt_offset="true" />
        </div>
    </div>

    <div v-if="vDebug" class="debug">
        <div style="flex: 1;">
            standard:<br>
            {{ loans_standard }}<br>
            min_repay: <br>
            {{ loans_repay }}<br>
            <template v-if="loans_canRepay && loans_standard > 0">
                <button @click="repayLoans2('standard', 1)">Pay 1</button><br>
                <button @click="repayLoans2('standard', 5)">Pay 5</button><br>
                <button @click="repayLoans2('standard', 10)">Pay 10</button><br>
            </template>
        </div>
        <!-- <div style="flex: 1;">
            high:<br>
            {{ loans_high }}<br>
            min_repay: <br>
            {{ loans_high_repay }}<br>
            <template v-if="loans_canRepay && loans_high > 0">
                <button @click="repayLoans2('high', 1)">Pay 1</button><br>
                <button @click="repayLoans2('high', 5)">Pay 5</button><br>
                <button @click="repayLoans2('high', 10)">Pay 10</button><br>
            </template>
        </div> -->
    </div>
</div>
</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue'
import { mapGetters } from "vuex";
import Profitability from '@/profitability-nestle-js';

export default {
    components: { CoinIndicator },
    data() {
        return {
            mbs_paid_loan: false,
        }
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vGameboard: 'gameboard',
            vPhase: 'phase',
        }),
        loans_standard() {
            return this.vGameboard?.getValue('loans', 'standard');
        },
        loans_high() {
            return this.vGameboard?.getValue('loans', 'high');
        },
        loans_repay() {
            return this.vGameboard?.getValue('loans', 'repay');
        },
        // loans_high_repay() {
        //     return this.vGameboard?.getValue('loans', 'high.repay');
        // },
        loans_canRepay() {
            return this.vPhase.market_phase === 2 && this.vPhase.phase_step === 3;
        },
    },
    methods: {
        Interact_Loan_Standard(amount) {
            if (amount > 0) {
                if (this.vPhase.period !== 0 && this.loans_canRepay) {
                    if (amount < this.loans_standard_repay || amount > Math.round((this.loans_standard / 100) * 50)) {
                        alert("Incorrect amount for loans repayment. Repay between 10% and 50% of your loans")
                        return
                    }

                    Profitability.Participant.repayLoan('standard', amount);
                } else if (this.vPhase.period === 0) {
                    if (amount === 1 && this.mbs_paid_loan == false) { // This probably needs to be restricted from the backend since it doesn't work
                        this.mbs_paid_loan == true;
                        Profitability.Participant.mbsPayLoan(amount);
                    } else {
                       alert("Please pay only $1 loan once only for now")
                    }
                } else {
                    alert("Please wait for the right step to pay loan")
                }
            }
        },
        Interact_Loan_High(amount) {
            console.log("Pay loan high!")
            if(amount > 0) {
                Profitability.Participant.repayLoan('high', amount);
            }
        },
        repayLoans2(type, amount) {
            Profitability.Participant.repayLoan(type, amount);
        },
        repayLoans(type) {
            console.log(type);
            return;
            const actualOweAmount = this.loans_actualOwe;
            if (actualOweAmount === 0) return;

            // TODO: amount is actually defined by user at 'cash'
            // so amount need to be pass in to backend to check if amount is correct or not
            const amount = actualOweAmount; // for now we simply use actual owe amount

            // TODO: you can add a frontend-validation here when player drag the wrong amount
            if (actualOweAmount !== amount) {
                // TODO: warning?
                return;
            }

            Profitability.Participant.payLoans(amount);
        }
    },
}
</script>

<style lang="scss">
.Section_Loans{
    top: 76%;
    left: 51.1%;
    position: fixed;
    width: 13.2%;
    height: 20.5%;

    .PlayerBoard_Loans{
        top: 76%;
        left: 51.1%;
        position: fixed;
        width: 13.2%;
        height: 20.5%;
    }

    .Standard_Role {
        top: 76.6%;
        left: 51.2%;
        position: fixed;
        width: 6%;
        height: 15.7%;
    }
    .Standard_Role_0 {
        top: 76.6%;
        left: 51.48%;
        position: fixed;
        width: 12.4%;
        height: 15.7%;
    }
    .coin_size_adjust {
        position: absolute;
        top: 12%;
        left: 0;
        width: 55%;
    }

    .High_Interest {
        top: 76.6%;
        left: 57.6%;
        position: fixed;
        width: 6%;
        height: 15.7%;
    }

    .frame_border {
        border: 5px solid red;
        border-radius: 8px;

        &.box {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}

.Section_Loans > .debug {
    position: absolute;
    inset: 0;
    margin-top: 1.5rem;
    pointer-events: none;

    display: flex;
    font-weight: bold;
    color: black;
}

.Section_Loans > .debug button {
    pointer-events: auto;
}
</style>
