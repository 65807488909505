<template>
    <div class="Period_Progress">
        <img src="../../assets/Gameboard/Period/period_overlay.png" class="ProgressOverlay">
        <div class="MakeshiftProgressBar">
            <!-- <div class="MakeshiftProgressFill"></div> -->
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>
    .Period_Progress{
        left: 2.5%;
        top: 16.3%;
        position: absolute;
        width: 3.5%;
        height: 79%;
        background-image: url("../../assets/Gameboard/Period/period_bar.png");
        background-position: center;
        background-size: 100% 100%;
        z-index: 2;
    }

    .ProgressOverlay{
        top: 5%;
        left: 20%;
        position: absolute;
        height: 90%;
        background-position: center;
        background-color: transparent;
    }
    .MakeshiftProgressBar{
        top: 5%;
        left: 42.5%;
        position: absolute;
        width: 15%;
        height: 90%;
        background-color: white;
    }
    .MakeshiftProgressFill{
        width: 100%;
        height: 100%;
        background-color: green;
    }
</style>