<style lang="scss">
.Marketing_Overview {
    .Marketing_Board_1 {
        top: 10%;
        left: 4.65%;
        position: fixed;
        width: 87.3%;
        height: 79%;

        background-image: url("../assets/Marketing/Panel_Market.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .Marketing_Board_2 {
        top: 8.2%;
        left: 9.5%;
        position: fixed;
        width: 82.8%;
        height: 81%;

        background-image: url("../assets/Marketing/Panel_Market.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .Nescafe_Reveal_Statistic {
        top: 24%;
        left: 5.2%;
        position: fixed;
        width: 86.1%;
        height: 63.5%;

        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .Nescafe_Reveal_Statistic.Classic {
        background-image: url("../assets/Marketing/Nescafe_Classic_Stats_Reveal.png");
    }

    .Nescafe_Reveal_Statistic.Gold {
        background-image: url("../assets/Marketing/Nescafe_Gold_Stats_Reveal.png");
    }

    .Nescafe_Reveal_Team {
        left: 5.2%;
        top: 11%;
        position: fixed;
        width: 86.1%;
        height: 11.5%;

        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .Nescafe_Reveal_Team.Classic {
        background-image: url("../assets/Marketing/Nescafe_Classic_Team_Order.png");
    }

    .Nescafe_Reveal_Team.Gold {
        background-image: url("../assets/Marketing/Nescafe_Gold_Team_Order.png");
    }

    .Nescafe_Reveal_Team_Alt {
        left: 10.2%;
        top: 9.9%;
        position: fixed;
        width: 81.3%;
        height: 11.4%;

        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .Nescafe_Reveal_Team_Alt.Classic {
        background-image: url("../assets/Marketing/Nescafe_Classic_Team_Order.png");
    }

    .Nescafe_Reveal_Team_Alt.Gold {
        background-image: url("../assets/Marketing/Nescafe_Gold_Team_Order.png");
    }

    .Nescafe_Side_Bar_Stock {
        top: 6.5%;
        left: 0%;
        position: fixed;
        width: 7%;
        height: 85%;

        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .Nescafe_Side_Bar_Stock.Classic {
        background-image: url("../assets/Marketing/Nescafe_Classic_Stockleft.png");
    }

    .Nescafe_Side_Bar_Stock.Gold {
        background-image: url("../assets/Marketing/Nescafe_Gold_Stockleft.png");
    }

    .Nescafe_Purchase_Screen.Classic, .Nescafe_Purchase_Screen.Gold {
        position: fixed;
        overflow-y: auto;
        top: 23%;
        left: 10.2%;
        width: 81.7%;
        height: 64%;
        display: grid;
        grid-template-columns: 371px 371px 371px 371px;
        grid-template-rows: 195px;
        grid-gap: 1rem 1rem;
        /* background: green; */
        padding: 0 1% 0 0.2%;
    }


    .Proceed_Page {
        left: 58%;
        top: 91%;
        position: fixed;
        width: 33.75%;
        height: 7.5%;

        background-color: transparent;
        border: transparent;

        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
    }

    .Proceed_Page.Card_Pick_Classic {
        background-image: url("../assets/Marketing/Button_Advance_Classic.png");
    }

    .Proceed_Page.Card_Pick_Gold {
        background-image: url("../assets/Marketing/Button_Advance_Gold.png");
    }

    .Proceed_Page.Finish_Classic {
        background-image: url("../assets/Marketing/Button_Next_Classic.png");
    }

    .Proceed_Page.Finish_Gold {
        background-image: url("../assets/Marketing/Button_Next_Gold.png");
    }

    .team {
        position: relative;
        top: 13%;
        left: 12.2%;
        width: 85%;
        height: 75%;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        grid-template-rows: 4.8rem;
        grid-gap: 0rem 1.5rem;
    }

    .reveal_placement_classic.show {
        background: url("../assets/Marketing/Reveal_Placement_Done_2.png") no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
    }
    .reveal_placement_classic.show.highlight {
        background: url("../assets/Marketing/Reveal_Placement_Selected.png") no-repeat;
        background-size: 100% 100%;
    }
    .reveal_placement_classic.unshow {
        background: url("../assets/Marketing/Reveal_Placement.png") no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
    }
    .team_input {
        font-family: NestleBrush_Reg;
        margin-left: 15%;
        font-size: 3.5rem;
        text-align: left;
    }
    .team_input.show {
        color: white;
    }
    .team_input.unshow {
        color: black;
    }

    .small_input {
        font-size: 1.5rem;
    }

    .group {
        border-radius: 12px;
        position: relative;
        top: -80%;
        left: 57%;
        width: 36%;
        height: 75.5%;
    }

    .group_label {
        font-family: NestleBrush_Reg;
        font-size: 3rem;
        text-transform: uppercase;
        color: white;
    }

    .a {
        background-color: #59af8b;
    }

    .b {
        background-color: #6591d0;
    }

    .c {
        background-color: #fb8c50;
    }

    .d {
        background-color: #9963b5;
    }

    .e {
        background-color: #ffd250;
    }

    .e > p {
        color: black;
    }

    .f {
        background-color: #d9cdb4;
    }

    .f > p {
        color: black;
    }

    .stats {
        margin: 0 0 0 24.8%;
        position: relative;
        width: 71.5%;
        height: 11.5%;
    }

    .stats.first {
        top: 26%;
    }

    .stats.second {
        top: 32.6%;
    }

    .stats.third {
        top: 39.3%;
    }

    .stats.total {
        top: 45.9%;
    }

    .stats.g {
        margin: 0 0 0 24.8%;
        position: relative;
        width: 71.5%;
        height: 11.3%;
    }

    .stats.g.first {
        top: 25.7%;
    }

    .stats.g.second {
        top: 32.5%;
    }

    .stats.g.third {
        top: 39.5%;
    }

    .stats.g.total {
        top: 46.2%
    }

    .eye {
        border-color: transparent;
        width: 7%;
        height: 100%;
        margin: 0 0 0 93%;
    }

    .eye.hide {
        background: url("../assets/Marketing/Reveal_Layer.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .eye.unhide {
        background: none;
    }

    .eye_g {
        border-color: transparent;
        width: 7.4%;
        height: 100%;
        margin: 0 0 0 92.8%;
    }

    .eye_g.hide {
        background: url("../assets/Marketing/Reveal_Layer.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .eye_g.unhide {
        background: none;
    }

    .eye_placement {
        background: url("../assets/Marketing/Reveal_Layer.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-color: transparent;
        position: absolute;
        margin: 0.42rem auto auto 0.15rem;
        width: 5.8%;
        height: 70%;

        &.A {
            left: 8.1%;
        }

        &.B {
            left: 25.1%;
        }

        &.C {
            left: 41.9%;
        }

        &.D {
            left: 59%;
        }

        &.E {
            left: 76.1%;
        }

        &.F {
            left: 93%;
        }

        &.hide {
            background: none
        }
    }

    .st_input {
        display: block;
        color: white;
        width: 13.7%;
        border: none;
        font-family: NestleBrush_Reg;
        background: none;
        border: none;
        text-align: center;
        font-size: 34px;
        padding-top: 14px;
        position: absolute;
        /* top: 15%; */
    }

    .st_input.a {
        left: 0%;
    }

    .st_input.b {
        left: 16%;
    }

    .st_input.c {
        left: 31.5%;
    }

    .st_input.d {
        left: 47%;
    }

    .st_input.e {
        color: black;
        left: 62.6%;
    }

    .st_input.f {
        color: black;
        left: 78.2%;
    }

    .st_input.hide {
        display: none;
        color: white;
        width: 7%;
        text-align: center;
        font-size: 2rem;
    }

    .st_input.a2 {
        width: 45%;
        position: absolute;
        left: 28%;
        top: 14.9%;
    }

    .st_input.b2 {
        width: 45%;
        position: absolute;
        left: 28%;
        top: 30%;
    }

    .st_input.c2 {
        width: 45%;
        position: absolute;
        left: 28%;
        top: 45%;
    }

    .st_input.d2 {
        width: 45%;
        position: absolute;
        left: 28%;
        top: 60.2%;
    }

    .st_input.e2 {
        width: 45%;
        position: absolute;
        left: 28%;
        top: 75.3%;
        color: black;
    }

    .st_input.f2 {
        width: 45%;
        position: absolute;
        left: 28%;
        top: 90.3%;
        color: black;
    }

    .nobg {
        background: none;
    }

    .cover {
        position: absolute;
        top: 5%;
        border: none;
        width: 13%;
        height: 78%;
        border-radius: 12px;
        /* background: black; */
        z-index: 10;
    }
    .cover.a { left: -0.1%; }
    .cover.b { left: 5.9%; }
    .cover.c { left: 11.85%; }
    .cover.d { left: 17.9%; }
    .cover.e { left: 23.9%; }
    .cover.f { left: 29.95%; }


    .debug {
        top: 10%;
        left: 4.65%;
        position: fixed;
        width: 87.3%;
        height: 79%;

        pointer-events: none;

        display: flex;
        flex-direction: column;

        .team-order-container {
            display: flex;

            height: 16%;
            width: 100%;

            position: relative;

            .box {
                position: absolute;
                top: 20%;
                right: 2%;
                bottom: 12%;
                left: 11%;

                border: 1px solid blue;

                display: flex;
                flex: 1;

                .team_data {
                    display: flex;
                    flex: 1;
                    //align-items: center;
                    justify-content: center;
                }
            }
        }

        .team-order-stats-reveal-container {
            display: flex;
            flex: 1;

            height: 84%;
            width: 100%;

            position: relative;

            .box {
                position: absolute;
                top: 25%;
                right: 10%;
                bottom: 9%;
                left: 25%;

                display: flex;
                flex: 1;

                .team_data {
                    flex: 1;

                    display: flex;
                    flex-direction: column;

                    & > div {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <PeriodDisplay />

    <div class="Marketing_Overview" v-if="isLoaded">
        <div v-if="vPhase.phase_step === 0 || vPhase.phase_step === 2" class="Marketing_Board_1">
            <!-- Facilitator12.jpg : Pg1 -->
            <div v-if="type === 'classic'">
                <div class="Nescafe_Reveal_Team Classic">
                    <div class="team">
                        <template v-for="i in indexes" :key="i">
                            <div class="reveal_placement_classic"
                                 :class="{show: show_btn === true, unshow: show_btn === false}">
                                <p class="team_input" :class="{show: show_btn === true, unshow: show_btn === false}">
                                    {{ (i + 1) }}<span class="small_input">{{ ordinalPostfix[i] }}</span>
                                </p>
                                <div class="group" :class="players_array[players_placement[i]]">
                                    <p class="group_label">{{ players_array[players_placement[i]] }}</p>
                                </div>
                            </div>
                        </template>
                        <template v-for="(t, i) in vTeams" :key="i">
                            <button
                                v-if="!revealPositionData['classic'][i]"
                                class="eye_placement" 
                                :class="[`${t.name}`]"
                                disabled>   
                                </button>
                        </template>
                    </div>
                </div>
                <div class="Nescafe_Reveal_Statistic Classic">
                    <template v-for="(row, rowI) in fields.classic" :key="row">
                        <div class="stats" :class="fields.classNames[rowI]">
                            <template v-for="i in indexes" :key="i">
                                <input
                                    class="st_input nobg"
                                    :class="{hide: !get(reveals, [row, i]), unhide: get(reveals, [row, i]), [String.fromCharCode(97 + i)]: true}"
                                    :value="data.classic[row][i]"
                                    disabled
                                >
                            </template>
                            <button class="eye" :class="{hide: !isRowAllReveal(row), unhide: isRowAllReveal(row)}"
                                    @click="toggleRevealsRow(row)"></button>
                        </div>
                    </template>
                </div>
                <div v-if="show_btn">
                </div>
            </div>

            <!-- Facilitator25.jpg Pg3 -->
            <div v-if="type === 'gold'">
                <div class="Nescafe_Reveal_Team Gold">
                    <div class="team">
                        <template v-for="i in indexes" :key="i">
                            <div class="reveal_placement_classic"
                                 :class="{show: show_btn === true, unshow: show_btn === false}">
                                <p class="team_input" :class="{show: show_btn === true, unshow: show_btn === false}">
                                    {{ (i + 1) }}<span class="small_input">{{ ordinalPostfix[i] }}</span>
                                </p>
                                <div class="group" :class="players_array[players_placement_g[i]]">
                                    <p class="group_label">{{ players_array[players_placement_g[i]] }}</p>
                                </div>
                            </div>
                        </template>

                        <template v-for="(t, i) in vTeams" :key="i">
                            <button
                                v-if="!revealPositionData['gold'][i]"
                                class="eye_placement" 
                                :class="[`${t.name}`]"
                                disabled>   
                                </button>
                        </template>
                    </div>
                </div>
                <div class="Nescafe_Reveal_Statistic Gold">

                    <template v-for="(row, rowI) in fields.gold" :key="row">
                        <div class="stats g" :class="fields.classNames[rowI]">
                            <template v-for="i in indexes" :key="i">
                                <input
                                    class="st_input nobg"
                                    :class="{hide: !get(reveals, [row, i]), unhide: get(reveals, [row, i]), [String.fromCharCode(97 + i)]: true}"
                                    :value="data.gold[row][i]"
                                    disabled
                                >
                            </template>
                            <button class="eye_g" :class="{hide: !isRowAllReveal(row), unhide: isRowAllReveal(row)}"
                                    @click="toggleRevealsRow(row)"></button>
                        </div>
                    </template>

                </div>
            </div>

        </div>

        <div v-if="vPhase.phase_step === 1 || vPhase.phase_step === 3" class="Marketing_Board_2">
            <!-- Facilitator17.jpg : Pg2 -->
            <div v-if="vPhase.phase_step === 1">
                <div class="Nescafe_Reveal_Team_Alt Classic">
                    <div class="team">
                        <template v-for="i in indexes" :key="i">
                            <div class="reveal_placement_classic show"
                                 :class="{highlight: classicTeamIdOrder[i] === currentTeamId}">
                                <p class="team_input show">
                                    {{ (i + 1) }}<span class="small_input">{{ ordinalPostfix[i] }}</span>
                                </p>
                                <div class="group" :class="players_array[players_placement[i]]">
                                    <p class="group_label">{{ players_array[players_placement[i]] }}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="Nescafe_Side_Bar_Stock Classic">
                    <template v-for="i in indexes" :key="i">
                        <input
                            v-if="i < vTeams.length && vTeam?.name == String.fromCharCode(65 + i)"
                            class="st_input"
                            :class="`${String.fromCharCode(97 + i)}2`"
                            :value="teamStocks[vTeams[i].id]"
                            disabled
                        >
                    </template>
                </div>

                <div class="Nescafe_Purchase_Screen Classic">

                    <template v-for="(marketCard, i) in marketCards" :key="i">
                        <MarketCard
                            role="participant"
                            :marketCard="marketCard"
                            :teamDict="vTeamDict"
                            :assignedBG="true"
                        ></MarketCard>
                    </template>

                </div>
<!--                <button class="Proceed_Page Finish_Classic" @click="Advance_Page"></button>-->
            </div>

            <!-- Facilitator29.jpg : Pg4 -->
            <div v-if="vPhase.phase_step === 3">
                <div class="Nescafe_Reveal_Team_Alt Gold">
                    <div class="team">

                        <template v-for="i in indexes" :key="i">
                            <div class="reveal_placement_classic show"
                                 :class="{highlight: goldTeamIdOrder[i] === currentTeamId}">
                                <p class="team_input show">
                                    {{ (i + 1) }}<span class="small_input">{{ ordinalPostfix[i] }}</span>
                                </p>
                                <div class="group" :class="players_array[players_placement_g[i]]">
                                    <p class="group_label">{{ players_array[players_placement_g[i]] }}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="Nescafe_Side_Bar_Stock Gold">
                    <template v-for="i in indexes" :key="i">
                        <input
                            v-if="i < vTeams.length && vTeam?.name == String.fromCharCode(65 + i)"
                            class="st_input"
                            :class="`${String.fromCharCode(97 + i)}2`"
                            :value="teamStocks[vTeams[i].id]"
                            disabled
                        >
                    </template>
                </div>

                <div class="Nescafe_Purchase_Screen Gold">

                    <template v-for="(marketCard, i) in marketCards" :key="i">
                        <MarketCard
                            role="participant"
                            :marketCard="marketCard"
                            :teamDict="vTeamDict"
                            :assignedBG="true"
                        ></MarketCard>
                    </template>

                </div>

<!--                <button class="Proceed_Page Finish_Gold" @click="Advance_Page"></button>-->
            </div>
        </div>

    </div>
</template>

<script>
import PeriodDisplay from '@/components/Period/PeriodDisplay.vue';
import { mapGetters } from "vuex";
import _ from "lodash";
import MarketCard from '@/components/MarketCard';

export default {
    components: {
        MarketCard, PeriodDisplay
    },
    data() {
        return {
            fields: {
                classNames: [
                    'first',
                    'second',
                    'third',
                    'total',
                ],
                classic: [
                    'price',
                    'pfme',
                    'rnd',
                    'total_pfme',
                ],
                gold: [
                    'pfme',
                    'pfme_price_ratio',
                    'rnd',
                    'total_pfme',
                ],
            },

            data: {
                classic: {
                    price: [],
                    pfme: [],
                    rnd: [],
                    total_pfme: [],
                },
                gold: {
                    pfme: [],
                    pfme_price_ratio: [],
                    rnd: [],
                    total_pfme: [],
                },
            },

            // players arrangement
            players_array: ['a', 'b', 'c', 'd', 'e', 'f'],
            players_placement: [1, 0, 3, 2, 4, 5],

            // arrange by placement on players_array
            players_placement_g: [0, 1, 2, 3, 4, 5],


            //
            indexes: _.times(6),
            ordinalPostfix: [
                'st', 'nd', 'rd', 'th', 'th', 'th',
            ],
            classicTeamIdOrder: [],
            goldTeamIdOrder: [],
            revealPositionData: {
                classic: [false, false, false, false, false, false],
                gold: [false, false, false, false, false, false]
            },
            isLoaded: false
        }
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vTeams: 'teams',
            vTeam: 'team',
            vTeamDict: 'teamDict',
            vTrainingSessionData: 'trainingSessionData',
            vPhase: 'phase',
            vMarketCards: 'marketCards',
        }),
        type() {
            return this.vPhase.phase_step < 2 ? 'classic' : 'gold';
        },
        teamOrder() {
            const { team_order } = this.vTrainingSessionData;
            /**
             * team_order is an array that contains a list of team_id in order
             * [
             *      team_id,
             *      team_id,
             *      team_id,
             * ]
             */
            return team_order[this.type] || [];
        },
        teamOrderDataDict() {
            const { team_order_data } = this.vTrainingSessionData;
            if (!team_order_data) return {};
            const teamOrderDataThisType = team_order_data[this.type] || [];

            /**
             * teamOrderDataDict will contains teamOrderData object key by team_id
             * {
             *     team_id: {
             *         unit_price: value,
             *         pfme: value,
             *         rnd: value,
             *         total_pfme: value,
             *         pfme_price_ratio: value,
             *         ...
             *     },
             *     team_id: {
             *         ...
             *     },
             *     team_id: {
             *         ...
             *     },
             * }
             */

            return _.keyBy(teamOrderDataThisType, 'team_id');
        },
        marketCards() {
            return _.filter(this.vMarketCards, card => card.type === this.type);
            const { market_cards } = this.vTrainingSessionData;
            return market_cards[this.type];
        },
        currentTeamId() {
            const { market_phase_data } = this.vTrainingSessionData;
            const marketDataByType = market_phase_data[this.type];
            return _.get(marketDataByType, `move`);
        },
        teamStocks() {
            const { market_phase_data } = this.vTrainingSessionData;
            const marketDataByType = market_phase_data[this.type];
            const { team_stocks } = marketDataByType;
            /**
             * team stocks is a json that contains stock amount by team_id
             * {
             *     team_id: stock_amount,
             *     team_id: stock_amount,
             *     team_id: stock_amount,
             * }
             */
            return team_stocks || {};
        },
        reveals() {
            const { market_phase_data } = this.vTrainingSessionData;
            const reveals = market_phase_data.reveals || {};
            return reveals[this.type] || {};
        },
        show_btn() {
            // 24 as in there's 6 teams x 4 rows of data to be reveals
            return _.filter(_.flatMap(this.reveals), i => i).length >= 24;
        }
    },
    mounted() {
        // map value from backend

        if (!this.isLoaded) {
            this.mapValues();

            this.isLoaded = true
        }
    },
    watch: {
        vTrainingSessionData(val, old) {
            if (val.reveal_Data !== old.reveal_data) {
                if (typeof this.vTrainingSessionData.reveal_data == "object") {
                    this.revealPositionData = this.vTrainingSessionData.reveal_data
                } else if (typeof this.vTrainingSessionData.reveal_data == "string") {
                    this.revealPositionData = JSON.parse(this.vTrainingSessionData.reveal_data)
                }
            }
        }
    },
    methods: {
        get: _.get,
        toggleRevealsIndividual(row, i) {
            return;
        },
        toggleRevealsRow(row) {
            return;
        },

        // check a single row is reveals
        isRowAllReveal(row) {
            if (!this.reveals[row]) return false;
            return _.filter(this.reveals[row], i => !i).length === 0;
        },

        mapValues() {
            const { team_order, team_order_data, market_phase_data, reveal_data } = this.vTrainingSessionData;
            const classicTeamOrderDataArr = team_order_data.classic || [];
            const classicTeamOrderData = _.keyBy(classicTeamOrderDataArr, 'team_id');

            const goldTeamOrderDataArr = team_order_data.gold || [];
            const goldTeamOrderData = _.keyBy(goldTeamOrderDataArr, 'team_id');

            if (reveal_data != null || reveal_data == []) {
                if (typeof this.vTrainingSessionData.reveal_data == "string")
                    this.revealPositionData = JSON.parse(reveal_data)
                else if (typeof this.vTrainingSessionData.reveal_data == "object")
                    this.revealPositionData = reveal_data
            } else {
                Profitability.Trainer.revealPositionOrderData(this.revealPositionData);
            }

            // populate data
            const { fields } = this;
            _.each(fields, (_fields, type) => {
                this.data[type] = _.reduce(_fields, (result, field) => {
                    result[field] = _.map(this.vTeams, (team) => {
                        if (type === 'classic')
                            return classicTeamOrderData[team.id] && _.round(classicTeamOrderData[team.id][field], 2);
                        else
                            return goldTeamOrderData[team.id] && _.round(goldTeamOrderData[team.id][field], 2);
                    });
                    return result;
                }, {});
            });

            // team order data (classic)
            const classicTeamOrder = team_order.classic;
            this.classicTeamIdOrder = classicTeamOrder;
            this.players_placement = _.map(classicTeamOrder, (team_id) => {
                return this.vTeamDict[team_id].index;
            });

            // team order data (gold)
            const goldTeamOrder = team_order.gold;
            this.goldTeamIdOrder = goldTeamOrder;
            this.players_placement_g = _.map(goldTeamOrder, (team_id) => {
                return this.vTeamDict[team_id].index;
            });
        },
    }
}
</script>
