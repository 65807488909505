<template>
    <div class="Section_Land">
        <img src="../../assets/Gameboard/Sections/section_land_buildings.png" class="PlayerBoard_Land">
        <div :class="{frame_border: vPhase.period === 0 && vPhase.phase_step === 1}"></div>
        <CoinIndicator :pass_currency_type="2" :pass_cash="amount" ref="Coin_Indicator_Static" />

        <div v-if="vDebug" class="debug">
            <div v-if="canDragToLandNBuilding">
                <button @click="cashToLandNBuilding" title="Drag 20 Coin Here">20 coin here</button>
            </div>
        </div>
    </div>
</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue';
import { mapGetters } from "vuex";

export default {
    components: { CoinIndicator },
    // mounted(){
    //     setTimeout(() => {this.$refs["Coin_Indicator_Static"].cash = 14}, 1000)
    // }
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vGameboard: 'gameboard',
            vPhase: 'phase',
        }),
        amount() {
            return this.vGameboard?.getValue('land_n_building');
        },
        canDragToLandNBuilding() {
            return this.vPhase.period === 0 && this.vPhase.phase_step === 1 && this.amount === 0;
        },
    },
    methods: {
        cashToLandNBuilding() {
            Profitability.Participant.mbsCashToLandNBuilding();
        },
        Interact_Land(cash) {
            if (cash == 20) {
                Profitability.Participant.mbsCashToLandNBuilding();
            } else {
                alert("Please pay 20 coins to land and buildings")
            }
        }
    },
}
</script>

<style lang="scss">
.Section_Land {
    top: 11%;
    left: 7.15%;
    position: fixed;
    width: 6%;
    height: 15%;

    .PlayerBoard_Land {
        top: 11%;
        left: 6.95%;
        position: fixed;
        width: 7.2%;
        height: 15%;
    }

    .frame_border {
        border: 5px solid red;
        border-radius: 8px;
        position: absolute;
        left: -5%;
        width: 125%;
        height: 100%;
    }

    .debug {
        position: absolute;
        inset: 0;
        margin-top: 1rem;

        display: flex;
        flex-direction: column;

        color: black;
        font-weight: bold;

        pointer-events: none;
        z-index: 100;

        button {
            pointer-events: auto;
        }
    }
}

</style>
