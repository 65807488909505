<style lang="scss">
.MBS_Container {
  background-image: url("../../assets/Gameboard/Panel/Form_MovingBS.png");
  top: 3%;
  right: 6.5%;
  position: fixed;
  width: 27%;
  height: 95%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: white;

  .step_display {
    position: absolute;
    top: 34.5%;
    width: 20%;
    font-size: 18px;
    font-weight: bold;
  }
  .step_display.prev {
    left: 47.5%;
  }
  .step_display.curr {
    left: 63.5%;
  }
  .step_display.next {
    left: 79.5%;
  }

  .wrapper {
    position: absolute;
    overflow-y: auto;
    top: 4.2%;
    left: 3%;
    width: 94.5%;
    height: 25.5%;
    padding: 0 2%;

    .text_description {
      position: relative;
      font-size: 20px;
      text-align: justify;
      width: 100%;
      height: 100%;

      pre {
        padding: 0;
        margin: 0;
        font-family: NestleText;
        white-space: pre-wrap; /* Since CSS 2.1 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
      }
    }
  }

  .text_display {
    position: absolute;
    top: 39%;
    right: 52%;
    width: 36.5%;
    height: 54.5%;
    /* background: green; */

    p {
      position: absolute;
      width: 100%;
      font-size: 18px;
      text-align: right;
    }

    .factory { top: 9%; }
    .equipment { top: 18%; }
    .wip { top: 27%; }
    .fgoods { top: 35.5%; }
    .owed { top: 44.7%; }
    .total_wwh { top: 53%; }
    .shared_cap { top: 66.4%; }
    .pl { top: 75.6%; }
    .loans { top: 84.5%; }
    .total_wicf { top: 93%; }
  }

  .mbs_col {
    position: absolute;
    top: 38.7%;
    width: 14%;
    height: 54.5%;
  }
  .mbs_col.col_1 {
    left: 51%;
  }
  .mbs_col.col_2 {
    left: 67%;
  }
  .mbs_col.col_3 {
    left: 82.7%;
  }

  .input_box {
    position: absolute;
    width: 100%;
    border-radius: 5px;

    input {
      position: relative;
      font-size: 20px;
      text-align: center;
      width: 100%;
      padding: 3px 0;
      font-family: NestleBrush_Reg;
    }
  }
  .input_factory { top: 9%; }
  .input_eq { top: 18%; }
  .input_wip { top: 27%; }
  .input_fg { top: 35.5%; }
  .input_owed { top: 44.7%; }
  .input_wwh_t { top: 53%; }
  .input_sc { top: 66.4%; }
  .input_pl { top: 75.6%; }
  .input_loans { top: 84.5%; }
  .input_wicf_t { top: 93%; }

  .brown {
    background: #63503f;
    border: none;
  }
  .white {
    background: white;
    border: none;
  }
  .black_text {
    color: black;
    background: none;
    border: none;
    outline: none;
  }
  .white_text {
    color: white;
    background: none;
    border: none;
    outline: none;
  }

  .answer_check {
    background: url("../../assets/Gameboard/Period/checked_step_white.png");
    background-size: 100% 100%;
    position: absolute;
    right: 1%;
    width: 5%;
    height: 2.5%;
    z-index: 5;

    &.cash { top: 39%; }
    &.land_n_building { top: 43.8%; }
    &.equipment { top: 48.6%; }
    &.wip { top: 53.5%; }
    &.finished_good { top: 58.1%; }
    &.owe_by_customers { top: 63.2%; }
    &.share_capital { top: 75.2%; }
    &.profit { top: 80%; }
    &.bank_loans { top: 85%; }
  }
}
</style>

<template>
    <div class="MBS_Container">
        <p v-if="step_capped - 1 > 0" class="step_display prev">Step {{ step_capped - 1 }}</p>
        <p v-if="step_capped > 0" class="step_display curr">Step {{ step_capped }}</p>
        <p class="step_display next">Step {{ step_capped + 1 }}</p>

        <div class="wrapper">
            <p class="text_description">
                <pre><b>{{actions_step[phase]}}</b></pre>
                <br />
                <pre v-if="phase < 10">{{actions[phase]}}</pre>
                <pre v-else>{{ action_final }}</pre>
            </p>
        </div>

        <div class="text_display">
            <p class="black_text">{{ text_display_desc[0] }}</p>
            <p v-if="phase >= 1" class="factory black_text">{{ text_display_desc[1] }}</p>
            <p v-if="phase >= 2" class="equipment black_text">{{ text_display_desc[2] }}</p>
            <p v-if="phase >= 2" class="wip black_text">{{ text_display_desc[3] }}</p>
            <p v-if="phase >= 3" class="fgoods black_text">{{ text_display_desc[4] }}</p>
            <p v-if="phase >= 4" class="owed black_text">{{ text_display_desc[5] }}</p>
            <p class="total_wwh">{{ text_display_desc[6] }}</p>

            <p class="shared_cap black_text">{{ text_display_desc[7] }}</p>
            <p v-if="phase >= 4" class="pl bottom black_text">{{ text_display_desc[8] }}</p>
            <p v-if="phase >= 6" class="loans bottom black_text">{{ text_display_desc[9] }}</p>
            <p class="total_wicf bottom">{{ text_display_desc[10] }}</p>
        </div>

        <template v-for="(col, i) in [step_capped - 2, step_capped - 1, step_capped]">

            <div v-if="col >= 0" class="mbs_col" :class="`col_${i + 1}`" :key="i">

                <!-- Cash, 2 x Total-->
                <div class="input_box" :class="{white: col===phase, brown: col!==phase}">
                    <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                           :disabled="col !== phase" v-model.number="ans[col].cash" @focus="$event.target.select()" @change="check_input">
                </div>

                <div class="input_box input_wwh_t" :class="{white: col===phase, brown: col!==phase}">
                    <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                           disabled :value="getWWHTotal(col)">
                </div>

                <div class="input_box input_wicf_t" :class="{white: col===phase, brown: col!==phase}">
                    <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                           disabled :value="getWICFTotal(col)">
                </div>

                <!-- Factory -->
                <div v-if="col >= 1">
                    <div class="input_box input_factory" :class="{white: col===phase, brown: col!==phase}">
                        <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                               :disabled="col !== phase" v-model.number="ans[col].land_n_building" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- Equipment/Land & Buildings-->
                <div v-if="col >= 2">
                    <div class="input_box input_eq" :class="{white: col===phase, brown: col!==phase}">
                        <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                               :disabled="col !== phase" v-model.number="ans[col].equipment" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                    <div class="input_box input_wip" :class="{white: col===phase, brown: col!==phase}">
                        <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                               :disabled="col !== phase" v-model.number="ans[col].wip" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- WIP + Finished Goods -->
                <div v-if="col >= 3">
                    <div class="input_box input_fg" :class="{white: col===phase, brown: col!==phase}">
                        <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                               :disabled="col !== phase" v-model.number="ans[col].finished_good" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- Owed By Customers + Profit/Loss -->
                <div v-if="col >= 4">
                    <div class="input_box input_owed" :class="{white: col===phase, brown: col!==phase}">
                        <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                               :disabled="col !== phase" v-model.number="ans[col].owe_by_customers" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- Shared Capitals -->
                <div class="input_box input_sc" :class="{white: col===phase, brown: col!==phase}">
                    <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                           :disabled="col !== phase" v-model.number="ans[col].share_capital" @focus="$event.target.select()"
                           @change="check_input">
                </div>

                <!-- Profit/Loss -->
                <div v-if="col >= 4">
                    <div class="input_box input_pl" :class="{white: col===phase, brown: col!==phase}">
                        <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                               :disabled="col !== phase" v-model.number="ans[col].profit" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

                <!-- Bank Loans -->
                <div v-if="col >= 6">
                    <div class="input_box input_loans" :class="{white: col===phase, brown: col!==phase}">
                        <input :class="{black_text: col===phase,white_text: col!==phase}" maxlength="3"
                               :disabled="col !== phase" v-model.number="ans[col].bank_loans" @focus="$event.target.select()"
                               @change="check_input">
                    </div>
                </div>

            </div>
            
        </template>
        
        <div v-for="className in classNames" :key="className">
            <div v-if="check[className]" class="answer_check" :class="className"></div>
        </div>

    </div>
    
</template>

<script type="text/javascript">

import { period_0_step, period_0 } from '@/constants';

export default {
    props: {
        phase: Number,
        cash_stored: Number
    },
    data() {
        return {
            actions_step: period_0_step,
            actions: period_0,
            action_final: 'Well done you have completed prework!',
            text_display_desc: [
                'Cash',
                'Land & Buildings',
                'Production Line',
                'Work In Progress',
                'Finished Goods',
                'Owed By Customers',
                'Total',
                'Share Capital',
                'Profit/Loss',
                'Bank Loans',
                'Total'
            ],
            classNames: [
                'cash',
                'land_n_building',
                'equipment',
                'wip',
                'finished_good',
                'owe_by_customers',
                'share_capital',
                'profit',
                'bank_loans'
            ],
            ans: {
                0: {
                    cash: null,
                    share_capital: null,
                },
                1: {
                    cash: null,
                    land_n_building: null,
                    
                    share_capital: null,
                },
                2: {
                    cash: null,
                    land_n_building: null,
                    equipment: null,
                    wip: null,
                    
                    share_capital: null,
                },
                3: {
                    cash: null,
                    land_n_building: null,
                    equipment: null,
                    wip: null,
                    finished_good: null,
                    
                    share_capital: null,
                },
                4: {
                    cash: null,
                    land_n_building: null,
                    equipment: null,
                    wip: null,
                    finished_good: null,
                    owe_by_customers: null,
                    
                    share_capital: null,
                },
                5: {
                    cash: null,
                    land_n_building: null,
                    equipment: null,
                    wip: null,
                    finished_good: null,
                    owe_by_customers: null,
                    
                    share_capital: null,
                    profit: null,
                },
                6: {
                    cash: null,
                    land_n_building: null,
                    equipment: null,
                    wip: null,
                    finished_good: null,
                    owe_by_customers: null,
                    
                    share_capital: null,
                    profit: null,
                },
                7: {
                    cash: null,
                    land_n_building: null,
                    equipment: null,
                    wip: null,
                    finished_good: null,
                    owe_by_customers: null,
                    
                    share_capital: null,
                    profit: null,
                    bank_loans: null
                },
                8: {
                    cash: null,
                    land_n_building: null,
                    equipment: null,
                    wip: null,
                    finished_good: null,
                    owe_by_customers: null,
                    
                    share_capital: null,
                    profit: null,
                    bank_loans: null
                },
                9: {
                    cash: null,
                    land_n_building: null,
                    equipment: null,
                    wip: null,
                    finished_good: null,
                    owe_by_customers: null,
                    
                    share_capital: null,
                    profit: null,
                    bank_loans: null
                }
            },
            phase_ans: {},
            check: {},
            WWHTotal: 0,
            WICFTotal: 0,
            log_phase: -1,
        };
    },
    computed: {
        step_capped() {
            return this.phase > 9? 9 : this.phase;
        }
    },
    mounted() {
        if (this.phase === 0) {
            this.phase_ans.cash = 75;

            this.phase_ans.share_capital = 75;
        }  else if (this.phase === 9) {
                this.phase_ans.cash = 3;
                this.phase_ans.land_n_building = 20;
                this.phase_ans.equipment = 14;
                this.phase_ans.wip = 10;
                this.phase_ans.finished_good = 12
                this.phase_ans.owe_by_customers = 25

                this.phase_ans.share_capital = 75;
                this.phase_ans.profit = 0;
                this.phase_ans.bank_loans = 9;
            }
    },
    watch: {
        phase() {
            if (this.log_phase !== this.phase) {
                this.check = {}
                this.log_phase += 1;
            }

            if (this.phase === 1) {
                this.phase_ans.cash = 55;
                this.phase_ans.land_n_building = 20;

                this.phase_ans.share_capital = 75;

            } else if (this.phase === 2) {
                this.phase_ans.cash = 30;
                this.phase_ans.land_n_building = 20;
                this.phase_ans.equipment = 15;
                this.phase_ans.wip = 10;

                this.phase_ans.share_capital = 75;

            } else if (this.phase === 3) {
                this.phase_ans.cash = 10;
                this.phase_ans.land_n_building = 20;
                this.phase_ans.equipment = 15;
                this.phase_ans.wip = 10;
                this.phase_ans.finished_good = 20

                this.phase_ans.share_capital = 75;

            } else if (this.phase === 4) {
                this.phase_ans.cash = 10
                this.phase_ans.land_n_building = 20
                this.phase_ans.equipment = 15
                this.phase_ans.wip = 10
                this.phase_ans.finished_good = 0
                this.phase_ans.owe_by_customers = 25

                this.phase_ans.share_capital = 75;
                this.phase_ans.profit = 5

            } else if (this.phase === 5) {
                this.phase_ans.cash = 7;
                this.phase_ans.land_n_building = 20;
                this.phase_ans.equipment = 15;
                this.phase_ans.wip = 10;
                this.phase_ans.finished_good = 0
                this.phase_ans.owe_by_customers = 25

                this.phase_ans.share_capital = 75;
                this.phase_ans.profit = 2

            } else if (this.phase === 6) {
                this.phase_ans.cash = 17;
                this.phase_ans.land_n_building = 20;
                this.phase_ans.equipment = 15;
                this.phase_ans.wip = 10;
                this.phase_ans.finished_good = 0
                this.phase_ans.owe_by_customers = 25

                this.phase_ans.share_capital = 75;
                this.phase_ans.profit = 2
                this.phase_ans.bank_loans = 10;

            } else if (this.phase === 7) {
                this.phase_ans.cash = 5;
                this.phase_ans.land_n_building = 20;
                this.phase_ans.equipment = 15;
                this.phase_ans.wip = 10;
                this.phase_ans.finished_good = 12
                this.phase_ans.owe_by_customers = 25

                this.phase_ans.share_capital = 75;
                this.phase_ans.profit = 2
                this.phase_ans.bank_loans = 10;

            } else if (this.phase === 8) {
                this.phase_ans.cash = 3;
                this.phase_ans.land_n_building = 20;
                this.phase_ans.equipment = 15;
                this.phase_ans.wip = 10;
                this.phase_ans.finished_good = 12
                this.phase_ans.owe_by_customers = 25

                this.phase_ans.share_capital = 75;
                this.phase_ans.profit = 1
                this.phase_ans.bank_loans = 9;

            } else if (this.phase === 9) {
                this.phase_ans.cash = 3;
                this.phase_ans.land_n_building = 20;
                this.phase_ans.equipment = 14;
                this.phase_ans.wip = 10;
                this.phase_ans.finished_good = 12
                this.phase_ans.owe_by_customers = 25

                this.phase_ans.share_capital = 75;
                this.phase_ans.profit = 0;
                this.phase_ans.bank_loans = 9;
            }
        }
    },
    methods: {
        getWWHTotal(col) {
            const form = this.ans[col];
            const wwhFields = [
                'cash',
                'land_n_building',
                'equipment',
                'wip',
                'finished_good',
                'owe_by_customers',
            ];
            const filtered = _.map(wwhFields, (field) => {
                if (Number.isInteger(form[field])) {
                    return form[field]
                } else {
                    return 0
                }
            });
            
            return _.sum(filtered);
        },
        getWICFTotal(col) {
            const form = this.ans[col];
            const wicfFields = [
                'share_capital',
                'profit',
                'bank_loans',
            ];
            const filtered = _.map(wicfFields, (field) => {
                if (Number.isInteger(form[field])) {
                    return form[field]
                } else {
                    return 0
                }
            });
            return _.sum(filtered);
        },
        check_input() {
            _.each(this.classNames, type => {
                if (Number.isInteger(this.phase_ans[type])) {
                    this.$data.check[type] = this.phase_ans[type] == this.$data.ans[this.phase][type];
                }
            });

            if (Object.values(this.check).every(item => item)) {
                this.$emit("canMovePhase", true)
            } else {
                this.$emit("canMovePhase", false)
            }
        },
    }
}

</script>