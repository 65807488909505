<style lang="scss">

.Marketing_Plan {
    top: 8%;
    left: 52.5%;
    position: fixed;
    width: 42%;
    height: 55%;

    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.Classic {
        background-image: url("../../../assets/Facilitator/Marketing_Plans_Classic.png");
    }

    &.Gold {
        background-image: url("../../../assets/Facilitator/Marketing_Plans_Gold.png");
    }

    .col {
        position: absolute;
        top: 29.8%;
        left: 27.6%;
        width: 8%;
        height: 56.8%;
        font-size: 1.9rem;
        color: white;
        text-align: center;

        &.b { left: 39%; }
        &.c { left: 50.8%; }
        &.d { left: 62.2%; }
        &.e { left: 73.8%; }
        &.f { left: 85.1%; }
    }

    .row {
        position: absolute;
        width: 100%;
        height: auto;
        
        &.first { top: 0%; }
        &.second { top: 22%; }
        &.third { top: 43.8%; }
        &.total { top: 65.4%; }
        &.avail { top: 88%; }
        &.black { color: black; }
    }

    .debug {
        position: absolute;
        inset: 28% 5% 12% 26%;

        display: flex;

        .team {
            display: flex;
            flex-direction: column;
            flex: 1;

            .text {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .tot {
        font-family: NestleText;
        position: absolute;
        left: 22.5%;
        font-size: 20px;

        &.pfme { top: 46%; }
        &.rnd { top: 58.5%; }
        &.avail { top: 83.9%; }

        &.gold {
            color: white;
            
            &.pfme { top: 33%; }
            &.rnd { top: 46%; }
        }
    }
}
</style>

<template>
    <div class="Marketing_Plan" :class="boardClass">

        <!-- TODO: team's marketing plan here -->
        <template v-for="i in [0,1,2,3,4,5]" :key="i">
            <template v-if="teamMarketingPlans[i]">
                
                <template v-if="type === 'classic'">
                    <div class="col" :class="[{b: i == 1}, {c: i == 2}, {d: i == 3}, {e: i == 4}, {f: i == 5}]">
                        <div class="row first" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].unit_price }}</div>
                        <div class="row second" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].pfme }}</div>
                        <div class="row third" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].rnd }}</div>
                        <div class="row total" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].total_pfme }}</div>
                        <div class="row avail" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].available_stock }}</div>
                    </div>
                </template>

                <template v-if="type === 'gold'">
                    <div class="col" :class="[{b: i == 1}, {c: i == 2}, {d: i == 3}, {e: i == 4}, {f: i == 5}]">
                        <div class="row first" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].pfme }}</div>
                        <div class="row second" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].rnd }}</div>
                        <div class="row third" :class="{black: i == 4 || i == 5}">
                            <template v-if="teamMarketingPlans[i].unit_price">
                                {{ teamMarketingPlans[i].pfme / teamMarketingPlans[i].unit_price % 1 == 0 ? 
                                    teamMarketingPlans[i].pfme / teamMarketingPlans[i].unit_price : 
                                    (teamMarketingPlans[i].pfme / teamMarketingPlans[i].unit_price).toFixed(2)
                                }}
                            </template>
                        </div>
                        <div class="row total" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].total_pfme }}</div>
                        <div class="row avail" :class="{black: i == 4 || i == 5}">{{ teamMarketingPlans[i].available_stock }}</div>
                    </div>
                </template>

            </template>
        </template>

        <!-- <div v-if="vDebug" class="debug">
            <template v-for="i in [0,1,2,3,4,5]">
                <div class="team">
                    <template v-if="teamMarketingPlans[i]">
                        <template v-if="type === 'classic'">
                            <div class="text">{{ teamMarketingPlans[i].unit_price }}</div>
                            <div class="text">{{ teamMarketingPlans[i].pfme }}</div>
                            <div class="text">{{ teamMarketingPlans[i].rnd }}</div>
                            <div class="text">{{ teamMarketingPlans[i].total_pfme }}</div>
                            <div class="text">{{ teamMarketingPlans[i].available_stock }}</div>
                        </template>
                        <template v-if="type === 'gold'">
                            <div class="text">{{ teamMarketingPlans[i].pfme }}</div>
                            <div class="text">{{ teamMarketingPlans[i].rnd }}</div>
                            <div class="text">
                                <template v-if="teamMarketingPlans[i].unit_price">
                                    {{ (teamMarketingPlans[i].pfme / teamMarketingPlans[i].unit_price).toFixed(2) }}
                                </template>
                            </div>
                            <div class="text">{{ teamMarketingPlans[i].total_pfme }}</div>
                            <div class="text">{{ teamMarketingPlans[i].available_stock }}</div>
                        </template>
                    </template>
                </div>
            </template>
        </div> -->

        <p class="tot pfme" :class="{gold: type === 'gold'}">{{ pfme_total }}</p>
        <p class="tot rnd" :class="{gold: type === 'gold'}">{{ rnd_total }}</p>
        <p class="tot avail" :class="{gold: type === 'gold'}">{{ avail_total }}</p>

    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        type: {
            type: String,
            validator: (value) => {
                return ['classic', 'gold'].indexOf(value) !== -1;
            },
        },
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
        }),
        boardClass() {
            return {
                classic: 'Classic',
                gold: 'Gold',
            }[this.type];
        },
        teamMarketingPlans() {
            const teamMarketingPlans = [];

            const { vTeams } = this;
            _.each(vTeams, (team) => {
                const marketingPlan = team.marketingData;
                const marketingPlanForThisType = marketingPlan[this.type] || {};

                if (marketingPlanForThisType.unit_price) {
                    const total_pfme = team.marketingDataTotalPFME;
                    const available_stock = this.type === 'classic' ? team.classicStockCount : team.goldStockCount;
                    const planToDisplay = {
                        ...marketingPlanForThisType,
                        total_pfme,
                        available_stock,
                    };
                    teamMarketingPlans.push(planToDisplay);
                } else {
                    teamMarketingPlans.push({});
                }
            });

            return teamMarketingPlans;
        },
        pfme_total() {
            let sum = 0

            _.each(this.vTeams, (team, i) => {
                Number.isInteger(this.teamMarketingPlans[i].pfme) ? sum += this.teamMarketingPlans[i].pfme : sum += 0
            });

            return isNaN(sum) ? 0 : sum;
        },
        rnd_total() {
            let sum = 0

            _.each(this.vTeams, (team, i) => {
                Number.isInteger(this.teamMarketingPlans[i].rnd) ? sum += this.teamMarketingPlans[i].rnd : sum += 0
            });

            return isNaN(sum) ? 0 : sum;
        },
        avail_total() {
            let sum = 0

            _.each(this.vTeams, (team, i) => {
                Number.isInteger(this.teamMarketingPlans[i].available_stock) ? sum += this.teamMarketingPlans[i].available_stock : sum += 0
            });

            return isNaN(sum) ? 0 : sum;
        }
    },
}
</script>
