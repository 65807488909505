import { Model } from '@vuex-orm/core';

export default class MarketCard extends Model {
  static entity = 'market_cards';

  static fields() {
    return {
      id: this.attr(null),

      type: this.attr(null),
      payment: this.attr(null),
      unit: this.attr(null),
      delivered: this.attr(null),

      team_id: this.attr(null),
    };
  }
}
