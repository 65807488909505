import axios from 'axios';

export function createAxios(context) {
  const baseURL = context.options.host;

  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(config => {
    config.headers.Accept = `application/json`;
    if (instance.token) {
      config.headers.Authorization = `Bearer ${instance.token}`;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  }, { synchronous: true });

  instance.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response && error.response.status <= 500) {
      const { data } = error.response;
      return Promise.reject(data);
    }
    return Promise.reject(error);
  }, { synchronous: true });

  return instance;
}
