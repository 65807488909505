<template>
    <div class="Section_Expenses">
        <img src="../../assets/Gameboard/Sections/section_expenses.png" class="PlayerBoard_Expenses">

        <div v-if="phase === 9" class="box dep frame_border" ref="Depreciation">
            <CoinIndicator :pass_currency_type="2" :pass_cash="depreciation" />
        </div>
        <div v-if="phase === 5" class="box ffoh frame_border" ref="FFOH_Pay" :index="6">
            <CoinIndicator :pass_currency_type="1" :pass_cash="ffoh" />
        </div>
        <div v-if="phase === 5" class="box moge frame_border" ref="MOGE_Pay" :index="7">
            <CoinIndicator :pass_currency_type="1" :pass_cash="moge" />
        </div>
        <div v-if="phase === 8" class="box interest frame_border" ref="Interest_Pay" :index="8">
            <CoinIndicator :pass_currency_type="1" :pass_cash="interest" />
        </div>
    </div>
</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue';

export default {
    props: {
        phase: Number,
        expenses: Object
    },
    components: { CoinIndicator },
    data() {
        return {
            ffohPaid: false,
            mogePaid: false,
            interestPaid: false,
        }
    },
    computed: {
        depreciation() {
            return this.expenses.depreciation;
        },
        ffoh() {
            return this.expenses.ffoh;
        },
        moge() {
            return this.expenses.moge;
        },
        interest() {
            return this.expenses.interest;
        },
    },
    methods: {
        payFFOH(amount) {
            if (amount !== 1) {
                alert("Incorrect amount for FFOH. Pay 1 for each production line you own")
                return;
            }

            this.$emit("Pay_Expense", 'ffoh')
            this.ffohPaid = true
        },
        payMOGE(amount) {
            if (amount !== 2) {
                alert("Incorrect amount for MOGE. Pay 2 each period")
                return
            }
                
            this.$emit("Pay_Expense", 'moge')
            this.mogePaid = true
        },
        payInterest(amount) {            
            if (amount !== 1) {
                alert("Plase pay $1 to Interest")
                return
            }

            this.$emit("Pay_Expense", 'interest')
            this.interestPaid = true
        },
        Interact_Expense(this_cash, target){
            if (this_cash > 0) {
                this.Purchase_Expense(this_cash, target)
            }
        },
        Purchase_Expense(this_cash, target){
            if (target === 'FFOH_Pay') {
                if (!this.ffohPaid) {
                    if (this.phase === 5) {
                        this.payFFOH(this_cash);
                    } else {
                        alert("Please wait for the right phase to pay FFOH")
                    }
                }
            } else if (target === 'MOGE_Pay') {
                if (!this.mogePaid) {
                    if (this.phase === 5) {
                        this.payMOGE(this_cash)
                    } else {
                        alert("Please wait for the right phase to pay MOGE")
                    }
                }
            } else if (target === 'Interest_Pay') {
                if (!this.interestPaid) {
                    if (this.phase === 8) {
                        this.payInterest(this_cash);
                    } else {
                        alert("Please wait for the right phase to pay interest")
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss">
.Section_Expenses {
    top: 10%;
    left: 51.1%;
    position: fixed;
    width: 13.6%;
    height: 65.1%;

    .PlayerBoard_Expenses {
        top: 10%;
        left: 51.1%;
        position: fixed;
        width: 13.6%;
        height: 65.1%;
    }

    .debug {
        position: absolute;
        inset: 0;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        padding-bottom: 3rem;

        div {
            display: flex;
            flex: 1;
            
            div {
                flex: 1;
            }

            button {
                height: 15%;
            }
        }
    }


    .box {
        position: fixed;
        width: 6.17%;
        height: 14.4%;
        padding-left: 2px;
        border-radius: 10px;
        
        &.pfme {
            left: 51.4%;
            top: 11.4%;
        }

        &.rnd {
            left: 57.77%;
            top: 11.4%;
        }

        &.dep {
            left: 51.4%;
            top: 26%;
        }

        &.ffoh {
            left: 57.77%;
            top: 26%;
        }

        &.moge {
            left: 51.4%;
            top: 41.2%;
        }

        &.interest {
            left: 57.77%;
            top: 41.2%;
        }

        &.tax {
            left: 51.4%;
            top: 56.35%;
        }

        &.dividends {
            left: 57.77%;
            top: 56.35%;
        }

    }
    
    .frame_border {
        border: 5px solid white;
        border-radius: 8px;
    }

}


</style>
