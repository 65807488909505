<style lang="scss">
.Period {
    top: 0%;
    right: 0%;
    width: 20rem;
    height: 3rem;
    position: absolute;
    background: url("../../assets/Marketing/Period_Header.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &.sum {
        width: 17rem;
    }

    &.period0 {
        width: 12rem;
    }

    &.market {
        width: 17rem;
    }

    .period-timeline {
        font-family: NestleBrush_Reg;
        text-align: right;
        font-size: 1.8rem;
        color: white;
        position: absolute;
        right: 5%;
        top: 2.5%;
    }
}
</style>

<template>
    <div class="Period" :class="{period0: vPhase.period === 0}, {market: vPhase.market_phase == 1}, {sum: vPhase.market_phase === 3}">
        <div v-if="isMarket" class="period-timeline">Period {{ vPhase.period + 1 }} | Market</div>
        <div v-else-if="vPhase.period == 0" class="period-timeline">Period {{ vPhase.period + 1 }}</div>
        <div v-else-if="vPhase.period > 0 && vPhase.market_phase == 0" class="period-timeline pre">Period {{ vPhase.period + 1 }} | Pre-Market</div>
        <div v-else-if="vPhase.period > 0 && vPhase.market_phase == 1" class="period-timeline pre">Period {{ vPhase.period + 1 }} | Market</div>
        <div v-else-if="vPhase.period > 0 && vPhase.market_phase == 2" class="period-timeline">Period {{ vPhase.period + 1 }} | Post-Market</div>       
        <div v-else-if="vPhase.market_phase === 3" class="period-timeline">Period {{ vPhase.period + 1 }} | Summary</div>        
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        isTrainer: {
            type: Boolean,
            default: false
        },

        isMarket: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters('profitability/participant', {
            pPhase: 'phase',
        }),

        ...mapGetters('profitability/trainer', {
            tPhase: 'phase',
        }),

        vPhase() {
            if (this.isTrainer) {
                return this.tPhase
            } else {
                return this.pPhase
            }
        }
    }
}
</script>