<template>
    <div class="Period_Objective" :style="cssProps">
        <p class="Step_Desciption">
            {{text_display}}
        </p>
        <button v-if="Show_Next && !isAtGBE" class="Next_Step" @click="Next_Step">Next</button>
        <img class="Checked_Icon" src="../../assets/Gameboard/Period/checked_step.png">        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default{
    props: {
        pass_position: Number, 
        text: String, 
        isAtGBE: Boolean,
        submitPA: Boolean,
        submitPAData: Object,
    },
    data() {
        return {
            skip_step: false,
            complete_step: false,
            current_color: 'white',
            position: this.pass_position,
            text_display: this.text,            
        }
    },
    async mounted() {
        if (!this.isEquipmentWIPPhase) {
            await Profitability.Participant.updateFactories(this.getNumOfFactories)
        }
    },  
    computed: {
        cssProps(){
            return{
                '--color-return': this.current_color,
                '--position-return': this.position + "%"
            }
        },
        ...mapGetters('profitability/participant', {
            vPhase: 'phase',
            vMarketingData: 'marketing_data',
            vTeamData: 'teamData',
            vGameboard: 'gameboard',
            vFactories: 'factories'
        }),
        Show_Next() {
            if (this.vPhase.market_phase === 0) {
                return (this.vPhase.market_phase == 0 && this.vPhase.phase_step !== 6)
            } else {
                if (this.vPhase.period < 2) {
                    return this.vPhase.market_phase == 2 && this.vPhase.phase_step !== 8
                } else {
                    return this.vPhase.market_phase == 2 && this.vPhase.phase_step !== 9
                }
            }
        },
        isPAPhase() {
            return this.vPhase.period > 1 && this.vPhase.market_phase === 2 && this.vPhase.phase_step === 8
        },
        isEquipmentWIPPhase() {
            return this.vPhase.market_phase === 0 && this.vPhase.phase_step === 3
        },
        marketingDataClassic() {
            return this.vMarketingData.order?.classic
        },
        marketingDataGold() {
            return this.vMarketingData.order?.gold;
        },
        suppliers() {
            return this.vGameboard?.getValue('suppliers', 'paid');
        },
        suppliers_owe() {
            return this.vGameboard?.getValue('suppliers', 'owe');
        },
        payables_owe() {
            return this.vGameboard?.getValue('payables', 'owe');
        },
        coPackPhase() {
            return this.vPhase.market_phase === 0 && this.vPhase.phase_step === 5
        },
        rawPackPhase() {
            return this.vPhase.market_phase === 2 && this.vPhase.phase_step === 2
        },
        getNumOfFactories() {
            let sum = 0
            this.vFactories.forEach(factory => {
                if (factory.production_line !== null) {
                    sum += 1
                }
            })

            return sum
        }
    },
    methods:{
        Next_Step() {
            // Trigger warning if participant proceeds without buying copack/raw pack
            if (this.coPackPhase && this.suppliers === 0) {
                if (this.suppliers_owe > 0) {
                    alert("Please pay the suppliers before moving on")
                    return
                }

                this.$emit("triggerEmptyBuy")
                return;
            } else if (this.rawPackPhase && this.suppliers === 0 && this.payables_owe === 0) {
                this.$emit("triggerEmptyBuy")
                return;
            } else if (this.isEquipmentWIPPhase) {
                console.log(this.vTeamData.data.data.factories + " " + this.getNumOfFactories)
                if (this.vTeamData.data.data.factories === this.getNumOfFactories) {
                    this.$emit("triggerEmptyBuy")
                    return;
                }
            }

            if (this.isPAPhase) {
                this.submitPerformanceAnalysis(this.submitPA, this.submitPAData)
            } else {
                Profitability.Participant.nextPhaseStep().catch(function(e) {
                    if (e.reason == "Please fill in balance sheet") {
                        alert("There is a number wrong in the Balance Sheet. Please check and correct, before moving forward")
                    }
                    if (e.reason == "Please fill in income statement") {
                        alert("There is a number wrong in the Income Statement. Please check and correct, before moving forward")
                    }
                });
            }
        },

        async submitPerformanceAnalysis(bool, data) {
            if (bool) {
                await Profitability.Participant.submitPerformanceAnalysis(data).then(
                    Profitability.Participant.nextPhaseStep()
                ).catch(function(e) {
                    this.$emit("triggerPAAlert");
                })
            } else {
                this.$emit("triggerPAAlert");
            }
        }
    }
}
</script>


<style>
    .Period_Objective{
        top: var(--position-return);
        left: 0;
        position: absolute;
        width: 99.5%;
        height: 13%;

        background-color: var(--color-return);
        border-color: transparent;
        z-index: 2;
    }

    .Period_Objective p{
        top: 12%;
        left: 5%;
        position: absolute;
        width: auto;
        height: 101%;
        text-align: left;
        font-size: 16px;
    }

    .Checked_Icon{
        right: 1%;
        top: 25%;
        position: absolute;
    }
    .Next_Step{
        right: 0.5%;
        top: 4.5%;
        background-color: black;
        position: absolute;
        width: 10%;
        height: 90%;
        font-size: 16px;
        color: white;
        border-radius: 12px;

        background-color: #392c23;
        border-color: transparent;
        z-index: 5;
    }
    .Next_Step:hover {
        background-color: black;
    }
    .Next_step:active {
        background-color: white;
        color: black;
    }
    .Step_Desciption{
        color: black;
        padding-right: 2rem;
    }
</style>