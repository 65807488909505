<template>
<div class="Section_Loans">
    <img src="../../assets/Gameboard/Sections/Gameboard_Loansbox.png" class="PlayerBoard_Loans">
    <div :class="{ frame_border: phase === 8}" class="box"></div>

    <div class="Standard_Role_0" ref="Standard_Loan_Pay" :index="11">
        <div v-if="loans_standard > 0" class="coin_size_adjust">
            <CoinIndicator :pass_currency_type="9" :pass_cash="loans_standard" />
        </div>
    </div>
</div>
</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue'

export default {
    props: {
        phase: Number,
        loan: Number,
    },
    components: { CoinIndicator },
    data() {
        return {
            paid_loan: false,
        }
    },
    computed: {
        loans_standard() {
            return this.loan
        },
    },
    methods: {
        Interact_Loan_Standard(amount) {
            if (this.phase === 8) {
                if (amount === 1 && this.paid_loan == false) { 
                    this.paid_loan = true;
                    this.$emit("Pay_Loan", amount)
                } else {
                    alert("Please pay only $1 loan once only for now")
                }
            } else {
                alert("Please wait for the right step to pay loan")
            }
        },
    },
}
</script>

<style lang="scss">
.Section_Loans{
    top: 76%;
    left: 51.1%;
    position: fixed;
    width: 13.2%;
    height: 20.5%;

    .PlayerBoard_Loans{
        top: 76%;
        left: 51.1%;
        position: fixed;
        width: 13.2%;
        height: 20.5%;
    }

    .Standard_Role_0 {
        top: 76.6%;
        left: 51.48%;
        position: fixed;
        width: 12.4%;
        height: 15.7%;
    }
    .coin_size_adjust {
        position: absolute;
        top: 12%;
        left: 0;
        width: 55%;
    }

    .frame_border {
        border: 5px solid red;
        border-radius: 8px;

        &.box {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
