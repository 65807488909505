<style lang="scss">
.PopUp {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.8);
    z-index: 10;
    padding-top: 22rem;

    &.atTrainerP0 {
        top: 0%;
        left: -3%;
        width: 103%;
        height: 100%;
    }

    &.atTrainer {
        top: -9%;
        left: -4.7%;
        width: 108.8%;
        height: 121.3%;
    }

    .wrapper {
        margin: auto;
        width: 38%;
        height: auto;
        overflow-y: auto;
        background: #b5dd7d;
        border-radius: 20px;
        padding: 3% 2%;
        margin-bottom: 2rem;

        &.wrapperManyText {
            top: 38.5%;
            left: 28%;
            width: 48%;
        }

        &.wrapperAtTrainerP0 {
            top: 36%;
        }

        &.wrapperAtTrainer {
            left: 36%;
        }

        pre {
            padding: 0;
            margin: 0;
            font-family: NestleText;
            font-size: 32px;
            white-space: pre-wrap;       /* Since CSS 2.1 */
            white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
            white-space: -pre-wrap;      /* Opera 4-6 */
            white-space: -o-pre-wrap;    /* Opera 7 */
            word-wrap: break-word;       /* Internet Explorer 5.5+ */
        }

        .gbFont {
            font-size: 24px;
        }
    }

    button {
        width: 38%;
        font-family: NestleBrush_Reg;
        border: none;
        outline: none;
        padding: 2% 0;
        font-size: 35px;
        border-radius: 25px;
    }

    .btn-group {
        margin: auto;
        width: 38%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .close-btn {
        background: #a4d65d;
    }
    .close-btn:hover {
        background: #729541;
    }
    .close-btn:active {
        background: #392c23;
        color: white;
    }

    .proceed-btn {
        background: #e1261d;
        color: white;
    }
    .proceed-btn:hover {
        background: #9e1a15;
    }
    .proceed-btn:active {
        background: #392c23;
        color: white;
    }

    .gbFont {
        font-size: 24px;
    }

    &.reset-team {
        border-radius: 0.5rem;
        padding: 4rem 2rem 0 2rem;

        .wrapper {
            position: absolute;
            width: 85%;

            pre {
                font-size: 1.5rem;
            }
        }

        .btn-group {
            position: absolute;
            bottom: 4rem;
            left: 0;
            width: 25.2rem;
            height: auto;
            left: 0;

            button {
                font-size: 20px;
                width: 33%;
            }

            .close-btn {
                left: 1%;
            }

            .proceed-btn {
                right: 27%;
            }
        }
    }
}
</style>

<template>
    <template v-if="resetTeamAtTrainer">
        <div v-if="alertOpen" class="PopUp reset-team">
            <div class="wrapper">
                <pre>{{ message }}</pre>
            </div>

            <div class="btn-group">
                <button class="close-btn" @click="close">Cancel</button>
                <button class="proceed-btn" @click="confirm">Confirm</button>
            </div>
        </div>
    </template>

    <template v-else>
        <div v-if="alertOpen" class="PopUp" :class="{atTrainerP0: popUpSize == 'trainerP0', atTrainer: popUpSize == 'trainer'}">
            <div class="wrapper" :class="{wrapperAtTrainerP0: popUpSize == 'trainerP0', wrapperAtTrainer: popUpSize == 'trainer', wrapperManyText: popUpSize == 'gameboardBigText'}">
                <pre :class="{gbFont: popUpSize == 'gameboard' || popUpSize == 'gameboardBigText'}">{{ message }}</pre>
            </div>

            <div class="btn-group" v-if="!disableButtonsView" :class="{gbFont: popUpSize == 'gameboard', gbBigFont: 'gameboardBigText', atTrainerP0: popUpSize == 'trainerP0', atTrainer: popUpSize == 'trainer'}">
                <button class="close-btn" :class="{solo: onlyClose == true, gbFont: popUpSize == 'gameboard'}" @click="close">{{ cancelText }}</button>
                <button v-if="onlyClose == false" class="proceed-btn" :class="{gbFont: popUpSize == 'gameboard'}" @click="confirm">{{ confirmText }}</button>
            </div>
        </div>
    </template>
</template>

<script>
export default {
    props: {
        message:{
            type: String
        },
        onlyClose:{
            type: Boolean
        },
        disableButtonsView: {
            type: Boolean,
            default: false
        },
        alertOpen: {
            type: Boolean
        },
        popUpSize: {
            type: String
        },
        resetTeamAtTrainer: {
            type: Boolean,
            default: false
        },
        confirmText: {
            type: String,
            default: 'Confirm',
        },
        cancelText: {
            type: String,
            default: 'Cancel',
        },
    },
    data() {
        return {

        }
    },
    methods: {
        close() {
            this.$emit("close_alert")
        },

        confirm() {
            this.$emit("confirm_alert")
        }
    }
}
</script>
