<style lang="scss">

.form_panel.Balance {
    /* background: green; */
    right: 7%;
    width: 26%;

    .title {
        padding: 0;
        margin: 0;
        position: absolute;
        left: 6.5%;
        width: 100%;
        color: white;
        font-size: 27px;
    }

    .tabs_group {
        position: absolute;
        /* background: red; */
        width: 13.5%;
        height: 100%;

        .bg {
            position: absolute;
            background: rgb(57, 44, 35, 0.9);
            right: 0;
            width: 84%;
            height: 100%;
            border-radius: 0 0 0 12px;
        }

        .tabs {
            position: absolute;
            background: #63513d;
            width: 100%;
            height: 8.5%;
            top: 0;
            right: 0;
            border-radius: 12px 0 0 12px;

            &.hidden {
                width: 73%;
                height: 6.5%;
                background: #524032;
                font-size: 0.75em;
            }

            &.p3 { top: 10%; }
            &.p4 { top: 20%; }
            &.p5 { top: 30%; }

            button {
                border: none;
                background: none;
                width: 100%;
                height: 100%;
                font-family: NestleBrush_Reg;
                color: white;
                font-size: 1.5em;
            }
        }
    }


    .bs_form {
        position: absolute;
        background: url('../../assets/Gameboard/Panel/panel_balance_sheet.png');
        background-size: 100% 100%;
        width: 88%;
        height: 100%;
        right: 0;

        .BF_Input {
            position: fixed;
            width: 2%;
            height: 2.25%;
            outline: none;
            border: none;
            background: none;
            font-family: NestleBrush_Reg;
            text-align: center;
            font-size: 16px;
            color: white;

            &.Liquid_Assets{ left: 89.7%; top:35.55%; }
            &.Assets_Customer{ left: 83%; top:39.9%; }
            &.Assets_WIP{ left: 83%; top:43.25%; }
            &.Assets_FG{ left: 83%; top:46.45%; }
            &.Total_Assets{ left: 86.5%; top:49.5%; }
            &.Liabilities{ left: 86.5%; top:54.2%; }
            &.Trade_Capital{ left: 89.7%; top:57.5%; }
            &.Current_Assets{ left: 89.7%; top:60.7%; }
            &.Fixed_Assets_Buildings{ left: 86.5%; top:65.55%; }
            &.Fixed_Assets_Equipment{ left: 86.5%; top:68.72%; }
            &.Total_Fixed_Assets{ left: 89.7%; top:71.95%; }
            &.Net_Assets{ left: 89.7%; top:75.2%; color: black; }
            &.Bank_Loans{ left: 89.7%; top:81.4%; }
            &.Share_Capital{ left: 86.5%; top:85.8%; }
            &.Retained_Earnings{ left: 86.5%; top:88.45%; }
            &.Equity{ left: 89.7%; top:91.37%; }
            &.Total_Liability{ left: 89.7%; top:94.15%; color: black; }
        }

        input[type="number"] {
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .answer_check {
            background: url("../../assets/Gameboard/Period/checked_step.png");
            background-size: 100% 100%;
            position: fixed;
            width: 1%;
            height: 2%;
            z-index: 2;

            &.Liquid_Assets{ left: 91.6%; top:35.55%; }
            &.Assets_Customer{ left: 84.85%; top:39.95%; }
            &.Assets_WIP{ left: 84.85%; top:43.25%; }
            &.Assets_FG{ left: 84.85%; top:46.55%; }
            &.Total_Assets{ left: 88.25%; top:49.7%; }
            &.Liabilities{ left: 88.25%; top:54.2%; }
            &.Trade_Capital{ left: 91.6%; top:57.6%; }
            &.Current_Assets{ left: 91.6%; top:60.9%; }
            &.Fixed_Assets_Buildings{ left: 88.25%; top:65.6%; }
            &.Fixed_Assets_Equipment{ left: 88.26%; top:68.9%; }
            &.Total_Fixed_Assets{ left: 91.6%; top:72%; }
            &.Net_Assets{ left: 91.6%; top:75.3%; }
            &.Bank_Loans{ left: 91.6%; top:81.6%; }
            &.Share_Capital{ left: 88.25%; top:85.8%; }
            &.Retained_Earnings{ left: 88.25%; top:88.5%; }
            &.Equity{ left: 91.6%; top:91.4%; }
            &.Total_Liability{ left: 91.6%; top:94.2%; }
        }
    }

    .info_box_btn {
        position: absolute;
        width: 4%;
        height: 3%;
        background: none;
        opacity: 0.5;
        border: none;

        &.Assets_Customer { left: 31.2%; top:17.85%; }
        &.Liabilities{ left: 33.85%; top:38.2%; }
        &.Trade_Capital { left: 23.75%; top: 43%; }
        &.Current_Assets { left: 32.4%; top: 47.4%; }
        &.Net_Assets { left: 41.7%; top: 68.2%; }
        &.Share_Capital { left: 39.2%; top: 83.2%; }
        &.Equity { left: 64.9%; top: 91%; }
        &.Total_Equity { left: 35%; top: 95%;}
    }

    .info_box {
        position: absolute;
        background: #b5dd7d;
        border-radius: 10px;
        width: 70%;
        height: 20%;
        z-index: 5;
        font-family: NestleBrush_Reg;

        &.Assets_Customer { top: 12%; right: 80%; }
        &.Liabilities{ top: 29%; right: 79%; }
        &.Trade_Capital { top: 36%; right: 85%; }
        &.Current_Assets { top: 40%; right: 79%; }
        &.Net_Assets { top: 61%; right: 73%; }
        &.Share_Capital { top: 72%; right: 75%; }
        &.Equity { top: 80%; right: 50%; }
        &.Total_Equity { top: 81%; right: 80%;}

        .wrapper {
            position: absolute;
            top: 5%;
            left: 3%;
            width: 94%;
            height: 88%;
            overflow-y: auto;
            background: #63513d;
            color: white;
            border-radius: 10px;
            text-align: justify;
            padding: 2% 3%;

            pre {
                font-size: 20px;
                padding: 0;
                margin: 0;
                font-family: NestleText;
                white-space: pre-wrap;       /* Since CSS 2.1 */
                white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                white-space: -pre-wrap;      /* Opera 4-6 */
                white-space: -o-pre-wrap;    /* Opera 7 */
                word-wrap: break-word;       /* Internet Explorer 5.5+ */
            }
        }
    }

    .debug {
        position: absolute;
        display: flex;
        flex-direction: column;
        color: black;
        font-weight: bold;
        z-index: 5;

        pointer-events: none;

        button {
            pointer-events: auto;
        }
    }

    .debug > div {
        flex: 1;
    }
}

</style>

<template>
    <div class="form_panel" :class="panelName">

        <div class="tabs_group">
            <div class="bg"></div>

            <template v-for="i in 4" :key="i">
                <div v-if="i > 0 && i <= vPhase.period" class="tabs" :class="{[`p${i + 1}`]: true, hidden: targetPeriod !== i}">
                    <button @click="setPeriod(i)">P{{ (i + 1) }}</button>
                </div>
            </template>
        </div>

        <p class="title">Balance Sheet</p>

        <div class="bs_form">
            <div v-if="vDebug" class="debug">
                <button @click="hackFill">Autofill</button>
                {{ ans.liquidated_assets }} {{ check.liquidated_assets }}
                <br>
                {{ ans.other_assets_due_customer }} {{ check.other_assets_due_customer }}
                <br>
                {{ ans.other_assets_work_in_progress }} {{ check.other_assets_work_in_progress }}
                <br>
                {{ ans.other_assets_finished_goods }} {{ check.other_assets_finished_goods }}
                <br>
                {{ ans.total_current_assets }} {{ check.total_current_assets }}
                <br><br>
                {{ ans.liabilities_owed }} {{ check.liabilities_owed }}
                <br>
                {{ ans.trade_net_working_capital }} {{ check.trade_net_working_capital }}
                <br>
                {{ ans.net_current_assets }} {{ check.net_current_assets }}
                <br><br>
                {{ ans.fixed_land_building }} {{ check.fixed_land_building }}
                <br>
                {{ ans.fixed_production_line }} {{ check.fixed_production_line }}
                <br>
                {{ ans.total_fixed_assets }} {{ check.total_fixed_assets }}
                <br>
                {{ ans.total_net_assets }} {{ check.total_net_assets }}
                <br><br>
                {{ ans.bank_loans }} {{ check.bank_loans }}
                <br>
                {{ ans.share_capital }} {{ check.share_capital }}
                <br>
                {{ ans.retained_earnings }} {{ check.retained_earnings }}
                <br>
                {{ ans.total_equity }} {{ check.total_equity }}
                <br>
                {{ ans.total_liability_shareholder_fund }} {{ check.total_liability_shareholder_fund }}
            </div>

            <template v-if="targetPeriod === vPhase.period && this.vPhase.market_phase >= 2 && this.vPhase.phase_step === 7">

                <template v-for="(className, i) in classNames" :key="i">
                    <div
                        v-if="check[variables[i]]"
                        class="answer_check"
                        :class="className"
                    ></div>
                </template>

            </template>

            <template v-for="(className, i) in classNames" :key="i">

                <input
                    v-model="input[variables[i]]"
                    class="BF_Input"
                    :class="className"
                    type="number"
                    maxlength="3"
                    @change="check_input(variables[i])"
                    :disabled="targetPeriod !== vPhase.period || vPhase.phase_step !== 7"
                    @focus="$event.target.select()"
                >

            </template>

        </div>

        <button class="info_box_btn Assets_Customer" @click="info_box('other_assets_due_customer')"></button>
        <button class="info_box_btn Liabilities" @click="info_box('liabilities_owed')"></button>
        <button class="info_box_btn Trade_Capital" @click="info_box('trade_net_working_capital')"></button>
        <button class="info_box_btn Current_Assets" @click="info_box('net_current_assets')"></button>
        <button class="info_box_btn Net_Assets" @click="info_box('total_net_assets')"></button>
        <button class="info_box_btn Share_Capital" @click="info_box('share_capital')"></button>
        <button class="info_box_btn Equity" @click="info_box('total_equity')"></button>
        <button class="info_box_btn Total_Equity" @click="info_box('total_liability_shareholder_fund')"></button>

        <div v-if="info_box_show.other_assets_due_customer === true" class="info_box Assets_Customer"
            v-on:click="info_box_due_customers">
            <div class="wrapper">
                <pre><b>{{balanceSheetTitle[0]}}</b></pre>
                <pre>{{balanceSheetInfoText[0]}}</pre>
            </div>
        </div>

        <div v-if="info_box_show.liabilities_owed === true" class="info_box Liabilities"
            v-on:click="info_box_liabilities">
            <div class="wrapper">
                <pre><b>{{balanceSheetTitle[1]}}</b></pre>
                <pre>{{balanceSheetInfoText[1]}}</pre>
            </div>
        </div>

        <div v-if="info_box_show.trade_net_working_capital === true" class="info_box Trade_Capital"
            v-on:click="info_box_trade_capital">
            <div class="wrapper">
                <pre><b>{{balanceSheetTitle[2]}}</b></pre>
                <pre>{{balanceSheetInfoText[2]}}</pre>
            </div>
        </div>

        <div v-if="info_box_show.net_current_assets === true" class="info_box Current_Assets"
            v-on:click="info_box_net_assets">
            <div class="wrapper">
                <pre><b>{{balanceSheetTitle[3]}}</b></pre>
                <pre>{{balanceSheetInfoText[3]}}</pre>
            </div>
        </div>

        <div v-if="info_box_show.total_net_assets === true" class="info_box Net_Assets"
            v-on:click="info_box_total_net_assets">
            <div class="wrapper">
                <pre><b>{{balanceSheetTitle[4]}}</b></pre>
                <pre>{{balanceSheetInfoText[4]}}</pre>
            </div>
        </div>

        <div v-if="info_box_show.share_capital === true" class="info_box Share_Capital"
            v-on:click="info_box_share_capital">
            <div class="wrapper">
                <pre><b>{{balanceSheetTitle[5]}}</b></pre>
                <pre>{{balanceSheetInfoText[5]}}</pre>
            </div>
        </div>

        <div v-if="info_box_show.total_equity === true" class="info_box Equity"
            v-on:click="info_box_equity">
            <div class="wrapper">
                <pre><b>{{balanceSheetTitle[6]}}</b></pre>
                <pre>{{balanceSheetInfoText[6]}}</pre>
            </div>
        </div>

        <div v-if="info_box_show.total_liability_shareholder_fund === true" class="info_box Total_Equity"
            v-on:click="info_box_total_equity">
            <div class="wrapper">
                <pre><b>{{balanceSheetTitle[7]}}</b></pre>
                <pre>{{balanceSheetInfoText[7]}}</pre>
            </div>
        </div>

    </div>
</template>

<script>

import { mapGetters } from "vuex";
import { balanceSheetTitle, balanceSheetInfoText } from '@/constants'

export default {
    props: {
        panelName: String,
        closeInfoBox: Boolean
    },
    emits: [
        'closePanel',
    ],
    data() {
        return {
            classNames: [
                'Liquid_Assets',
                'Assets_Customer',
                'Assets_WIP',
                'Assets_FG',
                'Total_Assets',
                'Liabilities',
                'Trade_Capital',
                'Current_Assets',
                'Fixed_Assets_Buildings',
                'Fixed_Assets_Equipment',
                'Total_Fixed_Assets',
                'Net_Assets',
                'Bank_Loans',
                'Share_Capital',
                'Retained_Earnings',
                'Equity',
                'Total_Liability',
            ],
            variables: [
                'liquidated_assets',
                'other_assets_due_customer',
                'other_assets_work_in_progress',
                'other_assets_finished_goods',
                'total_current_assets',
                'liabilities_owed',
                'trade_net_working_capital',
                'net_current_assets',
                'fixed_land_building',
                'fixed_production_line',
                'total_fixed_assets',
                'total_net_assets',
                'bank_loans',
                'share_capital',
                'retained_earnings',
                'total_equity',
                'total_liability_shareholder_fund',
            ],

            autofill: false,
            has_entered: false,
            mapper: {
                cash: 'liquidated_assets',
                due_from_customer: 'other_assets_due_customer',
                wip: 'other_assets_work_in_progress',
                finished_good: 'other_assets_finished_goods',
                total_current_asset: 'total_current_assets',
                owe_to_suppliers: 'liabilities_owed',
                trade_net_working_capital: 'trade_net_working_capital',
                net_current_assets: 'net_current_assets',
                land_n_building: 'fixed_land_building',
                production_line_n_equipment: 'fixed_production_line',
                total_fixed_assets: 'total_fixed_assets',
                net_assets: 'total_net_assets',
                bank_loans: 'bank_loans',
                share_capital: 'share_capital',
                retained_earnings_to_date: 'retained_earnings',
                total_equity: 'total_equity',
                total_liabilities_n_shareholders_funds: 'total_liability_shareholder_fund',
            },

            // Correct Answer
            ans: {
                liquidated_assets: 0,
                other_assets_due_customer: 0,
                other_assets_work_in_progress: 0,
                other_assets_finished_goods: 0,
                total_current_assets: 0,
                liabilities_owed: 0,
                trade_net_working_capital: 0,
                net_current_assets: 0,
                fixed_land_building: 0,
                fixed_production_line: 0,
                total_fixed_assets: 0,
                total_net_assets: 0,
                bank_loans: 0,
                share_capital: 0,
                retained_earnings: 0,
                total_equity: 0,
                total_liability_shareholder_fund: 0,
            },

            // User Input
            input: {
                liquidated_assets: 0,
                other_assets_due_customer: null,
                other_assets_work_in_progress: null,
                other_assets_finished_goods: null,
                total_current_assets: null,
                liabilities_owed: null,
                trade_net_working_capital: null,
                net_current_assets: null,
                fixed_land_building: null,
                fixed_production_line: null,
                total_fixed_assets: null,
                total_net_assets: null,
                bank_loans: null,
                share_capital: null,
                retained_earnings: null,
                total_equity: null,
                total_liability_shareholder_fund: null,
            },
            // backup of user input
            inputBak: {
                liquidated_assets: 0,
                other_assets_due_customer: null,
                other_assets_work_in_progress: null,
                other_assets_finished_goods: null,
                total_current_assets: null,
                liabilities_owed: null,
                trade_net_working_capital: null,
                net_current_assets: null,
                fixed_land_building: null,
                fixed_production_line: null,
                total_fixed_assets: null,
                total_net_assets: null,
                bank_loans: null,
                share_capital: null,
                retained_earnings: null,
                total_equity: null,
                total_liability_shareholder_fund: null,
            },

            // Check, is User Input Match Correct Answer
            check: {
            },

            // the current period
            targetPeriod: null,

            info_box_show: {
                other_assets_due_customer: false,
                liabilities_owed: false,
                trade_net_working_capital: false,
                net_current_assets: false,
                total_net_assets: false,
                share_capital: false,
                total_equity: false,
                total_liability_shareholder_fund: false
            },

            balanceSheetInfoText,
            balanceSheetTitle
        };
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vTeam: 'team',
            vTeamData: 'teamData',
            vMarketingData: 'marketing_data',
            vTeamMarketingCards: 'teamMarketCards',
            vPhase: 'phase',
            vTeamDataByPeriod: 'teamDataByPeriod',
        }),
        balanceSheet() {
            return this.vTeamData.balance_sheet || {};
        },
        submitted() {
            return this.vTeamData.data?.balance_sheet || false;
        },
        balanceSheetData() {
            return this.vTeamData.balance_sheet_data || {};
        },
        checkInfoName() {
            return this.$store.state.whichInfoButton.name
        }
    },
    watch: {
        vPhase(next, prev) {
            if (this.vPhase.market_phase === 2 && this.vPhase.phase_step === 7) {
                this.targetPeriod = this.vPhase.period;
            } else if (this.vPhase.period !== prev.period) {
                this.targetPeriod = this.vPhase.period;
            }
        },
        balanceSheet() {
            this.initializeData();
        },
        balanceSheetData() {
            _.each(this.$data.variables, (key) => {
                _.set(this.$data.input, key, _.get(this.balanceSheetData, key, null));
            });
            this.$data.inputBak = this.$data.input;
            this.check_input();
        },
        targetPeriod(next, prev) {
            if (next !== prev) {
                if (next === this.vPhase.period && this.vPhase.market_phase >= 2 && this.vPhase.phase_step === 7) {
                    // restore input
                    this.input = this.inputBak;
                } else {
                    if (prev === this.vPhase.period && this.vPhase.market_phase >= 2 && this.vPhase.phase_step === 7) {
                        // backup input
                        this.inputBak = this.input;
                    }

                    //
                    this.input = this.getAns(this.getBalanceSheetByPeriod(next));
                }
            }
        },
        checkInfoName: {
            deep: true,
            handler() {
                Object.keys(this.info_box_show).forEach((i) => {
                    if (this.$store.state.whichInfoButton.name != i) {
                        this.info_box_show[i] = false
                    }
                })                
            }
        },
        closeInfoBox : {
            deep: true,
            handler(val) {
                if (!val) {
                    Object.keys(this.info_box_show).forEach((i) => {
                        this.info_box_show[i] = false
                    }) 
                }
            }
        }
    },
    mounted() {
        this.targetPeriod = this.vPhase.period;
        this.initializeData();

    },
    methods: {
        setPeriod(targetPeriod) {
            this.targetPeriod = targetPeriod;
        },
        getBalanceSheetByPeriod(period) {
            return this.vTeamDataByPeriod[period]?.balance_sheet || {};
        },
        getAns(balanceSheet) {
            const ans = {};
            if (_.keys(balanceSheet).length > 0) {
                _.each(this.mapper, (value, key) => {
                    ans[value] = balanceSheet[key];
                });
            }
            return ans;
        },
        initializeData() {
            this.ans = this.getAns(this.balanceSheet);

            if (this.autofill) {
                this.autofillForm();
            }
        },
        autofillForm() {
            _.each(_.keys(this.$data.ans), key => {
                this.$data.input[key] = this.$data.ans[key];
            });
            this.$data.inputBak = this.$data.input;
            this.check_input();
        },
        check_input(variable) {
            _.each(_.keys(this.$data.ans), key => {
                this.$data.check[key] = this.$data.input[key] == this.$data.ans[key];
            });

            if (variable === 'total_liability_shareholder_fund') {
                this.submit();
            }
        },
        info_box(type) {
            this.$store.state.whichInfoButton.name = type

            Object.keys(this.info_box_show).forEach((i) => {
                if (this.$store.state.whichInfoButton.name === i) {
                    this.info_box_show[i] === true ? this.info_box_show[i] = false : this.info_box_show[i] = true
                } else {
                    this.info_box_show[i] = false
                }
            })
        },
        submit() {
            if (this.submitted) return;
            if (this.check.total_liability_shareholder_fund === true && this.vPhase.market_phase >= 2 && this.vPhase.phase_step === 7) {
                Profitability.Participant.submitBalanceSheet({
                    balance_sheet: {
                        ...this.$data.input,
                    },
                    proceed: this.check.total_liability_shareholder_fund,
                });
            }
        },
        hackFill() {
            this.autofillForm();
            this.submit();
        },
    },
}

</script>
