<template>
  <div class="Section_Cash" ref="Cash_Box" :index="13">

    <img src="../../assets/Gameboard/Sections/section_cash.png" class="PlayerBoard_Cash" />

    <input
      :class="{
        frame_border:
          vPhase.period === 0 &&
          (vPhase.phase_step === 1 ||
            vPhase.phase_step === 2 ||
            vPhase.phase_step === 3 ||
            vPhase.phase_step === 5 ||
            vPhase.phase_step === 7),
      }"
      class="input_cash"
      v-model.number="amount"
      placeholder="0"
      maxlength="3"
      @change="withdraw_cash()"
      @focus="$event.target.select()"
    />

    <p class="Coin_Display">{{ cash_stored }}</p>
    <div class="Coin_Box">
      <CoinIndicator :pass_currency_type="1" :pass_cash="cash_stored" />
    </div>

    <div v-if="vDebug" style="position: absolute; font-weight: bold; color: black">
      <div>cash_stored: {{ cash_stored }}</div>
    </div>
    
    <div
      :class="{ frame_border: vPhase.period === 0 && vPhase.phase_step === 6 }"
      class="box"
    ></div>
  
  </div>
</template>

<script>
import CoinIndicator from "../Coin_Indicator.vue";
import { mapGetters } from "vuex";

export default {
  components: { CoinIndicator },
  data() {
    return {
      amount: 0,
      cash_withdrawn: 0,
      cash_put_in: 0,
    };
  },
  computed: {
    ...mapGetters({
      vDebug: "debug",
    }),
    ...mapGetters("profitability/participant", {
      vGameboard: "gameboard",
      vPhase: "phase",
    }),
    cash_stored() {
      return this.vGameboard?.getValue("cash");
    },
    bank_cash() {
      return this.vGameboard?.getValue("bank", "cash");
    },
    bank_standard() {
      return this.vGameboard?.getValue("bank", "standard");
    },
    bank_high() {
      return this.vGameboard?.getValue("bank", "high");
    },
    customers_cash() {
      return this.vGameboard?.getValue("customers", "cash");
    },
    customers_credit() {
      return this.vGameboard?.getValue("customers", "credit");
    },
  },
  methods: {
    withdraw_cash() {
      var cash = parseInt(this.amount);
      this.amount = 0;

      if (cash > 0) {
        if (this.cash_stored >= cash) {
          this.cash_withdrawn = cash;
          this.$emit("withdraw_cash", cash);
          this.update_cash_box();
        } else alert("Amount exceeds available cash");
      }
    },
    insert_cash(cash) {
      if (cash > 0) {
        // console.log("insert_cash = " + cash);
        this.cash_put_in = cash;
        this.update_cash_box();
      }
    },
    update_cash_box() {
      //   console.log("update_cash_box");
      //   console.log("this.bank_cash is " + this.bank_cash);

      if (this.bank_cash > 0) {
        // console.log("Put loan into cash box");

        // make sure the bank loan is drag to cash first then only drag to loans
        Profitability.Participant.bankLoanToCash().then(() => {
          if (this.bank_standard > 0) {
            Profitability.Participant.bankLoanToLoans("standard");
          } else if (this.bank_high > 0) {
            Profitability.Participant.bankLoanToLoans("high");
          }
        });
      }

      if (this.customers_cash > 0) {
        if (this.vPhase.market_phase === 2 && this.vPhase.phase_step === 0) {
          Profitability.Participant.collectDeliveryPayment(
            "cash",
            this.customers_cash
          );
        }
      }

      if (this.cash_withdrawn > 0) {
        this.cash_stored -= this.cash_withdrawn;
        this.cash_withdrawn = 0;
      } else if (this.cash_put_in > 0) {
        this.cash_stored += this.cash_put_in;
        this.cash_put_in = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.Section_Cash {
  top: 76%;
  left: 23.5%;
  position: fixed;
  width: 19.2%;
  height: 21.5%;

  .PlayerBoard_Cash {
    top: 76%;
    left: 23.5%;
    position: fixed;
    width: 19.2%;
    height: 21.5%;
  }

  .input_cash {
    top: 52.8%;
    left: 53.6%;
    position: absolute;
    width: 25%;
    height: 16.4%;
    font-family: NestleBrush_Reg;
    font-size: 24px;

    background: transparent;
    border: none;
    outline: none;
    text-align: center;
    z-index: 2;
  }

  .Coin_Box {
    font-weight: normal;
    position: absolute;
    width: 31%;
  }

  .Coin_Display {
    position: absolute;
    font-size: 2.1rem;
    top: 74.5%;
    right: 64.5%;
    color: white;
  }

  .frame_border {
    border: 5px solid red;
    border-radius: 8px;

    &.box {
      position: absolute;
      width: 100%;
      height: 73%;
    }
  }
}
</style>
