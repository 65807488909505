<style lang="scss">
.Summary_Overview {
    top:7%;
    left: 4.45%;
    position: fixed;
    width: 91.8%;
    height: 83%;
    font-family: NestleBrush_Reg;

    background-image: url("../assets/Marketing/PostMarket/ResultPanelBG.png");
    background-size: 100% 100%;

    &.final {
        background-image: url("../assets/Marketing/PostMarket/p4-5/ResultPanelBG.png");
        background-size: 100% 100%;

    }

    &.lb_active {
        background-image: url("../assets/Marketing/PostMarket/p4-5/ResultPanelBG_02.png");
        background-size: 100% 100%; 
    }

    .white_text { color: white; }
    .black_text { color: black; }

    .tab_data_display {
        width: 100%;
        height: 100%;
        background: url("../assets/Marketing/PostMarket/ResultTab01_TabularData.png");
        background-size: 100% 100%;

        &.final {
           background: url("../assets/Marketing/PostMarket/p4-5/ResultTab01_TabularData.png");
           background-size: 100% 100%; 
        }

        .desc {
            position: absolute;
            text-align: left;
            margin-top: 0.5%;
            left: 1.3%;
            font-size: 2.2rem;
            vertical-align: center;
            width: 35%;
            height: auto;
        }

        .full_row {
            position: absolute;
            left: 2%;
            width: 96%;
            height: 7.5%;
        }
        .full_row.teams { top: 7%; width: 84.4%;}
        .full_row.nns { top: 19% }
        .full_row.og { top: 28%; }
        .full_row.rig { top: 36.5%; }
        .full_row.pricing { top: 45.3%; }
        .full_row.npp { top: 54%; }
        .full_row.npa { top: 62.7%; }
        .full_row.op { top: 71.5%; }
        .full_row.atr { top: 80.2%; }
        .full_row.roic { top: 89%; }

        .tab_wrap {
            position: absolute;
            width: 87.9%;
            height: 100%;
            border-radius: 8px;
        }
        .tab_wrap.brown { background: #63503f; color: white;}

        .input_team {
            position: absolute;
            top: 15%;
            font-size: 2rem;
            border-radius: 15px;
            width: 9.5%;
            height: 70%;
            text-align: center;
            border: 0;
            outline: 0;
            font-family: NestleBrush_Reg;
        }

        .input_team.a { background-color: #59af8b; left: 31%; }
        .input_team.b { background-color: #6591d0; left: 42.5%; }
        .input_team.c { background-color: #fb8c50; left: 54%; }
        .input_team.d { background-color: #9963b5; left: 65.5%; }
        .input_team.e { background-color: #ffd250; left: 77%; }
        .input_team.f { background-color: #d9cdb4; left: 88.5%; }
        .top { font-size: 3rem; height: 100%;}

        .reveal_btn {
            position: absolute;
            border: none;
            background: url("../assets/Marketing/PostMarket/Button_Vis.png");
            background-size: 100% 100%;
            width: 5%;
            height: 100%;
            right: 6%;
        }
        .reveal_btn.revealed {
            background: url("../assets/Marketing/PostMarket/Button_Vis_inactive.png");
            background-size: 100% 100%;
        }

        .highlight_btn {
            position: absolute;
            border: none;
            background: url("../assets/Marketing/PostMarket/Button_HL.png");
            background-size: 100% 100%;
            width: 5%;
            height: 100%;
            right: 0%;
        }
        .highlight_btn.revealed {
            background: url("../assets/Marketing/PostMarket/Button_HL_inactive.png");
        }
    }
    .line_graph_display {
        width: 100%;
        height: 100%;
        background: url("../assets/Marketing/PostMarket/ResultTab02_NNSLineGraph.png");
        background-size: 100% 100%;

        &.final {
            background: url("../assets/Marketing/PostMarket/p4-5/ResultTab02_NNSLineGraph.png");
            background-size: 100% 100%;
        }

        .graph_container {
            position: absolute;
            top: 2.8rem;
            left: 1.3rem;
            width: 92%;
            height: 93%;
            /* background: rgb(42, 50, 122, 0.5); */
        }

        .right_bar {
            position: absolute;
            right: 2.2%;
            top: 18%;
            height: 78%;
            width: 3.6%;
            /* background: rgb(42, 50, 122, 0.5); */

            .box {
                position: absolute;
                width: 100%;
                height: auto;
                padding: 27% 0;
                border-radius: 10px;
                color: white;
                font-size: 2.5vw;
            }
            .box.a { background-color: #59af8b; }
            .box.b { background-color: #6591d0; top: 17%; }
            .box.c { background-color: #fb8c50; top: 34%; }
            .box.d { background-color: #9963b5; top: 51%; }
            .box.e { background-color: #ffd250; top: 68%; color: black; }
            .box.f { background-color: #d9cdb4; top: 85%; color: black; }
        }
    }
    .bar_chart_display {
        width: 100%;
        height: 100%;
        background: url("../assets/Marketing/PostMarket/ResultTab03_ProfitBarChart.png");
        background-size: 100% 100%;

        &.final {
            background: url("../assets/Marketing/PostMarket/p4-5/ResultTab03_ProfitBarChart.png");
            background-size: 100% 100%;
        }

        .bar_container {
            position: absolute;
            top: 10%;
            left: 1.6rem;
            height: 78.25%;
            width: 95%;
            /* background: rgb(42, 50, 122, 0.5); */
        }

        .bar_container.avg {
            width: 92.5%;
        }

        .team_display {
            position: absolute;
            bottom: 3%;
            left: 5.3%;
            width: 84.4%;
            height: 9%;
            /* background: rgb(42, 50, 122, 0.5); */

            .box {
                position: absolute;
                width: 12.1%;
                height: auto;
                padding: 0.3% 0;
                border-radius: 10px;
                color: white;
                font-size: 2.5vw;
            }
            .box.a { background-color: #59af8b; left: 2.3%}
            .box.b { background-color: #6591d0; left: 20.3%; }
            .box.c { background-color: #fb8c50; left: 38.6%; }
            .box.d { background-color: #9963b5; left: 56.5%; }
            .box.e { background-color: #ffd250; left: 74.7%; color: black; }
            .box.f { background-color: #d9cdb4; left: 93.5%; color: black; }
        }
    }
    .invest_display {
        width: 100%;
        height: 100%;
        background: url("../assets/Marketing/PostMarket/ResultTab04_Invest.png");
        background-size: 100% 100%;

        &.final {
            background: url("../assets/Marketing/PostMarket/p4-5/ResultTab04_Invest.png");
            background-size: 100% 100%;
        }

        .full_row {
            position: absolute;
            left: 3.63%;
            width: 94.44%;
            height: 7.95%;
        }
        .full_row.first { top: 18%; }
        .full_row.second { top: 30.8%; }
        .full_row.third { top: 43.6% }
        .full_row.fourth { top: 56.3%; }
        .full_row.fifth { top: 69.1%; }
        .full_row.sixth { top: 81.7%; }

        .tab_wrap {
            position: absolute;
            border-radius: 6px;
            width: 100%;
            height: 100%;

            .position_box {
                position: absolute;
                top: 10%;
                width: 11.5%;
                height: 80%;

                input {
                    font-size: 42px;
                    width: 58%;
                    height: 90%;
                    text-align: center;
                    position: absolute;
                    left: 8%;
                    border: 0;
                    outline: 0;
                    font-family: NestleBrush_Reg;
                    background: none;
                }
            }
            .position_box.investBox { left: 42.3%; color: black; }
            .position_box.roic { left: 66%; }
            .position_box.sa { left: 85.8%; }
        }
        
        .e,.f {
            .position_box input { color: black; }
        }

        .next-btn {
            position: absolute;
            bottom: 1%;
            right: 1%;
            /* width: 10%; */
            padding: 0.3% 3%;
            background: #a3d65d;
            border: none;
            border-radius: 30px;
        }
        .next-btn:hover {
            background: #729541;
        }
        .next-btn:active {
            background: #392c23;
            color: white;
        }
    }

    .leaderboard_display {
        width: 100%;
        height: 100%;
        background: url("../assets/Marketing/PostMarket/p4-5/ResultTab04_Leaderboard01.png");
        background-size: 100% 100%;

        &.final {
            background: url("../assets/Marketing/PostMarket/p4-5/ResultTab04_Leaderboard02.png");
            background-size: 100% 100%;
        }

        .full_row {
            position: absolute;
            /* background: pink; */
            left: 3.63%;
            width: 94.44%;
            height: 7.95%;
        }
        .full_row.team { top: 15.6%; }
        .full_row.cnp { top: 26.6%; }
        .full_row.roic { top: 41.15%; }
        .full_row.total_invest { top: 56% }
        .full_row.cumu_rank { top: 70.7%; }

        .input_team {
            position: absolute;
            top: 15%;
            font-size: 38px;
            border-radius: 12px;
            border: 3px solid black;
            width: 11.1%;
            padding: 2px 0;
            text-align: center;
            outline: 0;
            font-family: NestleBrush_Reg;

            &.noborder {
                border: none;
            }
        }

        .f0 { left: 18.1%; }
        .f1 { left: 30.8%; }
        .f2 { left: 43.62%; }
        .f3 { left: 56.2%; }
        .f4 { left: 69.1%; }
        .f5 { left: 81.75%; }

        .a { background-color: #59af8b;  }
        .b { background-color: #6591d0; }
        .c { background-color: #fb8c50; }
        .d { background-color: #9963b5; }
        .e { background-color: #ffd250; }
        .f { background-color: #d9cdb4; }

        .reveal-btn {
            position: absolute;
            width: 11.1%;
            height: 100%;
            background: none;
            border: none;
        }
    }

    .tab_button {
        position: absolute;
        top: 0;
        background: none;
        border: none;
        width: 31%;
        height: 6%;
        z-index: 3;

        &.tab_data { left: 0.7% }
        &.line_graph { left: 34.5%; }
        &.bar_chart { left: 68.3%; }

        &.final {
            width: 23%;

            &.line_graph { left: 26%; }
            &.bar_chart { left: 51%; }
            &.invest { left: 76%; }
            &.leaderboard { left: 76%; }
        }

    } 
}

</style>

<template>
    <PeriodDisplay :isTrainer="true" />

    <div class="Summary_Overview" :class="[{final: enableInvest}, {lb_active: enableLeaderboard}]">
        <button class="tab_button tab_data" :class="{final: vPhase.period > 2}" @click="current_tab = 'data'"></button>
        <button class="tab_button line_graph" :class="{final: vPhase.period > 2}" @click="current_tab = 'line_graph'"></button>
        <button class="tab_button bar_chart" :class="{final: vPhase.period > 2}" @click="current_tab = 'bar_chart'"></button>
        
        <template v-if="vPhase.period === 3">
            <button class="tab_button leaderboard final" @click="current_tab = 'leaderboard'"></button>
        </template>

        <template v-if="vPhase.period === 4">
            <button v-if="enableInvest" class="tab_button invest final" @click="current_tab = 'invest'"></button>
            <button v-else class="tab_button leaderboard final" @click="current_tab = 'leaderboard'"></button>
        </template>

        <div v-if="current_tab == 'data'" class="tab_data_display" :class="{final: vPhase.period > 2}">
            <div class="full_row teams">
                <input class="input_team a white_text top" maxlength="3" value="A" readonly>
                <input class="input_team b white_text top" maxlength="3" value="B" readonly>
                <input class="input_team c white_text top" maxlength="3" value="C" readonly>
                <input class="input_team d white_text top" maxlength="3" value="D" readonly>
                <input class="input_team e black_text top" maxlength="3" value="E" readonly>
                <input class="input_team f black_text top" maxlength="3" value="F" readonly>
            </div>

            <div class="full_row nns">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.nns == true}">
                    <div v-if="tab_data_highlight.nns == true" class="desc">Net Net Sales</div>

                    <div v-if="tab_data_revealed.nns == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['net_net_sales'], 0)"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.nns == true}" @click="reveal('nns')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.nns == true}" @click="highlight('nns')"></button>
            </div>

            <div class="full_row og">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.og == true}">
                    <div v-if="tab_data_highlight.og == true" class="desc">OG%</div>

                    <div v-if="tab_data_revealed.og == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['og%'], 0) + '%'"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.og == true}" @click="reveal('og')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.og == true}" @click="highlight('og')"></button>
            </div>

            <div class="full_row rig">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.rig == true}">
                    <div v-if="tab_data_highlight.rig == true" class="desc">RIG%</div>

                    <div v-if="tab_data_revealed.rig == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['rig%'], 0) + '%'"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.rig == true}" @click="reveal('rig')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.rig == true}" @click="highlight('rig')"></button>
            </div>

            <div class="full_row pricing">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.pricing == true}">
                    <div v-if="tab_data_highlight.pricing == true" class="desc">Pricing%</div>

                    <div v-if="tab_data_revealed.pricing == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['pricing%'], 0) + '%'"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.pricing == true}" @click="reveal('pricing')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.pricing == true}" @click="highlight('pricing')"></button>
            </div>

            <div class="full_row npa">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.npa == true}">
                    <div v-if="tab_data_highlight.npa == true" class="desc">Net Profit Actual</div>

                    <div v-if="tab_data_revealed.npa == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['net_profit_actual'], 0)"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.npa == true}" @click="reveal('npa')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.npa == true}" @click="highlight('npa')"></button>
            </div>

            <div class="full_row npp">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.npp == true}">
                    <div v-if="tab_data_highlight.npp == true" class="desc">Net Profit Plan</div>

                    <div v-if="tab_data_revealed.npp == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="vPhase.period > 2 ? teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['net_profit_plan'], 0) : ''"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.npp == true}" @click="reveal('npp')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.npp == true}" @click="highlight('npp')"></button>
            </div>

            <div class="full_row op">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.op == true}">
                    <div v-if="tab_data_highlight.op == true" class="desc">Operating Profit (%)</div>

                    <div v-if="tab_data_revealed.op == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['operating_profit%'], 1) + '%'"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.op == true}" @click="reveal('op')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.op == true}" @click="highlight('op')"></button>
            </div>

            <div class="full_row atr">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.atr == true}">
                    <div v-if="tab_data_highlight.atr == true" class="desc">ATR</div>

                    <div v-if="tab_data_revealed.atr == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['atr'], 2)"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.atr == true}" @click="reveal('atr')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.atr == true}" @click="highlight('atr')"></button>
            </div>

            <div class="full_row roic">
                <div class="tab_wrap" :class="{brown: tab_data_highlight.roic == true}">
                    <div v-if="tab_data_highlight.roic == true" class="desc">ROIC</div>

                    <div v-if="tab_data_revealed.roic == true">
                        <template v-for="(team, i) in vTeams" :key="i">
                            <input
                                class="input_team white_text"
                                :class="`${teamClasses[i]}`"
                                maxlength="3"
                                :value="teamSummaryDataDict[team.id] && round(teamSummaryDataDict[team.id]['roic'], 1)"
                                readonly
                            >
                        </template>
                    </div>
                </div>

                <button class="reveal_btn" :class="{revealed: tab_data_reveal_btn.roic == true}" @click="reveal('roic')"></button>
                <button class="highlight_btn" :class="{revealed: tab_data_highlight.roic == true}" @click="highlight('roic')"></button>
            </div>
        </div>

        <div v-if="current_tab == 'line_graph'" class="line_graph_display" :class="{final: vPhase.period > 2}">
            <div class="graph_container">
                <LineChart :data="netNetSalesGraphData" :YAxisMax="highestYAxisValue"/>
            </div>
            <div class="right_bar">
                <div class="box a">A</div>
                <div class="box b">B</div>
                <div class="box c">C</div>
                <div class="box d">D</div>
                <div class="box e">E</div>
                <div class="box f">F</div>
            </div>
        </div>

        <div v-if="current_tab == 'bar_chart'" class="bar_chart_display" :class="{final: vPhase.period > 2}">
            <div class="bar_container">
                <BarChart :data="profitGraphData" :YAxisMax="highestProfit"/>
            </div>
            <div class="team_display">
                <div class="box a">A</div>
                <div class="box b">B</div>
                <div class="box c">C</div>
                <div class="box d">D</div>
                <div class="box e">E</div>
                <div class="box f">F</div>
            </div>
        </div>

        <div v-if="current_tab == 'invest'" class="invest_display" :class="{final: vPhase.period > 2}">

            <template v-for="(team, i) in vTeams" :key="i">
                <div class="full_row" :class="teamClasses2[i]">
                    <div class="tab_wrap" :class="teamClasses[i]">
                        <div class="position_box investBox">
                            <input class="" v-model.number="invest_amt[team.id]">
                        </div>
                        <div class="position_box roic">
                            <input class="white_text" :value="leaderboardData[i].cnp" readonly>
                        </div>
                        <div class="position_box sa">
                            <input class="white_text" :value="leaderboardData[i].roic && round(leaderboardData[i].roic) + '%'" readonly>
                        </div>
                    </div>
                </div>
            </template>

            <button class="next-btn" @click="switch_invest_to_lb">To Leaderboard</button>
        </div>

        <div v-if="current_tab == 'leaderboard'" class="leaderboard_display" :class="{final: vPhase.period === 4}">

            <div class="full_row team">
                <template v-for="(team, i) in leaderboardData" :key="i">
                    <p class="input_team noborder" :class="[`f${i}`, team.team_name, {white_text: i < 5}, {black_text: i >= 5}]"
                    >{{ team.team_name }}</p>
                </template>
            </div>

            <div class="full_row cnp">
                <template v-for="(team, i) in leaderboardData" :key="i">
                    <input v-if="lead_rvl.cnp[i]"
                        class="input_team white_text"
                        :class="[`f${i}`, team.team_name]"
                        maxlength="3"
                        :value="leaderboardData[i].cnp"
                        readonly
                    >
                    
                    <button class="reveal-btn" :class="[`f${i}`, team.team_name]" @click="lead_rvl.cnp[i] = true"></button>
                </template>
            </div>

            <div class="full_row roic">
                <template v-for="(team, i) in leaderboardData" :key="i">
                    <input v-if="lead_rvl.roic[i]"
                        class="input_team white_text"
                        :class="[`f${i}`, team.team_name]"
                        maxlength="3"
                        :value="leaderboardData[i].roic % 1 === 0? leaderboardData[i].roic + '%' : leaderboardData[i].roic.toFixed(2) + '%'"
                        readonly
                    >
                    
                    <button class="reveal-btn" :class="[`f${i}`, team.team_name]" @click="lead_rvl.roic[i] = true"></button>
                </template>
            </div>

            <div v-if="vPhase.period === 4" class="full_row total_invest">
                <template v-for="(team, i) in leaderboardData" :key="i">
                    <input v-if="lead_rvl.total_invest[i]"
                        class="input_team white_text"
                        :class="[`f${i}`, team.team_name]"
                        maxlength="3"
                        :value="leaderboardData[i].invest"
                        readonly
                    >
                    
                    <button class="reveal-btn" :class="[`f${i}`, team.team_name]" @click="lead_rvl.total_invest[i] = true"></button>
                </template>
            </div>

            <div v-if="vPhase.period === 4" class="full_row cumu_rank">
                <template v-for="(team, i) in leaderboardData" :key="i">
                    <input v-if="lead_rvl.cumu_rank[i]"
                        class="input_team white_text"
                        :class="[`f${i}`, team.team_name]"
                        maxlength="3"
                        :value="leaderboardData[i].final_p"
                        readonly
                    >
                    
                    <button class="reveal-btn" :class="[`f${i}`, team.team_name]" @click="lead_rvl.cumu_rank[i] = true"></button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import PeriodDisplay from "../components/Period/PeriodDisplay.vue"
import LineChart from "../components/Charts/LineChart.vue"
import BarChart from "../components/Charts/BarChart.vue"
import { mapGetters } from 'vuex';

export default {
    components: { PeriodDisplay, LineChart, BarChart },
    props: {
        vPhase: {},
        vTeams: {},
        vAllTeamSummaries: {},
    },
    data() {
        const teamSummaryDataDict = _.reduce(this.vAllTeamSummaries, (result, value, key) => {
            result[value.team_id] = value.summary;
            return result;
        }, {});
        const teamDataDict = _.reduce(this.vAllTeamSummaries, (result, value, key) => {
            result[value.team_id] = value.team_data;
            return result;
        }, {});

        return {
            current_tab: 'data',
            tab_data_revealed: {
                nns: false,
                og: false,
                rig: false,
                pricing: false,
                npp: false,
                npa: false,
                op: false,
                atr: false,
                roic: false
            },
            tab_data_highlight: {
                nns: false,
                og: false,
                rig: false,
                pricing: false,
                npp: false,
                npa: false,
                op: false,
                atr: false,
                roic: false
            },
            tab_data_reveal_btn: {
                nns: false,
                og: false,
                rig: false,
                pricing: false,
                npp: false,
                npa: false,
                atr: false,
                op: false,
                roic: false
            },
            lead_rvl: {
                cnp: [false, false, false, false, false, false],
                roic: [false, false, false, false, false, false],
                total_invest: [false, false, false, false, false, false],
                cumu_rank: [false, false, false, false, false, false]
            },
            invest_amt: {},
            lbData: {},

            // constants
            teamClasses: [
                'a', 'b', 'c', 'd', 'e', 'f',
            ],
            teamClasses2: [
                'first',
                'second',
                'third',
                'fourth',
                'fifth',
                'sixth',
            ],
            ordinal: [
                'st',
                'nd',
                'rd',
                'th',
                'th',
                'th',
            ],

            teamDataDict,
            teamSummaryDataDict,

            highestVal: 0,
            highestYAxisValue: 0,
            highestProfit: 0,

            // netNetSalesData format
            // netNetSalesData: [{
            //     team_id: 0,
            //     data: [1, 2, 3, 4, 5,],     // per period
            // }],

            // profitData format
            // profitData: [{
            //     team_id: 0,
            //     data: [1, 2, 3, 4, 5,],     // per period
            // }],

            // leaderboardData format
            // leaderboardData: _.times(6, (i) => ({
            //     team_id: i,
            //     cnp: 26 - i,
            //     cnp_placement: i,
            //     roic: (26 - i) + '%',
            //     roic_placement: i,
            //     sa: 26 - i,
            //     sa_placement: i,
            // })),
        }
    },
    mounted(){
        Profitability.Trainer.getLeaderboardData().then((res) => {
            this.lbData = res.leaderboard_data
        })

        this.netNetSalesData.forEach(nns => {
            if (nns.team_id) {
                nns.data.forEach(s => {
                    if (s > this.highestVal) {
                        this.highestVal = s
                    }
                })
            }
        })

        let length = this.highestVal.toString().length
        let lastNumber = Number(this.highestVal.toString()[length - 1])
        Number(this.highestVal.toString()[length - 1]) == 0 ? this.highestYAxisValue = this.highestVal : this.highestYAxisValue = this.highestVal + 10 - lastNumber
    
        this.profitGraphData.forEach(p => {
            if (p > this.highestProfit) {
                this.highestProfit = p
            }
        })

    },
    computed: {
        ...mapGetters('profitability/trainer', {
            vTrainingSessionData: 'trainingSessionData',
            vPhase: 'phase',
            vTeams: 'teams',
            vAllTeamSummaries: 'allTeamSummaries',
        }),
        netNetSalesData() {
            const allTeams = _.map(this.vTeams, (team) => {
                return {
                    team_id: team.id,
                    data: this.teamDataDict[team.id].net_net_sales || _.times(this.vPhase.period + 1, () => 0),
                };
            });
            if (allTeams.length !== 6) {
                _.times(6 - allTeams.length, i => {
                    allTeams.push({
                        data: _.times(this.vPhase.period + 1, () => 0),
                    });
                });
            }
            return allTeams;
        },
        netNetSalesGraphData() {
            return _.map(this.netNetSalesData, nns => {
                if (!nns || nns.length <= 1) {
                    return [];
                }
                const [a, ...data] = nns.data;
                return data;
            });
        },
        profitData() {
            const allTeams = _.map(this.vTeams, (team) => {
                return {
                    team_id: team.id,
                    data: this.teamDataDict[team.id].profit || _.times(this.vPhase.period + 1, () => 0),
                };
            });
            if (allTeams.length !== 6) {
                _.times(6 - allTeams.length, i => {
                    allTeams.push({
                        data: _.times(this.vPhase.period + 1, () => 0),
                    });
                });
            }
            
            return allTeams;
        },
        profitGraphData() {
            return _.map(this.profitData, teamData => {
                // -1 for period to reduce the first period as data do not contains first period
                return teamData.data && teamData.data[this.vPhase.period];
            });
        },
        leaderboard() {
            return Profitability.Trainer.getLeaderboardData().then((res) => {
                this.lbData = res
            })
        },
        leaderboardData() {
            let allTeams = _.map(this.vTeams, (team, i) => {
                return {
                    team_id: this.lbData[i].team_id,
                    team_name: team.name.toLowerCase(),
                    cnp: this.lbData[i].cumulative_net_profit,
                    roic: this.lbData[i].roic,
                    cnp_p: this.lbData[i].cumulative_net_profit_placement,
                    roic_p: this.lbData[i].roic_placement,
                    final_score: this.lbData[i].final_position_score,
                    final_p: this.lbData[i].final_position_score_placement,
                    invest: this.lbData[i].invest,
                    invest_p: this.lbData[i].invest_placement
                };
            });

            let sortTeam = allTeams.slice(0)
            sortTeam.sort(function(a, b) {
                return a.final_p - b.final_p
            })

            return sortTeam;
        },
        team_invest_data() {
            return this.vTrainingSessionData.team_invest_data;
        },
        alreadySubmitTeamInvestData() {
            return this.team_invest_data !== null;
        },
        enableLeaderboard() {
            if (this.vPhase.period === 3) {
                return true
            } else if (this.vPhase.period === 4) {
                return this.alreadySubmitTeamInvestData ? true : false
            }

            return false
        },
        enableInvest() {
            if (this.vPhase.period === 4) {
                return this.alreadySubmitTeamInvestData ? false : true
            }
        }
    },
    methods: {
        times: _.times,
        round: _.round,
        reveal(type) {
            this.tab_data_revealed[type] = true ? this.tab_data_revealed[type] == false : true
            this.tab_data_reveal_btn[type] = true ? this.tab_data_reveal_btn[type] == false : true
        },
        highlight(type) {
            if (this.tab_data_revealed[type] == true) {
                this.tab_data_highlight[type] = true ? this.tab_data_highlight[type] == false : true;
            }
        },

        Reveal_LB(n) {
            this.leaderboard_reveals[n] = !this.leaderboard_reveals[n];
        },

        switch_invest_to_lb() {
            return Profitability.Trainer.submitTeamInvest(this.invest_amt).then(() => {
                this.current_tab = 'leaderboard'
                
                Profitability.Trainer.getLeaderboardData().then((res) => {
                    this.lbData = res.leaderboard_data
                })
            }, e => {
                console.log(e.error)
            });
        },  
    }
}
</script>
