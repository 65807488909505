<template>
    <div class="GameboardElement_Container" :style="cssProps">

        <!-- Gameboard -->
        <div v-if="vPhase.market_phase == 0 || vPhase.market_phase == 2">

            <div class="Panel_PlayerBoard">
                <h1>Team {{ team.name }}</h1>
                
                <div class="Panel_Land">
                    <CoinIndicator :pass_currency_type="2" :pass_cash="getValue('land_n_building')" :is_trainer_view="true" />
                </div>

                <div class="Panel_Ext">
                    <div class="suppliers">
                        <CoinIndicator :pass_currency_type="9" :pass_cash="getValue('suppliers', 'owe') - getValue('suppliers', 'paid')" :is_trainer_view="true" />
                    </div>
                </div>

                <div class="Panel_Receivables"></div>

                <div class="Plot_Bg">
                    <div v-for="(fac, i) in factories" :key="i">
                        <div class="Panel_Plot" :class="`p${i}`">
                            <div v-if="fac.type === 'warehouse'">
                                <img src="../../assets/Gameboard/Sections/section_warehouse.png" class="PlayerBoard_Plot">
                                <div class="Warehouse_Units">
                                    <div v-for="(slot, i) in (fac.slots || [])" :key="i">
                                        <div v-if="slot.type === 'classic'" class="coin_align" :class="String.fromCharCode(97 + i)">
                                            <CoinIndicator :pass_currency_type="3" :pass_cash="4" />
                                        </div>
                                        <div v-else-if="slot.type === 'gold'" class="coin_align" :class="String.fromCharCode(97 + i)">
                                            <CoinIndicator :pass_currency_type="4" :pass_cash="7" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="fac.type === 'classic'">
                                <img src="../../assets/Gameboard/Sections/section_nescafe_classic.png" class="PlayerBoard_Plot">
                                <div class="Production_Line_Box">
                                    <CoinIndicator :pass_currency_type="2" :pass_cash="fac.production_line" />
                                    <button v-if="requireDepreciate" class="depreciate_btn" @click="get_cc">Depreciate</button>
                                </div>
                                <div class="Work_In_Progress_Box">
                                    <CoinIndicator :pass_currency_type="3" ref="WIP_Classic" :pass_cash= fac.wip />
                                </div>
                                <div class="Line_Units">
                                    <div v-for="(slot, i) in (fac.slots || [])" :key="i">
                                        <div v-if="slot.subtype === 'raw'">
                                            <div v-if="slot.type === 'classic'" class="coin_align" :class="String.fromCharCode(97 + i)">
                                                <CoinIndicator :pass_currency_type="3" :pass_cash="2" />
                                            </div>
                                        </div>

                                        <div v-if="slot.subtype === 'co_pack'">
                                            <div v-if="slot.type === 'classic'" class="coin_align" :class="String.fromCharCode(97 + i)">
                                                <CoinIndicator :pass_currency_type="3" :pass_cash="4" />
                                            </div>
                                            <div v-if="slot.type === 'gold'" class="coin_align" :class="String.fromCharCode(97 + i)">
                                                <CoinIndicator :pass_currency_type="4" :pass_cash="7" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="fac.type === 'gold'">
                                <img src="../../assets/Gameboard/Sections/section_nescafe_gold.png" class="PlayerBoard_Plot">
                                <div class="Production_Line_Box">
                                    <CoinIndicator :pass_currency_type="2" :pass_cash="fac.production_line" />
                                    <button v-if="requireDepreciate" class="depreciate_btn">Depreciate</button>
                                </div>
                                <div class="Work_In_Progress_Box">
                                    <CoinIndicator :pass_currency_type="4" ref="WIP_Gold" :pass_cash= fac.wip />
                                </div>
                                <div class="Line_Units">
                                    <div v-for="(slot, i) in (fac.slots || [])" :key="i">
                                        <div v-if="slot.subtype === 'raw'">
                                            <div v-if="slot.type === 'gold'" class="coin_align" :class="String.fromCharCode(97 + i)">
                                                <CoinIndicator :pass_currency_type="4" :pass_cash="4" />
                                            </div>
                                        </div>

                                        <div v-if="slot.subtype === 'co_pack'">
                                            <div v-if="slot.type === 'classic'" class="coin_align" :class="String.fromCharCode(97 + i)">
                                                <CoinIndicator :pass_currency_type="3" :pass_cash="4" />
                                            </div>
                                            <div v-if="slot.type === 'gold'" class="coin_align" :class="String.fromCharCode(97 + i)">
                                                <CoinIndicator :pass_currency_type="4" :pass_cash="7" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Panel_Expenses">
                    <div class="pfme">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="getValue('pfme', 'paid')" :is_trainer_view="true" />
                    </div>
                    <div class="rnd">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="getValue('rnd', 'paid')" :is_trainer_view="true" />
                    </div>
                    <div class="deprec">
                        <CoinIndicator :pass_currency_type="2" :pass_cash="getValue('depreciation')" :is_trainer_view="true" />
                    </div>
                    <div class="ffoh">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="getValue('ffoh', 'paid')" :is_trainer_view="true" />
                    </div>
                    <div class="moge">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="getValue('moge', 'paid')" :is_trainer_view="true" />
                    </div>
                    <div class="interest">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="getValue('interest', 'paid')" :is_trainer_view="true" />
                    </div>
                    <div class="tax">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="getValue('tax', 'paid')" :is_trainer_view="true" />
                    </div>
                    <div class="div">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="getValue('dividends', 'paid')" :is_trainer_view="true" />
                    </div>
                </div>

                <div class="Panel_Cash">
                    <div class="resize">
                        <CoinIndicator :pass_currency_type="1" :pass_cash="getValue('cash')" :is_trainer_view="true"/>
                    </div>
                </div>
                <div class="Panel_Payables">
                    <CoinIndicator :pass_currency_type="9" :pass_cash="getValue('payables', 'owe')" :is_trainer_view="true"/>
                </div>

                <div v-if="getValue('loans', 'standard') > 0" class="Panel_Loans with_high">
                    <div class="resize_standard">
                        <CoinIndicator :pass_currency_type="9" :pass_cash="getValue('loans', 'standard')" :is_trainer_view="true" :pass_debt_offset="true"/>
                    </div>
                    <div class="resize_high">
                        <CoinIndicator :pass_currency_type="9" :pass_cash="getValue('loans', 'high')" :is_trainer_view="true" :pass_debt_offset="true"/>
                    </div>
                </div>

                <div v-else class="Panel_Loans">
                    <div class="resize">
                        <CoinIndicator :pass_currency_type="9" :pass_cash="getValue('loans', 'standard')" :is_trainer_view="true"/>
                    </div>
                </div>

            </div>

            <div class="Side_Buttons">
                <li v-for="index in 2" :key="index">
                    <SideButtons ref="Sidepanel_list[index].name" :pass_button_name="Sidepanel_list[index].name"
                        :pass_position="Sidepanel_list[index].position" :pass_icon="Sidepanel_list[index].icon"
                        @select_side_panel="ReturnPanelClicked" @return_side_button="UpdateSideButtonRef" />
                </li>

                <li v-for="index in 2" :key="index">
                    <SideButtons ref="Sidepanel_list[index+2].name" 
                        :pass_button_name="Sidepanel_list[index+2].name" :pass_button_name_2="Sidepanel_list[index+2].name2"
                        :pass_position="Sidepanel_list[index+2].position" :pass_icon="Sidepanel_list[index+2].icon"
                        @select_side_panel="ReturnPanelClicked" @return_side_button="UpdateSideButtonRef" :noIcon="true"/>
                </li>

                <li v-for="index in 3" :key="index">
                    <SideButtons ref="Sidepanel_list[index+5].name" :pass_button_name="Sidepanel_list[index+5].name"
                        :pass_position="Sidepanel_list[index+5].position" :pass_icon="Sidepanel_list[index+5].icon"
                        @select_side_panel="ReturnPanelClicked" @return_side_button="UpdateSideButtonRef" />
                </li>
            </div>
            
        </div>

        <div v-if="vPhase.period !== 0 && vPhase.market_phase == 0" class="Panel_PeriodBoard">
            <h1 class="Period_Statement">Period {{vPhase.period + 1}} | Pre-Market</h1>
            <PeriodOverview v-if="vPhase.period < 3"
                :step="phase_step"
                :team="team"
                :isAtGBE="true"
            />
            <PeriodOverview v-else
                :step="phase_step + 1"
                :team="team"
                :isAtGBE="true"
            />
        </div>

        <div v-else-if="vPhase.period !== 0 && vPhase.market_phase == 2" class="Panel_PeriodBoard">
            <h1 class="Period_Statement">Period {{vPhase.period + 1}} | Post-Market</h1>
            <PeriodOverview 
                :step="phase_step"
                :team="team"
                :isAtGBE="true"
            />
        </div>

        <!-- Phase 1: Tutorial -->
        <div v-if="vPhase.period == 0">
            <MBS />
        </div>

    </div>
</template>

<script>
import Background from '@/components/Background.vue'
import SideButtons from '@/components/Gameboard_SideButtons.vue'
import SidePanel from '@/components/Gameboard_SidePanels_2';
import PeriodOverview from '@/components/Period/PeriodOverview.vue'
import MBS from '@/components/SidePanel/Gameboard_MBS.vue'
import PA from '@/components/SidePanel/Gameboard_PA.vue'
import OperationalPlan from '@/components/SidePanel/Gameboard_Operational_Plan.vue'
import CashFlow from '@/components/SidePanel/Gameboard_Cash_Flow.vue'

import SectionCash from '@/components/Section/Section_Cash.vue'
import SectionExpenses from '@/components/Section/Section_Expenses.vue'
import SectionExternalLocations from '@/components/Section/Section_External_Locations.vue'
import SectionLand from '@/components/Section/Section_Land.vue'
import SectionLoans from '@/components/Section/Section_Loans.vue'
import SectionPayables from '@/components/Section/Section_Payables.vue'
import SectionPlot from '@/components/Section/Section_Plot.vue'
import SectionReceivable from '@/components/Section/Section_Receivable.vue'
import CoinIndicator from '@/components/Coin_Indicator.vue'
import { preMarket, postMarket } from '@/constants';
import { mapGetters } from 'vuex';

const debugActions = [
    preMarket,
    [],
    postMarket,
];

export default {
    props: {
        team_id: Number,
        vPhase: Object,
    },
    components: {
        Background, SideButtons, SidePanel, PeriodOverview, CoinIndicator,
        SectionCash, SectionExpenses, SectionExternalLocations, SectionLand,
        SectionLoans, SectionPayables, SectionPlot, SectionReceivable,
        MBS, PA, OperationalPlan, CashFlow
    },
    mounted() {
    },
    data() {
        return {
            Sidepanel_list: [
                { name: "Rules", position: 25, icon: "rules", button: null, associated_panel: null },
                { name: "Marketing", position: 33, icon: "marketing_plan", button: null, associated_panel: null },
                { name: "Delivery", position: 41, icon: "delivery_record", button: null, associated_panel: null },
                { name: "Income", name2: "Statement", position: 49, icon: "is", button: null, associated_panel: null },
                { name: "Balance", name2: "Sheet", position: 57, icon: "balance_statement", button: null, associated_panel: null },
                { name: "Records", position: 65, icon: "records", button: null, associated_panel: null },
                { name: "Performance", position: 73, icon: "performance", button: null, associated_panel: null },
                { name: "Forecast", position: 81, icon: "forecast", button: null, associated_panel: null },
                { name: "Planning", position: 89, icon: "planning", button: null, associated_panel: null },
                { name: "Supplier_Pre", position: 97, icon: "", button: null, associated_panel: null },
                { name: "Supplier_Post", position: 105, icon: "", button: null, associated_panel: null },
                { name: "Bank", position: 0, icon: "", button: null, associated_panel: null }],

            marketPlanningCounter: 1,

            debugActions,

            operationalPlan: {},
            cashflowForecast: {},
        }
    },
    computed: {
        ...mapGetters('profitability/trainer', {
            vTeamDict: 'teamDict',
            vTeams: 'teams',
        }),
        team() {
            return this.vTeamDict[this.team_id]
        },
        gameboard() {
            return this.team.gameboard;
        },
        factories() {
            return Profitability.Models.Factory.query().with('slots').where('team_id', this.team_id).get() || [];
        },
        phase_step() {
            return this.team.team_data.phase_step;
        }
    },
    methods: {
        getValue(column, type = '*') {
            let value = 0;
            const items = this.gameboard[column];
            if (items && items.length > 0) {
                let filteredItems = [];
                if (type === '*') {
                filteredItems = items;
                } else if (type != null) {
                filteredItems = _.filter(items, v => v.type === type);
                } else {
                filteredItems = _.filter(items, v => v.type == null);
                }
                value = _.sumBy(filteredItems, 'value');
            }
            return value;
        },
    }
}
</script>

<style lang="scss">
.GameboardElement_Container {
    width: 100%;
    height: 100%;
    background: url("../../assets/background.jpg");
    background-size: cover;

    .btnActivity {
        border-radius: 8px 0 0 8px;
    }
    .btnActivity > p {
        font-size: 13.5px;
    }
    .btnActivity:hover {
        background: #b2b2b2;
    }
    .btnActivity:active {
        background: #392c23;
        width: 7%;
        color: white;
    }
    .btnActivity:active > p {
        color: white;
    }
    
    .Panel_PlayerBoard {
        top: 3%;
        left: 6%;
        position: absolute;
        width: 59.5%;
        height: 95%;
    
        background-image: url('../../assets/Gameboard/Panel/panel_gameboard.png');
        background-position: center;
        background-size: 100% 100%;
    
        z-index: 1;

        .Panel_Land {
            top: 8%;
            left: 2%;
            position: absolute;
            width: 12%;
            height: 16%;
            background: url('../../assets/Gameboard/Sections/section_land_buildings.png');
            background-size: 100% 100%;
        }

        .Panel_Ext {
            top: 25%;
            left: 2%;
            position: absolute;
            width: 11%;
            height: 52%;
            background: url('../../assets/Gameboard/Sections/section_external_locations.png');
            background-size: 100% 100%;

            .suppliers {
                position: absolute;
                top: -5%;
                left: 5%;
                width: 112%;
                height: 20%;
            }
        }

        .Panel_Receivables {
            top: 78%;
            left: 2%;
            position: absolute;
            width: 27%;
            height: 20%;
            background: url('../../assets/Gameboard/Sections/section_receivable.png');
            background-size: 100% 100%;
        }

        .Plot_Bg {
            position: absolute;
            top: 8%;
            left: 15%;
            width: 60%;
            height: 69.3%;
            background: url('../../assets/Gameboard/Panel/panel_plot_highlight.png');
            background-size: 100% 100%;

            .Panel_Plot {
                position: absolute;
                width: 49.5%;
                height: 49.5%;
                background: url('../../assets/Gameboard/Sections/section_buy_plot.png');
                background-size: 100% 100%;

                &.p0 { left: 0.5%; top: 0.5%; }
                &.p1 { left: 50%; top: 0.5%; }
                &.p2 { left: 0.5%; top: 50%; }
                &.p3 { left: 50%; top: 50%; }
                
                .Warehouse_Units {
                    top: 14%;
                    left: 0.4%;
                }

                .Production_Line_Box {
                    top: 0%;
                    left: 54%;
                }

                .Work_In_Progress_Box {
                    top: 42%;
                    left: 59.3%;
                }

                .Line_Units {
                    top: 5%;
                    left: -2.9%;
                }
            }
        }

        .Panel_Cash {
            top: 78%;
            left: 29%;
            position: absolute;
            width: 32%;
            height: 21%;
            background: url('../../assets/Gameboard/Sections/section_cash.png');
            background-size: 100% 100%;

            .resize {
                position: absolute;
                top: -10%;
                width: 35%;
                height: 70%;
            }
        }

        .Panel_Payables {
            top: 78%;
            left: 62%;
            position: absolute;
            width: 14%;
            height: 21%;
            background: url('../../assets/Gameboard/Sections/section_payables.png');
            background-size: 100% 100%;
        }

        .Panel_Expenses {
            top: 8%;
            left: 77%;
            position: absolute;
            width: 22%;
            height: 69.3%;
            background: url('../../assets/Gameboard/Sections/section_expenses.png');
            background-size: 100% 100%;

            .pfme {
                position: absolute;
                left: 3%;
                width: 43.5%;
                height: 20%;
            }
            .rnd {
                position: absolute;
                left: 51%;
                width: 43.5%;
                height: 20%;
            }
            .deprec {
                position: absolute;
                top: 23%;
                left: 3%;
                width: 43.5%;
                height: 20%;
            }
            .ffoh {
                position: absolute;
                top: 23%;
                left: 51%;
                width: 43.5%;
                height: 20%;
            }
            .moge {
                position: absolute;
                top: 46%;
                left: 3%;
                width: 43.5%;
                height: 20%;
            }
            .interest {
                position: absolute;
                top: 46%;
                left: 51%;
                width: 43.5%;
                height: 20%;
            }
            .tax {
                position: absolute;
                top: 69%;
                left: 3%;
                width: 43.5%;
                height: 20%;
            }
            .div {
                position: absolute;
                top: 69%;
                left: 51%;
                width: 43.5%;
                height: 20%;
            }
        }

        .Panel_Loans {
            top: 78%;
            left: 77%;
            position: absolute;
            width: 21%;
            height: 21%;
            background: url('../../assets/Gameboard/Sections/Gameboard_Loansbox.png');
            background-size: 100% 100%;

            &.with_high {
                background: url('../../assets/Gameboard/Sections/section_loans.png');
                background-size: 100% 100%;
            }

            .resize {
               position: absolute;
               width: 55%;
               height: 70%;
            }

            .resize_standard {
                position: absolute;
                left: -3%;
                width: 55%;
                height: 70%;
            }

            .resize_high {
                position: absolute;
                left: 46%;
                width: 55%;
                height: 70%;
            }
        }
    }
    
    .Panel_PeriodBoard {
        top: 0%;
        right: 0%;
        position: absolute;
        width: 31%;
        height: 24.5%;
    
        background-image: url('../../assets/Gameboard/Panel/panel_market_plan.png');
        background-position: center;
        background-size: 100% 100%;
    
        z-index: 1;
    }
    
    .Panel_PlayerBoard h1 {
        left: 3%;
        top: 0.5%;
        position: absolute;
        color: white;
        font-size: 36px;
    }
    
    .Panel_PeriodBoard h1 {
        left: 3%;
        top: 0;
        position: absolute;
        width: 92%;
        text-align: center;
        color: white;
        font-size: 26px;
    }
}

</style>
