<style lang="scss">
.Summary_Overview_Participants {
    position: fixed;
    top: 7%;
    left: 4.45%;
    width: 91.8%;
    height: 83%;
    
    background-image: url("../assets/Marketing/Panel_Market.png");
    background-size: 100% 100%;
    
    p {
        margin-top: 20%;
        font-family: NestleBrush_Reg;
        font-size: 48px;
        color: white;
    }
}

</style>

<template>
    <PeriodDisplay />

    <div class="Side_Controller">
        <div class="Side_Buttons">
            <li>
                <a href="/Rules" target="_blank">
                <SideButtons class="btnActivity" :pass_button_name="Sidepanel_list[0].name"
                            :pass_position="Sidepanel_list[0].position" :pass_icon="Sidepanel_list[0].icon" />
                </a>
            </li>
            <li v-for="index in 2" :key="index">
                <SideButtons ref="Sidepanel_list[index].name" :pass_button_name="Sidepanel_list[index].name"
                            :pass_position="Sidepanel_list[index].position" :pass_icon="Sidepanel_list[index].icon"
                            @select_side_panel="ReturnPanelClicked" @return_side_button="UpdateSideButtonRef" />
            </li>

            <li v-for="index in 2" :key="index">
                <SideButtons ref="Sidepanel_list[index+2].name" 
                            :pass_button_name="Sidepanel_list[index+2].name" :pass_button_name_2="Sidepanel_list[index+2].name2"
                            :pass_position="Sidepanel_list[index+2].position" :pass_icon="Sidepanel_list[index+2].icon"
                            @select_side_panel="ReturnPanelClicked" @return_side_button="UpdateSideButtonRef" :noIcon="true"/>
            </li>

            <li v-for="index in 3" :key="index">
                <SideButtons ref="Sidepanel_list[index+5].name" :pass_button_name="Sidepanel_list[index+5].name"
                            :pass_position="Sidepanel_list[index+5].position" :pass_icon="Sidepanel_list[index+5].icon"
                            @select_side_panel="ReturnPanelClicked" @return_side_button="UpdateSideButtonRef" />
            </li>
        </div>
        <SidePanel 
            @return_side_panel="UpdateSidePanelRef"
            @close_side_panel="ReturnPanelClicked" 
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PeriodDisplay from "../components/Period/PeriodDisplay.vue"
import SideButtons from '../components/Gameboard_SideButtons.vue'
import SidePanel from '../components/Gameboard_SidePanels_2';

export default {
    components: { PeriodDisplay, SideButtons, SidePanel },
    data() {
        return {
            Sidepanel_list: [
                { name: "Rules", position: 25, icon: "rules", button: null, associated_panel: null },
                { name: "Marketing", position: 33, icon: "marketing_plan", button: null, associated_panel: null },
                { name: "Delivery", position: 41, icon: "delivery_record", button: null, associated_panel: null },
                { name: "Income", name2: "Statement", position: 49, icon: "is", button: null, associated_panel: null },
                { name: "Balance", name2: "Sheet", position: 57, icon: "balance_statement", button: null, associated_panel: null },
                { name: "Records", position: 65, icon: "records", button: null, associated_panel: null },
                { name: "Performance", position: 73, icon: "performance", button: null, associated_panel: null },
                { name: "Forecast", position: 81, icon: "forecast", button: null, associated_panel: null },
                { name: "Planning", position: 89, icon: "planning", button: null, associated_panel: null },
                { name: "Supplier_Pre", position: 97, icon: "", button: null, associated_panel: null },
                { name: "Supplier_Post", position: 105, icon: "", button: null, associated_panel: null },
                { name: "Bank", position: 0, icon: "", button: null, associated_panel: null }
            ],
        }
    },
    computed: {
        ...mapGetters('profitability/participant', {
            vPhase: 'phase',
        }),
    },
    methods: {
        ReturnPanelClicked(name) {
            for (let i = 0; i < this.Sidepanel_list.length; i++) {
                if (this.Sidepanel_list[i].button != null)
                    this.Sidepanel_list[i].button.Signal_Hide(name)
            }

            this.Sidepanel_list.find(x => x.name == name).associated_panel.Show_Panel(name)
        },
        UpdateSideButtonRef(name, ref) {
            this.Sidepanel_list.find(x => x.name == name).button = ref
        },
        UpdateSidePanelRef(ref) {
            for (let i = 0; i < this.Sidepanel_list.length; i++)
                this.Sidepanel_list[i].associated_panel = ref
        },
    }
}
</script>
