<style lang="scss">

.form_panel.Marketing {
    background-image: url('../../assets/Gameboard/Panel/panel_marketing_plan.png');

    .MF_Input {
        position: absolute;
        width: 10%;
        height: auto;
        font-size: 1.5rem;
        font-family: NestleBrush_Reg;
        border: none;

        background: none;
        outline: none;
        text-align: center;

        &.NC {
            left: 28.5%;
        }
        &.NG {
            left: 77.7%;
        }
        &.UP {
            top: 16.1%;
        }
        &.PFME {
            top: 23.7%;
        }
        &.RD {
            top: 31.3%;
        }
        &.PR {
            top: 38.8%;
            color: white;
        }
        &.black {
            color: black;
        }
        &.Copack {
            top: 65.7%;
        }
    }

    .MF_Text {
        position: absolute;
        width: 10%;
        height: 5%;

        background: transparent;
        outline: none;
        text-align: center;

        &.NC_FG {
            top: 47.9%;
            left: 38.1%;
            color: white;
            font-size: 16px;
        }

        &.NC_CU {
            top: 52.9%;
            left: 38.1%;
            color: white;
            font-size: 16px;
        }

        &.NC_TA {
            top: 56.5%;
            left: 35.5%;
            color: white;
            font-size: 40px;
        }

        &.NG_FG {
            top: 47.9%;
            left: 87.7%;
            color: white;
            font-size: 16px;
        }

        &.NG_CU {
            top: 52.9%;
            left: 87.7%;
            color: white;
            font-size: 16px;
        }

        &.NG_TA {
            top: 56.5%;
            left: 85%;
            color: black;
            font-size: 40px;
        }

        &.Total_RD {
            top: 84.8%;
            left: 70%;
            color: black;
            font-size: 34px;
        }

        &.Total_PFME {
            top: 75.3%;
            left: 70%;
            color: black;
            font-size: 34px;
        }
    }

    .Close_Marketing_Form {
        bottom: 1.5%;
        left: 35%;
        position: absolute;
        width: 30%;
        height: 5.5%;
        border: transparent;
        background: #a4d65d;
        border-radius: 30px;
        font-size: 24px;
    }
    .Close_Marketing_Form:hover {
        background: #72963f;
    }
    .Close_Marketing_Form:active {
        background: #392c23;
        color: white;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
}

</style>

<template>
    <div class="form_panel" :class="panelName">
        <input
            class="MF_Input NC UP"
            type="number"
            step="0.5"
            placeholder="0"
            v-model.number="classic.unit_price"
            @change="e => checkValue('classic.unit_price', e)"
            @focus="$event.target.select()"
            :readonly="disableInput"
        >

        <input
            class="MF_Input NC PFME"
            type="number"
            placeholder="0"
            v-model.number="classic.pfme"
            :readonly="!classic.unit_price || disableInput"
            @focus="$event.target.select()"
            @change="limit_pfme"
        >
        <input
            class="MF_Input NC RD"
            type="number"
            placeholder="0"
            v-model.number="classic.rnd"
            :readonly="!classic.unit_price || disableInput"
            @focus="$event.target.select()"
            @change="limit_rd_classic"
        >
        <input class="MF_Input NC PR black" placeholder="0" readonly
               :value="isNaN((classic.pfme / classic.unit_price).toFixed(2)) ? 0 : (classic.pfme / classic.unit_price).toFixed(2)">
        <p class="MF_Text NC_FG">{{ classicRawCount }}</p>
        <p class="MF_Text NC_CU">{{ classicCoPackCount }}</p>
        <p class="MF_Text NC_TA">{{ classicRawCount + classicCoPackCount }}</p>

        <input
            class="MF_Input NG UP"
            type="number"
            placeholder="0"
            v-model.number="gold.unit_price"
            @change="e => checkValueGold('gold.unit_price', e)"
            @focus="$event.target.select()"
            :readonly="disableInput"
        >
        <input
            class="MF_Input NG PFME"
            type="number"
            placeholder="0"
            v-model.number="gold.pfme"
            :readonly="!gold.unit_price || disableInput"
            @focus="$event.target.select()"
            @change="limit_pfme_gold"
        >
        <input
            class="MF_Input NG RD"
            type="number"
            placeholder="0"
            v-model.number="gold.rnd"
            :readonly="!gold.unit_price || disableInput"
            @focus="$event.target.select()"
            @change="limit_rd_gold"
        >
        <input class="MF_Input NG PR" placeholder="0" readonly
               :value="isNaN((gold.pfme / gold.unit_price).toFixed(2)) ? 0 : (gold.pfme / gold.unit_price).toFixed(2)">
        <p class="MF_Text NG_FG">{{ goldRawCount }}</p>
        <p class="MF_Text NG_CU">{{ goldCoPackCount }}</p>
        <p class="MF_Text NG_TA">{{ goldRawCount + goldCoPackCount }}</p>

        <input
            class="MF_Input NC Copack"
            type="number"
            v-model.number="copack.classic"
            :readonly="vPhase.period === 1 || disableInput"
            @focus="$event.target.select()"
        >

        <input v-if="vPhase.period !== 1"
            class="MF_Input NG Copack"
            type="number"
            v-model.number="copack.gold"
            :readonly="disableInput"
            @focus="$event.target.select()"
        >
        <input v-else
            class="MF_Input NG Copack"
            type="number"
            value="1"
            readonly
        >

        <p class="MF_Text Total_RD">{{ classic.rnd + gold.rnd }}</p>
        <p class="MF_Text Total_PFME">{{ classic.pfme + gold.pfme }}</p>

        <button v-if="vPhase.market_phase !== 1" class="Close_Marketing_Form" @click="submitMarketing">Confirm</button>
    </div>
</template>

<script>

import Profitability from '@/profitability-nestle-js';
import { mapGetters } from "vuex";

export default {
    props: {
        panelName: String,
    },
    emits: [
        'close',
    ],
    data() {
        return {
            classic: {
                unit_price: 0,
                pfme: 0,
                rnd: 0,
            },
            gold: {
                unit_price: 0,
                pfme: 0,
                rnd: 0,
            },
            copack: {
                classic: 0,
                gold: 0
            }
        };
    },
    computed: {
        ...mapGetters('profitability/participant', {
            vTeam: 'team',
            vPhase: 'phase',
            vMarketingData: 'marketing_data',
        }),
        classicRawCount() {
            return this.vTeam.classicRawCount;
        },
        classicCoPackCount() {
            return this.vTeam.classicCoPackCount;
        },
        goldRawCount() {
            return this.vTeam.goldRawCount;
        },
        goldCoPackCount() {
            return this.vTeam.goldCoPackCount;
        },
        disableInput() {
            return !(this.vPhase.phase_step === 0 && this.vPhase.market_phase === 0)
        },
        coPackSetValue() {
            if (vPhase.period === 1) {
                this.copack.classic = 0
                this.copack.gold = 1
                // console.log("vPhase period 1 accessed")
            } else if (vPhase.market_phase === 0 && vPhase.phase_step !== 5) {
                this.copack.classic = this.classicCoPackCount
                this.copack.gold = this.goldCoPackCount
                // console.log("Premarket & not phase step 5 accessed")
            }
        }
    },
    watch: {
        vMarketingData(next, prev) {
            this.classic = next.classic;
            this.gold = next.gold;

            this.copack = {
                classic: 0,
                gold: 0,
                ...next.order,
            }
        },
    },
    mounted() {
        this.classic = this.vMarketingData?.classic || this.classic;
        this.gold = this.vMarketingData?.gold || this.gold;

        this.copack = {
            classic: 0,
            gold: 0,
            ...this.vMarketingData.order,
        }
    },
    methods: {
        checkValue(variableName, e) {
            // To run up to the nearest half
            const roundedNumber = Math.round(e.target.value * 2) / 2;
            _.set(this.$data, variableName, roundedNumber);

            this.limit_price_classic();
        },
        checkValueGold(variableName, e) {
            // To run up to the nearest half
            const roundedNumber = Math.round(e.target.value * 2) / 2;
            _.set(this.$data, variableName, roundedNumber);

            this.limit_price_gold();
        },
        submitMarketing() {
            if (this.vPhase.period === 1) {
                if (this.classic.unit_price === 0 || this.gold.unit_price === 0) {
                    alert("Must participate in both Classic and Gold")
                    return;
                }
            }

            if (this.classic.unit_price === 0 && this.gold.unit_price === 0) {
                alert("Must participate in either Classic or Gold")
                return;
            }

            if (!Number.isInteger(this.classic.pfme) || !Number.isInteger(this.classic.rnd) || !Number.isInteger(this.gold.pfme) || !Number.isInteger(this.gold.rnd)) {
                alert("PFME and R&D must be a whole number. No decimals.")
                return;
            }

            if (this.classic.unit_price > 0 && (this.classic.rnd <= 0 || this.classic.pfme <= 0)) {
                alert("You are bidding on Nescafe Classic. Therefore, Nescafe Classic PFME spend must be at least 1 (no more than 4 in Round 2) and R&D spend must be at least 1");
                return
            } else if (this.gold.unit_price > 0 && (this.gold.rnd <= 0 || this.gold.pfme <= 0)) {
                alert("You are bidding on Nescafe Gold. Therefore, Nescafe Gold PFME spend must be at least 1 (no more than 4 in Round 2) and R&D spend must be at least 1.");
                return
            }

            Profitability.Participant.updateTeamMarketingData({
                classic: this.classic,
                gold: this.gold,
                order: {
                    classic: this.copack.classic,
                    gold: this.copack.gold,
                },
            }).then((data) => {

            }).catch(err => {
                const { error } = err;
                if (error === 'invalid_action') {
                    // TODO: tell player they are not in the phase_step to perform this action
                    alert("Cannot edit marketing plan now")
                }
            });

            // console.log(this.disableInput)

            this.$emit('close');
        },
        limit_price_classic() {
            if (this.vPhase.period === 1) {
                // limit classic unit price between 4 and 8
                if (this.classic.unit_price < 5) {
                    alert("The minimum price for Classic is 5")
                    this.classic.unit_price = 5;
                } else if (this.classic.unit_price > 8) {
                    alert("The maximum price for Classic is 8")
                    this.classic.unit_price = 8;
                }
            } else {
                // limit classic unit price less than 8
                if (this.classic.unit_price > 0) {
                    if (this.classic.unit_price > 8) {
                        this.classic.unit_price = 8;
                    }
                } else if (this.classic.unit_price === 0) {
                    this.classic.pfme = 0;
                    this.classic.rnd = 0;
                }

                if (this.classic.unit_price > 0 && this.classic.pfme == 0 && this.classic.rnd == 0) {
                    this.classic.pfme = 1;
                    this.classic.rnd = 1;
                }
            }
        },
        limit_price_gold() {
            if (this.vPhase.period === 1) {
                // limit gold unit price between 8 and 11
                if (this.gold.unit_price < 8) {
                    alert("The minimum price for Gold is 8")
                    this.gold.unit_price = 8;
                } else if (this.gold.unit_price > 11) {
                    alert("The maximum price for Gold is 11")
                    this.gold.unit_price = 11;
                }
            } else {
                // limit gold unit price less than 11
                if (this.gold.unit_price > 0) {
                    if (this.gold.unit_price > 11) {
                        this.gold.unit_price = 11;
                    }
                } else if (this.gold.unit_price === 0) {
                    this.gold.pfme = 0;
                    this.gold.rnd = 0;
                }

                if (this.gold.unit_price > 0 && this.gold.pfme == 0 && this.gold.rnd == 0) {
                    this.gold.pfme = 1;
                    this.gold.rnd = 1;
                }
            }
        },
        limit_pfme() {
            if (this.vPhase.period === 1) {
                if (this.classic.pfme < 1) {
                    alert("The PFME spend in period 2 should be between 1 and 4")
                    this.classic.pfme = 1
                } else if (this.classic.pfme > 4) {
                    alert("The PFME spend in period 2 should be between 1 and 4")
                    this.classic.pfme = 4
                }
            } else {
                if (this.classic.pfme > 15) {
                    this.classic.pfme = 15
                } else if (this.classic.unit_price > 0 && this.classic.pfme <= 0) {
                    this.classic.pfme = 1
                }
            }
        },
        limit_pfme_gold() {
            if (this.vPhase.period === 1) {
                if (this.gold.pfme < 1) {
                    alert("The PFME spend in period 2 should be between 1 and 4")
                    this.gold.pfme = 1
                } else if (this.gold.pfme > 4) {
                    alert("The PFME spend in period 2 should be between 1 and 4")
                    this.gold.pfme = 4
                }
            } else {
                if (this.gold.pfme > 15) {
                    this.gold.pfme = 15
                } else if (this.gold.unit_price > 0 && this.gold.pfme <= 0) {
                    this.gold.pfme = 1
                }
            }
        },
        limit_rd_classic() {
            if (this.vPhase.period === 1) {
                if (this.classic.rnd !== 1) {
                    alert("The R&D spend in period 2 is 1")
                    this.classic.rnd = 1;
                }
            } else {
                if (this.classic.rnd > 15) {
                    this.classic.rnd = 15
                } else if (this.classic.unit_price > 0 && this.classic.rnd <= 0) {
                    this.classic.rnd = 1
                }
            }
        },
        limit_rd_gold() {
            if (this.vPhase.period === 1) {
                if (this.gold.rnd !== 1) {
                    alert("The R&D spend in period 2 is 1")
                    this.gold.rnd = 1;
                }
            } else {
                if (this.gold.rnd > 15) {
                    this.gold.rnd = 15
                } else if (this.gold.unit_price > 0 && this.gold.rnd <= 0) {
                    this.gold.rnd = 1
                }
            }
        },
    },
}

</script>
