<style lang="scss">
.CF_Container {
  background-image: url("../../assets/Gameboard/Panel/Form_P4CashFlow_Text.png");
  top: 3%;
  right: 6.5%;
  position: fixed;
  width: 27%;
  height: 87%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  .info_box {
    position: absolute;
    top: 4.8%;
    left: 2.5%;
    font-size: 0.92rem;
    text-align: justify;
    width: 95%;
    height: 10.8%;
    /* background: green; */
    color: white;
    overflow-y: scroll;

    pre {
      padding: 0;
      margin: 0;
      font-size: 20px;
      font-family: NestleText;
      white-space: pre-wrap; /* Since CSS 2.1 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word; /* Internet Explorer 5.5+ */
    }
  }

  .title {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    color: white;
    font-size: 27px;
  }

  .wrapper {
    position: absolute;
    top: 19.4%;
    right: 3%;
    width: 47%;
    height: 79%;
    /* background: rgb(0,0,0,0.7); */

    .answer_check {
      background: url("../../assets/Gameboard/Period/checked_step_white.png");
      background-size: 100% 100%;
      position: absolute;
      width: 10.5%;
      height: 2.9%;
      z-index: 2;

      &.cash_out { left: 24.7% }
      &.cash_in { left: 57.5% }
      &.bal { left: 92.5%; }
      
      &.add_money { top: 6.5%; }
      &.sub_total_1 { top: 42.4%; }
      &.loan_1 { top: 48.5%; color: black; }
      &.cash_sales { top: 54.5%; }
      &.sub_total_2 { top: 84.4%; }
      &.loan_2 { top: 90.4%; color: black; }
      &.ncf { top: 96.3%; color: black; }
    }
  }

  .input_field {
    position: absolute;
    font-size: 20px;
    text-align: center;
    width: 26.5%;
    height: auto;
    left: 0;
    color: white;
    background: none;
    border: none;
    outline: none;
    font-family: NestleBrush_Reg;

    &.cash_out { left: 1.1%; }
    &.cash_in { left: 36.1%; }
    &.bal { left: 69.2%; }
    
    &.add_money { top: 6.1%; }
    &.pay_sup { top: 12%; }
    &.wip { top: 18.2%; }
    &.pfme { top: 24.2%; }
    &.rnd { top : 30%; }
    &.copack { top: 36.1%; }
    &.sub_total_1 { top: 42.1%; }
    &.loan_1 { top: 48.1%; color: black; }
    &.cash_sales { top: 54.1%; }
    &.ffoh { top: 60.2%; }
    &.moge { top: 66.1%; }
    &.interest { top: 72.1%; }
    &.tax_div { top: 78.1%; }
    &.sub_total_2 { top: 84%; }
    &.loan_2 { top: 90%; color: black; }
    &.ncf { top: 95.9%; color: black; }
  }
}

.CF_Container_After {
  background-image: url("../../assets/Gameboard/Panel/Form_P4CashFlow.png");
  top: 20.5%;
  right: 8%;
  position: fixed;
  width: 23.5%;
  height: 70%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  .title {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    color: white;
    font-size: 27px;
  }

  .wrapper {
    position: absolute;
    top: 9.6%;
    right: 3%;
    width: 47%;
    height: 88%;
    /* background: rgb(0,0,0,0.7); */
  }

  .input_field {
    position: absolute;
    font-size: 20px;
    text-align: center;
    width: 26.5%;
    height: auto;
    left: 0;
    color: white;
    background: none;
    border: none;
    outline: none;
    font-family: NestleBrush_Reg;

    &.cash_out { left: 1.1%; }
    &.cash_in { left: 36.1%; }
    &.bal { left: 69.2%; }
    
    &.add_money { top: 6.1%; }
    &.pay_sup { top: 12%; }
    &.wip { top: 18.2%; }
    &.pfme { top: 24.2%; }
    &.rnd { top : 30%; }
    &.copack { top: 36.1%; }
    &.sub_total_1 { top: 42.2%; }
    &.loan_1 { top: 48.3%; color: black; }
    &.cash_sales { top: 54.2%; }
    &.ffoh { top: 60.2%; }
    &.moge { top: 66.2%; }
    &.interest { top: 72.1%; }
    &.tax_div { top: 78.1%; }
    &.sub_total_2 { top: 84.2%; }
    &.loan_2 { top: 90.15%; color: black; }
    &.ncf { top: 96%; color: black; }
  }
}
</style>

<template>
  <div :class="{CF_Container: panel === false, CF_Container_After: panel === true}">
    <p class="title">Cash Flow Forecast</p>

    <div v-if="!panel" class="info_box">
      <pre>{{ cf_text }}</pre>
    </div>

    <div class="wrapper">
      <input class="input_field cash_out pay_sup" v-model.number="input.cash_out.pay_sup" maxlength="3" @change="check_input" />
      <input class="input_field cash_out wip" v-model.number="input.cash_out.wip" maxlength="3" @change="check_input" />
      <input class="input_field cash_out pfme" v-model.number="input.cash_out.pfme" maxlength="3" @change="check_input" />
      <input class="input_field cash_out rnd" v-model.number="input.cash_out.rnd" maxlength="3" @change="check_input" />
      <input class="input_field cash_out copack" v-model.number="input.cash_out.copack" maxlength="3" @change="check_input" />
      <input class="input_field cash_out sub_total_1" v-model.number="input.cash_out.sub_total_1" maxlength="3" @change="check_input" />
      <input class="input_field cash_out ffoh" v-model.number="input.cash_out.ffoh" maxlength="3" @change="check_input" />
      <input class="input_field cash_out moge" v-model.number="input.cash_out.moge" maxlength="3" @change="check_input" />
      <input class="input_field cash_out interest" v-model.number="input.cash_out.interest" maxlength="3" @change="check_input" />
      <input class="input_field cash_out tax_div" v-model.number="input.cash_out.tax_div" maxlength="3" @change="check_input" />
      <input class="input_field cash_out sub_total_2" v-model.number="input.cash_out.sub_total_2" maxlength="3" @change="check_input" />

      <input class="input_field cash_in add_money" v-model.number="input.cash_in.add_money" maxlength="3" @change="check_input" />
      <input class="input_field cash_in loan_1" v-model.number="input.cash_in.loan_1" maxlength="3" @change="check_input" />
      <input class="input_field cash_in cash_sales" v-model.number="input.cash_in.cash_sales" maxlength="3" @change="check_input" />
      <input class="input_field cash_in loan_2" v-model.number="input.cash_in.loan_2" maxlength="3" @change="check_input" />

      <input class="input_field bal cash" v-model.number="input.bal.cash" maxlength="3" @change="check_input" />
      <input class="input_field bal add_money" v-model.number="input.bal.add_money" maxlength="3" @change="check_input" />
      <input class="input_field bal sub_total_1" v-model.number="input.bal.sub_total_1" maxlength="3" @change="check_input" />
      <input class="input_field bal loan_1" v-model.number="input.bal.loan_1" maxlength="3" @change="check_input" />
      <input class="input_field bal cash_sales" v-model.number="input.bal.cash_sales" maxlength="3" @change="check_input" />
      <input class="input_field bal sub_total_2" v-model.number="input.bal.sub_total_2" maxlength="3" @change="check_input" />
      <input class="input_field bal loan_2" v-model.number="input.bal.loan_2" maxlength="3" @change="check_input" />
      <input class="input_field bal ncf" v-model.number="input.bal.ncf" maxlength="3" @change="check_input" />
    
      <template v-for="(type, i) in input.check.cash_out" :key="i">
        <div v-if="input.check['cash_out'][i]" class="answer_check cash_out" :class="i"></div>
      </template>
      <template v-for="(type, i) in input.check.bal" :key="i">
        <div v-if="input.check['bal'][i]" class="answer_check bal" :class="i"></div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cf_text } from '@/constants'

export default {
  props: {
    cashflowForecast: Object,
    at_panel: Boolean
  },
  emits: [
    'update:cashflowForecast'
  ],
  computed: {
    ...mapGetters('profitability/participant', {
      vPhase: 'phase',
    }),
    input() {
      return this.$store.state.cfForm
    },
    isPhaseAtZero() {
      return this.vPhase.period > 2 && this.vPhase.phase_step === 0;
    }
  },
  data() {
    return {
      cf_text,
      panel: this.at_panel,
    };
  },
  watch: {
    cashflowForecast(next) {
      this.updateDataFromBackend(next);
    },
    input: {
      deep: true,
      handler() {
        this.$emit('update:cashflowForecast', this.input);
      },
    },
  },
  mounted() {
    this.updateDataFromBackend(this.cashflowForecast);

    let add_money = this.input['bal']['cash'] + this.input['cash_in']['add_money']
    let sub_total_1_co = this.input['cash_out']['pay_sup'] + this.input['cash_out']['wip'] + this.input['cash_out']['pfme'] + this.input['cash_out']['rnd'] + this.input['cash_out']['copack']
    let sub_total_1_b = this.input['bal']['add_money'] - this.input['cash_out']['sub_total_1']
    let loan_1 = this.input['bal']['sub_total_1'] + this.input['cash_in']['loan_1']
    let cash_sales = this.input['cash_in']['cash_sales'] + this.input['bal']['loan_1']
    let sub_total_2 = this.input['cash_out']['ffoh'] + this.input['cash_out']['moge'] + this.input['cash_out']['interest'] + this.input['cash_out']['tax_div']
    let loan_2 = this.input['cash_in']['loan_2'] + this.input['bal']['sub_total_2']
    let ncf = this.input['bal']['loan_2'] - this.input['bal']['cash']

    this.input.check['bal']['add_money'] = this.input['bal']['add_money'] == add_money
    this.input.check['cash_out']['sub_total_1'] = this.input['cash_out']['sub_total_1'] == sub_total_1_co
    this.input.check['bal']['sub_total_1'] = this.input['bal']['sub_total_1'] == sub_total_1_b
    this.input.check['bal']['loan_1'] = this.input['bal']['loan_1'] == loan_1
    this.input.check['bal']['cash_sales'] = this.input['bal']['cash_sales'] == cash_sales
    this.input.check['cash_out']['sub_total_2'] = this.input['cash_out']['sub_total_2'] == sub_total_2
    this.input.check['bal']['loan_2'] = this.input['bal']['loan_2'] == loan_2
    this.input.check['bal']['ncf'] = this.input['bal']['ncf'] == ncf
  },
  methods: {
    updateDataFromBackend(cashflowForecast) {
      _.each(this.structure, (v1, k1) => {
        _.each(v1, (v2, k2) => {
          _.set(this.$data.input, [k1, k2], _.get(cashflowForecast, [k1, k2], null));
        });
      });
    },
    check_input() {
      let add_money = this.input['bal']['cash'] + this.input['cash_in']['add_money']
      let sub_total_1_co = this.input['cash_out']['pay_sup'] + this.input['cash_out']['wip'] + this.input['cash_out']['pfme'] + this.input['cash_out']['rnd'] + this.input['cash_out']['copack']
      let sub_total_1_b = this.input['bal']['add_money'] - this.input['cash_out']['sub_total_1']
      let loan_1 = this.input['bal']['sub_total_1'] + this.input['cash_in']['loan_1']
      let cash_sales = this.input['cash_in']['cash_sales'] + this.input['bal']['loan_1']
      let sub_total_2 = this.input['cash_out']['ffoh'] + this.input['cash_out']['moge'] + this.input['cash_out']['interest'] + this.input['cash_out']['tax_div']
      let loan_2 = this.input['cash_in']['loan_2'] + this.input['bal']['sub_total_2']
      let ncf = this.input['bal']['loan_2'] - this.input['bal']['cash']

      this.input.check['bal']['add_money'] = this.input['bal']['add_money'] == add_money
      this.input.check['cash_out']['sub_total_1'] = this.input['cash_out']['sub_total_1'] == sub_total_1_co
      this.input.check['bal']['sub_total_1'] = this.input['bal']['sub_total_1'] == sub_total_1_b
      this.input.check['bal']['loan_1'] = this.input['bal']['loan_1'] == loan_1
      this.input.check['bal']['cash_sales'] = this.input['bal']['cash_sales'] == cash_sales
      this.input.check['cash_out']['sub_total_2'] = this.input['cash_out']['sub_total_2'] == sub_total_2
      this.input.check['bal']['loan_2'] = this.input['bal']['loan_2'] == loan_2
      this.input.check['bal']['ncf'] = this.input['bal']['ncf'] == ncf

      if (Object.values(this.input.check.cash_out).every(Boolean) && Object.values(this.input.check.bal).every(Boolean)) {
        this.$emit("canSubmitCF", true)
      } else {
        this.$emit("canSubmitCF", false)
      }
    }
  },
};
</script>
