import { Model } from '@vuex-orm/core';
import _ from "lodash";
import Factory from "./Factory";

const teamColors = [
  '#5AB08B',
  '#6891D3',
  '#FF8D51',
  '#9963B9',
  '#FFD451',
  '#DCCEB3',
];

const teamName = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
];

export default class Team extends Model {
  static entity = 'teams';

  static fields() {
    return {
      id: this.attr(null),
      index: this.attr(null),

      name: this.attr(null),
      // color: this.attr(null),

      retained_earnings: this.attr(null),
      net_assets: this.attr(null),

      data: this.attr(null),
      team_data: this.attr(null),
      gameboard: this.attr(null),
    };
  }

  get color() {
    return teamColors[this.index];
  }

  get teamData() {
    return this.team_data;
  }

  get marketingData() {
    return this.teamData?.marketing_data || {};
  }

  get marketingDataTotalPFME() {
    const { marketingData } = this;
    return _.sumBy(_.flatMap(marketingData), 'pfme');
  }

  get marketingDataTotalRnD() {
    const { marketingData } = this;
    return _.sumBy(_.flatMap(marketingData), 'rnd');
  }

  get classicStockCount() {
    const factories = Factory.query()
    .where('team_id', this.id)
    .with('slots')
    .get();

    const slots = _.flatMap(factories, (factory) => {
      return factory.slots;
    });

    const filterSlots = _.filter(slots, (slot) => slot && slot.type === 'classic');
    return filterSlots.length;
  }

  get goldStockCount() {
    const factories = Factory.query()
    .where('team_id', this.id)
    .with('slots')
    .get();

    const slots = _.flatMap(factories, (factory) => {
      return factory.slots;
    });

    const filterSlots = _.filter(slots, (slot) => slot && slot.type === 'gold');
    return filterSlots.length;
  }

  get classicRawCount() {
    const factories = Factory.query()
    .where('team_id', this.id)
    .with('slots')
    .get();

    const slots = _.flatMap(factories, (factory) => {
      return factory.slots;
    });

    const filterSlots = _.filter(slots, (slot) => slot && slot.type === 'classic' && slot.subtype === 'raw');
    return filterSlots.length;
  }

  get goldRawCount() {
    const factories = Factory.query()
    .where('team_id', this.id)
    .with('slots')
    .get();

    const slots = _.flatMap(factories, (factory) => {
      return factory.slots;
    });

    const filterSlots = _.filter(slots, (slot) => slot && slot.type === 'gold' && slot.subtype === 'raw');
    return filterSlots.length;
  }

  get classicCoPackCount() {
    const factories = Factory.query()
    .where('team_id', this.id)
    .with('slots')
    .get();

    const slots = _.flatMap(factories, (factory) => {
      return factory.slots;
    });

    const filterSlots = _.filter(slots, (slot) => slot && slot.type === 'classic' && slot.subtype === 'co_pack');
    return filterSlots.length;
  }

  get goldCoPackCount() {
    const factories = Factory.query()
    .where('team_id', this.id)
    .with('slots')
    .get();

    const slots = _.flatMap(factories, (factory) => {
      return factory.slots;
    });

    const filterSlots = _.filter(slots, (slot) => slot && slot.type === 'gold' && slot.subtype === 'co_pack');
    return filterSlots.length;
  }
}
