import { Model } from '@vuex-orm/core';

import FactorySlot from './FactorySlot';

export default class Factory extends Model {
  static entity = 'factories';

  static fields() {
    return {
      id: this.attr(null),
      training_session_id: this.attr(null),
      team_id: this.attr(null),
      index: this.attr(null),

      type: this.attr(null),
      production_line: this.attr(null),
      wip: this.attr(null),
      last_depreciation_period: this.attr(null),

      slots: this.hasMany(FactorySlot, 'factory_id'),
    };
  }
}
