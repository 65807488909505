<template>
    <div class="Section_Land">
        <img src="../../assets/Gameboard/Sections/section_land_buildings.png" class="PlayerBoard_Land">
        <div :class="{frame_border: phase === 1}"></div>
        
        <CoinIndicator :pass_currency_type="2" :pass_cash="amount" ref="Coin_Indicator_Static" />
    </div>
</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue';
import { mapGetters } from "vuex";

export default {
    props: {
        phase: Number,
    },
    data() {
        return {
            amount: 0,
        }
    },
    components: { CoinIndicator },
    computed: {
        canDragToLandNBuilding() {
            return this.phase === 1 && this.amount === 0;
        },
    },
    methods: {
        Interact_Land(cash) {
            if(this.phase == 1) {
                if (cash == 20) {
                    this.amount += 20
                    this.$emit("to_land");
                } else {
                    alert("Please pay 20 coins to land and buildings")
                }
            } else {
                alert("Please pay land and building at 2nd step.")
            }
        }
    },
}
</script>

<style lang="scss">
.Section_Land {
    top: 11%;
    left: 7.15%;
    position: fixed;
    width: 6%;
    height: 15%;

    .PlayerBoard_Land {
        top: 11%;
        left: 6.95%;
        position: fixed;
        width: 7.2%;
        height: 15%;
    }

    .frame_border {
        border: 5px solid red;
        border-radius: 8px;
        position: absolute;
        left: -5%;
        width: 125%;
        height: 100%;
    }
}

</style>
