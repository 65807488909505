<template>
  <div class="Section_Receivable">
    <img src="../../assets/Gameboard/Sections/section_receivable.png" class="PlayerBoard_Receivable">

    <!-- <div v-if="vDebug" class="debug">
        <div>
            receivables: {{ receivables }}
            <button v-if="receivables_canReceive" @click="collectPayment">Collect</button>
        </div>
    </div> -->

    <div class="receivable_box">
      <CoinIndicator :pass_currency_type="1" :pass_cash="receivables"/>
      <button v-if="receivables_canReceive" class="receive_button" @click="collectPayment"></button>
    </div>

    <div
      :class="{ frame_border: vPhase.period === 0 && vPhase.phase_step === 4 }"
    ></div>
  </div>


  <CoinComponent ref="CComponent" @use_coin="drop_function"/>
</template>

<script>
import Profitability from '@/profitability-nestle-js';
import { mapGetters } from 'vuex';
import CoinComponent from '../Coin_Component.vue';
import CoinIndicator from '../Coin_Indicator.vue';

export default {
  components: { CoinComponent, CoinIndicator },
  computed: {
    ...mapGetters({
      vDebug: 'debug',
    }),
    ...mapGetters('profitability/participant', {
      vGameboard: 'gameboard',
      vPhase: 'phase',
    }),
    receivables() {
      return this.vGameboard?.getValue('receivables');
    },
    receivables_canReceive() {
      return this.vPhase.market_phase === 0 && this.vPhase.phase_step === 1 && this.receivables > 0;
    },
  },
  watch: {
    receivables_canReceive() {
      if (this.receivables_canReceive) {
        setTimeout(() => {
          this.$refs['CComponent'].set_custom_grab(0.105, 0.125, this.receivables, 'Cash_Box', 'Receivable');
        }, 100);
      } else {
        this.$refs['CComponent'].reset(0);
      }
    },
  },
  methods: {
    drop_function() {
      this.collectPayment();
      this.$refs['CComponent'].reset();
    },
    collectPayment() {
      Profitability.Participant.collectPaymentFromCustomer();
    },
  },
};
</script>

<style lang="scss">
.Section_Receivable {
  top: 76%;
  left: 6.95%;
  position: fixed;
  width: 16.5%;
  height: 20.65%;

  .PlayerBoard_Receivable {
    top: 76%;
    left: 6.95%;
    position: fixed;
    width: 16.5%;
    height: 20.65%;
  }

  .Section_Receivable > .debug {
    position: absolute;
    inset: 0;
    margin-top: 1.5rem;
    pointer-events: none;

    display: flex;
    font-weight: bold;
    color: black;
  }

  .Section_Receivable > .debug button {
    pointer-events: auto;
  }

  .receivable_box {
    font-weight: normal;
    position: relative;
    width: 33%;
    height: 100%;
    margin-top: 5%;
  }

  .receivable_amt {
    top: 93.2%;
    right: 76.75%;
  }

  .receive_button {
    position: absolute;
    top: -800%;
    left: 140%;
    width: 40%;
    height: 20%;
    background: url("../../assets/Gameboard/Sections/Collect_Credit_Button.png");
    background-size: 100% 100%;
    border: none;
  }

  .frame_border {
    border: 5px solid red;
    border-radius: 8px;
    position: absolute;
    top: 0;
    width: 95%;
    height: 100%;
  }
}
</style>
