export const begin = [
    'WELCOME TO THE INDUCTION FOR VALUE CREATION 1\n\nThis induction will show you how the game works, and how your decisions affect cash and profit. As you go through it, you will learn to do all the steps needed in the game, and you will meet most of the financial terms that will be used in the course, which are the foundation of good communication across the business in Nestlé.\n\nYou will be running a coffee business, in competition with five other suppliers. As you get your factory set up, you can buy coffee from co-packers and sell it, later you can manufacture it yourself.',

    'GAME BOARD\n\nThe game board shows your coffee business. It will come to life as each area is introduced.\n\nYou will be selling Nescafé Classic and Nescafé Gold.\n\nThe External Locations on the left show the other businesses that you deal with. These include your Investors, the Suppliers of Raw and Packaging materials, your Customers, and the Bank.\n\nThe factory area next to that is where you will produce coffee, and store inventory from co-packers.\n\nThe Cash area at the bottom shows how much money you have at any time, and next to that are the amounts owed to you by customers, and how much you owe to suppliers and the  bank.\n\nWhen you pay Expenses on the right, you will take money from Cash to pay them.',

    'THE MOVING BALANCE SHEET\n\nNow we will set the business up, and make our first sale.\n\nEach time you do something, it changes the financial position of the business, and you will fill in a Balance Sheet to show that.\n\nThe Balance Sheet is simply a list of things that have value (What we have) and where the money came from to buy them. You can see the values on the board, and enter them into the Balance Sheet at each step.\n\nLet\'s do it! Click Next, then follow the instructions on the right of the board.'
]

// MBS 
export const mbs_1 = 'AWESOME! NOW YOU HAVE SOME CASH!\n\nLET\'S LOOK AT WHAT HAPPENED HERE...\n\nThe company decided to invest in your new business, and provided you with 75 in cash. If you were starting a small independent business, you would have to raise that money from friends or family, and they would be your shareholders. Here, Nestlé is your investor.\n\nA balance sheet is a snapshot of the business. It shows us the value of what we have in the business (at the top) and where it came from (at the bottom).\n\nThe balance sheet always has to balance! So, the total in the top has to equal the total in the bottom.\n\nClick Close, then follow the instructions for step 2.'

export const mbs_2 = [
    'YOU JUST BOUGHT A FACTORY!\n\nYou use cash to buy things (assets) and to pay expenses. We have just bought a factory for our production lines and warehousing. If you pay an expense (like your phone bill) your cash goes down, and you have nothing to show for it. But if you buy an asset (like a factory) although your cash goes down, you have the asset; which you could sell, and get the cash back again. In this case, the total on the balance sheet will not change.\n\nYour balance sheet has now changed as you have a new asset (Land and Buildings). The Balance Sheet will change every time you move chips on the board.\n\nNext we are going to buy a production line...',
    
    'BUYING EQUIPMENT: A PRODUCTION LINE\n\nTo produce coffee, we need a production and packaging line. These go where it says "Buy equipment line".\n\nYour first production line is for Nescafé Classic. You must pay for the equipment itself, and for “Work In Progress” (WIP). This is the value of products still on the production line but not yet finished.\n\nClick next, then follow the instructions for step 3.'
]

export const mbs_3 = [
    'NOTICE WHAT\'S HAPPENING ON YOUR BOARD!\n\nThe \'chips\' on your board represent value, whether or not it is cash. Anything with value is an asset.\n\nYou have 20 brown chips on Land and Buildings. Brown chips show your Fixed Assets. These are things you keep to run the business, which do not quickly turn to cash.\n\nWork in Progress is a Current Asset. These are things that you expect to turn into cash soon.',

    'BUYING "CO-PACKED" GOODS\n\nYou have opened a production line, but it will take one period to manufacture the first products, which you can sell in the next period. So, there is a delay of one period between buying the production line and selling products from it.\n\nIn the meantime, you can buy "Co-Packed" goods. These allow you to get your product to market more quickly, using production and packaging partners. You can buy and sell co-packed goods in the same business period.\n\nThis period you are going to buy some Classic co-packed units. Do this now...'
]

export const mbs_4 = [
    'YOU NOW HAVE PRODUCT TO SELL!\n\nYou can see your co-packed products are in a Warehouse. The cost of each product is shown (4 for each Classic unit). These are ready to sell.\n\nCo-Packers can help you get to market quicker, and give you extra capacity, but the product costs more than when you make it yourself. Also, your Co-Packers must be paid in cash!',

    'TIME HAS MOVED ON AND YOU HAVE SOLD YOUR 5 CO-PACKED PRODUCTS!\n\nGood news! Your products are in demand, and you have sold them! In future periods, you will compete against other teams for customer orders.  You will make a marketing plan for your business, and set prices for your products.\n\nIn this introductory period, we have a fixed market, and every team has sold 5 units. Let\'s deliver the products to the customer.',

    'FILLING THE DELIVERY RECORD\n\nIn future, you will decide whether to deliver units you have produced, or co-packed units.\n\nThis will affect the profit you make on a given sale, your \'Product Margin\'. That is the Revenue from your customer, less the cost of the product (Cost of Goods Sold).\n\nIn this example, you have only co-packed goods, so you have to deliver those.\n\nOpen the delivery record and follow the instructions to deliver your goods.'
]

export const mbs_5 = [
    'PAYMENT TERMS\n\nThe product has been delivered, but the customer has negotiated terms, to pay us at the beginning of the next period. Once we have delivered the product, we record the sale, even if we have not yet been paid. Here is one source of the difference between Profit and Cash; when we make the sale, we create Profit, whether or not we have received the Cash.\n\nMoney \'Owed from Customers\' is called \'Receivables\' in the USA, and \'Debtors\' in the UK.',

    'PAUSE AND REFLECT\n\nWhat has happened in the Balance Sheet over the past 5 steps? Most of the time, the totals have stayed the same, because we have been buying assets (which have the same value as the cash we paid for them. But now we have two new additions - \'Owed from Customers\' and \'Profit\' lines. What do you notice about theTotal Assets and the Profit?\n\nHow much profit has your company made? How much cash does it have available? HINT: Cash and profit are not the same!\n\nWhat would happen if the company ran out of cash?',

    'It\'s time to pay the expenses!\n\nDo you know what FFOH and MOGE mean?\n\nFFOH is your \'Fixed Factory Overheads\'. This includes factory managers’ salaries, heat and power, and running costs such as lubricants and spare parts. You pay 1 per period for each production line you own.\n\nMOGE is your \'Marketing and Other General Expenses\'. This covers management salaries, rent, office heating and lighting, IT, HR, marketing and selling expenses, and many other things. This is fixed at 2 per period.'
]

export const mbs_6 = 'TAKING LOANS / GOING TO THE BANK!\n\nWhen you pay expenses, both cash and profit go down. Most of your cash has been used up to buy a factory, equipment, materials (WIP) and goods to sell. You need more cash now, to buy raw materials.\n\nIn the game, you can take a loan at any time, within limits. Most businesses borrow money, because they can make more profit using that extra money, than they have to pay in interest.\n\nFor now, you will take a loan of 10.'

export const mbs_7 = 'ORDERING RAW MATERIALS\n\nRemember you bought co-packed units before, when your own production line was not ready? Now you will buy raw and packaging materials to produce coffee on your own production line! This means that each unit costs you less, and can make a better profit margin.\n\nIf you invest in a new production line in future, it will take one period before you can sell what it produces.'

export const mbs_8 = 'INTEREST ON LOANS / LOAN REPAYMENTS\n\nYou need to pay interest on any money borrowed. The interest cost is 1 per period, for every 10 borrowed. For example, if you had 26 in loans remaining, you would pay 3 in interest.\n\nYou also need to pay back at least 10% of your loans (rounded up) each period.\n\nWhen you pay Interest on your loan, it reduces your cash, and also reduces your profit because interest is an expense.\n\nWhen you repay part of the loan amount, it reduces your cash but it does not affect your profit. You reduce the amount you owe the bank, and your cash, by the same amount.\n\nDo that now...'

export const mbs_9 = 'DEPRECIATION\n\nLast step! Now you will depreciate your production line.\n\nWhen we buy Fixed Assets like production lines, we don’t show the full cost as an expense (on the income statement) in the first year. Instead, we spread the cost over time. This is called depreciation. The asset value is put on the balance sheet, and depreciates (reduces) a little each period.\n\nWhen you depreciate equipment, you do not spend cash. Instead, you take depreciation from the value of the production line (brown chips) and put it on the Depreciation expense. This is called a NON-CASH expense because no cash moves.\n\nClassic Production lines depreciate by 1 per period, Gold by 2 per period.'

export const mbs_10 = 'YOU\'RE COMPLETE! NOW PAUSE AND REFLECT\n\nWell done! You have completed period 1, the Moving Balance Sheet. You have seen how to use the chips and the board, to run the business, but you didn\'t have to make any decisions. When you join the course, you will have to make all the decisions about how to make the business successful. Working in a team, you can discuss all the options, and see how your decisions lead to results.\n\nReview your Balance Sheet. What has happened over the past few steps? What did you learn?\n\nWhat must you do in this game to create value in your business?\n\nWhat questions would you like to ask the facilitator? Make a note of them, so you can ask them when you join the course.'

// Period 1
export const p1_begin = [
    'WELCOME TO PERIOD 1, THE "MOVING BALANCE SHEET"\n\nThis first period will show you how to use the game, and show you how your decisions affect cash  and profit.',

    'GAME BOARD \n\nThe game board shows your coffee business. You will be selling Nescafé Classic and Nescafé Gold. External Locations on the left include your Investors, Suppliers, Customers, and the Bank. The factory area next to that is where you will produce coffee, and buy inventory from co-packers.\n\nThe Cash area at the bottom shows how much money you have at any time, and next to that are the  amounts owed to you by customers, and what you owe to suppliers and the bank.\n\nWhen you pay expenses on the right, you will take money from Cash to pay them.',

    'THE MOVING BALANCE SHEET\n\nNow we will set the business up, and make our first sale.\n\nEach time you do something, you will fill in a Balance Sheet to show the financial impact.\n\nThe Balance Sheet is a list of things that have value (What we have) and where the money came  from to buy them. You can see the values on the board, and enter them into the Balance Sheet at each step.\n\nLet\'s do it! Click Next, then follow the instructions on the right of the board.'
]

export const p_1 = 'AWESOME! YOU NOW HAVE SOME CASH!\n\nLET\'S LOOK AT WHAT HAPPENED HERE...\n\nA balance sheet is a snapshot of the business. It shows us the value of what we have in the business (at the top) and where it came from (at the bottom).\n\nThe balance sheet must always balance! So, the total in the top has to equal the total in the bottom. Click Close, then follow the instructions for step 2.'

export const p_2 = [
    'YOU JUST BOUGHT A FACTORY!\n\nYou use cash to buy things (assets) and to pay expenses. We have just bought a factory for our  production lines and warehousing.\n\nYour balance sheet has now changed as you have a new asset (Land and Buildings). The Balance  Sheet will change every time you move chips on the board.\n\nNext we are going to buy a production line...',
    
    'BUYING EQUIPMENT: A PRODUCTION LINE\n\nTo produce coffee, we need a production and packaging line. These go where it says "Buy equipment  line".\n\nYour first production line is for Nescafé Classic. You must pay for the equipment itself, and for “Work  In Progress” (WIP). This is the value of products still on the production line but not yet finished.\n\nClick next, then follow the instructions for step 3.'
]

export const p_3 = [
    'NOTICE WHAT\'S HAPPENING ON YOUR BOARD!\n\nThe \'chips\' on your board represent value, whether or not it is cash. Anything with value is an asset.\n\nYou have 20 brown chips on Land and Buildings. Brown chips show your Fixed Assets. These are  things you keep to run the business, which do not quickly turn to cash.\n\nWork in Progress is a Current Asset. These are things that you expect to turn into cash soon.',

    'BUYING "CO-PACKED" GOODS\n\nYou have opened a production line, but it takes one period to produce your products ready to sell in  the next period\'s market. So, there is a delay of one period between buying the production line and  selling products from it.\n\nIn the meantime, you can buy "Co-Packed" goods. These allow you to get your product to market more quickly, using production and packaging partners. You can buy and sell co-packed goods in the same business period.\n\nThis period you are going to buy some Classic co-packed units. Do this now...'
]

export const p_4 = [
    'YOU NOW HAVE PRODUCT TO SELL!\n\nYou can see your co-packed products are in a Warehouse. The cost of each product is shown (4 for  each Classic unit). These are ready to sell.\n\nCo-Packers can help you get to market quicker, and give you extra capacity, but the product costs  more than when you make it yourself. Co-Packers must be paid in cash!',

    'TIME HAS MOVED ON AND YOU HAVE SOLD YOUR 5 CO-PACKED PRODUCTS!\n\nGood news! Your products are in demand! In future periods, you will make a marketing plan for your  business, and set prices for your products. You will compete against other teams for customer  orders.\n\nIn this period, we have a fixed market, and every team has sold 5 units. Let\'s deliver the products to the customer.',

    'FILLING THE DELIVERY RECORD\n\nIn future, you will decide whether to deliver units you have produced, or co-packed units.\n\nThis will affect the profit you make on a given sale, your \'Product Margin\'. That is the Revenue from  your customer, less the cost of the product (Cost of Goods Sold).\n\nIn this example, you have only co-packed goods, so you have to deliver those. Open the delivery record and follow the instructions to deliver your goods.'
]

export const p_5 = [
    'PAYMENT TERMS\n\nThe product has been delivered, but the customer has negotiated payment terms. They will pay us  at the beginning of the next period. Once we have delivered the product, we record the sale, even if  we have not yet been paid.\n\nMoney \'Owed from Customers\' is called \'Receivables\' (US) or \'Debtors\' (UK).',

    'PAUSE AND REFLECT\n\nWhat has happened in the Balance Sheet over the past 5 steps? You may notice two new additions - we now have \'Owed from Customers\' and also \'Profit\' lines. What do you notice about them?\n\nHow much profit has your company made? How much cash does it have available? HINT: Cash and profit are not the same!\n\nWhat are the risks in this company?',

    'It\'s time to pay the expenses!\n\nDo you know what FFOH and MOGE mean?\n\nFFOH is your \'Fixed Factory Overheads\'. You pay 1 per period for each production line you own. MOGE is your \'Marketing and Other General Expenses\'. This is fixed at 2 per period.'
]

export const p_6 = 'TAKING LOANS / GOING TO THE BANK!\n\nWhen you pay expenses, both cash and profit go down. Most of your cash has been used to buy a factory, equipment, materials (WIP) and goods to sell. You need more cash now, to buy raw  materials.\n\nIn the game, you can take a loan at any time, within limits.\n\nFor now, you will take a loan of 10.'

export const p_7 = 'ORDERING RAW MATERIALS\n\nRemember you bought co-packed units before, when your own production line was not ready? Now  you will buy raw and packaging materials to produce coffee on your own production line! This  means that each unit costs you less, and can make a better profit margin.\n\nIf you invest in a new production line in future, it will take one period before you can sell what it  produces.'

export const p_8 = 'INTEREST ON LOANS / LOAN REPAYMENTS\n\nYou need to pay interest on any money borrowed. The interest cost is 1 per period for every 10  borrowed. For example, if you had 26 in loans remaining, you would pay 3 in interest.\n\nYou also need to pay back at least 10% of your loans (rounded up) each period. Do that now...\n\nWhen you pay Interest on your loan, it reduces your cash, and also reduces your profit because  interest is an expense.\n\nWhen you repay part of the loan amount, it reduces your cash but it does not affect your profit. You  reduce the amount you owe the bank, and cash, by the same amount.'

export const p_9 = 'DEPRECIATION\n\nLast step! You will now depreciate your production line.\n\nWhen we buy Fixed Assets like production lines, we don\'t show the full cost as an expense (on the  income statement) in the first year. Instead, we spread the cost over time. This is called  depreciation. The asset value is put on the balance sheet, and depreciates (reduces) a little each  period.\n\nWhen you depreciate equipment, you do not spend more cash. Instead, you take depreciation from  the value of the production line (brown chips) and put it on the Depreciation expense. This is called a  NON-CASH expense because no cash moves.\n\nClassic Production lines depreciate by 1 per period, Gold by 2 per period.'

export const p_10 = 'YOU\'RE COMPLETE! NOW PAUSE AND REFLECT\n\nWell done! You have completed period 1, the Moving Balance Sheet.\n\nReview your Balance Sheet. What has happened over the past 5 steps? What did you learn? What must you do in this game to create value in your business?\n\nYour facilitator will bring you back to the main room when all the teams are ready.'

// Period 2
export const p2_pre_0 = [
    'WELCOME TO PERIOD 2.\n\nIn this period the competition starts! You will make some marketing decisions and compete for customers in the market.\n\nYou have a checklist to guide you. Click on the “i” button to the left of the step to see the instructions. Click the button again to close. Follow the instructions for each step and then press Next to move forward.\n\nAt the end of the period, you will complete your own Income Statement and Balance Sheet to see how well you have done.',

    'The first step is the marketing plan. This contains your strategic decisions ready to compete for customers. There will be two markets, one for the Classic customers and one for the Gold customers. You need to make decisions for both products. You already have some Classic finished goods to sell, and you are going to buy one co-packed Gold later, ready for the market.\n\nYou need to set your selling price, PFME spend, and R&D spend. As this is the first round of competing there are some stricter limits around these prices and spends. Check the instructions for the limits before filling in your plan.\n\nGood luck!'
]

export const p2_post_0 = 'You have competed in your first market! How did you do?\n\nDon\'t worry if it didn\'t go to plan! You now know a bit more about your competitors and can adjust your strategy at the start of the next period.\n\nFor now, let\'s deliver the goods you did sell and receive payment.\n\nFollow the instructions on the checklist.'

export const p2_post_2 = 'SUPPLIER CREDIT!\n\nYou have built trust with your supplier, and they will now offer you credit on any raw and packaging  materials you buy. You can receive the goods now and pay for them at the start of next period, or pay  cash for them now. The choice is yours!\n\nOnce you have received the raw materials, they are processed into finished goods ready to sell next  period. They will show on your board as finished goods.'

export const p2_post_5 = 'It’s time to complete your financial statements, the income statement and the balance sheet. Help each other find the numbers and make sure you all understand the calculations.\n\nA tick (check mark) will show for each correct entry, which will then allow you to move to the next line.\n\nYou’ll be an expert at these by the end of the sessions!'

export const p2_post_8 = 'Congratulations, you have completed your financial statements and balanced your books!\n\nWhile you are waiting for the other teams, reflect on this period as a team. What went well? What were your challenges? What questions do you have for the facilitator?'


// Period 3
export const p3_pre_0 = [
    'In this period you make all the decisions. Demand for both Classic and Gold is growing, and you can buy  new production capacity (for use in Period 4), or use co-packers (with immediate delivery), to compete  on both products. There is a market forecast near the end of the RULES.\n\nSpend some time thinking about your company strategy, and your competitors, before completing the  marketing plan.',

    'Your Marketing Plan determines how well you compete for customers. You can now make your own  decisions on capacity, co-packers, PFME, Price, and R&D. You can check the rules at any time by clicking  on RULES.\n\nThe minimum spend on PFME is 1 for both markets, with no maximum.\n\nThe maximum unit price is 8 for Classic and 11 for Gold.\n\nThe minimum spend on R&D is 1 for both markets, with no maximum.\n\nAlways look at your cash, and think whether anything you spend will make you more profit.'
]

export const p3_pre_3 = 'You can now buy another production line to increase your capacity. Check the RULES to find out the costs. You can only buy one each period, either Classic or Gold.\n\nThe cost of the production line and the WIP (work in progress) will have to be paid from Cash, so plan  carefully before doing this.'

export const p3_post_8 = 
    'Congratulations! You have completed your income statement and balanced your books!\n\nYou now have a new form "Performance Analysis" to calculate your KPIs. Click on the Performance icon to complete.'

export const p3_post_9 = 
    'Brilliant, you have completed the round, balanced your books and done your Performance Analysis! Reflect on this period, as a team. What went well, what were the challenges?\n\nHow does each of the KPIs reflect what you have just discussed? What questions do you have for the  facilitator about them?'


// Period 4
export const p4_pre_0 = [
    'WELCOME TO PERIOD 4!\n\nIn this period you should know if your strategy is working. How is it going? Do you need to change your direction or continue as planned? What decisions will maximise your ROIC?\n\nThe focus this period is forecasting. We will be comparing planned profit versus actual profit at the end of the period...',

    'FORECASTING\n\nThe purpose of the operational plan is to find out what everyone in the business is expecting to do, and  to align them to it. It is also important to make sure the plan makes financial sense and will lead to a  profit.\n\nThe purpose of the Operational Plan is to get everyone’s efforts aligned to support the company strategy.  It is also important to make sure the plan makes financial sense, and will lead to a profit.\n\nThe cash flow forecast must be consistent with the operational plan. It shows whether you have enough  cash to fund the plan.\n\nYou are about to complete these for your business. Remember the golden rules: plan for a profit, plan an  improvement and plan realistically!'
]

export const p4_post_3 = 'ROIC\n\nYou are about to pay interest and repay loans. Think about what you need to pay for tax and dividends and how to optimise ROIC.\n\nYou must repay at least 10% of loans and a maximum of 50%.'

export const p4_post_8 = 'Congratulations, you have completed period 4!\n\nWhile you are waiting for the other teams, reflect on this period as a team.\n\nWhat went well? What were your challenges?\n\nHow are your KPIs looking on your performance analysis form and how can you improve them next period?'

// Period 5
export const p5_pre_0 = 'WELCOME TO PERIOD 5!\n\nThis is it, the final period! How is it going? What decisions will maximise value and leave your business in the best possible position for the future? At the end of the round investors will be comparing the teams and deciding where to invest.\n\nGo for it!'

export const p5_post_3 = 'You are about to pay interest and repay loans. Think about what you need to pay for tax and dividends  and how to optimise ROIC.\n\nYou must repay at least 10% and a maximum of 50% of your total loan value.'

export const p5_post_8 = 'Congratulations, you have completed the simulation!\n\nWhile you are waiting for the other teams, reflect on the simulation as a team.\n\nWhat have you learnt? What will you take back to the business?\n\n\nYou will also be given a few minutes to present your simulation business to the investors. What are the strengths in your business and why should they invest in you?\n\n\nMany thanks! We hope you enjoyed it.\n\nwww.profitability.com'