<template>
  <div v-if="is_warehouse === true">
    <img src="../../assets/Gameboard/Sections/section_warehouse.png" class="PlayerBoard_Plot">
    <div class="Warehouse_Units">
      <div v-for="(slot, i) in (factory.slots || [])" :key="i">
        <div v-if="slot.type === 'classic'" class="coin_align" :class="String.fromCharCode(97 + i)">
          <CoinIndicator :pass_currency_type="3" :pass_cash="4"/>
        </div>
        <div v-else-if="slot.type === 'gold'" class="coin_align" :class="String.fromCharCode(97 + i)">
          <CoinIndicator :pass_currency_type="4" :pass_cash="7"/>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="is_classic === true">
    <img src="../../assets/Gameboard/Sections/section_nescafe_classic.png" class="PlayerBoard_Plot">
    <div class="Production_Line_Box">
      <CoinIndicator :pass_currency_type="2" :pass_cash="productionLine"/>
      <button v-if="requireDepreciate" class="depreciate_btn" @click="get_cc">Depreciate</button>
    </div>
    <div class="Work_In_Progress_Box">
      <CoinIndicator :pass_currency_type="3" ref="WIP_Classic" :pass_cash="factory.wip" />
    </div>
    <div class="Line_Units">
      <div v-for="(slot, i) in (factory.slots || [])" :key="i">
        <div v-if="slot.subtype === 'raw'">
          <div v-if="slot.type === 'classic'" class="coin_align" :class="String.fromCharCode(97 + i)">
            <CoinIndicator :pass_currency_type="3" :pass_cash="2"/>
          </div>
        </div>

        <div v-if="slot.subtype === 'co_pack'">
          <div v-if="slot.type === 'classic'" class="coin_align" :class="String.fromCharCode(97 + i)">
            <CoinIndicator :pass_currency_type="3" :pass_cash="4"/>
          </div>
          <div v-if="slot.type === 'gold'" class="coin_align" :class="String.fromCharCode(97 + i)">
            <CoinIndicator :pass_currency_type="4" :pass_cash="7"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="is_gold === true">
    <img src="../../assets/Gameboard/Sections/section_nescafe_gold.png" class="PlayerBoard_Plot">
    <div class="Production_Line_Box">
      <CoinIndicator :pass_currency_type="2" :pass_cash="productionLine"/>
      <button v-if="requireDepreciate" class="depreciate_btn" @click="get_cc">Depreciate</button>
    </div>
    <div class="Work_In_Progress_Box">
      <CoinIndicator :pass_currency_type="4" ref="WIP_Gold" :pass_cash="factory.wip" />
    </div>
    <div class="Line_Units">
      <div v-for="(slot, i) in (factory.slots || [])" :key="i">
        <div v-if="slot.subtype === 'raw'">
          <div v-if="slot.type === 'gold'" class="coin_align" :class="String.fromCharCode(97 + i)">
            <CoinIndicator :pass_currency_type="4" :pass_cash="4"/>
          </div>
        </div>

        <div v-if="slot.subtype === 'co_pack'">
          <div v-if="slot.type === 'classic'" class="coin_align" :class="String.fromCharCode(97 + i)">
            <CoinIndicator :pass_currency_type="3" :pass_cash="4"/>
          </div>
          <div v-if="slot.type === 'gold'" class="coin_align" :class="String.fromCharCode(97 + i)">
            <CoinIndicator :pass_currency_type="4" :pass_cash="7"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <img src="../../assets/Gameboard/Sections/section_buy_plot.png" class="PlayerBoard_Plot">
  </div>

  <div v-if="vDebug" style="position:absolute; color: black; font-weight: 500;">
    <div style="padding: 3rem 1rem 1rem 1rem;">
      <div>{{ factory.type }}</div>
      <div v-for="slot in (factory.slots || [])" :key="slot">
        {{ slot.type }} - {{ slot.subtype }}
      </div>
      <template v-if="factory.type === 'gold' || factory.type === 'classic'">
        <div>production_line: {{ productionLine }}</div>
        <div>wip: {{ factory.wip }}</div>

        <div v-if="requireWipPayment">
          <button @click="payWip">Pay WIP</button>
        </div>
        <div v-if="requireDepreciate">
          <button @click="get_cc">Depreciate</button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Profitability from '@/profitability-nestle-js';
import { mapGetters } from 'vuex';
import CoinIndicator from '../Coin_Indicator.vue';

export default {
  components: { CoinIndicator },
  props: {
    index: Number,
  },
  emits: ['spawn_coin', 'depreciated'],
  data() {
    return {
      WIP: 0,
    };
  },
  computed: {
    ...mapGetters({
      vDebug: 'debug',
    }),
    ...mapGetters('profitability/participant', {
      vGameboard: 'gameboard',
      vFactories: 'factories',
      vPhase: 'phase',
    }),
    factory() {
      return this.vFactories[this.index] || {};
      // const { factories } = this.vGameboard;
      // return factories && factories[this.index] || {};
    },
    productionLine() {
      return this.factory.production_line;
    },
    is_warehouse() {
      return this.factory.type === 'warehouse';
    },
    is_classic() {
      return this.factory.type === 'classic';
    },
    is_gold() {
      return this.factory.type === 'gold';
    },
    converted() {
      return this.factory.type !== null;
    },
    wip() {
      return this.factory.wip;
    },
    requireWipPayment() {
      return (this.is_classic || this.is_gold) && this.wip === 0;
    },
    requireDepreciate() {
      if (this.vPhase.period !== 0) {
        if (this.vPhase.market_phase === 2 && this.vPhase.phase_step === 4)
          return (this.is_classic || this.is_gold) && this.factory.last_depreciation_period !== this.vPhase.period;
      } else {
        if (this.vPhase.phase_step === 9)
          return (this.is_classic || this.is_gold) && this.factory.last_depreciation_period !== this.vPhase.period;
      }
      return false;
    },
    depreciateAmount() {
      return this.is_classic ? 1 : 2;
    },
  },
  watch: {
    requireDepreciate(next) {
      if (!next) this.$emit('depreciated');
    },
  },
  methods: {
    get_cc() {
      const this_plot = 'Plot_' + (this.index + 1);
      this.$emit('spawn_coin', this_plot, this.depreciateAmount);
    },
    Change_Plot(this_type) {
      switch (this_type) {
        case 0:
          this.is_warehouse = true;
          break;
        case 1:
          if (this.vPhase.period !== 0 && this.vPhase.phase_step === 3) {
            this.is_classic = true;
            Profitability.Participant.buyEquipment(this.index, 'classic');
          } else if (this.vPhase.period === 0 && this.vPhase.phase_step === 2) {
            this.is_classic = true;
            Profitability.Participant.mbsBuyEquipment(this.index, 'classic');
          } else {
            return;
          }
          break;
        case 2:
          if (this.vPhase.period !== 0 && this.vPhase.phase_step === 3) {
            this.is_gold = true;
            Profitability.Participant.buyEquipment(this.index, 'gold');
          } else {
            return;
          }
          break;
      }
      this.converted = true;
    },
    Update_Plot(cash) {
      if (this.vPhase.period !== 0 && this.vPhase.phase_step === 3) {
        if (this.is_classic)
          this.$refs['WIP_Classic'].cash = cash;

        if (this.is_gold)
          this.$refs['WIP_Gold'].cash = cash;

        Profitability.Participant.buyEquipmentPayWip(this.index, cash);
      } else if (this.vPhase.period === 0 && this.vPhase.phase_step === 2) {
        if (this.is_classic)
          this.$refs['WIP_Classic'].cash = cash;

        Profitability.Participant.mbsBuyEquipmentPayWip(this.index, cash);
      }
    },
    payWip() {
      const amount = this.is_classic ? 10 : 20;
      if (this.vPhase.period !== 0 && this.vPhase.phase_step === 3) {
        Profitability.Participant.buyEquipmentPayWip(this.index, amount);
      } else if (this.vPhase.period === 0 && this.vPhase.phase_step === 2) {
        Profitability.Participant.mbsBuyEquipmentPayWip(this.index, amount);
      }
    },
    depreciate() {
      if (this.vPhase.period !== 0) {
        Profitability.Participant.depreciateEquipment(this.index);
      } else {
        Profitability.Participant.mbsDepreciateEquipment(this.index);
      }
    },
  },
};
</script>

<style lang="scss">
.PlayerBoard_Plot {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.Line_Units {
  position: absolute;
  /* background: rgb(0,0,0,0.5); */
  width: 40%;
  height: 50%;
  top: 10%;
  right: 64%;
  /* display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 7.4vh 7vh;
  grid-gap: 0 0.3rem; */

  .coin_align {
    position: absolute;
    width: 100%;
    height: 100%;

    &.a {
      top: 0;
      left: 0;
    }

    &.b {
      top: 0;
      left: 60%;
    }

    &.c {
      top: 46%;
      left: 0;
    }

    &.d {
      top: 46%;
      left: 60%;
    }

    &.e {
      top: 90.5%;
      left: 0;
    }

    &.f {
      top: 90.5%;
      left: 60%;
    }
  }
}

.Warehouse_Units {
  position: absolute;
  /* background: rgb(0,0,0,0.5); */
  width: 40%;
  height: 50%;
  top: 20.5%;
  right: 61%;
  /* display: grid;
  grid-template-columns: 4.75vw 4.75vw 4.75vw;
  grid-template-rows: 8.6vh;
  grid-gap: 0 0.3rem; */

  .coin_align {
    position: absolute;
    width: 100%;
    height: 100%;

    &.a {
      top: 0;
      left: 0;
    }

    &.b {
      top: 0;
      left: 77.9%;
    }

    &.c {
      top: 0;
      left: 155.5%;
    }

    &.d {
      top: 65%;
      left: 0;
    }

    &.e {
      top: 65%;
      left: 77.9%;
    }

    &.f {
      top: 65%;
      left: 155.5%;
    }
  }

}

.Production_Line_Box {
  top: 5%;
  right: 10px;
  position: absolute;
  width: 40%;
  height: 50%;
  padding-left: 2.5px;
}

.Work_In_Progress_Box {
  top: 47.5%;
  right: 6px;
  position: absolute;
  width: 40%;
  height: 50%;
}

.depreciate_btn {
  background: #a4d65d;
  border: none;
  border-radius: 30px;
  position: absolute;
  width: 87%;
  height: 20%;
  top: -2%;
  right: 3%;
  font-size: 16px;
  z-index: 10;
}

.depreciate_btn:hover {
  background: #729541;
}

.depreciate_btn:active {
  background: #392c23;
  color: white;
}
</style>
