<template>
  <div class="Login_Container" :style="cssProps">
    <Background/>
    <div class="LoginScreen">
      <div class="form" :class="{error}">
        <div class="TID">
          <label class="label">Training ID</label>
          <input class="input" v-model="training_id" placeholder="Training ID" maxlength="30">
        </div>
        <div class="UN">
          <label class="label">Username</label>
          <input class="input" v-model="username" placeholder="Username" maxlength="30">
        </div>
        <div class="PW">
          <label class="label">Password</label>
          <input class="input" type="password" v-model="password" placeholder="Password" maxlength="30">
        </div>

        <div class="btns">
          <button class="btn-login" @click="login">LOGIN</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Profitability from '@/profitability-nestle-js';
import { useWindowSize } from 'vue-window-size';
import Background from '../components/Background.vue';

export default {
  components: { Background },
  emits: [
    'login',
  ],
  data() {
    // initialize username/password with environment variable can help you to speed up development
    // without keep on repeating key in username/password
    // BUT beware, ONLY set the .env for development environment only
    return {
      training_id: process.env.VUE_APP_TRAINING_ID ?? '',
      username: process.env.VUE_APP_USERNAME ?? '',
      password: process.env.VUE_APP_PASSWORD ?? '',

      error: null,
    };
  },
  setup() {
    const { width, height } = useWindowSize();

    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    login() {
      this.error = null;

      const credentials = {
        training_id: this.training_id,
        username: this.username,
        password: this.password,
      };

      return Profitability.Auth.login(credentials).then(response => {
        this.$emit('login', Profitability.role);

        switch (Profitability.role) {
          case 'participant':
            this.$router.push({ name: 'Participant' });
            break;
          case 'trainer':
            this.$router.push({ name: 'Facilitator' });
            break;
          default:
            Profitability.debug('login')('Invalid Role');
            break;
        }
      }).catch(err => {
        if (err.response) {
          const { response } = err;
          const { data } = response;
          const { message } = data;

          // TODO: display error message if login failed
          Profitability.debug('Login failed!')(message);

          // Form error structure: -
          // errors = {
          //   [field]: [errorMessage],
          // }
          // TODO: display error for each field respectively

          return;
        }

        if (err.error === 'unauthorized' || err.error === 'invalid_training_id') {
          this.error = err.error;
        }

        // unexpected error occurred!
        throw err;
      });
    },
    return_login_info() {
      // console.log(this.UN)
      // console.log(this.PW)
      this.$emit('login', 0, this.UN, this.PW);
    },
  },
  computed: {
    cssProps() {
      // the base resolution
      const width = 1920;
      const height = 1080;
      const ratio = width / height;

      // the actual view resolution
      let vw = this.windowWidth;
      let vh = this.windowHeight;
      const vRatio = vw / vh;

      // maintain aspect ratio
      if (vRatio >= ratio) {
        // when height is dominant, adjust the width
        vw = vh * ratio;
      } else {
        // when width is dominant, adjust the height
        vh = vw / ratio;
      }

      // get the scaleW and scaleH
      const scaleW = vw / width;
      const scaleH = vh / height;

      // center the view
      const marginTop = (this.windowHeight - vh) / 2;
      const marginLeft = (this.windowWidth - vw) / 2;

      return {
        // '--width': scaleW,
        // '--height': scaleH,
        // '--ori-width': width + "px",
        // '--ori-height': height + "px",

        // you can direct set the css style here, unless you need to use the variable in hierarchy child
        width: `${width}px`,
        height: `${height}px`,
        transform: `scale(${scaleW}, ${scaleH})`,

        'margin-top': `${marginTop}px`,
        'margin-left': `${marginLeft}px`,
      };
    },
  },
};
</script>

<style lang="scss">
.Login_Container {
  //width: var(--ori-width);
  //height: var(--ori-height);
  //transform: scale(var(--height), var(--width));
  transform-origin: top left;
  overflow: hidden;

  .LoginScreen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-image: url('../assets/Login/login_screen_3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90% 100%;
    width: 100%;
    height: 100%;
    z-index: 0;

    display: flex;
    justify-content: center;
  }

  .form {
    margin-top: 450px;
    background: url(/img/background.de6390a4.jpg) 50% 50%;
    border-radius: 1rem;
    width: 770px;
    height: 460px;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    .TID, .UN, .PW {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .label {
        text-align: left;
        padding: 0.5rem;
        font-size: 1.5rem;
        color: white;
        font-family: NestleBrush_Reg;
      }

      .input {
        background: white;
        border-radius: 0.5rem;
        width: 100%;
        height: 4.25rem;
        padding: 0.5rem;
        font-size: 24px;
        font-family: NestleBrush_Reg;
      }
    }

    &.error {
      .input {
        border: 5px solid red;
      }
    }

    .btns {
      display: flex;
      justify-content: flex-end;
    }

    .btn-login {
      margin-top: 2rem;
      z-index: 1;
      width: 300px;
      height: 60px;
      background: #a4d65d;
      border: none;
      border-radius: 40px;
      font-size: 40px;

      &:hover {
        background: #729541;
      }

      &:active {
        background: #392c23;
        color: white;
      }
    }
  }
}
</style>
