<template>
<div class="Section_Receivable">
    <img src="../../assets/Gameboard/Sections/section_receivable.png" class="PlayerBoard_Receivable">

    <div class="receivable_box">
        <CoinIndicator :pass_currency_type="1" :pass_cash="receivables" />
        <button v-if="receivables_canReceive" class="receive_button" @click="collectPayment"></button>
    </div>

    <div
        :class="{ frame_border: phase === 4 }"
    ></div>
</div>

</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue'
import { mapGetters } from "vuex";

export default {
    props: {
        phase: Number,
        receivables: Number
    },
    components: { CoinIndicator },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        })
    },
    methods: {

    },
}
</script>

<style lang="scss">
    .Section_Receivable{
        top: 76%;
        left: 6.95%;
        position: fixed;
        width: 16.5%;
        height: 20.65%;

        .PlayerBoard_Receivable{
            top: 76%;
            left: 6.95%;
            position: fixed;
            width: 16.5%;
            height: 20.65%;
        }

        .Section_Receivable > .debug {
            position: absolute;
            inset: 0;
            margin-top: 1.5rem;
            pointer-events: none;

            display: flex;
            font-weight: bold;
            color: black;
        }

        .Section_Receivable > .debug button {
            pointer-events: auto;
        }

        .receivable_box {
            font-weight: normal;
            position: relative;
            width: 33%;
            height: 100%;
            margin-top: 5%;
        }

        .receivable_amt {
            top: 93.2%;
            right: 76.75%;
        }

        .receive_button {
            position: absolute;
            top: -800%;
            left: 140%;
            width: 40%;
            height: 20%;
            background: url("../../assets/Gameboard/Sections/Collect_Credit_Button.png");
            background-size: 100% 100%;
            border: none;
        }

        .frame_border {
            border: 5px solid red;
            border-radius: 8px;
            position: absolute;
            top: 0;
            width: 95%;
            height: 100%;
        }
    }
</style>
