<style lang="scss">

.form_panel.Income {
    right: 7%;
    width: 26%;

    .title {
        padding: 0;
        margin: 0;
        position: absolute;
        left: 6.5%;
        width: 100%;
        color: white;
        font-size: 27px;
    }

    .tabs_group {
        position: absolute;
        width: 13.5%;
        height: 100%;

        .bg {
            position: absolute;
            background: rgb(57, 44, 35, 0.9);
            right: 0;
            width: 84%;
            height: 100%;
            border-radius: 0 0 0 12px;
        }

        .tabs {
            position: absolute;
            background: #63513d;
            width: 100%;
            height: 8.5%;
            top: 0;
            right: 0;
            border-radius: 12px 0 0 12px;

            &.hidden {
                width: 73%;
                height: 6.5%;
                background: #524032;
                font-size: 0.75em;
            }

            &.p3 { top: 10%; }
            &.p4 { top: 20%; }
            &.p5 { top: 30%; }

            button {
                border: none;
                background: none;
                width: 100%;
                height: 100%;
                font-family: NestleBrush_Reg;
                color: white;
                font-size: 1.5em;
            }
        }
    }

    .is_form {
        position: absolute;
        background: url('../../assets/Gameboard/Panel/panel_income_statement.png');
        background-size: 100% 100%;
        width: 88%;
        height: 100%;
        right: 0;

        .IS_Input {
            position: fixed;
            width: 2%;
            height: 2.25%;

            background: transparent;
            border: none;
            outline: none;
            text-align: center;
            font-size: 16px;
            color: white;
            font-family: NestleBrush_Reg;

            &.NC {
                left: 81.9%;
                &.NNS { top: 34.65%; }
                &.COGS { top: 37.95%; }
                &.MC { top: 41.18%; }
                &.PFME { top: 44.2%; }
                &.RD { top: 47.4%; }
                &.FD { top: 50.9%; }
                &.TPFE { top: 53.9%; }
                &.PC { top: 57.2%; }
            }

            &.NG {
                left: 85.66%; color: black;
                &.NNS { top: 34.65%; }
                &.COGS { top: 37.95%; }
                &.MC { top: 41.18%; color:white}
                &.PFME { top: 44.2%; }
                &.RD { top: 47.4%; }
                &.FD { top: 50.9%; }
                &.TPFE { top: 53.9%; }
                &.PC { top: 57.2%; color:white}
            }

            &.Total {
                left: 89.6%;
                &.PC { top: 57.2%;}
                &.FFOH { top: 60.4%;}
                &.MOGE { top: 63.7%;}
                &.TFOE { top: 66.8%;}
                &.OP { top: 70%;}
                &.IL { top: 73.25%;}
                &.EBT { top: 76.5%;}
                &.TAX { top: 79.7%;}
                &.NPOL { top: 82.9%; color: black;}
                &.REL { top: 87.4%; cursor: default;}
                &.NPP { top: 89.45%; color: black;}
                &.DIV { top: 92.1%;}
                &.EARNS { top: 94.6%; color: black;}
            }
        }

        input[type="number"] {
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .answer_check {
            background: url("../../assets/Gameboard/Period/checked_step.png");
            background-size: 100% 100%;
            position: absolute;
            width: 4.5%;
            height: 2.5%;
            z-index: 2;

            &.NC {
                left: 60.00%;
                &.NNS { top: 10.6%; }
                &.COGS { top: 15.10%; }
                &.MC { top: 19.80%; }
                &.PFME { top: 24.2%; }
                &.RD { top: 29.0%; }
                &.FD { top: 33.7%; }
                &.TPFE { top: 38.1%; }
                &.PC { top: 42.5%; }
            }

            &.NG {
                left: 76.9%;
                &.NNS { top: 10.6%; }
                &.COGS { top: 15.10%; }
                &.MC { top: 19.80%; }
                &.PFME { top: 24.2%; }
                &.RD { top: 29.0%; }
                &.FD { top: 33.7%; }
                &.TPFE { top: 38.1%; }
                &.PC { top: 42.5%; }
            }

            &.Total {
                left: 93.8%;
                &.PC { top: 42.5%;}
                &.FFOH { top: 47.3%;}
                &.MOGE { top: 52.1%;}
                &.TFOE { top: 56.5%;}
                &.OP { top: 61.0%;}
                &.IL { top: 65.7%;}
                &.EBT { top: 70.3%;}
                &.TAX { top: 74.8%;}
                &.NPOL { top: 79.5%; color: black;}
                &.REL { top: 85.5%;;}
                &.NPP { top: 89.45%; color: black;}
                &.DIV { top: 92.8%; cursor: pointer;}
                &.EARNS { top: 96.2%;}
            }
        }
    }

    // always put the css/scss class under a parent class, so it won't affect other when the class name is same
    .debug {
        position: absolute;
        display: flex;
        flex-direction: column;
        color: black;
        font-weight: bold;
        z-index: 5;
        pointer-events: none;

        button {
            pointer-events: auto;
        }
    }

    .debug > div {
        flex: 1;
    }
}

</style>

<template>
    <div class="form_panel" :class="panelName">
        <div class="tabs_group">
            <div class="bg"></div>

            <template v-for="i in 4" :key="i">
                <div v-if="i > 0 && i <= vPhase.period" class="tabs"
                     :class="{[`p${i + 1}`]: true, hidden: targetPeriod !== i}">
                    <button @click="setPeriod(i)">P{{ (i + 1) }}</button>
                </div>
            </template>
        </div>

        <p class="title">Income Statement</p>

        <div class="is_form">
            <div v-if="vDebug" class="debug">
                <button @click="hackFill">Autofill</button>
                NC NS: {{ ans.classic.net_sales }}, {{ check.classic.net_sales }}
                <br>
                NC COG: {{ ans.classic.cost_of_goods_sold }}, {{ check.classic.cost_of_goods_sold }}
                <br>
                NC MC: {{ ans.classic.marg_contribution }}, {{ check.classic.marg_contribution }}
                <br>
                NC PFME: {{ ans.classic.pfme }}, {{ check.classic.pfme }}
                <br>
                NC RD: {{ ans.classic.research_and_dev }}, {{ check.classic.research_and_dev }}
                <br>
                NC FD: {{ ans.classic.factory_deprec }}, {{ check.classic.factory_deprec }}
                <br>
                NC TPFE: {{ ans.classic.total_fixed_expenses }}, {{ check.classic.total_fixed_expenses }}
                <br>
                NC PC: {{ ans.classic.product_contribution }}, {{ check.classic.product_contribution }}
                <br>
                <br>
                NG NS: {{ ans.gold.net_sales }}, {{ check.gold.net_sales }}
                <br>
                NG COG: {{ ans.gold.cost_of_goods_sold }}, {{ check.gold.cost_of_goods_sold }}
                <br>
                NG MC: {{ ans.gold.marg_contribution }}, {{ check.gold.marg_contribution }}
                <br>
                NG PFME: {{ ans.gold.pfme }}, {{ check.gold.pfme }}
                <br>
                NG RD: {{ ans.gold.research_and_dev }}, {{ check.gold.research_and_dev }}
                <br>
                NG FD: {{ ans.gold.factory_deprec }}, {{ check.gold.factory_deprec }}
                <br>
                NG TPFE: {{ ans.gold.total_fixed_expenses }}, {{ check.gold.total_fixed_expenses }}
                <br>
                NG PC: {{ ans.gold.product_contribution }}, {{ check.gold.product_contribution }}
                <br>
                <br>
                Total PC: {{ ans.total.pc }}, {{ check.total.pc }}
                <br>
                Total FFOH: {{ ans.total.ffoh }}, {{ check.total.ffoh }}
                <br>
                Total MOGE: {{ ans.total.moge }}, {{ check.total.moge }}
                <br>
                Total TFOE: {{ ans.total.tfoe }}, {{ check.total.tfoe }}
                <br>
                Total OP: {{ ans.total.op }}, {{ check.total.op }}
                <br>
                Total IL: {{ ans.total.il }}, {{ check.total.il }}
                <br>
                Total EBT: {{ ans.total.ebt }}, {{ check.total.ebt }}
                <br>
                Total TAX: {{ ans.total.tax }}, {{ check.total.tax }}
                <br>
                Total NPOL: {{ ans.total.npol }}, {{ check.total.npol }}
                <br>
                Total NPP: {{ ans.total.npol }}, {{ check.total.npol }}
                <br>
                Total DIV: {{ ans.total.dividends }}, {{ check.total.dividends }}
                <br>
                Total EARNS: {{ ans.total.earns }}, {{ check.total.earns }}
            </div>

            <template v-if="targetPeriod === vPhase.period && this.vPhase.market_phase >= 2 && this.vPhase.phase_step === 5">
                <template v-for="(className, i) in classNames.nescafe" :key="i">
                    <div
                        v-if="check.classic[variables.nescafe[i]]"
                        class="answer_check NC"
                        :class="className"
                    ></div>
                </template>
                <template v-for="(className, i) in classNames.nescafe" :key="i">
                    <div
                        v-if="check.gold[variables.nescafe[i]]"
                        class="answer_check NG"
                        :class="className"
                    ></div>
                </template>
                <template v-for="(className, i) in classNames.total" :key="i">
                    <div
                        v-if="check.total[variables.total[i]] && (
                            (i > 0 && check.total[variables.total[i - 1]]) ||
                            (i === 0 &&
                            (
                                check.classic[variables.nescafe[variables.nescafe.length - 1]] &&
                                check.gold[variables.nescafe[variables.nescafe.length - 1]]
                            )))"
                        class="answer_check Total"
                        :class="className"
                    ></div>
                </template>
            </template>

            <template v-for="(className, i) in classNames.nescafe" :key="i">
                <input
                       v-model="input.classic[variables.nescafe[i]]"
                       class="IS_Input NC"
                       :class="className"
                       type="number"
                       maxlength="3"
                       @change="check_input"
                       :disabled="targetPeriod !== vPhase.period || vPhase.phase_step !== 5"
                       @focus="$event.target.select()"
                >
            </template>

            <template v-for="(className, i) in classNames.nescafe" :key="i">
                <input
                       v-model="input.gold[variables.nescafe[i]]"
                       class="IS_Input NG"
                       :class="className"
                       type="number"
                       maxlength="3"
                       @change="check_input"
                       :disabled="targetPeriod !== vPhase.period || vPhase.phase_step !== 5"
                       @focus="$event.target.select()"
                >
            </template>

            <template v-for="(className, i) in classNames.total" :key="i">
                <input
                    v-model="input.total[variables.total[i]]"
                    class="IS_Input Total"
                    :class="className"
                    type="number"
                    maxlength="3"
                    @change="check_input(`total.${variables.total[i]}`)"
                    :disabled="targetPeriod !== vPhase.period || vPhase.phase_step !== 5"
                    @focus="$event.target.select()"
                >

            </template>

            <p class="IS_Input Total REL">{{ ans.total.retained_earnings_prev }}</p>

        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    props: {
        panelName: String,
    },
    emits: [
        'closePanel',
    ],
    data() {
        return {
            classNames: {
                nescafe: [
                    'NNS',
                    'COGS',
                    'MC',
                    'PFME',
                    'RD',
                    'FD',
                    'TPFE',
                    'PC',],
                total: [
                    'PC',
                    'FFOH',
                    'MOGE',
                    'TFOE',
                    'OP',
                    'IL',
                    'EBT',
                    'TAX',
                    'NPOL',
                    'NPP',
                    'DIV',
                    'EARNS',
                ],
            },
            variables: {
                nescafe: [
                    'net_sales',
                    'cost_of_goods_sold',
                    'marg_contribution',
                    'pfme',
                    'research_and_dev',
                    'factory_deprec',
                    'total_fixed_expenses',
                    'product_contribution',
                ],
                total: [
                    'pc',
                    'ffoh',
                    'moge',
                    'tfoe',
                    'op',
                    'il',
                    'ebt',
                    'tax',
                    'npol',
                    'npp',
                    'dividends',
                    'earns',
                ],
            },

            autofill: false,
            has_entered: false,

            types: ['classic', 'gold'],
            typeMapper: {
                sales: 'net_sales',
                cost: 'cost_of_goods_sold',
                margin: 'marg_contribution',
                pfme: 'pfme',
                rnd: 'research_and_dev',
                depreciation: 'factory_deprec',
                total_product_fixed_expenses: 'total_fixed_expenses',
                product_contribution: 'product_contribution',
            },
            mapper: {
                pc: 'pc',
                ffoh: 'ffoh',
                moge: 'moge',
                total_fixed_overhead_expenses: 'tfoe',
                trading_operating_profit: 'op',
                interest: 'il',
                earning_before_tax: 'ebt',
                tax: 'tax',
                net_profit: ['npol', 'npp'],
                retained_earnings_prev: 'retained_earnings_prev',
                dividends: 'dividends',
                retained_earnings: 'earns',
            },

            // Correct Answer
            ans: {
                classic: {
                    net_sales: 0,
                    cost_of_goods_sold: 0,
                    marg_contribution: 0,
                    pfme: 0,
                    research_and_dev: 0,
                    factory_deprec: 0,
                    total_fixed_expenses: 0,
                    product_contribution: 0,
                },
                gold: {
                    net_sales: 0,
                    cost_of_goods_sold: 0,
                    marg_contribution: 0,
                    pfme: 0,
                    research_and_dev: 0,
                    factory_deprec: 0,
                    total_fixed_expenses: 0,
                    product_contribution: 0,
                },
                total: {
                    pc: 0,
                    ffoh: 0,
                    moge: 0,
                    tfoe: 0,
                    op: 0,
                    il: 0,
                    ebt: 0,
                    tax: 0,
                    npol: 0,
                    retained_earnings_prev: 0,
                    npp: 0,
                    dividends: 0,
                    earns: 0,
                },
            },

            // User Input
            input: {
                classic: {
                    net_sales: null,
                    cost_of_goods_sold: null,
                    marg_contribution: null,
                    pfme: null,
                    research_and_dev: null,
                    factory_deprec: null,
                    total_fixed_expenses: null,
                    product_contribution: null,
                },
                gold: {
                    net_sales: null,
                    cost_of_goods_sold: null,
                    marg_contribution: null,
                    pfme: null,
                    research_and_dev: null,
                    factory_deprec: null,
                    total_fixed_expenses: null,
                    product_contribution: null,
                },
                total: {
                    pc: null,
                    ffoh: null,
                    moge: null,
                    tfoe: null,
                    op: null,
                    il: null,
                    ebt: null,
                    tax: null,
                    npol: null,
                    npp: null,
                    dividends: null,
                    earns: null,
                },
            },
            // backup of user input
            inputBak: {
                classic: {
                    net_sales: null,
                    cost_of_goods_sold: null,
                    marg_contribution: null,
                    pfme: null,
                    research_and_dev: null,
                    factory_deprec: null,
                    total_fixed_expenses: null,
                    product_contribution: null,
                },
                gold: {
                    net_sales: null,
                    cost_of_goods_sold: null,
                    marg_contribution: null,
                    pfme: null,
                    research_and_dev: null,
                    factory_deprec: null,
                    total_fixed_expenses: null,
                    product_contribution: null,
                },
                total: {
                    pc: null,
                    ffoh: null,
                    moge: null,
                    tfoe: null,
                    op: null,
                    il: null,
                    ebt: null,
                    tax: null,
                    npol: null,
                    npp: null,
                    dividends: null,
                    earns: null,
                },
            },

            // Check, is User Input Match Correct Answer
            check: {
                classic: { init: true },
                gold: { init: true },
                total: {},
            },

            // the current period
            targetPeriod: null,
        };
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vTeam: 'team',
            vTeamData: 'teamData',
            vMarketingData: 'marketing_data',
            vTeamMarketingCards: 'teamMarketCards',
            vPhase: 'phase',
            vTeamDataByPeriod: 'teamDataByPeriod',
        }),
        incomeStatement() {
            return this.vTeamData.income_statement || {};
        },
        submitted() {
            return this.vTeamData.data?.income_statement || false;
        },
        incomeStatementData() {
            return this.vTeamData.income_statement_data || {};
        },
    },
    watch: {
        vPhase(next, prev) {
            if (this.vPhase.market_phase === 2 && this.vPhase.phase_step === 5) {
                this.targetPeriod = this.vPhase.period;
            } else if (this.vPhase.period !== prev.period) {
                this.targetPeriod = this.vPhase.period;
            }
        },
        incomeStatement() {
            this.initializeData();
        },
        incomeStatementData() {
            _.each(['classic', 'gold', 'total'], (type) => {
                _.each(this.variables[type === 'total'? 'total' : 'nescafe'], (key) => {
                    _.set(this.$data.input, [type, key], _.get(this.incomeStatementData, [type, key], null));
                });
            });
            this.$data.inputBak = this.$data.input;
            this.check_input();
        },
        targetPeriod(next, prev) {
            if (next !== prev) {
                if (next === this.vPhase.period && this.vPhase.market_phase >= 2 && this.vPhase.phase_step === 5) {
                    // restore input
                    this.input = this.inputBak;
                } else {
                    if (prev === this.vPhase.period && this.vPhase.market_phase >= 2 && this.vPhase.phase_step === 5) {
                        // backup input
                        this.inputBak = this.input;
                    }

                    //
                    this.input = this.getAns(this.getInputStatementByPeriod(next));
                }
            }
        },
    },
    mounted() {
        this.targetPeriod = this.vPhase.period;
        this.initializeData();
    },
    methods: {
        setPeriod(targetPeriod) {
            this.targetPeriod = targetPeriod;
        },
        getInputStatementByPeriod(period) {
            return this.vTeamDataByPeriod[period]?.income_statement || {};
        },
        getAns(incomeStatement) {
            const ans = {
                classic: {},
                gold: {},
                total: {},
            };

            if (_.keys(incomeStatement).length > 0) {
                _.each(this.types, type => {
                    _.each(this.typeMapper, (value, key) => {
                        let values = value;
                        if (!Array.isArray(value)) {
                            values = [value];
                        }
                        _.each(values, field => {
                            ans[type][field] = incomeStatement[key][type];
                        });
                    });
                });

                _.each(this.mapper, (value, key) => {
                    let values = value;
                    if (!Array.isArray(value)) {
                        values = [value];
                    }
                    _.each(values, field => {
                        ans.total[field] = incomeStatement[key];
                    });
                });
                ans.total.pc = incomeStatement.product_contribution.total;
            }

            return ans;
        },
        initializeData() {
            this.ans = this.getAns(this.incomeStatement);

            if (this.autofill) {
                this.autofillForm();
            }
        },
        autofillForm() {
            _.each(['classic', 'gold', 'total'], type => {
                _.each(_.keys(this.$data.ans[type]), key => {
                    this.$data.input[type][key] = this.$data.ans[type][key];
                });
            });
            this.$data.inputBak = this.$data.input;
            this.check_input();
        },
        check_input(variable) {
            // if (_.keys(this.incomeStatement).length === 0) return

            _.each(['classic', 'gold', 'total'], type => {
                _.each(_.keys(this.$data.ans[type]), key => {
                    this.$data.check[type][key] = this.$data.input[type][key] == this.$data.ans[type][key];
                });
            });

            if (variable === 'total.earns') {
                this.submit();
            }

        },
        submit() {
            if (this.submitted) return;
            if (this.check.total.earns === true && this.vPhase.market_phase >= 2 && this.vPhase.phase_step === 5) {
                Profitability.Participant.submitIncomeStatement({
                    income_statement: {
                        ...this.$data.input,
                    },
                    proceed: this.check.total.earns,
                });
            }
        },
        hackFill() {
            this.autofillForm();
            this.submit();
        },
    },
}

</script>
