<style lang="scss">

.post-market-phase {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    font-family: NestleBrush_Reg;

    .post-market-phase-content {
        display: flex;
        flex: 1;

        .ts {
            display: flex;
            flex: 1;
            margin: 0 0.25rem;
        }

        .is_bs {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 0.5% 0 0 0;

            .tab {
                display: flex;

                .IS { left: 50.75%; }
                .BS { right: 0.55%; }
                button {
                    background: none;
                    font-family: NestleBrush_Reg;
                    border-radius: 15px 15px 0 0;
                    position: absolute;
                    bottom: 93.8%;
                    width: 24%;
                    height: 5.45%;
                    border: 0;
                    color: white;
                    font-size: 1.8rem;


                    &.inactive {
                        background: rgb(61, 55, 49, 1);
                        width: 23%;
                        height: 4.5%;
                        font-size: 1.5rem;
                        color: gray;
                    }

                    &.active {
                        background: rgb(99, 81, 61, 1);
                    }
                }
            }
        }
    }

    .open-board-button {
        background: none;
        border: none;
        position: absolute;
        width: 5.5%;
        height: 5%;

        &.a { top: 11.9%; left: 19.05%; }
        &.b { top: 11.9%; left: 43.1%; }
        &.c { top: 40.2%; left: 19.05%; }
        &.d { top: 40.2%; left: 43.1%; }
        &.e { top: 70.5%; left: 19.05%; }
        &.f { top: 70.5%; left: 43.1%; }
    }

    .show_results_btn {
        background: #a4d65d;
        width: 16%;
        height: 7%;
        border: none;
        border-radius: 30px;

        position: absolute;
        right: 0;
        bottom: -7.5%;
    }
    .show_results_btn:hover {
        background: #729541;
    }
    .show_results_btn:active {
        background: #392c23;
        color: white;
    }

    .reset-button {
        background: #e1261d;
        color: white;
        border-radius: 25px;
        width: 30%;
        height: 7%;
        border: none;

        position: absolute;
        left: 0%;
        bottom: -7.5%;
    }
    .reset-button:hover {
        background: #9e1a15;
    }
    .reset-button:active {
        background: #392c23;
        color: white;
    }
}

</style>

<template>

<div class="post-market-phase">

    <div class="post-market-phase-content">

        <div class="ts">
            <TeamStatus></TeamStatus>

            <template v-for="(team, i) in vTeams" :key="i">
                <button class="open-board-button" :class="String.fromCharCode(97 + i)" @click="participant_box_ctrl(i)"></button>
            </template>
        </div>
        <div class="is_bs">
            <div class="tab">
                <button class="IS" :class="{active: tab === 'is', inactive: tab === 'bs'}" @click="tab = 'is'">Income Statement</button>
                <button class="BS" :class="{active: tab === 'bs', inactive: tab === 'is'}" @click="tab = 'bs'">Balance Sheet</button>
            </div>
            <template v-if="tab === 'is'">
                <TeamIncomeStatements></TeamIncomeStatements>
            </template>
            <template v-if="tab === 'bs'">
                <TeamBalanceSheets></TeamBalanceSheets>
            </template>
        </div>

    </div>

    <div class="controls">
        <button class="show_results_btn" @click="next">Show Results</button>
        <button class="reset-button" @click="reset">Reset to Start of Period/Phase</button>
    </div>

    <ViewParticipantsBoard v-if="participantBoxOpen"
        v-on:click="participantBoxOpen = false"
        :isAtPeriod0="false"
        :team_id="team_id"
    />

    <Alert v-if="trigger_alert == true"
        :alertOpen="trigger_alert"
        :message="message"
        :onlyClose="false"
        :popUpSize="'trainer'"
        @close_alert="trigger_alert = false"
        @confirm_alert="next_alert"
    />

    <Alert v-if="trigger_alert_next == true"
        :alertOpen="trigger_alert_next"
        :message="message2"
        :onlyClose="false"
        :popUpSize="'trainer'"
        @close_alert="trigger_alert_next = false"
        @confirm_alert="confirm_alert"
    />

    <Alert v-if="trigger_alert_2 == true"
        :alertOpen="trigger_alert_2"
        :message="reset_message"
        :onlyClose="false"
        :popUpSize="'trainer'"
        @close_alert="trigger_alert_2 = false"
        @confirm_alert="reset_alert"
    />
</div>

</template>

<script>
import Profitability from '@/profitability-nestle-js';
import TeamStatus from './TeamStatus';
import TeamIncomeStatements from './TeamIncomeStatements';
import TeamBalanceSheets from './TeamBalanceSheets';
import ViewParticipantsBoard from "../ViewParticipantsBoard"
import Alert from "@/components/Alert_PopUp";
import { mapGetters } from "vuex";

export default {
    components: {
        TeamStatus,
        TeamIncomeStatements,
        TeamBalanceSheets,
        ViewParticipantsBoard,
        Alert
    },
    data() {
        return {
            tab: "is",
            participantBoxOpen: false,

            message: "Are you sure you want to proceed?",
            message2: "Are you sure? Moving forward may cause the game to CRASH.",
            reset_message: "Are you sure?",
            trigger_alert: false,
            trigger_alert_next: false,
            trigger_alert_2: false,
            team: {},
            team_id: null,
        };
    },
    mounted() {
        // console.log("Team ID loaded: " + this.vTeams[0].id)
    },
    computed: {
        ...mapGetters('profitability/trainer', {
            vPhase: 'phase',
            vTeams: 'teams',
        }),
    },
    methods: {
        next() {
            // MBS will not show summary (marketPhase 3)
            if (this.vPhase.period === 0) {
                this.nextPeriod();
            } else {
                Profitability.Trainer.checkAllReady().then(() => {
                    this.nextPhase();
                }, e => {
                    this.trigger_alert = true;
                    this.confirm_alert;
                })
            }
        },
        next_alert() {
            this.trigger_alert = false;
            this.trigger_alert_next = true;
        },

        confirm_alert() {
            this.trigger_alert_next = false;
            this.nextPhase();
        },

        reset() {
            this.trigger_alert_2 = true;
        },
        reset_alert() {
            Profitability.Trainer.resetMarketPhase();
            this.trigger_alert_2 = false;
        },
        nextPhase() {
            Profitability.Trainer.nextMarketPhase();
        },
        nextPeriod() {
            Profitability.Trainer.nextPeriod();
        },
        participant_box_ctrl(num) {
            this.participantBoxOpen === true ? this.participantBoxOpen = false : this.participantBoxOpen = true
            this.team_id = this.vTeams[num].id
        },
    },
}
</script>
