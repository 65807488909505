<template>
  <div class="Section_Expenses">
    <img
      src="../../assets/Gameboard/Sections/section_expenses.png"
      class="PlayerBoard_Expenses"
    />

    <div class="box pfme" ref="PFME_Pay" :index="4">
      <CoinIndicator :pass_currency_type="1" :pass_cash="pfme" />
    </div>
    <div class="box rnd" ref="RnD_Pay" :index="5">
      <CoinIndicator :pass_currency_type="1" :pass_cash="rnd" />
    </div>
    <div
      class="box dep"
      ref="Depreciation"
      :class="{ frame_border: vPhase.period === 0 && vPhase.phase_step === 9 }"
    >
      <CoinIndicator :pass_currency_type="2" :pass_cash="depreciation" />
    </div>
    <div
      class="box ffoh"
      ref="FFOH_Pay"
      :class="{ frame_border: vPhase.period === 0 && vPhase.phase_step === 5 }"
      :index="6"
    >
      <CoinIndicator :pass_currency_type="1" :pass_cash="ffoh" />
    </div>
    <div
      class="box moge"
      ref="MOGE_Pay"
      :class="{ frame_border: vPhase.period === 0 && vPhase.phase_step === 5 }"
      :index="7"
    >
      <CoinIndicator :pass_currency_type="1" :pass_cash="moge" />
    </div>
    <div
      class="box interest"
      ref="Interest_Pay"
      :class="{ frame_border: vPhase.period === 0 && vPhase.phase_step === 8 }"
      :index="8"
    >
      <CoinIndicator :pass_currency_type="1" :pass_cash="interest" />
    </div>
    <div class="box tax" ref="Tax_Pay" :index="9">
      <CoinIndicator :pass_currency_type="10" :pass_cash="tax" />
    </div>
    <div class="box dividends" ref="Dividends_Pay" :index="10">
      <button
        class="info-btn"
        v-if="isTaxAndDividendsPhase && dividendType == 1"
        :class="{animate: !isDividendBtnClicked}"
        @click="clickDividendBtn()"
      ></button>
      <CoinIndicator :pass_currency_type="10" :pass_cash="dividends" />
    </div>

    <div v-if="vDebug" class="debug" style="font-weight: bold; color: black;">
            <div>
                <div>
                    pfme: {{ pfme }}
                    pfme_owe: {{ pfme_owe }}
                    <button v-if="canPay('pfme')" @click="payPFME">Pay</button>
                </div>
                <div>
                    rnd: {{ rnd }}
                    rnd_owe: {{ rnd_owe }}
                    <button v-if="canPay('rnd')" @click="payRnD">Pay</button>
                </div>
            </div>
            <div>
                <div>
                    depreciation: {{ depreciation }}
                </div>
                <div>
                    ffoh: {{ ffoh }}
                    ffoh_owe: {{ ffoh_owe }}
                    <button v-if="canPay('ffoh')" @click="payFFOH()">Pay</button>
                </div>
            </div>
            <div>
                <div>
                    moge: {{ moge }}
                    moge_owe: {{ moge_owe }}
                    <button v-if="canPay('moge')" @click="payMOGE()">Pay</button>
                </div>
                <div>
                    interest: {{ interest }}
                    interest_owe: {{ interest_owe }}
                    <button v-if="canPay('interest')" @click="payInterest()">Pay</button>
                </div>
            </div>
            <div>
                <div>
                    tax: {{ tax }}
                    tax_owe: {{ tax_owe }}
                    <button v-if="canPay('tax')" @click="payTax">Pay</button>
                </div>
                <div>
                    dividends: {{ dividends }}
                    dividends_owe: {{ dividends_owe }}
                    <button v-if="canPay('dividends')" @click="payDividends">Pay</button>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import CoinIndicator from "../Coin_Indicator.vue";
import { mapGetters } from "vuex";
import Profitability from "@/profitability-nestle-js";

const canPayMarketStep = {
  pfme: {
    market: 0,
    step: 4,
  },
  rnd: {
    market: 0,
    step: 4,
  },
  ffoh: {
    market: 2,
    step: 1,
  },
  moge: {
    market: 2,
    step: 1,
  },
  interest: {
    market: 2,
    step: 3,
  },
  tax: {
    market: 2,
    step: 6,
  },
  dividends: {
    market: 2,
    step: 6,
  },
};

export default {
  components: { CoinIndicator },
  data() {
    return {
        isDividendBtnClicked: false
    };
  },
  computed: {
    ...mapGetters({
      vDebug: "debug",
    }),
    ...mapGetters("profitability/participant", {
      vGameboard: "gameboard",
      vPhase: "phase",
      vTeamData: "teamData"
    }),
    isTaxAndDividendsPhase() {
      return this.vPhase.market_phase == 2 && this.vPhase.phase_step == 5;
    },
    dividendType() {
      return this.vTeamData.data.dividend_type
    },
    pfme() {
      return this.vGameboard?.getValue("pfme", "paid");
    },
    pfme_owe() {
      return this.vGameboard?.getValue("pfme", "owe");
    },
    rnd() {
      return this.vGameboard?.getValue("rnd", "paid");
    },
    rnd_owe() {
      return this.vGameboard?.getValue("rnd", "owe");
    },
    depreciation() {
      return this.vGameboard?.getValue("depreciation", "*");
    },
    ffoh() {
      return this.vGameboard?.getValue("ffoh", "paid");
    },
    ffoh_owe() {
      return this.vGameboard?.getValue("ffoh", "owe");
    },
    moge() {
      return this.vGameboard?.getValue("moge", "paid");
    },
    moge_owe() {
      return this.vGameboard?.getValue("moge", "owe");
    },
    interest() {
      return this.vGameboard?.getValue("interest", "paid");
    },
    interest_owe() {
      return this.vGameboard?.getValue("interest", "owe");
    },
    tax() {
      return this.vGameboard?.getValue("tax", "paid");
    },
    tax_owe() {
      return this.vGameboard?.getValue("tax", "owe");
    },
    dividends() {
      return this.vGameboard?.getValue("dividends", "paid");
    },
    dividends_owe() {
      return this.vGameboard?.getValue("dividends", "owe");
    },
  },
  methods: {
    clickDividendBtn() {
        this.$emit('openDividendsInfoBox')
        this.isDividendBtnClicked = true
    },
    canPay(type) {
      const canPayData = canPayMarketStep[type] || {};
      if (
        this.vPhase.period !== 0 &&
        canPayData.market === this.vPhase.market_phase &&
        canPayData.step === this.vPhase.phase_step
      ) {
        return this[`${type}_owe`] - this[type] > 0;
      } else if (this.vPhase.period === 0) {
        switch (this.vPhase.phase_step) {
          case 5:
          case 8:
            return this[`${type}_owe`] - this[type] > 0;
          default:
            break;
        }
      }

      return false;
    },
    payPFME() {
      const amount = this.pfme_owe;
      Profitability.Participant.payRnDnPFME("pfme", amount);
    },
    payRnD() {
      const amount = this.rnd_owe;
      Profitability.Participant.payRnDnPFME("rnd", amount);
    },
    payFFOH(cash) {
      // const amount = this.ffoh_owe;

      if (cash == this.ffoh_owe) {
        if (this.vPhase.period !== 0) {
          Profitability.Participant.payFFOHnMOGE("ffoh", cash);
        } else {
          Profitability.Participant.mbsPayFFOHnMOGE("ffoh", cash);
        }
      }
    },
    payMOGE(cash) {
      // const amount = this.moge_owe;

      if (cash == this.moge_owe) {
        if (this.vPhase.period !== 0) {
          Profitability.Participant.payFFOHnMOGE("moge", cash);
        } else {
          Profitability.Participant.mbsPayFFOHnMOGE("moge", cash);
        }
      }
    },
    payInterest(cash) {
      // const amount = this.interest_owe;

      if (cash == this.interest_owe) {
        if (this.vPhase.period !== 0) {
          Profitability.Participant.payLoanInterest(cash);
        } else {
          return Profitability.Participant.mbsPayInterest(cash);
        }
      }
    },
    payTax() {
      const amount = this.tax_owe;
      Profitability.Participant.payTaxNDividends("tax", amount);
    },
    payDividends() {
      const amount = this.dividends_owe;
      Profitability.Participant.payTaxNDividends("dividends", amount);
    },
    Interact_Expense(this_cash, target) {
      if (this_cash > 0) {
        // if (this.vPhase.period === 0) {
        //     if (this.vPhase.phase_step === 0) {
        //         alert("Drag the silver coins to cash")
        //         return false;
        //     } else if (this.vPhase.phase_step === 1) {
        //         alert("Drag the silver coins to land & buildings")
        //         return false;
        //     }
        // }
        this.Purchase_Expense(this_cash, target);
      }
    },
    Purchase_Expense(this_cash, target) {
      if (this.canPay("pfme")) {
        Profitability.Participant.payRnDnPFME("pfme", this_cash);
        // console.log("Pay PFME")
      } else if (this.canPay("rnd")) {
        Profitability.Participant.payRnDnPFME("rnd", this_cash);
        // console.log("Pay RND")
      } else if (target === "FFOH_Pay" && this.canPay("ffoh")) {
        this.payFFOH(this_cash);
        // console.log("Pay FFOH")
      } else if (target === "MOGE_Pay" && this.canPay("moge")) {
        this.payMOGE(this_cash);
        // console.log("Pay MOGE")
      } else if (target === "Interest_Pay" && this.canPay("interest")) {
        this.payInterest(this_cash);
        // console.log("Pay Interest")
      } else if (this.canPay("tax")) {
        Profitability.Participant.payTaxNDividends("tax", this_cash);
        // console.log("Pay Tax")
      } else if (this.canPay("dividends")) {
        Profitability.Participant.payTaxNDividends("dividends", this_cash);
        // console.log("Pay Dividends")
      }
    },
  },
};
</script>

<style lang="scss">
.Section_Expenses {
  top: 10%;
  left: 51.1%;
  position: fixed;
  width: 13.6%;
  height: 65.1%;

  .PlayerBoard_Expenses {
    top: 10%;
    left: 51.1%;
    position: fixed;
    width: 13.6%;
    height: 65.1%;
  }

  .debug {
    position: absolute;
    inset: 0;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    padding-bottom: 3rem;

    div {
      display: flex;
      flex: 1;

      div {
        flex: 1;
      }

      button {
        height: 15%;
      }
    }
  }

  .box {
    position: fixed;
    width: 6.17%;
    height: 14.4%;
    padding-left: 2px;
    border-radius: 10px;

    &.pfme {
      left: 51.4%;
      top: 11.4%;
    }

    &.rnd {
      left: 57.77%;
      top: 11.4%;
    }

    &.dep {
      left: 51.4%;
      top: 26%;
    }

    &.ffoh {
      left: 57.77%;
      top: 26%;
    }

    &.moge {
      left: 51.4%;
      top: 41.2%;
    }

    &.interest {
      left: 57.77%;
      top: 41.2%;
    }

    &.tax {
      left: 51.4%;
      top: 56.35%;
    }

    &.dividends {
      left: 57.77%;
      top: 56.35%;

      .info-btn {
        position: absolute;
        right: 0.3rem;
        bottom: 0.6rem;
        width: 1.2rem;
        height: 1.2rem;

        background: url("../../assets/Gameboard/Period/info.png");
        background-size: 100% 100%;
        border: none;
        z-index: 5;
      }
    }
  }

  .frame_border {
    border: 5px solid white;
    border-radius: 8px;
  }

  .animate::before {
    content: "";
    border-radius: 1000px;
    min-width: 2rem;
    min-height: 2rem;
    border: 6px solid #fff;
    box-shadow: 0 0 60px rgba(255, 255, 255, 0.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
  }

  .animate:hover::before,
  .animate:focus::before {
    opacity: 1;
  }

  .animate::after {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 6px solid #fff;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }

  .animate:hover::after,
  .animate:focus::after {
    animation: none;
    display: none;
  }

  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }
}
</style>
