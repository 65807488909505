<template>
    <div v-if="is_warehouse === true">
        <img src="../../assets/Gameboard/Sections/section_warehouse.png" class="PlayerBoard_Plot">
        <div class="Warehouse_Units">
            <div v-for="(slot, i) in (all_factory.slots || [])" :key="slot">
                <div v-if="slot.type === 'classic'" class="coin_align" :class="`c${i}`">
                    <CoinIndicator :pass_currency_type="3" :pass_cash="4" />
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="is_classic === true">
        <img src="../../assets/Gameboard/Sections/section_nescafe_classic.png" class="PlayerBoard_Plot">
        <div class="Production_Line_Box">
            <CoinIndicator :pass_currency_type="2" :pass_cash="all_factory.production_line" />
            <button v-if="requireDepreciate" class="depreciate_btn" @click="get_cc">Depreciate</button>
        </div>
        <div class="Work_In_Progress_Box">
            <CoinIndicator :pass_currency_type="3" ref="WIP_Classic" :pass_cash= all_factory.wip />
        </div>
        <div class="Line_Units">
            <div v-for="(slot, i) in (all_factory.slots || [])" :key="i">
                <div v-if="slot.subtype === 'raw'">
                    <div v-if="slot.type === 'classic'" class="coin_align" :class="`c${i}`">
                        <CoinIndicator :pass_currency_type="3" :pass_cash="2" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <img src="../../assets/Gameboard/Sections/section_buy_plot.png" class="PlayerBoard_Plot">
    </div>

    <div v-if="vDebug" style="position:absolute; color: black; font-weight: 500;">
        <div style="padding: 3rem 1rem 1rem 1rem;">
            <div>{{ all_factory.type }}</div>
            <div v-for="slot in (all_factory.slots || [])" :key="slot">
                {{ slot.type }} - {{ slot.subtype }}
            </div>
            <template v-if="all_factory.type === 'gold' || all_factory.type === 'classic'">
                <div>production_line: {{ all_factory.production_line }}</div>
                <div>wip: {{ all_factory.wip }}</div>

                <div v-if="requireWipPayment">
                    <button @click="payWip">Pay WIP</button>
                </div>
                <div v-if="requireDepreciate">
                    <button @click="get_cc">Depreciate</button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue'
import { mapGetters } from "vuex";
import Profitability from '@/profitability-nestle-js';

export default {
    components: { CoinIndicator },
    props: {
        index: Number,
        phase: Number,
        factory: Object,
        dep: Number
    },
    emits: ['spawn_coin'],
    data() {
        return {
            WIP: 0,
        }
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        all_factory() {
            return this.factory[this.index] || {};
        },
        is_warehouse() {
            return this.all_factory.type === 'warehouse';
        },
        is_classic() {
            return this.all_factory.type === 'classic';
        },
        converted() {
            return this.all_factory.type !== null;
        },
        wip() {
            return this.all_factory.wip;
        },
        requireWipPayment() {
            return (this.is_classic || this.is_gold) && this.wip === 0;
        },
        requireDepreciate() {
            return this.phase === 9 && this.all_factory.production_line === 15
        },
    },
    methods: {
        get_cc(){
            var this_plot = "Plot_" + (this.index + 1)
            var coin_amount = 1
            this.$emit("spawn_coin", this_plot, coin_amount)
        },
        Change_Plot(this_type) {
            switch (this_type) {
                case 0:
                    this.is_warehouse = true
                    break
                case 1:
                    this.is_classic = true
                    this.$emit("Convert_Classic_Plot");

                    break
            }
            this.converted = true
        },
        Update_Plot(cash) {
            if (phase === 2) {
                if (this.is_classic)
                    this.$refs["WIP_Classic"].cash = cash;
            }
        },
        depreciate() {
            this.$emit("Depreciate_Line")
        }
    }
}
</script>

<style lang="scss">
.PlayerBoard_Plot {
    top: 0%;
    left: 0%;
    position: absolute;
    width: 100%;
    height: 100%;
}

.Line_Units {
    position: absolute;
    width: 40%;
    height: 50%;
    top: 10%;
    right: 64%;

    .coin_align {
        position: absolute;
        width: 100%;
        height: 100%;

        &.c0 { top: 0; left: 0; }
        &.c1 { top: 0; left: 60%; }
        &.c2 { top: 46%; left: 0; }
        &.c3 { top: 46%; left: 60%; }
        &.c4 { top: 90.5%; left: 0; }
        &.c5 { top: 90.5%; left: 60%; }
    }
}

.Warehouse_Units {
    position: absolute;
    width: 40%;
    height: 50%;
    top: 20.5%;
    right: 61%;

    .coin_align {
        position: absolute;
        width: 100%;
        height: 100%;

        &.c0 { top: 0; left: 0; }
        &.c1 { top: 0; left: 77.9%; }
        &.c2 { top: 0; left: 155.5%; }
        &.c3 { top: 65%; left: 0; }
        &.c4 { top: 65%; left: 77.9%; }
        &.c5 { top: 65%; left: 155.5%; }
    }

}

.Production_Line_Box {
    top: 5%;
    right: 10px;
    position: absolute;
    width: 40%;
    height: 50%;
    padding-left: 2.5px;
}

.Work_In_Progress_Box {
    top: 47.5%;
    right: 6px;
    position: absolute;
    width: 40%;
    height: 50%;
}

.depreciate_btn {
    background: #a4d65d;
    border: none;
    border-radius: 30px;
    position: absolute;
    width: 87%;
    height: 20%;
    top: -2%;
    right: 3%;
    font-size: 16px;
    z-index: 10;

    &.frame {
        border: 5px solid white;
    }
}
.depreciate_btn:hover {
    background: #729541;
}
.depreciate_btn:active {
    background: #392c23;
    color: white;
}
</style>
