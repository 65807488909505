<style lang="scss">

.Nescafe_Purchase_Panel {
    width: 371px;
    height: 195px;

    cursor: pointer;

    &.Classic {
        &.Cash {
            background: url("../assets/Marketing/Nescafe_Classic_Panel_Cash.png") 100% 100% no-repeat ;
            background-size: 100% 100%;
        }
        &.Credit {
            background: url("../assets/Marketing/Nescafe_Classic_Panel_Credit.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    &.Gold {
        &.Cash {
            background: url("../assets/Marketing/Nescafe_Gold_Panel_Cash.png") no-repeat;
            background-size: 100% 100%;
        }
        &.Credit {
            background: url("../assets/Marketing/Nescafe_Gold_Panel_Credit.png") no-repeat;
            background-size: 100% 100%;
        }
    }

    .Num_Coins {
        position: absolute;
        font-size: 6.7rem;
        margin-left: 2.8%;
        color: white;
    }

    .Num_Coins_Gold {
        color: black;
    }

    .Coins_Img {
        position: relative;
        top: 9%;
        left: 37.5%;
        width: 59%;
        height: 67%;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto;
        grid-gap: 5px;
    }

    .Coin {
        position: absolute;
        width: 27%;
        height: 45%;

        &.Classic {
            background: url("../assets/Marketing/Classic_Mug.png") no-repeat;
            background-size: 100% 100%;
        }

        &.Gold {
            background: url("../assets/Marketing/Gold_Jar.png") no-repeat;
            background-size: 100% 100%;
        }

        &.Opacity {
            opacity: 0.3;
        }
    }

    .Assigned_BG {
        background: rgb(0,0,0,0.7);
        width: 371px;
        height: 195px;
        position: absolute;
        border-radius: 11px;
        z-index: 3;
    }
    .group_market_card {
        font-family: NestleBrush_Reg;
        font-size: 5rem;
        text-transform: uppercase;
        width: 35%;
        height: 50%;
        border-radius: 10px;
        position: relative;
        top: 22%;
        left: 34%;
        color: white;
    }
    .A {
        background-color: #59af8b;
    }
    .B {
        background-color: #6591d0;
    }
    .C {
        background-color: #fb8c50;
    }
    .D {
        background-color: #9963b5;
    }
    .E {
        background-color: #ffd250;
        color: black;
    }
    .F {
        background-color: #d9cdb4;
        color: black;
    }
}

</style>

<template>
    <div class="Nescafe_Purchase_Panel"
         :class="{
            Cash: marketCard.payment === 'cash', Credit: marketCard.payment === 'credit',
            Classic: marketCard.type === 'classic', Gold: marketCard.type === 'gold',
         }"
         @click="assignCard"
         :disabled="role === 'participant'"
    >

        <!-- TODO: indicate the card already assign to team -->
        <div v-if="marketCard.team_id && assignedBG == true" class="Assigned_BG">
            <!-- {{ teamDict[marketCard.team_id].name }} -->
            <div class="group_market_card" :class="teamDict[marketCard.team_id].name">
                {{ teamDict[marketCard.team_id].name }}
            </div>
        </div>

        <p class="Num_Coins">{{ marketCard.unit }}</p>

        <div class="Coins_Img">
            <div v-for="i in 6" :key="i">
                <div v-if="i <= marketCard.unit">
                    <div class="Coin" :class="{Classic: marketCard.type === 'classic', Gold: marketCard.type === 'gold',}"></div>
                </div>
                <div v-else>
                    <div class="Coin Opacity" :class="{Classic: marketCard.type === 'classic', Gold: marketCard.type === 'gold',}"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Profitability from '@/profitability-nestle-js';
import { mapGetters } from "vuex";

export default {
    props: {
        role: String,
        marketCard: Object,
        teamDict: Object,
        assignedBG: Boolean
    },
    computed: {
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
            vTrainingSessionData: 'trainingSessionData',
        }),
    },
    methods: {
        assignCard() {
            if (this.role === 'trainer') {
                if (this.marketCard.team_id === null) {
                    Profitability.Trainer.assignMarketCardToTeam(this.marketCard.id);
                }
            }
        },
        teamStocks() {
            const { market_phase_data } = this.vTrainingSessionData;
            const marketDataByType = market_phase_data[this.type];
            const { team_stocks } = marketDataByType;
            /**
             * team stocks is a json that contains stock amount by team_id
             * {
             *     team_id: stock_amount,
             *     team_id: stock_amount,
             *     team_id: stock_amount,
             * }
             */
            return team_stocks || {};
        },
    },
}
</script>
