<style lang="scss">
.post-market-phase {
    .income-statement {
        position: absolute;
        top: 6.2%;
        right: 0.55%;
        width: 48.7%;
        height: 92.5%;

        .container {
            position: absolute;
            width: 100%;
            height: 100%;

            .team_display {
                position: absolute;
                top: 1.6%;
                left: 1.6%;
                width: 96.3%;
                height: 8%;
                background: url("../../../assets/Facilitator/Form_Facilitator_TEAMROW.png");
                background-size: 98.4% 100%;
            }

            .score_display {
                width: 100%;
                height: 100%;
                background: url("../../../assets/Facilitator/Form_Facilitator_TAB.png");
                background-size: 100% 100%;

                .score {
                    position: absolute;
                    top: 9.7%;
                    left: 1.6%;
                    width: 96.3%;
                    height: 87.8%;
                    background: url("../../../assets/Facilitator/Form_Facilitator_IS.png");
                    background-size: 100% 100%;
                    background-attachment: local;
                    background-repeat: no-repeat;

                    overflow-y: auto;
                }
            }
        }

        .teams {
            .team {
                position: absolute;
                top: 3.4%;
                height: 180%;

                &.A { left: 31%; }
                &.B { left: 42.2%; }
                &.C { left: 53%; }
                &.D { left: 64%; }
                &.E { left: 74.9%; }
                &.F { left: 86%; }
            }
        }

        .field-item {
            position: absolute;
            color: white;
            font-size: 24px;
            width: 70px;

            &.f-1 { top: 4.7%; }
            &.f-2 { top: 9.36%; }
            &.f-3 { top: 14.1%; }
            &.f-4 { top: 18.65%; }
            &.f-5 { top: 23.4%; }
            &.f-6 { top: 28.2%; }
            &.f-7 { top: 32.8%; }
            &.f-8 { top: 37.5%; }
            &.f-9 { top: 42.1%; }
            &.f-10 { top: 47%; }
            &.f-11 { top: 51.65%; }
            &.f-12 { top: 56.3%; }
            &.f-13 { top: 61%; }
            &.f-14 { top: 65.6%; }
            &.f-15 { top: 70.35%; }
            &.f-16 { top: 79.2%; }
            &.f-17 { top: 84.1%; }
            &.f-18 { top: 89.2%; }
            &.f-19 { top: 94.15%; }
            &.black { color: black; }
        }
    }
}
</style>

<template>

    <div class="income-statement">
        <div class="container">
            <div class="team_display"></div>
            <div class="score_display">
                <div class="score">
                    <div class="teams">
                        <template v-for="i in indexes" :key="i">
                            <div class="team" :class="String.fromCharCode(65 + i)" v-if="vTeams[i]" :key="i">
                                <template v-for="(field, j) in fields" :key="j">
                                    <div class="field-item" :class="[`f-${j}`, {black: i === 4 || i === 5}]">
                                        {{ vTeams[i]?.teamData.income_statement ? getValue(vTeams[i]?.teamData.income_statement, field) : '&nbsp' }}
                                    </div>
                                </template>
                            </div>
                            
                            <div class="team" :class="String.fromCharCode(65 + i)" v-else>
                                <template v-for="(field, j) in fields" :key="j">
                                    <div class="field-item" :class="[`f-${j}`, {black: i === 4 || i === 5}]">
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

const fields = [
    // marginal contribution
    'sales',
    'cost',
    'margin',

    // product fixed expenses
    'pfme',
    'rnd',
    'depreciation',
    'total_product_fixed_expenses',
    'product_contribution',

    // fixed overhead expenses
    'ffoh',
    'moge',
    'total_fixed_overhead_expenses',

    'trading_operating_profit',
    'interest',
    'earning_before_tax',
    'tax',
    'net_profit',

    // retained earnings
    'retained_earnings_prev',
    'net_profit',
    'dividends',
    'retained_earnings',
];

export default {
    data() {
        return {
            indexes: _.times(6),

            fields,
        };
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/trainer', {
            vTeams: 'teams',
        }),
    },
    methods: {
        getValue(statement, field) {
            let i = _.indexOf(fields, field);
            if (i < 8) {
                const data = _.get(statement, field, null);
                if (data) {
                    return data.classic + data.gold;
                }
                return '';
            } else {
                return _.get(statement, field);
            }
        },
    },
}
</script>
