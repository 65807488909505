<style lang="scss">
.Section_External_Locations{
    top: 27%;
    left: 6.95%;
    position: fixed;
    width: 6.75%;
    height: 48%;

    .PlayerBoard_External{
        top: 27%;
        left: 6.95%;
        position: fixed;
        width: 6.75%;
        height: 48%;
    }
    .Supplier_Box{
        top: 27.5%;
        left: 7.3%;
        position: fixed;
        width: 6%;
        height: 10.5%;
        /* background-color: green; */
    }
    .Button_Supplier{
        top: 27.5%;
        left: 7%;
        position: fixed;
        width: 6%;
        height: 10.5%;
        background-color: transparent;
        border-color: transparent;
    }
    .Order_Button {
        border: none;
        border-radius: 20px;
        top: 28%;
        left: 7.8%;
        position: fixed;
        width: 5%;
        height: 3%;
        font-size: 22px;
        background: #a4d65d;
    }
    .Investor_Box {
        top: 49.15%;
        left: 7.3%;
        position: fixed;
        width: 6%;
        height: 10.5%;
        background: none;
    }
    .Customers_Box {
        top: 38.5%;
        left: 7.3%;
        position: fixed;
        width: 6%;
        height: 10.5%;
        background-color: none;
    }
    .Collect_Cash {
        position: absolute;
        top: 5.5%;
        left: 3.5%;
        width: 45%;
        height: 40%;
        background: url("../../assets/Gameboard/Sections/Collect_Cash_Button.png");
        background-size: 100% 100%;
        border: none;
    }
    .Collect_Credit {
        position: absolute;
        top: 5.5%;
        left: 52%;
        width: 45%;
        height: 40%;
        background: url("../../assets/Gameboard/Sections/Collect_Credit_Button.png");
        background-size: 100% 100%;
        border: none;
    }
    .Button_Bank{
        top: 60%;
        left: 7.3%;
        position: fixed;
        width: 6%;
        height: 10.3%;
        background-color: transparent;
        border-color: transparent;
    }
    .Bank_Box {
        top: 60%;
        left: 7%;
        position: fixed;
        width: 6%;
        height: 10.3%;
        /* background: black; */
    }

    .coin_adjust {
        position: absolute;
        top: -9%;
        left: -11.5%;
        width: 100%;
        height: 50%;
        /* background: green; */
    }

    .frame_border {
        border: 5px solid red;
        border-radius: 8px;
    }

    .debug {
        position: absolute;
        inset: 0; margin-top: 1rem;
        pointer-events: none;

        display: flex;
        flex-direction: column;

        color: black;
        font-weight: bold;

        div {
            flex: 1;
        }

        button {
            font-size: 12px;
        }
    }
}
</style>


<template>
    <div class="Section_External_Locations">
        <img src="../../assets/Gameboard/Sections/section_external_locations.png" class="PlayerBoard_External">

        <button v-if="phase === 3 || phase === 7" class="Order_Button" disabled>Order</button>

        <div class="Supplier_Box" ref="Supplier_Box" :class="{frame_border: phase === 3 || phase === 7}">
            <div class="coin_adjust">
                <CoinIndicator :pass_currency_type="9" :pass_cash="suppliers" />
            </div>
        </div>

        <button class="Button_Supplier" @click="open_supplier_form"></button>

        <!-- <div class="Supplier_Box" ref="Supplier_Box" v-if="suppliers_canPay" :index="14">
            <div class="coin_adjust">
                <CoinIndicator :pass_currency_type="9" :pass_cash="suppliers" />
            </div>
        </div> -->

        <div v-if="investors > 0" class="Investor_Box" :class="{frame_border: phase === 0}">
            <div v-if="investors_canDragToCash"></div>
        </div>

        <div class="Customers_Box" :class="{frame_border: phase === 4}">
            <div v-if="customers_canCollectCash"></div>
            <div v-if="customers_canCollectCredit"></div>
        </div>

        <button class="Button_Bank" @click="open_bank" :class="{frame_border: phase === 6}"></button>

        <div class="Bank_Box" v-if="bank_cash > 0">
            <div v-if="bank_can_collect"></div>
        </div>

        <div v-if="vDebug" class="debug">
            <div>
                suppliers: {{ suppliers }}
            </div>
            <div>
                cash: {{ customerCash }}<button v-if="customers_canCollectCash" @click="collectFromCustomer('cash')" style="pointer-events: auto;">Collect</button>
                <br>
                credit: {{ customerCredit }}<button v-if="customers_canCollectCredit" @click="collectFromCustomer('credit')" style="pointer-events: auto;">Collect</button>
            </div>
            <div>
                investors: {{ investorCash }}
                <br>
                <button v-if="investors_canDragToCash" @click="investorToCash" style="pointer-events: auto;">ToCash</button>
            </div>
            <div>
                cash: {{ bankAmt }}
                <button v-if="bank_cash > 0" @click="takeLoanToCash" style="pointer-events: auto;">Take</button>
                <br>
            </div>
        </div>
    </div>
    <CoinComponent ref="CComponent" @use_coin="drop_function" :standalone="true"/>
</template>

<script>
import CoinIndicator from '../Coin_Indicator.vue'
import CoinComponent from '../Coin_Component.vue'
import { mapGetters } from "vuex";
import Profitability from '@/profitability-nestle-js';

export default {
    components: { CoinIndicator, CoinComponent },
    props: {
        phase: Number,
        investorCash: Number,
        customerCash: Number,
        customerCredit: Number,
        bankAmt: Number,
        supplierCash: Number,
        cash_stored: Number
    },
    emits: ['open_bank_button'],
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            vDebug: 'debug',
        }),
        ...mapGetters('profitability/participant', {
            vGameboard: 'gameboard',
            vPhase: 'phase',
        }),
        suppliers() {
            return this.supplierCash;
        },
        customers_cash() {
            return this.customerCash;
        },
        customers_credit() {
            return this.customerCredit;
        },
        customers_canCollectCash() {
            if(this.customers_cash > 0){
                setTimeout(() => {
                    this.$refs["CComponent"].set_custom_grab(0.525, 0.075, this.customers_cash, "Cash_Box", "Customer Cash")
                }, 100)
            }

            return this.customers_cash > 0;
        },
        customers_canCollectCredit() {
            if(this.customers_credit > 0){
                setTimeout(() => {
                    this.$refs["CComponent"].set_custom_grab(0.535, 0.075, this.customers_credit, "Receivables_Box", "Customer Credit")
                }, 100)
            }

            return this.customers_credit > 0;
        },
        investors() {
            return this.investorCash
        },
        investors_canDragToCash() {
            if(this.investors > 0 && this.phase === 0){
                setTimeout(() => {
                    this.$refs["CComponent"].set_custom_grab(0.425, 0.075, 75, "Cash_Box", "Investor")
                }, 100)
            }
        },
        bank_cash() {
            return this.bankAmt
        },
        bank_can_collect(){
            if(this.bank_cash > 0)
                this.$refs["CComponent"].set_custom_grab(0.325, 0.075, this.bank_cash, "Cash_Box", "Bank")

            return this.bank_cash > 0;
        },
    },
    methods:{
        drop_function(cash, location, func)
        {
            var move_to = func + " > " + location
            switch(move_to){
                case "Investor > Cash_Box":
                    this.investorToCash()
                    break
                case "Customer Credit > Receivables_Box":
                    this.collectFromCustomer('credit')
                    break
                case "Customer Cash > Cash_Box":
                    this.collectFromCustomer('cash')
                    break
                case "Suppliers > Payable_Box":
                    this.toPayables()
                    break
                case "Bank > Cash_Box":
                    this.takeLoanToCash()
                    break
            }
        },
        open_bank() {
            this.$emit("open_bank_button", "Bank")
        },
        open_supplier_form() {
            this.$emit("open_supplier_panel", this.phase <= 3? "Supplier_Pre" : "Supplier_Post")
        },
        paySuppliers(cash) {
            const actualOweAmount = this.suppliers;
            if (actualOweAmount === 0) return;

            if (cash === actualOweAmount) {
                if (this.phase === 3) {
                    this.$emit("Pay_Copack")
                    this.$refs["CComponent"].reset()
                } else if (this.phase === 7) {
                    this.$emit("Pay_Raw")
                    this.$refs["CComponent"].reset()
                }
            } else {
                const type = this.phase === 3? 'co-packers' : 'raw pack';
                alert(`Incorrect amount. Pay the full cost of ${type} from cash`)
            }

        },
        collectFromCustomer(type) {
            if (type === 'credit') {
                this.$emit("Collect_Copack_Receive", this.customers_credit)
            }

            this.$refs["CComponent"].reset()
        },

        takeLoanToCash() {
            this.$emit("Loan_To_Cash", 10)
        },
        takeLoanToStandard() {
            Profitability.Participant.bankLoanToLoans('standard');
        },
        investorToCash() {
            this.investor -= 75;
            this.$emit("from_investor");
            this.$refs["CComponent"].reset()
        },
    },
}
</script>
