<style lang="scss">
.OP_Container {
  background-image: url("../../assets/Gameboard/Panel/Form_P4OperationalPlan_Text.png");
  top: 3%;
  right: 6.5%;
  position: fixed;
  width: 27%;
  height: 87%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  .info_box {
    position: absolute;
    top: 4.8%;
    left: 2.5%;
    font-size: 0.92rem;
    text-align: justify;
    width: 95%;
    height: 10.8%;
    /* background: green; */
    color: white;
    overflow-y: scroll;

    pre {
      padding: 0;
      margin: 0;
      font-size: 20px;
      font-family: NestleText;
      white-space: pre-wrap; /* Since CSS 2.1 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word; /* Internet Explorer 5.5+ */
    }
  }

  .title {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    color: white;
    font-size: 27px;
  }

  .wrapper {
    position: absolute;
    top: 23%;
    right: 2.5%;
    width: 48%;
    height: 84.5%;
    /* background: rgb(0,0,0,0.7); */

    .answer_check {
      background: url("../../assets/Gameboard/Period/checked_step_white.png");
      background-size: 100% 100%;
      position: absolute;
      width: 10.5%;
      height: 2.9%;
      z-index: 2;

      &.nc { left: 22% }
      &.ng { left: 57.5% }
      &.total { left: 92.5%; }
      
      &.nns { top: 9.5%; }
      &.mc { top: 18.3%; }
      &.tpfe { top: 36.3%; }
      &.pc { top: 41%; }
      &.tpoe { top: 54.6%; }
      &.op1 { top: 59.4%; }
      &.before_tax { top: 68.7%; }
      &.budget { top: 77.6%; }
      &.total_nns { top: 84.5%; }
    }
  }

  .input_field {
    position: absolute;
    font-size: 20px;
    text-align: center;
    width: 26.4%;
    height: auto;
    left: 0;
    background: none;
    border: none;
    outline: none;
    font-family: NestleBrush_Reg;

    &.nc { color: white; }
    &.ng { color: black; left: 35.6%;}
    &.price { top: 4.4%; }
    &.nns { top: 9%; }
    &.est_cost { top: 13.6%; }
    &.mc { top: 18%; }
    &.pfme { top: 22.5%; }
    &.rnd { top: 26.9%; }
    &.fd { top: 31.4%; }
    &.tpfe { top: 36%; }
    &.pc { top: 40.7%; }

    &.total { color: white; top: 40.7%; left: 70.6%;}
    &.ffoh { top: 45.5%; }
    &.moge { top: 50%; }
    &.tpoe { top: 54.5%; }
    &.op1 { top: 59%; }
    &.interest { top: 63.55%; }
    &.before_tax { top: 68.35%; }
    &.tax { top: 72.8%; }
    &.budget { top: 77.4%; color: black; }
    &.total_nns { top: 84.3%; color: black; }

    &.white { color: white; }
  }
}

.OP_Container_After {
  background-image: url("../../assets/Gameboard/Panel/Form_P4OperationalPlan.png");
  top: 20.5%;
  right: 8%;
  position: fixed;
  width: 23.5%;
  height: 70%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  .title {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    color: white;
    font-size: 27px;
  }

  .wrapper {
    position: absolute;
    top: 12.1%;
    right: 3%;
    width: 48%;
    height: 84.5%;
    /* background: rgb(0,0,0,0.7); */
  }

  .input_field {
    position: absolute;
    font-size: 20px;
    text-align: center;
    width: 26.4%;
    height: auto;
    left: 0;
    background: none;
    border: none;
    outline: none;
    font-family: NestleBrush_Reg;

    &.nc { color: white; }
    &.ng { color: black; left: 36%;}
    &.price { top: 5.3%; }
    &.nns { top: 10.4%; }
    &.est_cost { top: 15.7%; }
    &.mc { top: 20.5%; }
    &.pfme { top: 25.7%; }
    &.rnd { top: 30.9%; }
    &.fd { top: 35.9%; }
    &.tpfe { top: 41%; }
    &.pc { top: 46.5%; }

    &.total { color: white; top: 46.5%; left: 70.8%;}
    &.ffoh { top: 51.5%; }
    &.moge { top: 57%; }
    &.tpoe { top: 62%; }
    &.op1 { top: 67.4%; }
    &.interest { top: 72.5%; }
    &.before_tax { top: 77.8%; }
    &.tax { top: 82.8%; }
    &.budget { top: 88.1%; color: black; }
    &.total_nns { top: 95.9%; color: black; }

    &.white { color: white; }
  }
}
</style>

<template>
  <div :class="{OP_Container_After: panel === true, OP_Container: panel === false}">
    <p class="title">Operational Plan</p>

    <div v-if="!panel" class="info_box">
      <pre>{{ op_text }}</pre>
    </div>

    <div class="wrapper">
      <input class="input_field nc" v-model.number="input.nc.units" maxlength="3" @change="check_input" />
      <input class="input_field price nc" v-model.number="input.nc.price" maxlength="3" @change="check_input" />
      <input class="input_field nns nc" v-model.number="input.nc.nns" maxlength="3" @change="check_input" />
      <input class="input_field est_cost nc" v-model.number="input.nc.est_cost" maxlength="3" />
      <input class="input_field mc nc" v-model.number="input.nc.mc" maxlength="3" @change="check_input" />
      <input class="input_field pfme nc" v-model.number="input.nc.pfme" maxlength="3" @change="check_input" />
      <input class="input_field rnd nc" v-model.number="input.nc.rnd" maxlength="3" @change="check_input" />
      <input class="input_field fd nc" v-model.number="input.nc.fd" maxlength="3" @change="check_input" />
      <input class="input_field tpfe nc" v-model.number="input.nc.tpfe" maxlength="3" @change="check_input" />
      <input class="input_field pc nc" v-model.number="input.nc.pc" maxlength="3" @change="check_input" />

      <input class="input_field ng" v-model.number="input.ng.units" maxlength="3" @change="check_input" />
      <input class="input_field price ng" v-model.number="input.ng.price" maxlength="3" @change="check_input" />
      <input class="input_field nns ng" v-model.number="input.ng.nns" maxlength="3" @change="check_input" />
      <input class="input_field est_cost ng" v-model.number="input.ng.est_cost" maxlength="3" @change="check_input" />
      <input class="input_field mc ng white" v-model.number="input.ng.mc" maxlength="3" @change="check_input" />
      <input class="input_field pfme ng" v-model.number="input.ng.pfme" maxlength="3" @change="check_input" />
      <input class="input_field rnd ng" v-model.number="input.ng.rnd" maxlength="3" @change="check_input" />
      <input class="input_field fd ng" v-model.number="input.ng.fd" maxlength="3" @change="check_input" />
      <input class="input_field tpfe ng" v-model.number="input.ng.tpfe" maxlength="3" @change="check_input" />
      <input class="input_field pc ng white" v-model.number="input.ng.pc" maxlength="3" @change="check_input" />

      <input class="input_field total" v-model.number="input.total.pc" maxlength="3" @change="check_input" />
      <input class="input_field total ffoh" v-model.number="input.total.ffoh" maxlength="3" @change="check_input" />
      <input class="input_field total moge" v-model.number="input.total.moge" maxlength="3" @change="check_input" />
      <input class="input_field total tpoe" v-model.number="input.total.tpoe" maxlength="3" @change="check_input" />
      <input class="input_field total op1" v-model.number="input.total.op1" maxlength="3" @change="check_input" />
      <input class="input_field total interest" v-model.number="input.total.interest" maxlength="3" @change="check_input" />
      <input class="input_field total before_tax" v-model.number="input.total.before_tax" maxlength="3" @change="check_input" />
      <input class="input_field total tax" v-model.number="input.total.tax" maxlength="3" @change="check_input" />
      <input class="input_field total budget" v-model.number="input.total.budget" maxlength="6" @change="check_input" />
      <input class="input_field total total_nns" v-model.number="input.total.total_nns" maxlength="6" @change="check_input" />
      
      <template v-for="(type, i) in input.check.nc" :key="i">
        <div v-if="input.check['nc'][i]" class="answer_check nc" :class="i"></div>
      </template>
      <template v-for="(type, i) in input.check.ng" :key="i">
        <div v-if="input.check['ng'][i]" class="answer_check ng" :class="i"></div>
      </template>
      <template v-for="(type, i) in input.check.total" :key="i">
        <div v-if="input.check['total'][i]" class="answer_check total" :class="i"></div>
      </template>

    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { op_text } from '@/constants'

export default {
  props: {
    operationalPlan: Object,
    at_panel: Boolean,
  },
  emits: [
    'update:operationalPlan'
  ],
  computed: {
    ...mapGetters('profitability/participant', {
      vPhase: 'phase',
      vTeamData: 'teamData',
    }),
    input() {
      return this.$store.state.opForm
    },
    isPhaseAtZero() {
      return this.vPhase.period > 2 && this.vPhase.phase_step === 0;
    }
  },
  data() {
    return {
      op_text,
      panel: this.at_panel,
    };
  },
  watch: {
    operationalPlan(next) {
      this.updateDataFromBackend(next);
    },
    input: {
      deep: true,
      handler() {
        this.$emit('update:operationalPlan', this.input);
      },
    },
  },
  mounted() {
    this.updateDataFromBackend(this.operationalPlan);

    _.each(['nc', 'ng'], type => {
        let nns = this.input[type]['units'] * this.input[type]['price']
        let mc = this.input[type]['nns'] - this.input[type]['est_cost']
        let tpfe = this.input[type]['pfme'] + this.input[type]['rnd'] + this.input[type]['fd']
        let pc = this.input[type]['mc'] - this.input[type]['tpfe']
        
        this.input.check[type]['nns'] = this.input[type]['nns'] == nns
        this.input.check[type]['mc'] = this.input[type]['mc'] == mc
        this.input.check[type]['tpfe'] = this.input[type]['tpfe'] == tpfe
        this.input.check[type]['pc'] = this.input[type]['pc'] == pc
    });

    let pc_t = this.input['nc']['pc'] + this.input['ng']['pc']
    let tpoe = this.input['total']['ffoh'] + this.input['total']['moge']
    let op1 = this.input['total']['pc'] - this.input['total']['tpoe']
    let ebt = this.input['total']['op1'] - this.input['total']['interest']
    let bnp = this.input['total']['before_tax'] - this.input['total']['tax']

    this.input.check['total']['pc'] = this.input['total']['pc'] == pc_t
    this.input.check['total']['tpoe'] = this.input['total']['tpoe'] == tpoe
    this.input.check['total']['op1'] = this.input['total']['op1'] == op1
    this.input.check['total']['before_tax'] = this.input['total']['before_tax'] == ebt
    this.input.check['total']['budget'] = this.input['total']['budget'] == bnp
    
    this.input.check['total']['total_nns'] = this.input['total']['op1'] / (this.input.nc.nns + this.input.ng.nns) * 100 == this.input.total.total_nns
  },
  methods: {
    updateDataFromBackend(operationalPlan) {
      _.each(this.structure, (v1, k1) => {
        _.each(v1, (v2, k2) => {
          _.set(this.input, [k1, k2], _.get(operationalPlan, [k1, k2], null));
        });
      });
    },
    check_input() {
      _.each(['nc', 'ng'], type => {
          let nns = this.input[type]['units'] * this.input[type]['price']
          let mc = this.input[type]['nns'] - this.input[type]['est_cost']
          let tpfe = this.input[type]['pfme'] + this.input[type]['rnd'] + this.input[type]['fd']
          let pc = this.input[type]['mc'] - this.input[type]['tpfe']
          
          this.input.check[type]['nns'] = this.input[type]['nns'] == nns
          this.input.check[type]['mc'] = this.input[type]['mc'] == mc
          this.input.check[type]['tpfe'] = this.input[type]['tpfe'] == tpfe
          this.input.check[type]['pc'] = this.input[type]['pc'] == pc
      });

      let pc_t = this.input['nc']['pc'] + this.input['ng']['pc']
      let tpoe = this.input['total']['ffoh'] + this.input['total']['moge']
      let op1 = this.input['total']['pc'] - this.input['total']['tpoe']
      let ebt = this.input['total']['op1'] - this.input['total']['interest']
      let bnp = this.input['total']['before_tax'] - this.input['total']['tax']

      this.input.check['total']['pc'] = this.input['total']['pc'] == pc_t
      this.input.check['total']['tpoe'] = this.input['total']['tpoe'] == tpoe
      this.input.check['total']['op1'] = this.input['total']['op1'] == op1
      this.input.check['total']['before_tax'] = this.input['total']['before_tax'] == ebt
      this.input.check['total']['budget'] = this.input['total']['budget'] == bnp

      this.input.check['total']['total_nns'] = (this.input['total']['op1'] / (this.input.nc.nns + this.input.ng.nns) * 100).toFixed(1) == this.input.total.total_nns

      if (Object.values(this.input.check.nc).every(Boolean) && Object.values(this.input.check.ng).every(Boolean) && Object.values(this.input.check.total).every(Boolean)) {
        this.$emit("canSubmitOP", true)
      } else {
        this.$emit("canSubmitOP", false)
      }
    }
  },
};
</script>
