import Echo from 'laravel-echo';
import io from 'socket.io-client';

window.io = io;

const defaultOptions = {
  broadcaster: 'socket.io',
  host: 'http://localhost:6001',
};

export function createEcho(context) {
  return new Echo({
    ...defaultOptions,
    ...context.options.echo,
  });
}
