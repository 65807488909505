import { createStore } from 'vuex';

const performanceAnalysis = {
    state: {
        // period 3
        ans_nns: {
            nns_c: null,
            nns_g: null,
            total: null,
        },
        ans_3: {
            og: null,
            rig: null,
            op1: null,
            trade_net: null,
        },

        // period 4 - 5
        ans_4_5: {
            og: null,
            rig: null,
            net_cash_flow: null,
            op1: null,
            atr: null,
            roic: null,
        },

        // checks
        check: {
            p3: {
              nns_c: false,
              nns_g: false,
              total: false,
              og: false,
              rig: false,
              op1: false,
              trade_net: false,

            },

            p4_5: {
              nns_c: false,
              nns_g: false,
              total: false,
              og: false,
              rig: false,
              net_cash_flow: false,
              op1: false,
              atr: false,
              roic: false,
            }
        },
    },
}

const opForm = {
    state: {
        nc: {
            units: null,
            price: null,
            nns: null,
            est_cost: null,
            mc: null,
            pfme: null,
            rnd: null,
            fd: null,
            tpfe: null,
            pc: null,
        },
        ng: {
            units: null,
            price: null,
            nns: null,
            est_cost: null,
            mc: null,
            pfme: null,
            rnd: null,
            fd: null,
            tpfe: null,
            pc: null,
        },
        total: {
            pc: null,
            ffoh: null,
            moge: null,
            tpoe: null,
            op1: null,
            interest: null,
            before_tax: null,
            tax: null,
            budget: null,
            total_nns: null,
        },

        check: {
            nc: { nns: false, mc: false, tpfe: false, pc: false },
            ng: { nns: false, mc: false, tpfe: false, pc: false },
            total: { pc: false, tpoe: false, op1: false, before_tax: false, budget: false, total_nns: false },
        }
    }
}

const cfForm = {
    state: {
        cash_out: {
            pay_sup: null,
            wip: null,
            pfme: null,
            rnd: null,
            copack: null,
            sub_total_1: null,
            ffoh: null,
            moge: null,
            interest: null,
            tax_div: null,
            sub_total_2: null
        },
        cash_in: {
            add_money: null,
            loan_1: null,
            cash_sales: null,
            loan_2: null
        },
        bal: {
            cash: null,
            add_money: null,
            sub_total_1: null,
            loan_1: null,
            cash_sales: null,
            sub_total_2: null,
            loan_2: null,
            ncf: null
        },

        check: {
            cash_out: { sub_total_1: false, sub_total_2: false },
            bal: { add_money: false, sub_total_1: false, loan_1: false, loan_2: false, ncf: false}
        }
    }
}

const releaseDeliveryButton = {
    state: {
        release: false
    }
}

const whichInfoButton = {
    state: {
        name: ""
    }
}

const usual = {
    state() {
        return {
            app: true,
            debug: process.env.VUE_APP_DEBUG === '1' || process.env.VUE_APP_DEBUG === 'true' || localStorage.getItem('app.debug') === 'gameka',
        }
    },
    getters: {
        state: state => state,
        debug: state => state.debug,
    },
    mutations: {},
    actions: {},
}

// Create a new store instance.
export const store = createStore({
    modules: {
        usual,
        performanceAnalysis,
        opForm,
        cfForm,
        releaseDeliveryButton,
        whichInfoButton
    }
});
