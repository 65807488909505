<template>
  <div class="Facilitator_Container" :style="cssProps" v-if="isLoaded">
    <Background />
    <PeriodDisplay v-if="vPhase.market_phase !== 3" :isTrainer="true" />

    <div class="Facilitator_Oveview">
      <template v-if="vPhase.period !== 0">
        <template v-if="vPhase.market_phase === 0">
          <div class="Facilitator_Board">
            <PreMarketPhase></PreMarketPhase>
          </div>
        </template>

        <template v-if="vPhase.market_phase === 1">
          <MarketingOverview></MarketingOverview>
        </template>

        <template v-if="vPhase.market_phase === 2">
          <div class="Facilitator_Board post">
            <PostMarketPhase></PostMarketPhase>
          </div>
        </template>

        <template v-if="vPhase.market_phase === 3">
          <SummaryOverview
            :vPhase="vPhase"
            :vTeams="vTeams"
            :vAllTeamSummaries="vAllTeamSummaries"
          ></SummaryOverview>
          <div>
            <button
              v-if="vPhase.period < 4"
              class="next-button"
              @click="nextPeriod"
            >
              Next
            </button>
          </div>
        </template>
      </template>
      <template v-else>
        <Period_0></Period_0>
      </template>
    </div>

    <div style="position: absolute; bottom: 0; right: 0">
      <button class="btn btn-logout" @click="logoutDialog = true">
        Logout
      </button>
    </div>

    <Alert
      v-if="logoutDialog == true"
      :alertOpen="logoutDialog"
      :message="'Logout?'"
      popUpSize="gameboard"
      @close_alert="logoutDialog = false"
      @confirm_alert="confirmLogout"
    />
  </div>

  <div class="Facilitator_Container" :style="cssProps" v-else>
    <Background />
    <Alert
      :alertOpen="true"
      :message="'Loading. Please wait...'"
      :disableButtonsView="true"
      popUpSize="gameboardBigText"
    />
  </div>
</template>

<script>
import Background from "../components/Background.vue";
import PreMarketPhase from "@/components/Facilitator/PreMarketPhase/PreMarketPhase";
import PostMarketPhase from "@/components/Facilitator/PostMarketPhase/PostMarketPhase";
import MarketingOverview from "@/components/Facilitator/MarketPhase/TrainerMarketingOverview";
import Period_0 from "@/components/Facilitator/Period_0/Period_0";
import PeriodDisplay from "../components/Period/PeriodDisplay.vue";
import SummaryOverview from "@/components/Summary_Overview";
import { mapGetters } from "vuex";
import Profitability from "@/profitability-nestle-js";
import { useWindowSize } from "vue-window-size";
import Alert from "../components/Alert_PopUp.vue";

export default {
  components: {
    Background,
    PreMarketPhase,
    PostMarketPhase,
    MarketingOverview,
    Period_0,
    PeriodDisplay,
    SummaryOverview,
    Alert,
  },
  setup() {
    const { width, height } = useWindowSize();

    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  emits: ["logout"],
  data() {
    return {
      logoutDialog: false,
      isLoaded: false
    };
  },
  mounted() {
    setInterval(() => {
      this.isLoaded = true
    }, 1000)
  },
  computed: {
    ...mapGetters({
      vDebug: "debug",
    }),
    ...mapGetters("profitability/trainer", {
      vTrainingSessionData: "trainingSessionData",
      vPhase: "phase",
      vTeams: "teams",
      vAllTeamSummaries: "allTeamSummaries",
    }),
    cssProps() {
      // the base resolution
      const width = 1920;
      const height = 1080;
      const ratio = width / height;

      // the actual view resolution
      let vw = this.windowWidth;
      let vh = this.windowHeight;
      const vRatio = vw / vh;

      // maintain aspect ratio
      if (vRatio >= ratio) {
        // when height is dominant, adjust the width
        vw = vh * ratio;
      } else {
        // when width is dominant, adjust the height
        vh = vw / ratio;
      }

      // get the scaleW and scaleH
      const scaleW = vw / width;
      const scaleH = vh / height;

      // center the view
      const marginTop = (this.windowHeight - vh) / 2;
      const marginLeft = (this.windowWidth - vw) / 2;

      return {
        // '--width': scaleW,
        // '--height': scaleH,
        // '--ori-width': width + "px",
        // '--ori-height': height + "px",

        // you can direct set the css style here, unless you need to use the variable in hierarchy child
        width: `${width}px`,
        height: `${height}px`,
        transform: `scale(${scaleW}, ${scaleH})`,

        "margin-top": `${marginTop}px`,
        "margin-left": `${marginLeft}px`,
      };
    },
  },
  methods: {
    Switch_Page(page) {
      if (page < 0) return;

      this.currentPage = page;
      if (page === 2) {
        this.submitMarketCardSetup();
      }

      // this.$emit("return");
    },
    nextPeriod() {
      Profitability.Trainer.nextPeriod();
    },
    confirmLogout() {
      this.logoutDialog = false;
      localStorage.clear();
      window.location.href = "/";
    },
  },
};
</script>


<style lang="scss">
.Facilitator_Container {
  /* width: var(--ori-width);
    height: var(--ori-height);
    transform: scale(var(--height), var(--width)); */
  transform-origin: top left;
  overflow: hidden;

  .Facilitator_Board {
    top: 7%;
    left: 4.45%;
    position: fixed;
    width: 91.8%;
    height: 83%;

    background-image: url("../assets/Facilitator/Facilitator_01_BGPanel.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.post {
      background-image: url("../assets/Facilitator/Facilitator_01_BGPanel_LeftHL.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .next-button {
    background: white;
    border-radius: 30px;
    width: 12%;
    height: 6%;
    border: none;
    font-size: 40px;

    position: absolute;
    right: 3.6%;
    bottom: 2.5%;
  }
  .next-button:hover {
    background: #b2b2b2;
  }
  .next-button:active {
    background: #392c23;
    color: white;
  }
}
</style>
