<template>
  <!-- <div v-if="isParticipant">
    <Gameboard />
  </div>
  <div v-else-if="isFacilitator">
    <Facilitatorboard @return = "LoginScreenAuthorize"/>
  </div>
  <div v-else>
    <LoginScreen @login = "LoginScreenAuthorize"/>
  </div> -->
  <router-view @login="LoginScreenAuthorize" @return="LoginScreenAuthorize" @logout="showLogoutDialog">
  </router-view>
</template>

<script>
import LoginScreen from './views/LoginScreen.vue'
import Gameboard from './views/Gameboard_2.vue'
import Facilitatorboard from './views/Facilitatorboard_2.vue'
import Rules from './views/Rules.vue'

export default {
  name: 'App',
  components: { LoginScreen, Gameboard, Facilitatorboard, Rules },
  methods:{
    LoginScreenAuthorize(type, username, password) {
      //Type pass in is used to bypass username/password logins for easier access now, will be removed in the future
      if(username == "participant" && password == "nestlep")
        type = 1
      if(username == "facilitator" && password == "nestlef")
        type = 2

      //Test for changing scenes
      switch(type) {
        case 1:
          this.$router.push({name:'Participant'})
          break;
        case 2:
          this.$router.push({name:'Facilitator'})
          break;
        case 3:
          this.$router.push({name:'Rules'})
          break;
        default:
          this.$router.push({name:'Login'})
          break;
      }
    },
  },
}
</script>

<style>
*, *:before, *:after {
	margin: 0;
	padding: 0;

	-webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	-ms-box-sizing: border-box !important;
	box-sizing: border-box !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: black;
}

button {
  cursor: pointer;
  font-family: NestleBrush_Reg;
  font-size: 30px;
}
</style>
