<template>
  <div class="Gameboard_Container" :style="cssProps" v-if="vTeam !== null">
    <Background />

    <!-- Performance Analysis at end of Period 3 onwards -->
    <!-- <div v-if="vPhase.period > 1 && vPhase.market_phase == 2 && vPhase.phase_step == 8">
            <PA @canSubmitPA="canSubmitPA"/>
        </div> -->

    <!-- Operational Planning & Cash Flow before the start of Market Phases (Period 4 onwards) -->
    <div
      v-if="
        vPhase.period > 2 &&
        vPhase.market_phase == 0 &&
        vPhase.phase_step == 0 &&
        !alreadySubmitOPnCF
      "
    >
      <button
        class="tab op"
        :class="{ activeTab: tab_name == 'op' }"
        @click="tab_name = 'op'"
      >
        Op. Plan
      </button>
      <button
        class="tab cf"
        :class="{ activeTab: tab_name == 'cf' }"
        @click="tab_name = 'cf'"
      >
        Cash Flow
      </button>
      <OperationalPlan
        v-if="tab_name === 'op'"
        v-model:operationalPlan="operationalPlan"
        @canSubmitOP="canSubmitOP"
        :at_panel="false"
      />
      <CashFlow
        v-else-if="tab_name === 'cf'"
        v-model:cashflowForecast="cashflowForecast"
        @canSubmitCF="canSubmitCF"
        :at_panel="false"
      />
      <button
        v-if="!alreadySubmitOPnCF"
        class="market_planning_btn"
        @click="marketPlanningProceed"
      >
        Next
      </button>
    </div>

    <!-- Gameboard -->
    <div v-if="vPhase.market_phase == 0 || vPhase.market_phase == 2">
      <div class="Panel_PlayerBoard">
        <h1>Team {{ vTeam?.name }}</h1>
        <SectionCash ref="SectionCash" @withdraw_cash="Withdrawal" />
        <SectionLand ref="SectionLand" />
        <SectionLoans ref="SectionLoans" />
        <SectionPayables ref="SectionPayables" />
        <SectionPlot ref="SectionPlot" />
        <SectionExternalLocations
          ref="SectionExternalLocations"
          @open_supplier_panel="ReturnPanelClicked"
          @open_bank_button="ReturnPanelClicked"
        />
        <SectionReceivable ref="SectionReceivable" />
        <SectionExpenses
          ref="SectionExpenses"
          @openDividendsInfoBox="triggerAlertDividend = true"
        />
      </div>

      <div class="Side_Controller">
        <div class="Side_Buttons">
          <li>
            <a href="/Rules" target="_blank">
              <SideButtons
                class="btnActivity"
                :pass_button_name="Sidepanel_list[0].name"
                :pass_position="Sidepanel_list[0].position"
                :pass_icon="Sidepanel_list[0].icon"
              />
            </a>
          </li>
          <li v-for="index in 7" :key="index">
            <SideButtons
              ref="Sidepanel_list[index].name"
              :pass_button_name="Sidepanel_list[index].name"
              :pass_position="Sidepanel_list[index].position"
              :pass_icon="Sidepanel_list[index].icon"
              @select_side_panel="ReturnPanelClicked"
              @return_side_button="UpdateSideButtonRef"
            />
          </li>
        </div>
        <SidePanel
          @return_side_panel="UpdateSidePanelRef"
          @close_side_panel="ReturnPanelClicked"
          @Borrow_Money="Borrow"
          @customer_cash_emit="Customer_Cash"
          @submitPAFroMSidePanel="submitPAFroMSidePanel"
          @storyRawMats="storyPurchaseRawMats"
        />
      </div>

      <CoinComponent ref="coincomponent" @use_coin="UpdateCoinInteractivity" />
    </div>

    <div v-else-if="vPhase.period > 0 && vPhase.market_phase == 3">
      <SummaryOverview
        :vPhase="vPhase"
        :vTeams="vTeams"
        :vAllTeamSummaries="vAllTeamSummaries"
        @return="UpdateSummaryOverview"
      />
    </div>

    <div
      v-else-if="
        vPhase.period > 0 && vPhase.market_phase == 1 && vPhase.phase_step === 4
      "
    >
      <MarketSummary />
    </div>

    <div v-else>
      <MarketingOverview @return="UpdateMarketingOverview" />

      <div class="Side_Controller">
        <div class="Side_Buttons">
          <li v-for="index in 1" :key="index">
            <SideButtons
              ref="Sidepanel_list[index].name"
              :pass_button_name="Sidepanel_list[index].name"
              :pass_position="Sidepanel_list[index].position"
              :pass_icon="Sidepanel_list[index].icon"
              @select_side_panel="ReturnPanelClicked"
              @return_side_button="UpdateSideButtonRef"
            />
          </li>
        </div>
        <SidePanel
          @return_side_panel="UpdateSidePanelRef"
          @close_side_panel="ReturnPanelClicked"
          @Borrow_Money="Borrow"
          @customer_cash_emit="Customer_Cash"
        />
      </div>
    </div>

    <div v-if="preMarketPeriodBoard" class="Panel_PeriodBoard">
      <h1 class="Period_Statement">
        Period {{ vPhase.period + 1 }} | Pre-Market
      </h1>
      <PeriodOverview
        v-if="vPhase.period < 3"
        :step="vPhase.phase_step"
        @triggerEmptyBuyOverview="triggerEmptyBuy"
        @triggerPAAlertOverview="triggerPAAlert"
      />
      <PeriodOverview
        v-else
        :step="vPhase.phase_step + 1"
        @triggerEmptyBuyOverview="triggerEmptyBuy"
        @triggerPAAlertOverview="triggerPAAlert"
      />

      <div v-if="vDebug" class="period debug">
        <div style="margin-top: 1.2rem; font-weight: bold; color: black">
          <div>
            {{ debugPhase }}
          </div>
          <div style="margin-top: 0.5rem">
            <button @click="prevPhase">Prev</button>
            <button @click="nextPhase">Next</button>
          </div>
          <div style="margin-top: 0.7rem">
            {{ debugActions[debugPhase.market_phase][debugPhase.phase_step] }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="vPhase.period !== 0 && vPhase.market_phase == 2"
      class="Panel_PeriodBoard"
    >
      <h1 class="Period_Statement">
        Period {{ vPhase.period + 1 }} | Post-Market
      </h1>
      <PeriodOverview
        :step="vPhase.phase_step"
        @triggerEmptyBuyOverview="triggerEmptyBuy"
        @triggerPAAlertOverview="triggerPAAlert"
        :submitPA="submitPA"
        :submitPAData="submitPAData"
      />

      <div v-if="vDebug" class="period debug">
        <div style="margin-top: 1.2rem; font-weight: bold; color: black">
          <div>
            {{ debugPhase }}
          </div>
          <div style="margin-top: 0.5rem">
            <button @click="prevPhase">Prev</button>
            <button @click="nextPhase">Next</button>
          </div>
          <div style="margin-top: 0.7rem">
            {{ debugActions[debugPhase.market_phase][debugPhase.phase_step] }}
          </div>
        </div>
      </div>
    </div>

    <!-- Phase 1: Tutorial -->
    <div v-if="vPhase.period == 0">
      <MBS />
    </div>

    <div style="position: absolute; bottom: 0; right: 0">
      <button class="btn btn-logout" @click="logoutDialog = true">Logout</button>
    </div>

    <Alert
        v-if="logoutDialog == true"
        :alertOpen="logoutDialog"
        :message="'Logout?'"
        popUpSize="gameboard"
        @close_alert="logoutDialog = false"
        @confirm_alert="confirmLogout"
    />

    <Alert
      v-if="triggerAlert == true"
      :alertOpen="triggerAlert"
      :message="emptyMessage"
      :onlyClose="false"
      :popUpSize="'gameboard'"
      @close_alert="triggerAlert = false"
      @confirm_alert="confirm_alert"
    />

    <Alert
      v-if="triggerAlert2 == true"
      :alertOpen="triggerAlert2"
      :message="PAMessage"
      :onlyClose="false"
      :popUpSize="'gameboard'"
      @close_alert="triggerAlert2 = false"
      @confirm_alert="confirm_alert"
    />

    <Alert
      v-if="triggerAlert3 == true"
      :alertOpen="triggerAlert3"
      :message="OPCFMessage"
      :onlyClose="false"
      :popUpSize="'gameboardBigText'"
      @close_alert="triggerAlert3 = false"
      @confirm_alert="confirmOPCFAlert"
    />

    <Alert
      v-if="triggerAlertDividend == true"
      :alertOpen="triggerAlertDividend"
      :message="dividendMsg"
      :onlyClose="true"
      popUpSize="gameboardBigText"
      @close_alert="triggerAlertDividend = false"
    />

    <StoryWindow :triggerStoryRawMats="triggerStoryRawMats" />
  </div>

  <div class="Gameboard_Container" :style="cssProps" v-else>
    <Background />
    <Alert
      :alertOpen="true"
      :message="'Loading. Please wait...'"
      :disableButtonsView="true"
      popUpSize="gameboardBigText"
    />
  </div>
</template>

<script>
import MarketingOverview from "../components/Marketing_Overview.vue";
import SummaryOverview from "../components/Summary_Overview_Participants.vue";
import MarketSummary from "../components/MarketSummary.vue";

import Background from "../components/Background.vue";
import SideButtons from "../components/Gameboard_SideButtons.vue";
import SidePanel from "../components/Gameboard_SidePanels_2";
import PeriodOverview from "../components/Period/PeriodOverview.vue";
import MBS from "../components/SidePanel/Gameboard_MBS.vue";
import PA from "../components/SidePanel/Gameboard_PA.vue";
import OperationalPlan from "../components/SidePanel/Gameboard_Operational_Plan.vue";
import CashFlow from "../components/SidePanel/Gameboard_Cash_Flow.vue";
import StoryWindow from "../components/SidePanel/Gameboard_Story_Window.vue";
import Alert from "@/components/Alert_PopUp";

import SectionCash from "../components/Section/Section_Cash.vue";
import SectionExpenses from "../components/Section/Section_Expenses.vue";
import SectionExternalLocations from "../components/Section/Section_External_Locations.vue";
import SectionLand from "../components/Section/Section_Land.vue";
import SectionLoans from "../components/Section/Section_Loans.vue";
import SectionPayables from "../components/Section/Section_Payables.vue";
import SectionPlot from "../components/Section/Section_Plot.vue";
import SectionReceivable from "../components/Section/Section_Receivable.vue";
import CoinComponent from "../components/Coin_Component.vue";
import { mapGetters } from "vuex";
import { useWindowSize } from "vue-window-size";
import { preMarket, postMarket } from "@/constants";

const debugActions = [preMarket, [], postMarket];

export default {
  setup() {
    const { width, height } = useWindowSize();

    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  components: {
    Background,
    SideButtons,
    SidePanel,
    PeriodOverview,
    CoinComponent,
    SectionCash,
    SectionExpenses,
    SectionExternalLocations,
    SectionLand,
    SectionLoans,
    SectionPayables,
    SectionPlot,
    SectionReceivable,
    MarketingOverview,
    SummaryOverview,
    MBS,
    PA,
    MarketSummary,
    OperationalPlan,
    CashFlow,
    StoryWindow,
    Alert,
  },
  emits: ["login", "logout", "return"],
  data() {
    return {
      Sidepanel_list: [
        {
          name: "Rules",
          position: 28,
          icon: "rules",
          button: null,
          associated_panel: null,
        },
        {
          name: "Marketing",
          position: 36.5,
          icon: "marketing_plan",
          button: null,
          associated_panel: null,
        },
        {
          name: "Delivery",
          position: 45,
          icon: "delivery_record",
          button: null,
          associated_panel: null,
        },
        {
          name: "Income",
          name2: "Statement",
          position: 53.5,
          icon: "is",
          button: null,
          associated_panel: null,
        },
        {
          name: "Balance",
          name2: "Sheet",
          position: 62,
          icon: "balance_statement",
          button: null,
          associated_panel: null,
        },
        {
          name: "Performance",
          position: 70.5,
          icon: "performance",
          button: null,
          associated_panel: null,
        },
        {
          name: "Forecast",
          position: 79,
          icon: "forecast",
          button: null,
          associated_panel: null,
        },
        {
          name: "Planning",
          position: 87.5,
          icon: "planning",
          button: null,
          associated_panel: null,
        },
        {
          name: "Supplier_Pre",
          position: 96,
          icon: "",
          button: null,
          associated_panel: null,
        },
        {
          name: "Supplier_Post",
          position: 104,
          icon: "",
          button: null,
          associated_panel: null,
        },
        {
          name: "Bank",
          position: 0,
          icon: "",
          button: null,
          associated_panel: null,
        },
      ],

      In_Marketing_Segment: true,
      In_Summary_Segment: true,
      In_AfterPreMarket_Segment: true,
      submitOP: false,
      submitCF: false,
      submitPA: false,
      tab_name: "op",
      submitPAData: {},
      triggerStoryRawMats: false,
      triggerAlert: false,
      triggerAlert2: false,
      triggerAlert3: false,
      triggerAlertDividend: false,
      emptyMessage:
        "You have not bought anything on this step, are you sure you would like to proceed?",
      PAMessage:
        "There may be errors in the Performance Analysis sheet. Click 'Confirm' to move on anyway and 'Cancel' to check and correct the errors.",
      OPCFMessage:
        "There may be incorrect answers in the Operational Plan and/or Cashflow Forecast. Click 'Confirm' to move on anyway and 'Cancel' to check and correct the errors.",
      dividendMsg:
        "Normally, you pay ⅓ of your Net Profit to shareholders as a dividend at the end of each period. If you do not have the available cash, your Shareholders have allowed you to pay the remaining cash value after making your tax payment instead of the full value of 1/3 of your Net Profit. They understand that your cash position is temporary and don't want you to take on any liabilities in this round to service the dividend payment.",
      marketSummaryUpdate: {},
      debugActions,
      logoutDialog: false,

      operationalPlan: {},
      cashflowForecast: {},

      isLoaded: false
    };
  },

  // mounted() {
  //   setInterval(() => {
  //     this.isLoaded = true
  //   }, 1000)
  // },

  computed: {
    cssProps() {
      // the base resolution
      const width = 1920;
      const height = 1080;
      const ratio = width / height;

      // the actual view resolution
      let vw = this.windowWidth;
      let vh = this.windowHeight;
      const vRatio = vw / vh;

      // maintain aspect ratio
      if (vRatio >= ratio) {
        // when height is dominant, adjust the width
        vw = vh * ratio;
      } else {
        // when width is dominant, adjust the height
        vh = vw / ratio;
      }

      // get the scaleW and scaleH
      const scaleW = vw / width;
      const scaleH = vh / height;

      // center the view
      const marginTop = (this.windowHeight - vh) / 2;
      const marginLeft = (this.windowWidth - vw) / 2;

      return {
        // '--width': scaleW,
        // '--height': scaleH,
        // '--ori-width': width + "px",
        // '--ori-height': height + "px",

        // you can direct set the css style here, unless you need to use the variable in hierarchy child
        width: `${width}px`,
        height: `${height}px`,
        transform: `scale(${scaleW}, ${scaleH})`,

        "margin-top": `${marginTop}px`,
        "margin-left": `${marginLeft}px`,
      };
    },
    ...mapGetters({
      vDebug: "debug",
    }),
    ...mapGetters("profitability/participant", {
      vTeam: "team",
      vPhase: "phase",
      vTeams: "teams",
      vTeamData: "teamData",
      vAllTeamSummaries: "allTeamSummaries",
      vGameboard: "gameboard",
    }),
    debugPhase() {
      return this.vPhase;
    },
    preMarketPeriodBoard() {
      return (
        (this.vPhase.period > 0 &&
          this.vPhase.market_phase == 0 &&
          this.vPhase.period <= 2) ||
        (this.vPhase.period > 2 &&
          this.vPhase.market_phase == 0 &&
          this.vPhase.phase_step == 0 &&
          this.alreadySubmitOPnCF) ||
        (this.vPhase.period > 2 &&
          this.vPhase.market_phase == 0 &&
          this.vPhase.phase_step > 0)
      );
    },
    vOperationalPlan() {
      return this.vTeamData.operational_plan;
    },
    vCashflowForecast() {
      return this.vTeamData.cashflow_forecast;
    },
    alreadySubmitOPnCF() {
      return this.vOperationalPlan != null && this.vCashflowForecast != null;
    },
    isPAPhase() {
      if (
        this.vPhase.period > 1 &&
        this.vPhase.market_phase == 2 &&
        this.vPhase.phase_step == 8
      )
        return true;

      return false;
    },
    cash() {
      return this.vGameboard?.getValue("cash");
    },
  },
  watch: {
    vOperationalPlan() {
      this.operationalPlan = this.vOperationalPlan;

      if (!this.vTeamData.operational_plan) {
        this.submitOP = false;
      }
    },
    vCashflowForecast() {
      this.cashflowForecast = this.vCashflowForecast;

      if (!this.vTeamData.cashflow_forecast) {
        this.submitCF = false;
      }
    },
    vPhase() {
      if (this.isPAPhase) {
        this.Sidepanel_list.find(
          (x) => x.name == "Performance"
        ).button.Signal_Hide("Performance");
      } else if (
        this.vPhase.period > 1 &&
        this.vPhase.market_phase === 2 &&
        this.vPhase.phase_step === 9
      ) {
        this.Sidepanel_list.find((x) => x.name == "Performance").button = null;
      }
    },
    cash(next) {
      this.$refs["coincomponent"]?.reset(0);
    },
  },
  methods: {
    ReturnPanelClicked(name) {
      for (let i = 0; i < this.Sidepanel_list.length; i++) {
        if (this.Sidepanel_list[i].button != null)
          this.Sidepanel_list[i].button.Signal_Hide(name);
      }

      this.Sidepanel_list.find(
        (x) => x.name == name
      ).associated_panel.Show_Panel(name);
    },
    UpdateSideButtonRef(name, ref) {
      this.Sidepanel_list.find((x) => x.name == name).button = ref;
    },
    UpdateSidePanelRef(ref) {
      for (let i = 0; i < this.Sidepanel_list.length; i++)
        this.Sidepanel_list[i].associated_panel = ref;
    },
    Withdrawal(amount) {
      this.$refs["coincomponent"].updateCoin(amount);
    },
    Borrow(borrow) {
      this.$refs["coincomponent"].updateCoinBorrow(borrow);
    },
    Customer_Cash(borrow) {
      this.$refs["coincomponent"].updateCoinCustomerCash(borrow);
    },
    UpdateMarketingOverview(inmarket) {
      this.In_Marketing_Segment = inmarket;
    },
    UpdateSummaryOverview(insummary) {
      this.In_Summary_Segment = insummary;
    },
    UpdateAfterPreMarketOverview(inside) {
      this.In_AfterPreMarket_Segment = inside;
    },
    UpdateCoinInteractivity(cash, section_name) {
      switch (section_name) {
        case "Section_Land":
          this.$refs["SectionLand"].Interact_Land(cash);
          return;
        case "Plot_1":
          this.$refs["SectionPlot"].Interact_Board(cash, section_name);
          break;
        case "Plot_2":
          this.$refs["SectionPlot"].Interact_Board(cash, section_name);
          break;
        case "Plot_3":
          this.$refs["SectionPlot"].Interact_Board(cash, section_name);
          break;
        case "Plot_4":
          this.$refs["SectionPlot"].Interact_Board(cash, section_name);
          break;
        case "PFME_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "RnD_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "FFOH_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "MOGE_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "Interest_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "Tax_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "Dividends_Pay":
          this.$refs["SectionExpenses"].Interact_Expense(cash, section_name);
          break;
        case "Standard_Loan_Pay":
          this.$refs["SectionLoans"].Interact_Loan_Standard(cash);
          break;
        case "High_Loan_Pay":
          this.$refs["SectionLoans"].Interact_Loan_High(cash);
          break;
        case "Cash_Box":
          this.$refs["SectionCash"].insert_cash(cash);
          break;
        case "Supplier_Box":
          this.$refs["SectionExternalLocations"].paySuppliers(cash);
          break;
        case "Payable_Box":
          this.$refs["SectionPayables"].payPayables(cash);
          break;
      }
    },
    async marketPlanningProceed() {
      if (this.operationalPlan == null || this.cashflowForecast == null) {
        alert(
          "Please fill in at least one value for both Operational Plan and Cashflow Forecast forms"
        );
        return;
      }

      if (this.submitOP && this.submitCF) {
        await Profitability.Participant.updateTeamData(
          this.operationalPlan,
          this.cashflowForecast
        );
      } else {
        _.each(["nc", "ng"], (type) => {
          this.operationalPlan[type]["nns"] == null
            ? (this.operationalPlan[type]["nns"] = 0)
            : "";
          this.operationalPlan[type]["mc"] == null
            ? (this.operationalPlan[type]["mc"] = 0)
            : "";
          this.operationalPlan[type]["tpfe"] == null
            ? (this.operationalPlan[type]["tpfe"] = 0)
            : "";
          this.operationalPlan[type]["pc"] == null
            ? (this.operationalPlan[type]["pc"] = 0)
            : "";
        });

        this.operationalPlan["total"]["pc"] == null
          ? (this.operationalPlan["total"]["pc"] = 0)
          : "";
        this.operationalPlan["total"]["tpoe"] == null
          ? (this.operationalPlan["total"]["tpoe"] = 0)
          : "";
        this.operationalPlan["total"]["op1"] == null
          ? (this.operationalPlan["total"]["op1"] = 0)
          : "";
        this.operationalPlan["total"]["before_tax"] == null
          ? (this.operationalPlan["total"]["before_tax"] = 0)
          : "";
        this.operationalPlan["total"]["budget"] == null
          ? (this.operationalPlan["total"]["budget"] = 0)
          : "";

        this.cashflowForecast["bal"]["add_money"] == null
          ? (this.cashflowForecast["bal"]["add_money"] = 0)
          : "";
        this.cashflowForecast["cash_out"]["sub_total_1"] == null
          ? (this.cashflowForecast["cash_out"]["sub_total_1"] = 0)
          : "";
        this.cashflowForecast["bal"]["sub_total_1"] == null
          ? (this.cashflowForecast["bal"]["sub_total_1"] = 0)
          : "";
        this.cashflowForecast["bal"]["loan_1"] == null
          ? (this.cashflowForecast["bal"]["loan_1"] = 0)
          : "";
        this.cashflowForecast["bal"]["cash_sales"] == null
          ? (this.cashflowForecast["bal"]["cash_sales"] = 0)
          : "";
        this.cashflowForecast["cash_out"]["sub_total_2"] == null
          ? (this.cashflowForecast["cash_out"]["sub_total_2"] = 0)
          : "";
        this.cashflowForecast["bal"]["loan_2"] == null
          ? (this.cashflowForecast["bal"]["loan_2"] = 0)
          : "";
        this.cashflowForecast["bal"]["ncf"] == null
          ? (this.cashflowForecast["bal"]["ncf"] = 0)
          : "";
        this.triggerAlert3 = true;
      }
    },
    canSubmitOP(bool) {
      this.submitOP = bool;
    },
    canSubmitCF(bool) {
      this.submitCF = bool;
    },
    submitPAFroMSidePanel(bool, data) {
      this.submitPA = bool;
      this.submitPAData = data;
    },
    triggerEmptyBuy() {
      this.triggerAlert = true;
    },
    triggerPAAlert() {
      this.triggerAlert2 = true;
    },
    confirm_alert() {
      this.triggerAlert = false;
      this.triggerAlert2 = false;
      return Profitability.Participant.nextPhaseStep();
    },
    async confirmOPCFAlert() {
      await Profitability.Participant.updateTeamData(
        this.operationalPlan,
        this.cashflowForecast
      ).then((this.triggerAlert3 = false));

      console.log(this.vOperationalPlan);
      console.log(this.vCashflowForecast);
    },
    storyPurchaseRawMats() {
      this.triggerStoryRawMats = true;
    },

    prevPhase() {
      Profitability.Participant.prevPhaseStep();
    },
    nextPhase() {
      Profitability.Participant.nextPhaseStep();
    },

    confirmLogout() {
      this.logoutDialog = false
      localStorage.clear()
      window.location.href = "/"
    },
  },
};
</script>

<style lang="scss">
.Gameboard_Container {
  /* width: var(--ori-width);
    height: var(--ori-height);
    transform: scale(var(--height), var(--width)); */
  transform-origin: top left;
  overflow: hidden;

  .mybutton {
    position: absolute;
    z-index: 5;
  }
  .btnActivity {
    border-radius: 8px 0 0 8px;
  }
  .btnActivity:hover {
    background: #b2b2b2;
  }
  .btnActivity:active {
    background: #392c23;
    width: 7%;
    color: white;
  }

  .btnActivity:active > p {
    color: white;
  }

  .Panel_PlayerBoard {
    top: 3%;
    left: 6%;
    position: fixed;
    width: 59.5%;
    height: 95%;

    background-image: url("../assets/Gameboard/Panel/panel_gameboard.png");
    background-position: center;
    background-size: 100% 100%;

    z-index: 1;
  }

  .Panel_PeriodBoard {
    top: 0;
    right: 0;
    position: fixed;
    width: 31%;
    height: 24.5%;

    background-image: url("../assets/Gameboard/Panel/panel_market_plan.png");
    background-position: center;
    background-size: 100% 100%;

    z-index: 1;
  }

  .Panel_PlayerBoard h1 {
    left: 8%;
    top: 3.6%;
    position: fixed;
    color: white;
    font-size: 36px;
  }

  .Panel_PeriodBoard h1 {
    left: 70%;
    top: 0;
    position: fixed;
    width: 29%;
    text-align: center;
    color: white;
    font-size: 30px;
  }

  .period.debug {
    position: absolute;
    inset: 0;
    z-index: 100;
    pointer-events: none;
  }

  .period.debug button {
    pointer-events: auto;
  }
  .tab {
    border: none;
    position: absolute;
    bottom: 6.5%;
    width: 8%;
    padding: 0.3% 0;
    background: rgb(61, 55, 49, 1);
    border-radius: 0 0 20px 20px;
    font-size: 24px;
    color: gray;

    &.op {
      right: 25.5%;
    }
    &.cf {
      right: 17%;
    }
  }
  .tab:hover {
    background: #392c23;
    color: white;
  }
  .tab:active {
    background: white;
    color: black;
  }
  .activeTab {
    background: rgb(99, 81, 61, 1);
    color: white;
    bottom: 5.5%;
    padding: 0.6% 0;
  }

  .market_planning_btn {
    border: none;
    position: absolute;
    bottom: 4.5%;
    right: 6.5%;
    width: 9%;
    height: 4.5%;

    background: #a3d65d;
    border-radius: 30px;
  }

  .market_planning_btn:hover {
    background: #729541;
  }

  .market_planning_btn:active {
    background: #392c23;
    color: white;
  }
}
</style>
