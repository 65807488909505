<style lang="scss">
.form_panel.order {
  &.Supplier_Pre {
    background-image: url("../assets/Gameboard/Panel/panel_copacker_order.png");
  }

  &.Supplier_Post {
    background-image: url("../assets/Gameboard/Panel/panel_rp_order.png");
  }

  &.frame_border {
    border: 5px solid white;
    border-radius: 8px;
  }

  .OR_Input {
    position: absolute;
    width: 17.5%;
    height: 10%;
    border: none;
    background: none;
    outline: none;
    font-family: NestleBrush_Reg;

    text-align: center;
    font-size: 28px;
    color: black;

    &.NC {
      &.Unit_Cost {
        top: 30%;
        left: 28%;
      }

      &.Unit_Ordered {
        top: 49%;
        left: 28%;
      }

      &.Cost_Order {
        top: 67%;
        left: 28%;
      }
    }

    &.NG {
      &.Unit_Cost {
        top: 30%;
        left: 54%;
      }

      &.Unit_Ordered {
        top: 49%;
        left: 54%;
      }

      &.Cost_Order {
        top: 67%;
        left: 54%;
      }
    }
  }

  .OR_Total {
    left: 78%;
    position: absolute;
    width: 17.5%;
    height: 10%;

    text-align: center;
    font-size: 28px;
    color: black;

    &.Unit_Cost {
      top: 27%;
    }

    &.Unit_Ordered {
      top: 45.5%;
    }

    &.Cost_Order {
      top: 64%;
    }
  }

  .Order_Button {
    bottom: 2.5%;
    left: 34.75%;
    position: absolute;
    width: 31%;
    height: 14.5%;

    background-color: #a4d65d;
    border: none;
    border-radius: 20px;
    font-size: 18px;
  }

  .Order_Button:hover{
    background-color: #729541;
  }

  .Order_Button:active {
    background-color: #392c23;
    color: white;
  }

  .white_box {
    background: white;
    border-radius: 6px;
  }
}
</style>

<template>
  <div class="form_panel order" :class="[panelName, {frame_border: vPhase.period === 0 && (vPhase.phase_step === 3 || vPhase.phase_step === 7)}]">
    <input
      class="OR_Input NC Unit_Cost"
      :value="classic_unit_price"
      maxlength="3"
      readonly
    />

    <input
      v-if="vPhase.period === 1 && vPhase.market_phase == 0"
      class="OR_Input NC Unit_Ordered"
      placeholder="0"
      v-model.number="classic_unit_p2"
      maxlength="3"
      disabled
      @focus="$event.target.select()"
    />
    <input
      v-else
      class="OR_Input NC Unit_Ordered white_box"
      placeholder="0"
      v-model.number="classic_unit"
      maxlength="3"
      @focus="$event.target.select()"
    />

    <input
      class="OR_Input NC Cost_Order"
      :value="isNaN(classic_unit_price * classic_unit) ? 0 : classic_unit_price * classic_unit"
      maxlength="3"
      readonly
    />
    <input
      class="OR_Input NG Unit_Cost"
      :value="gold_unit_price"
      maxlength="3"
      readonly
    />

    <input
      v-if="vPhase.period === 1 && vPhase.market_phase == 0"
      class="OR_Input NG Unit_Ordered"
      placeholder="0"
      v-model.number="gold_unit_p2"
      maxlength="3"
      disabled
      @focus="$event.target.select()"
    />
    <input
      v-else
      class="OR_Input NG Unit_Ordered white_box"
      placeholder="0"
      v-model.number="gold_unit"
      maxlength="3"
      :disabled="disableGold"
      @focus="$event.target.select()"
    />

    <input
      v-if="vPhase.period === 1"
      class="OR_Input NG Cost_Order"
      :value="7"
      maxlength="3"
      readonly
    />
    <input 
      v-else
      class="OR_Input NG Cost_Order"
      :value="isNaN(gold_unit_price * gold_unit) ? 0 : gold_unit_price * gold_unit"
      maxlength="3"
      readonly
    />

    <p class="OR_Total Unit_Cost"></p>
    <p class="OR_Total Unit_Ordered">{{ isNaN(classic_unit + gold_unit) ? 0 : classic_unit + gold_unit }}</p>
    <p class="OR_Total Cost_Order">
      {{ isNaN(classic_unit_price * classic_unit + gold_unit_price * gold_unit) ? 0 : classic_unit_price * classic_unit + gold_unit_price * gold_unit }}
    </p>
    <button class="Order_Button" @click="submit">Place Order</button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    panelName: String,
  },
  emits: ["close"],
  data() {
    return {
      classic_unit: 0,
      classic_unit_p2: 0,
      gold_unit: 0,
      gold_unit_p2: 1,
      triggerStoryMat: true,
    };
  },
  computed: {
    ...mapGetters("profitability/participant", {
      vPhase: "phase",
      vGameboard: "gameboard",
      vMarketingData: 'marketing_data',
    }),
    classic_unit_price() {
      return this.panelName === "Supplier_Pre" ? 4 : 2;
    },
    gold_unit_price() {
      return this.panelName === "Supplier_Pre" ? 7 : 4;
    },
    disableGold() {
      return this.vPhase.period === 0;
    },
    cash_stored() {
      return this.vGameboard?.getValue("cash");
    },
  },
  mounted() {
    if (this.vPhase.period >= 2 && this.vPhase.market_phase === 0) {
      this.classic_unit = this.vMarketingData.order?.classic;
      this.gold_unit = this.vMarketingData.order?.gold;
    }
  },
  methods: {
    submit() {
      if (this.vPhase.period === 0) {
        // Period 1 tutorial
        if (this.panelName === "Supplier_Pre") {
          if (this.classic_unit === 5) {
            Profitability.Participant.mbsBuyCoPack({
              classic: this.classic_unit,
            }).catch((err) => {
              err.reason && alert(err.reason);
            });
          } else {
            alert("Please purchase 5 unit of classic co-pack");
            return;
          }
        } else {
          if (this.classic_unit === 6) {
            Profitability.Participant.mbsBuyRaw({
              classic: this.classic_unit,
            }).catch((err) => {
              err.reason && alert(err.reason);
            });
          } else {
            alert("Please purchase 6 unit of classic raw");
            return;
          }
        }
      } else if (this.vPhase.period === 1) {
        // Phase 1 - Limit buying
        if (this.panelName === "Supplier_Pre") {
          Profitability.Participant.buyCoPack({
            classic: 0,
            gold: 1,
          });

        } else {
          if (this.classic_unit > 0 && this.triggerStoryMat) {
            console.log("this.triggerStoryMat: " + this.triggerStoryMat)
            this.$emit('storyPurchaseRawMats')
            this.triggerStoryMat = false
          }

          Profitability.Participant.buyRawPackagingMaterials({
            classic: this.classic_unit,
            gold: this.gold_unit,
          }).catch(function (e) {
            if (e.error == "insufficient_warehouse_slot") {
              alert("You don't have enough space on your line for these products. Reduce the number and try again")
            } else if (e.error == "insufficient_cash") {
              alert("You don't have enough cash for this order. Reduce the order or get a bank loan")
            }
          });          
        }
      } else {
        // Phase 2 onwards
        if (this.panelName === "Supplier_Pre") {
          Profitability.Participant.buyCoPack({
            classic: this.classic_unit,
            gold: this.gold_unit,
          }).catch(function (e) {
            if (e.error == "insufficient_cash") {
              alert("You don't have enough cash for this order. Reduce the order or get a bank loan")
            }
          });
        } else {
          Profitability.Participant.buyRawPackagingMaterials({
            classic: this.classic_unit,
            gold: this.gold_unit,
          }).catch(function (e) {
            if (e.error == "insufficient_warehouse_slot") {
              alert("You don't have enough space on your line for these products. Reduce the number and try again")
            } else if (e.error == "insufficient_cash") {
              alert("You don't have enough cash for this order. Reduce the order or get a bank loan")
            }
          });
        }
      }

      this.$emit("close");
    },
  },
};
</script>
