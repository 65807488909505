import EventEmitter from 'events';
import _ from 'lodash';

import { createAxios } from './utils/axios';
import { createEcho } from './utils/echo';
import { createVuexStore } from './utils/vuex';

import Modules from './modules';
import EventType from './EventType';
import profitabilityStore from './vuex/profitability';
import VuexORM from './vuex/models';
import debug from 'debug';

const defaultOptions = {
  host: 'http://localhost:8000',
  echo: {
    broadcaster: 'socket.io',
    host: 'http://localhost:6001',
  },
};

class Profitability extends EventEmitter {
  _initialized = false;

  _options = {};

  _axios = null;
  _echo = null;
  _store = null;

  _user = null;

  EventType = EventType;

  get options() {
    return this._options;
  }

  // utils
  get axios() {
    return this._axios;
  }

  get echo() {
    return this._echo;
  }

  get store() {
    return this._store;
  }

  get Models() {
    return VuexORM.models;
  }

  get debug() {
    return (namespace) => debug(`profitability:${namespace}`);
  }

  // auth
  get authenticated() {
    return this.user != null;
  }

  get user() {
    return this._user;
  }

  get role() {
    return this.user?.role;
  }

  constructor() {
    super();
  }

  /**
   * Initialize Profitability package
   * @param options
   * @param {String} options.host
   * @param {Object} options.echo
   * @param {String} options.echo.host
   * @param {Boolean} options.log
   * @returns {Promise<void>|*}
   */
  initialize(options = {}) {
    const log = debug('profitability:initialize');
    if (this._initialized) {
      console.group('Profitability');
      console.warn('Please call initialize once only!');
      console.groupEnd();
      if (this.initializing) return this.initializing;
      return Promise.resolve();
    }

    this._initialized = true;
    // this._log = new Logger(options);
    if (options.log) {
      localStorage.debug = 'profitability:*';
    }

    log('initializing');

    this._options = {
      ...defaultOptions,
      ...options,
    };

    this._axios = createAxios(this);
    this._echo = createEcho(this);
    this._store = this._options.store || createVuexStore();
    this._store.registerModule('profitability', profitabilityStore);
    VuexORM.registerVue(this._store);

    _.each(Modules, (Module, key) => {
      this[key] = new Module(this);
    });

    return this.rehydrateAuth().then(() => {
      return this.subscribe();
    }).finally(() => {
      log('initialized');
    });
  }

  subscribe() {
    // debug('profitability:subscribe')('subscribe');
    return Promise.resolve();
  }

  rehydrateAuth() {
    // debug('profitability:rehydrateAuth')('rehydrateAuth');
    const promises = [];

    const authString = localStorage.getItem('profitability:auth');
    if (authString) {
      const log = debug('profitability:rehydrateAuth');
      try {
        const auth = JSON.parse(authString);

        log('Authenticated');
        log(`Welcome back, ${auth.user.name}`);

        this.setAuthToken(auth.access_token);
        this._user = auth.user;
        const onLoggedIn = this._onLoggedIn();

        if (typeof onLoggedIn === 'object' && onLoggedIn instanceof Promise) {
          promises.push(onLoggedIn);
        }

      } catch (e) {
        log('Auth file corrupted. Unauthenticated');
        localStorage.removeItem('profitability:auth');
      }
    }

    return Promise.all(promises);
  }

  setAuthToken(token) {
    this._axios.token = token;
    this._echo.connector.options.auth = {
      headers: {
        authorization: `Bearer ${token}`
      },
    };
  }

  _onLoggedIn() {
    const log = debug('profitability:_onLoggedIn');

    // attempt to leave all channel if _onLoggedIn is called
    // to prevent double listeners subscribed
    _.each(this.echo.connector.channels, (channel, key) => {
      this.echo.leave(key);
    });

    // subscribe to user channel
    const userChannel = `user.${this.user.id}`;
    log(`subscribe to ${userChannel}`);
    const privateUserChannel = this.echo.private(userChannel);
    this.emit(EventType.SubscribeToUser, privateUserChannel);
    privateUserChannel.listen(`.TrainingStartedEvent`, (event) => {
      debug(`profitability:Echo.TrainingStartedEvent`)(event);
      this.emit(EventType.TrainingStartedEvent, event);
    });

    // subscribe to general channel
    log(`subscribe to general`);
    const generalChannel = this.echo.channel('general');
    this.emit(EventType.SubscribeToGeneral, generalChannel);

    const promises = [];

    this.store.commit('profitability/participant/reset');
    this.store.commit('profitability/trainer/reset');

    // pull session data
    if (this.role === 'participant') {
      return this.Participant._onLoggedIn();
    } else if (this.role === 'trainer') {
      return this.Trainer._onLoggedIn();
    }

    return Promise.resolve();
  }
}

export default Profitability;
