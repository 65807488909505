<template>
    <PeriodProgress />
    <div class="Period_Detail" v-if="vPhase.market_phase == 0">
        <div v-if="vPhase.period < 3">
            <div v-for="i in preMarket.length" :key="i">
                <div v-if="i == 0">
                    <PeriodDetail class="round-border-up" :pass_position="(i * 14) - 14" :text="preMarket[i-1]" :isAtGBE="isAtGBE"
                    :class="[(i-1 < step ? 'green' : 'ungrey'), (i-1 > step ? 'grey' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" @triggerPAAlert="triggerPAAlert" />
                </div>
                <div v-else-if="i == preMarket.length">
                    <PeriodDetail class="round-border-down grey" :pass_position="(i * 14) - 14" :text="preMarket[i-1]" :isAtGBE="isAtGBE"
                    :class="[(i-1 < step ? 'green' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" @triggerPAAlert="triggerPAAlert" />
                </div>
                <div v-else>
                    <PeriodDetail :pass_position="(i * 14) - 14" :text="preMarket[i-1]" :isAtGBE="isAtGBE"
                    :class="[(i-1 < step ? 'green' : 'ungrey'), (i-1 > step ? 'grey' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" @triggerPAAlert="triggerPAAlert" />
                </div>

                <button v-if="i-1 === step && i !== preMarket.length && !isAtGBE" class="info_btn" :style="{ top: (i * 14 - 12.5) + '%' }" @click="checkInfoBox"></button>
                <button v-if="i-1 === step && vPhase.period > 1 && !isAtGBE" class="info_btn" :style="{ top: (i * 14 - 12.5) + '%' }" @click="checkInfoBox"></button>
            </div>
        </div>
        <div v-else>
            <div v-for="i in preMarketOPCF.length" :key="i">
                <div v-if="i == 1">
                    <PeriodDetail class="round-border-up" :pass_position="(i * 14) - 14" :text="preMarketOPCF[i-1]" :isAtGBE="isAtGBE"
                    :class="[(submittedOPnCF ? 'grey' : 'ungrey'), (submittedOPnCF ? 'green' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" @triggerPAAlert="triggerPAAlert" />
                </div>
                <div v-else-if="i == preMarketOPCF.length">
                    <PeriodDetail class="round-border-down grey" :pass_position="(i * 14) - 14" :text="preMarketOPCF[i-1]" :isAtGBE="isAtGBE"
                    :class="[(i-1 < step ? 'green' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" @triggerPAAlert="triggerPAAlert" />
                </div>
                <div v-else>
                    <PeriodDetail :pass_position="(i * 14) - 14" :text="preMarketOPCF[i-1]" :isAtGBE="isAtGBE"
                    :class="[(i-1 < step ? 'green' : 'ungrey'), (i-1 > step ? 'grey' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" @triggerPAAlert="triggerPAAlert" />
                </div>

                <button v-if="i-1 === step && i !== preMarketOPCF.length && !isAtGBE" class="info_btn" :style="{ top: (i * 14 - 12.5) + '%' }" @click="checkInfoBox"></button>
                <button v-if="i-1 === step && vPhase.period > 1 && !isAtGBE" class="info_btn" :style="{ top: (i * 14 - 12.5) + '%' }" @click="checkInfoBox"></button>
            </div>
        </div>
    </div>
    <div class="Period_Detail" v-else>
        <div v-if="vPhase.period < 2">
            <div v-for="i in postMarket.length" :key="i">
                <div v-if="i == 1">
                    <PeriodDetail class="round-border-up" :pass_position="(i * 14.5) - 14.5" :text="postMarket[i-1]"
                    :class="[(i-1 < step ? 'green' : 'ungrey'), (i-1 > step ? 'grey' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" :isAtGBE="isAtGBE" @triggerPAAlert="triggerPAAlert" />
                </div>
                <div v-else-if="i == postMarket.length">
                    <PeriodDetail class="round-border-down grey" :pass_position="(i * 14.5) - 14.5" :text="postMarket[i-1]"
                    :class="[(i-1 < step ? 'green' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" :isAtGBE="isAtGBE" @triggerPAAlert="triggerPAAlert" />
                </div>
                <div v-else>
                    <PeriodDetail :pass_position="(i * 14.5) - 14.5" :text="postMarket[i-1]"
                    :class="[(i-1 < step ? 'green' : 'ungrey'), (i-1 > step ? 'grey' : 'ungrey')]" @triggerEmptyBuy="triggerEmptyBuy" :isAtGBE="isAtGBE" @triggerPAAlert="triggerPAAlert" />
                </div>

                <button v-if="i-1 === step && i !== postMarket.length && !isAtGBE" class="info_btn" :style="{ top: (i * 14.5) - 13 + '%' }" @click="checkInfoBox"></button>
                <button v-if="i-1 === step && vPhase.period > 1 && !isAtGBE" class="info_btn" :style="{ top: (i * 14.5) - 13 + '%' }" @click="checkInfoBox"></button>
            </div>
        </div>
        <div v-else>
            <div v-for="i in postMarketPA.length" :key="i">
                <div v-if="i == 1">
                    <PeriodDetail class="round-border-up" :pass_position="(i * 14.5) - 14.5" :text="postMarketPA[i-1]"
                    :class="[(i-1 < step ? 'green' : 'ungrey'), (i-1 > step ? 'grey' : 'ungrey')]"
                    @triggerEmptyBuy="triggerEmptyBuy" :submitPA="submitPA" :submitPAData="submitPAData" :isAtGBE="isAtGBE" @triggerPAAlert="triggerPAAlert" />
                </div>
                <div v-else-if="i == postMarketPA.length">
                    <PeriodDetail class="round-border-down grey" :pass_position="(i * 14.5) - 14.5" :text="postMarketPA[i-1]"
                    :class="[(i-1 < step ? 'green' : 'ungrey')]"
                    @triggerEmptyBuy="triggerEmptyBuy" :submitPA="submitPA" :submitPAData="submitPAData" :isAtGBE="isAtGBE" @triggerPAAlert="triggerPAAlert" />
                </div>
                <div v-else>
                    <PeriodDetail :pass_position="(i * 14.5) - 14.5" :text="postMarketPA[i-1]"
                    :class="[(i-1 < step ? 'green' : 'ungrey'), (i-1 > step ? 'grey' : 'ungrey')]"
                    @triggerEmptyBuy="triggerEmptyBuy" :submitPA="submitPA" :submitPAData="submitPAData" :isAtGBE="isAtGBE" @triggerPAAlert="triggerPAAlert" />
                </div>

                <button v-if="i-1 === step && i !== postMarketPA.length && !isAtGBE" class="info_btn" :style="{ top: (i * 14.5) - 13 + '%' }" @click="checkInfoBox"></button>
                <button v-if="i-1 === step && vPhase.period > 1 && !isAtGBE" class="info_btn" :style="{ top: (i * 14.5) - 13 + '%' }" @click="checkInfoBox"></button>
            </div>
        </div>
    </div>

    <div v-if="showInfo && !isAtGBE && vPhase.market_phase !== 1" class="Info_Box" v-on:click="checkInfoBox">
        <div class="wrapper">
            <div v-if="vPhase.period === 1">
                <pre v-if="vPhase.market_phase == 0">{{ infoBoxP2PreMarket[step] }}</pre>
                <pre v-if="vPhase.market_phase == 2">{{ infoBoxP2PostMarket[step] }}</pre>
            </div>
            <div v-else-if="vPhase.period < 3">
                <div v-if="vPhase.market_phase == 0">
                    <pre>{{ infoBoxPreMarket[step] }}</pre>
                </div>
                <div v-if="vPhase.market_phase == 2">
                    <pre>{{ infoBoxPostMarket[step] }}</pre>
                </div>
            </div>
            <div v-else>
                <div v-if="vPhase.market_phase == 0">
                    <pre>{{ infoBoxPreMarketP4P5[step-1] }}</pre>
                </div>
                <div v-if="vPhase.market_phase == 2">
                    <pre>{{ infoBoxPostMarketP4P5[step] }}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PeriodDetail from './PeriodDetail.vue'
import PeriodProgress from './PeriodProgress.vue'
import { mapGetters } from 'vuex'
import { preMarket, postMarket, preMarketOPCF, postMarketPA, infoBoxP2PreMarket,
         infoBoxP2PostMarket, infoBoxPreMarket, infoBoxPreMarketP4P5, infoBoxPostMarketP4P5,
         infoBoxPostMarket } from '@/constants'

export default {
    components: { PeriodDetail, PeriodProgress },
    props: {
        submitPAData: Object,
        submitPA: Boolean,
        team: Object,
        step: Number,
        isAtGBE: Boolean,
    },
    emits: ['triggerEmptyBuyOverview', 'triggerPAAlertOverview'],
    data() {
        return {
            preMarket,
            preMarketOPCF,
            postMarket,
            postMarketPA,
            infoBoxP2PreMarket,
            infoBoxP2PostMarket,
            infoBoxPreMarket,
            infoBoxPreMarketP4P5,
            infoBoxPostMarketP4P5,
            infoBoxPostMarket,
            showInfo: false,
        }
    },
    computed: {
        ...mapGetters('profitability/participant', {
            vPhaseParticipant: 'phase',
            vTeamData: 'teamData',
        }),
        ...mapGetters('profitability/trainer', {
            vTeam: 'team',
            vPhaseTrainer: 'phase'
        }),
        vPhase() {
            if (this.isAtGBE) {
                return this.vPhaseTrainer
            } else {
                return this.vPhaseParticipant
            }
        },
        submittedOPnCF() {
            if (this.isAtGBE) {
                return this.team.teamData.operational_plan && this.team.teamData.cashflow_forecast
            } else {
                return this.vTeamData.cashflow_forecast && this.vTeamData.operational_plan
            }
        },
        checkInfoName() {
            return this.$store.state.whichInfoButton.name
        }
    },
    watch: {
        checkInfoName: {
            deep: true,
            handler() {
                if (this.checkInfoName !== "show_period") {
                    this.showInfo = false
                }
            }
        },
        vPhase: {
            deep: true,
            handler(val, old) {
                if (val.phase_step != old.phase_step) {
                    this.showInfo = false
                }
            }
        }

    },
    methods: {
        checkInfoBox() {
            this.$store.state.whichInfoButton.name = "show_period"

            if (this.$store.state.whichInfoButton.name === "show_period") {
                this.showInfo === true ? this.showInfo = false : this.showInfo = true
            }
        },
        triggerEmptyBuy() {
            this.$emit("triggerEmptyBuyOverview")
        },
        triggerPAAlert() {
            this.$emit("triggerPAAlertOverview")
        },
        confirm_alert() {
            this.triggerAlert = false
            return Profitability.Participant.nextPhaseStep();
        },
    },
}
</script>

<style lang="scss">
    .Period_Detail {
        position: absolute;
        top: 17%;
        left: 8.1%;
        width: 90%;
        height: 80%;
        overflow-y: auto;
    }

    .round-border-up {
        border-radius: 7px 7px 0px 0px;
    }
    .round-border-down {
        border-radius: 0px 0px 7px 7px;
    }

    .grey{
        background-color: white;
    }

    .grey > p {
        color: black;
    }

    .grey > button {
        display: none;
    }

    .grey > p > button {
        display: none;
    }

    .grey > img {
        display: none;
    }

    .ungrey > img {
        display: none;
    }

    .green {
        background-color: #b5dd7d;
    }

    .green > button {
        display: none;
    }

    .green > img {
        display: block;
    }

    .info_btn {
        position: absolute;
        left: 0.65%;
        margin-bottom: 1.15%;
        width: 3%;
        height: 7.5%;

        background: url("../../assets/Gameboard/Period/info.png");
        background-size: 100% 100%;
        border: none;
        z-index: 5;
    }

    .Info_Box {
        position: absolute;
        background: #b5dd7d;
        border-radius: 10px;
        top: 17%;
        width: 78%;
        height: 80%;
        right: 105%;
        z-index: 5;
        font-family: NestleText;

        .wrapper {
            position: absolute;
            top: 5%;
            left: 3%;
            width: 93.5%;
            height: 89%;
            overflow-y: auto;
            background: #63513d;
            color: white;
            border-radius: 10px;
            text-align: justify;
            padding: 2% 3%;

            pre {
                padding: 0;
                margin: 0;
                font-size: 22px;
                font-family: NestleText;
                white-space: pre-wrap;       /* Since CSS 2.1 */
                white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                white-space: -pre-wrap;      /* Opera 4-6 */
                white-space: -o-pre-wrap;    /* Opera 7 */
                word-wrap: break-word;       /* Internet Explorer 5.5+ */
            }
        }
    }
</style>
