<template>
  <div class="Section_Plot">
    <div class="Plot_1" :class="{frame_border: vPhase.period === 0 && (vPhase.phase_step === 2 || vPhase.phase_step === 9)}">
      <PlotDetail ref="Plot_1" :index="0" @spawn_coin="get_coin" @depreciated="resetCoin" />
    </div>

    <div class="Plot_2">
      <PlotDetail ref="Plot_2" :index="1" @spawn_coin="get_coin" @depreciated="resetCoin" />
    </div>

    <div class="Plot_3">
      <PlotDetail ref="Plot_3" :index="2" @spawn_coin="get_coin" @depreciated="resetCoin" />
    </div>

    <div class="Plot_4">
      <PlotDetail ref="Plot_4" :index="3" @spawn_coin="get_coin" @depreciated="resetCoin" />
    </div>
  </div>
  <CoinComponent ref="CComponent" @use_coin="drop_function"/>
</template>

<script>
import { mapGetters } from 'vuex';
import CoinComponent from '../Coin_Component.vue';
import PlotDetail from './Plot_Detail.vue';

export default {
  components: { CoinComponent, PlotDetail },
  data() {
    return {
      allPlotsTaken: false,
    };
  },
  computed: {
    ...mapGetters('profitability/participant', {
      vFactories: 'factories',
      vPhase: 'phase',
    }),
    is_warehouse() {
      return this.factory.type === 'warehouse';
    },
    is_classic() {
      return this.factory.type === 'classic';
    },
    is_gold() {
      return this.factory.type === 'gold';
    },
    converted() {
      return this.factory.type !== null;
    },
  },
  methods: {
    get_coin(plot_type, coin_amount) {
      this.$refs['CComponent'].reset(0);
      setTimeout(() => {
        switch (plot_type) {
          case 'Plot_1':
            this.$refs['CComponent'].set_custom_grab(0.83, 0.26, coin_amount, 'Depreciation_Pay', plot_type, 'depreciate');
            break;
          case 'Plot_2':
            this.$refs['CComponent'].set_custom_grab(0.83, 0.4275, coin_amount, 'Depreciation_Pay', plot_type, 'depreciate');
            break;
          case 'Plot_3':
            this.$refs['CComponent'].set_custom_grab(0.5, 0.26, coin_amount, 'Depreciation_Pay', plot_type, 'depreciate');
            break;
          case 'Plot_4':
            this.$refs['CComponent'].set_custom_grab(0.5, 0.4275, coin_amount, 'Depreciation_Pay', plot_type, 'depreciate');
            break;
        }
      }, 50);

    },
    resetCoin() {
      this.$refs['CComponent'].reset(0);
    },
    drop_function(cash, location, func) {
      this.$refs[func].depreciate();
      this.$refs['CComponent'].reset();
    },
    Interact_Board(this_cash, this_plot) {
      // console.log("Section_Plot::Interact_Board - this_cash : this_plot : ", {this_cash, this_plot}, " || this.$refs[this_plot] : " , this.$refs[this_plot])

      if (this.$refs[this_plot].converted == false) {
        //! Buy
        if (this_cash > 0)
          if (this.vPhase.period === 0)
            if (this.vPhase.phase_step === 2)
              if (this_plot === 'Plot_1')
                if (this_cash !== 15)
                  alert('Please purchase Nescafe Classic line with 15 coins');
                else
                  this.Purchase_Plot_Land(this_cash, this_plot);
              else
                alert('Not the time to purchase plot');
            else if (this_cash !== 15 || this_cash !== 20) {
              alert('Please drop sufficient tokens to purchase plot \n15 tokens for Nescafe Classic Line \n20 tokens for Nescafe Gold Line');
              return;
            }
        this.Purchase_Plot_Land(this_cash, this_plot);
        //! Convert
      } else {
        this.Update_WIP(this_cash, this_plot);
      }
    },
    Purchase_Plot_Land(this_cash, this_plot) {
      if (this_cash == 20)
        this.$refs[this_plot].Change_Plot(2);
      else if (this_cash == 15)
        this.$refs[this_plot].Change_Plot(1);
      else
        alert('Please drop sufficient tokens to purchase plot \n15 tokens for Nescafe Classic Line \n20 tokens for Nescafe Gold Line');
    },
    Update_WIP(this_cash, this_plot) {
      if (this.$refs[this_plot].is_classic == true) {
        if (this_cash == 10) {
          this.$refs[this_plot].Update_Plot(this_cash);
        } else {
          alert('Incorrect WIP amount. Pay 10 coins for Classic, and 20 coins for Gold');
        }
      } else if (this.$refs[this_plot].is_gold == true) {
        if (this_cash == 20) {
          this.$refs[this_plot].Update_Plot(this_cash);
        } else {
          alert('Incorrect WIP amount. Pay 10 coins for Classic, and 20 coins for Gold');
        }
      } else if (this.$refs[this_plot].is_warehouse == true) {
        this.Purchase_Plot_Land(this_cash, this_plot);
      } else {
        alert('Invalid action or money dropped to fill Work-In-Progress is not sufficient');
      }
    },
  },
};
</script>

<style lang="scss">
.Section_Plot {
  top: 10%;
  left: 15.5%;
  position: fixed;
  width: 34.25%;
  height: 64.75%;

  background-image: url("../../assets/Gameboard/Panel/panel_plot_highlight.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .Plot_1 {
    top: 10%;
    left: 16%;
    position: fixed;
    width: 16.5%;
    height: 32%;
  }

  .Plot_2 {
    top: 10%;
    left: 32.75%;
    position: fixed;
    width: 16.5%;
    height: 32%;
  }

  .Plot_3 {
    top: 42%;
    left: 16%;
    position: fixed;
    width: 16.5%;
    height: 32%;
  }

  .Plot_4 {
    top: 42%;
    left: 32.75%;
    position: fixed;
    width: 16.5%;
    height: 32%;
  }

  .frame_border {
    border: 5px solid red;
    border-radius: 8px;
  }
}

</style>
