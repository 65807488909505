<template>
  <div class="Coin_Indicator" ref="coin_stack" v-for="n in pass_cash" :key="n">
    <!-- setcoinref -->
    <img
      class="Allign_Coin"
      src="../assets/Gameboard/Currency/coin_assets.png"
      :ref="'coin_' + n"
    />

    <!-- <div v-for="i in Math.floor(Math.log10(n) + 1)" :key="i">
            <p :ref="'stack_' + (i+1)" class="Coin_Amount">{{coin_indicator_amount[i]}}</p>
        </div> -->
  </div>
  <p ref="stack_1" class="Coin_Amount">{{ coin_indicator_amount[0] }}</p>
  <p ref="stack_2" class="Coin_Amount">{{ coin_indicator_amount[1] }}</p>
  <p ref="stack_3" class="Coin_Amount">{{ coin_indicator_amount[2] }}</p>
  <p ref="stack_4" class="Coin_Amount">{{ coin_indicator_amount[3] }}</p>
  <p ref="stack_5" class="Coin_Amount">{{ coin_indicator_amount[4] }}</p>
  <p ref="stack_6" class="Coin_Amount">{{ coin_indicator_amount[5] }}</p>
</template>

<script>
export default {
  props: {
    pass_currency_type: {
      type: Number,
      default: 0,
    },
    pass_cash: {
      type: Number,
      default: 0,
    },
    pass_debt_offset: {
      type: Boolean,
      default: false,
    },
    pass_late_init: {
      type: Boolean,
      default: false,
    },
    is_trainer_view: {
      type: Boolean,
      default: false,
    },
    is_check_pass_minute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debt_offset_setter: this.pass_debt_offset,
      currency_type: this.pass_currency_type,
      //cash: this.pass_cash,
      coin_indicator_amount: [0, 0, 0, 0, 0, 0],
      late_init: this.pass_late_init,
      currency_cash: require("../assets/Gameboard/Currency/coin_cash.png"),
      currency_cash_bundle: require("../assets/Gameboard/Currency/cash_bundle.png"),
      currency_debt_bundle: require("../assets/Gameboard/Currency/cash_bundle_debt.png"),
      currency_asset: require("../assets/Gameboard/Currency/coin_assets.png"),
      currency_classic: require("../assets/Gameboard/Currency/coin_classic.png"),
      currency_gold: require("../assets/Gameboard/Currency/coin_gold.png"),
      copacked_currency_classic: require("../assets/Gameboard/Currency/coin_classic_copacked.png"),
      copacked_currency_gold: require("../assets/Gameboard/Currency/coin_gold_copacked.png"),
      currency_debt: require("../assets/Gameboard/Currency/coin_debt.png"),
      adjustHeight: 85,
    };
  },
  watch: {
    pass_cash(newVal, oldVal) {
      this.Initialize(newVal);
      //this.cash = newVal;
    },
  },
  mounted() {
    this.Initialize(this.pass_cash);
    if (this.is_check_pass_minute) {
      for (let i = 1; i < 11; i++) {
        setTimeout(() => {
          this.Initialize(this.pass_cash);
        }, 6000 * i);
      }
    }
    // this.$watch('cash',(newVal, oldVal) => {
    //     setTimeout(() => {
    //         this.Initialize(newVal)
    //     }, this.late_init ? 1000 : 0) })
  },
  methods: {
    async Initialize(new_coin_val = 0) {
      var wait = await Promise.resolve(
        this.coin_indicator_amount.length == new_coin_val
      );
      //console.log("currency_type:" + this.currency_type)
      for (let i = 0; i < this.coin_indicator_amount.length; i++) {
        if (this.$refs["stack_" + (i + 1)] !== undefined) {
          this.$refs["stack_" + (i + 1)].style.color = "transparent";
          this.$refs["stack_" + (i + 1)].style.top = 3000 + "px";
        }
        this.coin_indicator_amount[i] = 0;
      }
      for (let i = 0; i < new_coin_val; i++) {
        this.$refs["coin_" + (i + 1)].style.opacity = 0;
      }

      var startPoint = 0;
      var newColumn = 0;
      var coin_spacing = 53;
      var is_cash_stack = false;
      switch (this.currency_type) {
        case 1:
          if (new_coin_val > 20) {
            newColumn = 1;
            is_cash_stack = true;
            var check_stack = parseInt(new_coin_val / 20);
            for (let i = 0; i < check_stack; i++) {
              this.SetCoin_Position(
                this.currency_cash_bundle,
                i + 1,
                i,
                0,
                12,
                true,
                45.5,
                coin_spacing
              );
            }

            new_coin_val = new_coin_val - check_stack * 20;
            for (let i = check_stack; i < new_coin_val + check_stack; i++) {
              if (startPoint > 9) {
                startPoint = 0;
                newColumn++;
              }
              this.SetCoin_Position(
                this.currency_cash,
                i + 1,
                startPoint,
                newColumn,
                52,
                false,
                45.5,
                coin_spacing
              );
              startPoint++;
            }

            this.$refs["stack_1"].style.top = 47 - check_stack * 4.8 + "px";
            this.$refs["stack_1"].style.left = 28 + "px";
            this.$refs["stack_1"].style.color = "black";
            this.coin_indicator_amount[0] = check_stack * 20;
          } else {
            is_cash_stack = false;
            this.Initialize_Coin_Standard(
              this.currency_cash,
              new_coin_val,
              coin_spacing
            );
          }
          break;
        //! Currency Asset
        case 2:
          coin_spacing = 55;
          this.Initialize_Coin_Standard(
            this.currency_asset,
            new_coin_val,
            coin_spacing
          );
          break;
        //! Currency Classic
        case 3:
          this.Initialize_Coin_Standard(
            this.currency_classic,
            new_coin_val,
            coin_spacing
          );
          break;
        //! Currency Gold
        case 4:
          this.Initialize_Coin_Standard(
            this.currency_gold,
            new_coin_val,
            coin_spacing
          );
          break;
        //! Currency Classic Copacked
        case 7:
          this.$refs["coin_1"].src = this.copacked_currency_classic;
          this.$refs["coin_1"].style.bottom = 30 + "px";
          this.$refs["stack_1"].style.bottom = 35 + "px";
          this.$refs["stack_1"].style.color = "black";
          this.coin_indicator_amount[0] = 4;
          return;
        //! Currency Gold Copacked
        case 8:
          this.$refs["coin_1"].src = this.copacked_currency_gold;
          this.$refs["coin_1"].style.bottom = 30 + "px";
          this.$refs["stack_1"].style.bottom = 52.5 + "px";
          this.$refs["stack_1"].style.color = "black";
          this.coin_indicator_amount[0] = 7;
          return;
        //! Currency Debt
        case 9:
          if (new_coin_val > 20) {
            newColumn = 1;
            is_cash_stack = true;
            var check_stack = parseInt(new_coin_val / 20);
            for (let i = 0; i < check_stack; i++) {
              this.SetCoin_Position(
                this.currency_debt_bundle,
                i + 1,
                i,
                0,
                16.5,
                true,
                45.5,
                coin_spacing
              );
            }

            new_coin_val = new_coin_val - check_stack * 20;
            for (let i = check_stack; i < new_coin_val + check_stack; i++) {
              if (startPoint > 9) {
                startPoint = 0;
                newColumn++;
              }
              if (this.debt_offset_setter)
                this.SetCoin_Position(
                  this.currency_debt,
                  i + 1,
                  startPoint,
                  newColumn,
                  -35,
                  false,
                  45.5,
                  coin_spacing,
                  this.debt_offset_setter
                );
              else
                this.SetCoin_Position(
                  this.currency_debt,
                  i + 1,
                  startPoint,
                  newColumn,
                  57,
                  false,
                  45.5,
                  coin_spacing,
                  this.debt_offset_setter
                );

              startPoint++;
            }

            this.$refs["stack_1"].style.top = 47 - check_stack * 4.8 + "px";
            this.$refs["stack_1"].style.left = 28 + "px";
            this.$refs["stack_1"].style.color = "black";
            this.coin_indicator_amount[0] = check_stack * 20;
          } else {
            is_cash_stack = false;
            this.Initialize_Coin_Standard(
              this.currency_debt,
              new_coin_val,
              coin_spacing
            );
          }
          break;
        // For tax and dividents : cash becomes stack when value > 10
        case 10:
          if (new_coin_val > 20) {
            newColumn = 1;
            is_cash_stack = true;
            var check_stack = 1;
            for (let i = 0; i < check_stack; i++) {
              this.SetCoin_Position(
                this.currency_cash_bundle,
                i + 1,
                i,
                0,
                12,
                true,
                45.5,
                coin_spacing
              );
            }

            this.$refs["stack_1"].style.top = 47 - check_stack * 4.8 + "px";
            this.$refs["stack_1"].style.left = 28 + "px";
            this.$refs["stack_1"].style.color = "black";
            this.coin_indicator_amount[0] = new_coin_val;

            this.$refs["stack_2"].style.opacity = 0;
            this.$refs["stack_3"].style.opacity = 0;
            this.$refs["stack_4"].style.opacity = 0;
            this.$refs["stack_5"].style.opacity = 0;
            this.$refs["stack_6"].style.opacity = 0;
          } else {
            is_cash_stack = false;
            this.Initialize_Coin_Standard(
              this.currency_cash,
              new_coin_val,
              coin_spacing
            );
            this.$refs["stack_2"].style.opacity = 1;
            this.$refs["stack_3"].style.opacity = 1;
            this.$refs["stack_4"].style.opacity = 1;
            this.$refs["stack_5"].style.opacity = 1;
            this.$refs["stack_6"].style.opacity = 1;
          }
          break;
        case 11:
        if (new_coin_val > 10) {
            newColumn = 1;
            is_cash_stack = true;
            var check_stack = 1;
            for (let i = 0; i < check_stack; i++) {
              this.SetCoin_Position(
                this.currency_debt_bundle,
                i + 1,
                i,
                0,
                12,
                true,
                45.5,
                coin_spacing
              );
            }

            this.$refs["stack_1"].style.top = 47 - check_stack * 4.8 + "px";
            this.$refs["stack_1"].style.left = 46 + "px";
            this.$refs["coin_1"].style.left = 30 + 'px';
            this.$refs["stack_1"].style.color = "black";
            this.coin_indicator_amount[0] = new_coin_val;

            this.$refs["stack_2"].style.opacity = 0;
            this.$refs["stack_3"].style.opacity = 0;
            this.$refs["stack_4"].style.opacity = 0;
            this.$refs["stack_5"].style.opacity = 0;
            this.$refs["stack_6"].style.opacity = 0;
          } else {
            is_cash_stack = false;
            this.Initialize_Coin_Standard(
              this.currency_debt,
              new_coin_val,
              coin_spacing
            );
            this.$refs["stack_2"].style.opacity = 1;
            this.$refs["stack_3"].style.opacity = 1;
            this.$refs["stack_4"].style.opacity = 1;
            this.$refs["stack_5"].style.opacity = 1;
            this.$refs["stack_6"].style.opacity = 1;
          }
        default:
          break;
      }
      if (new_coin_val < 11 && is_cash_stack == false)
        this.SetIndicator_Single(new_coin_val);
      else
        this.SetIndicator_Multi(
          new_coin_val,
          coin_spacing - 1,
          is_cash_stack,
          this.debt_offset_setter
        );
    },
    Initialize_Coin_Standard(coin_type, coin_value, coin_spacing) {
      var startPoint = 0;
      var newColumn = 0;

      var coin_width = 0;
      if (this.is_trainer_view) {
        coin_width = 42;
        coin_spacing -= 8;
      } else {
        coin_width = 50.5;
      }

      if (coin_value < 11) {
        for (let i = 0; i < coin_value; i++) {
          this.SetCoin_Position(
            coin_type,
            i + 1,
            i,
            0,
            null,
            false,
            coin_width
          );
        }
      } else {
        for (let i = 0; i < coin_value; i++) {
          if (startPoint > 9) {
            startPoint = 0;
            newColumn++;
          }
          this.SetCoin_Position(
            coin_type,
            i + 1,
            startPoint,
            newColumn,
            12.5,
            false,
            coin_width,
            coin_spacing
          );
          startPoint++;
        }
      }
    },
    SetCoin_Position(
      currency_type,
      coin_number,
      this_coin_stack,
      column,
      offset,
      support_cash_stack,
      coin_size,
      spacing = 40.5,
      debt_offset = false
    ) {
      this.$refs["coin_" + coin_number].src = currency_type;

      if (this.$refs["coin_" + coin_number]?.style) {
        this.$refs["coin_" + coin_number].style.opacity = 1;
      }

      var stack_size = 0;
      var stack_top = 0;

      if (this.is_trainer_view) {
        stack_size = 70;
        stack_top = 47;
      } else {
        stack_size = 80;
        stack_top = 40;
      }

      if (offset == null) this.$refs["coin_" + coin_number].style.left = offset;
      else if (offset && !support_cash_stack)
        this.$refs["coin_" + coin_number].style.left =
          offset + column * spacing + "px";
      else if (offset && support_cash_stack)
        this.$refs["coin_" + coin_number].style.left = offset + "px";

      if (support_cash_stack) {
        this.$refs["coin_" + coin_number].style.top =
          stack_top - 5 * this_coin_stack + "px";
        // this.$refs['coin_' + coin_number].style.width = 55 + "%"
        // this.$refs['coin_' + coin_number].style.width = 60 + "px"
        this.$refs["coin_" + coin_number].style.width = stack_size + "px";
      } else {
        if (debt_offset)
          this.$refs["coin_" + coin_number].style.top =
            160 - 5 * this_coin_stack + "px";
        else
          this.$refs["coin_" + coin_number].style.top =
            this.adjustHeight - 5 * this_coin_stack + "px";

        this.$refs["coin_" + coin_number].style.width = coin_size + "px";
      }
    },
    SetIndicator_Single(amount) {
      if (amount != 0) {
        this.Create_Indicator(1, amount, 0, "Single");
        this.coin_indicator_amount[0] = amount;
      }
    },
    SetIndicator_Multi(
      amount,
      coinWidth,
      support_cash_stack,
      debt_offset = false
    ) {
      var currentStack = 0;
      var newColumn = 1;
      if (support_cash_stack) newColumn = 2;

      for (let i = 0; i < amount; i++) {
        if (currentStack > 9) {
          this.coin_indicator_amount[newColumn - 1] = currentStack;
          if (support_cash_stack)
            this.Create_Indicator(
              newColumn,
              currentStack,
              coinWidth,
              "Combined",
              debt_offset
            );
          else
            this.Create_Indicator(
              newColumn,
              currentStack,
              coinWidth,
              "Multiple",
              debt_offset
            );

          newColumn++;
          currentStack = 0;
        }
        currentStack++;
      }

      if (currentStack != 0) {
        this.coin_indicator_amount[newColumn - 1] = currentStack;
        if (support_cash_stack)
          this.Create_Indicator(
            newColumn,
            currentStack,
            coinWidth,
            "Combined",
            debt_offset
          );
        else
          this.Create_Indicator(
            newColumn,
            currentStack,
            coinWidth,
            "Multiple",
            debt_offset
          );
      }
    },
    Create_Indicator(
      which_stack,
      amount,
      coinWidth,
      type,
      debt_offset = false
    ) {
      var newCoinWidth = 0;
      var left_multiple = 0;
      var left_combined = 0;

      if (this.is_trainer_view) {
        left_multiple = 12;
        left_combined = 61;
        newCoinWidth = 45;
      } else {
        left_multiple = 15;
        left_combined = 54;
        newCoinWidth = coinWidth;
      }

      if (debt_offset) {
        if (type == "Combined")
          this.$refs["stack_" + which_stack] !== undefined ?
          this.$refs["stack_" + which_stack].style.top =
            156 - 5 * (amount + 3) + "px" : ''
        else if (type == "Single" || type == "Multiple")
          this.$refs["stack_" + which_stack] !== undefined ?
          this.$refs["stack_" + which_stack].style.top =
            this.adjustHeight - 3 - 5 * (amount + 3) + "px" : ''
      } else
        this.$refs["stack_" + which_stack] == undefined ?
          ""
          : (this.$refs["stack_" + which_stack].style.top =
              this.adjustHeight - 3 - 5 * (amount + 3) + "px")

      switch (type) {
        //! Non cash stack and below 10 coins
        case "Single":
          this.$refs["stack_" + which_stack].style.left = null;
          break;
        //! Non cash stack and above 10 coins
        case "Multiple":
          this.$refs["stack_" + which_stack].style.left =
            left_multiple + (which_stack - 1) * newCoinWidth + "px";
          break;
        // For cash stack
        case "Combined":
          if (debt_offset)
            this.$refs["stack_" + which_stack] !== undefined ? 
            this.$refs["stack_" + which_stack].style.left =
              -39 + (which_stack - 1) * newCoinWidth + "px" : ''
          else
            this.$refs["stack_" + which_stack] !== undefined ? 
            this.$refs["stack_" + which_stack].style.left =
              left_combined + (which_stack - 1) * newCoinWidth + "px" : ''
          break;
      }

      this.$refs["stack_" + which_stack] !== undefined ? 
      this.$refs["stack_" + which_stack].style.color = "black" : ''
    },
  },
};
</script>

<style>
.Coin_Indicator {
  position: absolute;
  height: 100%;
  width: 100%;
}
.Allign_Coin {
  bottom: 50%;
  right: 30%;
  position: absolute;
  width: 40%;
}
.Coin_Amount {
  right: 30%;
  position: absolute;
  margin-top: 2vh;
  width: 40%;
  height: 15%;

  font-size: 18px;
  z-index: 9;
}
</style>