import { Model } from '@vuex-orm/core';

export default class TeamData extends Model {
  static entity = 'team_data';

  static fields() {
    return {
      id: this.attr(null),
      team_id: this.attr(null),
      training_session_data_id: this.attr(null),

      period: this.attr(null),
      phase_step: this.attr(null),

      data: this.attr(null),
      state: this.attr(null),

      operational_plan: this.attr(null),
      cashflow_forecast: this.attr(null),
      income_statement_data: this.attr(null),
      balance_sheet_data: this.attr(null),

      marketing_data: this.attr(null),

      delivery_statement: this.attr(null),
      income_statement: this.attr(null),
      balance_sheet: this.attr(null),

      moving_balance_sheet: this.attr(null),
      summary: this.attr(null),

      created_at: this.attr(null),
      updated_at: this.attr(null),

      performance_analysis: this.attr(null),
      invest: this.attr(null),
    };
  }
}
